import { useTheme } from "@emotion/react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Collapse,
  Drawer,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
  SnackbarContent,
  Switch,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Client } from "@stomp/stompjs";
import { jwtDecode } from "jwt-decode";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { receiveNewMail, receiveNewTicket } from "../../common/CommonActions";
import NotificationsMenu from "../../common/NotificationsMenu";
import {
  CreateNewBookingListMobile,
  LogoutListMobile,
  ProfileListMobile,
  SideMenuLinksMobile,
} from "../../common/SideMenuLink";
import { SETTINGS } from "../../properties/Settings";
import { AuthRepository } from "../../repositories/AuthRepository";
import { NotificationsRepository } from "../../repositories/NotificationsRepository";
import { TicketRepository } from "../../repositories/TicketRepository";
import NotificationSettings from "../settings/NotificationSettings";
import { alpha } from "@mui/system";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
        ...theme.applyStyles("dark", {
          backgroundColor: "#8796A5",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#003892",
    }),
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#aab4be",
    borderRadius: 20 / 2,
    ...theme.applyStyles("dark", {
      backgroundColor: "#8796A5",
    }),
  },
}));
export default function HeaderMobile({
  setOpen,
  open,
  setTypographyFontSize,
  typographyFontSize,
  setH6FontSize,
  setH5FontSize,
  setH4FontSize,
  h6FontSize,
  h5FontSize,
  h4FontSize,
  themeMode,
  setThemeMode,
}) {
  const [redirectTo, setRedirectTo] = React.useState();
  const location = useLocation();
  const globalState = useSelector((state) => state);
  const [currentUser, setCurrentUser] = React.useState();
  const [notifications, setNotifications] = React.useState([]);
  const [unreadNotifications, setUnreadNotifications] = React.useState([]);
  const subscriptionNotification = React.useRef();
  const clientNotification = React.useRef();
  const currentNotifications = React.useRef();
  const theme = useTheme();
  const navigate = useNavigate();
  const [menuLinks, setMenuLinks] = React.useState(SideMenuLinksMobile);
  const [menuLinksDown, setMenuLinksDown] = React.useState(
    CreateNewBookingListMobile
  );
  const [menuLinksProfile, setMenuLinksProfile] =
    React.useState(ProfileListMobile);
  const [menuLinksLogout, setMenuLinksLogout] =
    React.useState(LogoutListMobile);
  const [submenuExpanded, setSubmenuExpanded] = React.useState({
    submenuPath: undefined,
  });
  const [selectedItem, setSelectedItem] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [ticket, setTicket] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [ticketNumber, setTicketNumber] = React.useState("");
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [hasMore, setHasMore] = React.useState(true);
  const notificationsRef = React.useRef([]);
  const [isFocused, setIsFocused] = React.useState(false);

  const checkActiveTab = (item) => {
    return (
      (window.location.pathname.includes(item.selectedPath) &&
        window.location.pathname !== "/" &&
        item.path !== "/") ||
      (window.location.pathname === item.selectedPath &&
        item.selectedPath === "/")
    );
    return false;
  };

  const getUserInitials = (user) => {
    if (!user) return "";
    const firstNameInitial = user.firstName?.charAt(0)?.toUpperCase() || "";
    const lastNameInitial = user.lastName?.charAt(0)?.toUpperCase() || "";
    return firstNameInitial + lastNameInitial;
  };
  const getUserFullName = (user) => {
    if (!user) return "";
    return `${user.firstName || ""} ${user.lastName || ""}`;
  };

  const getUserEmail = (user) => {
    if (!user) return "";
    return `${user.email || ""}`;
  };

  React.useEffect(() => {
    loadCurrentUser();
  }, []);

  React.useEffect(() => {
    setRedirectTo(undefined);
  }, [location]);

  const loadCurrentUser = () => {
    if (localStorage.getItem("jwt")) {
      setCurrentUser(jwtDecode(localStorage.getItem("jwt")));
      getNotifications(0);
      getUnreadNotifications();
    }
  };

  const getNotifications = (currentPage) => {
    NotificationsRepository.getUnreadPageable(currentPage, size).then((res) => {
      if (currentPage === 0) {
        currentNotifications.current = res.data.content;
        setNotifications(res.data.content);
      } else {
        notificationsRef.current = [
          ...notificationsRef.current,
          ...res.data.content,
        ];
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...res.data.content,
        ]);
      }

      res.data.content.length > 0 ? setHasMore(true) : setHasMore(false);
    });
  };

  const getUnreadNotifications = () => {
    NotificationsRepository.getUnread().then((res) => {
      setUnreadNotifications(res.data);
    });
  };

  const fetchMoreNotifications = () => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      getNotifications(nextPage);
      return nextPage;
    });
  };

  React.useEffect(() => {
    if (isMobile) {
      if (
        currentUser &&
        currentUser.userId &&
        AuthRepository.hasAnyRole(["ROLE_CLIENT"])
      ) {
        if (subscriptionNotification.current) {
          subscriptionNotification.current.unsubscribe();
        }
        const onConnected = () => {
          subscriptionNotification.current =
            clientNotification.current.subscribe(
              `/user/notifications`,
              function (msg) {
                let tmpMsgParsed = JSON.parse(msg.body);

                if (tmpMsgParsed?.newMailNotification) {
                  dispatch(receiveNewMail(tmpMsgParsed));
                }

                if (
                  tmpMsgParsed?.newMessageOnTicketNotification &&
                  currentUser.organization.id ==
                    tmpMsgParsed?.newMessageOnTicketNotification?.organization
                      .id
                ) {
                  dispatch(receiveNewTicket(tmpMsgParsed));
                  enqueueSnackbar(
                    `A new message has been left for ticket ${tmpMsgParsed?.newMessageOnTicketNotification?.ticketNumber}`,
                    {
                      variant: "info",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      action: (key) => (
                        <IconButton
                          onClick={() => closeSnackbar(key)}
                          size="small"
                          style={{ color: "white" }}
                        >
                          <Icon>close</Icon>
                        </IconButton>
                      ),
                      classes: { variantInfo: "rgb(45 152 162)" },
                    }
                  );
                }

                if (
                  tmpMsgParsed?.statusChangedOnTicketNotification &&
                  currentUser.organization.id ==
                    tmpMsgParsed?.statusChangedOnTicketNotification
                      ?.organization.id
                ) {
                  dispatch(receiveNewTicket(tmpMsgParsed));
                  enqueueSnackbar(
                    `Status changed to ${tmpMsgParsed?.statusChangedOnTicketNotification?.status} for ticket ${tmpMsgParsed?.statusChangedOnTicketNotification?.ticketNumber}`,
                    {
                      variant: "info",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      action: (key) => (
                        <IconButton
                          onClick={() => closeSnackbar(key)}
                          size="small"
                          style={{ color: "white" }}
                        >
                          <Icon>close</Icon>
                        </IconButton>
                      ),
                      classes: { variantInfo: "rgb(45 152 162)" },
                    }
                  );
                }
                setNotifications([]);
                setUnreadNotifications([]);
                getNotifications(0);
                getUnreadNotifications();
              },
              { id: currentUser.userId }
            );
        };
        let onDisconnected = () => {
          clientNotification.current.unsubscribe(`/user/notifications`, {
            id: currentUser.userId,
          });
        };
        clientNotification.current = new Client({
          brokerURL: SETTINGS.SOCKET_URL,
          reconnectDelay: 5000,
          heartbeatIncoming: 4000,
          heartbeatOutgoing: 4000,
          connectHeaders: {
            Authorization: `Bearer ${AuthRepository.getToken()}`,
          },
          onConnect: onConnected,
          onDisconnect: onDisconnected,
        });
        clientNotification.current.activate();
      }
    }
  }, [currentUser]);

  const fetchTicketByNumber = async (ticketNumber) => {
    try {
      const response = await TicketRepository.getByTicketNumber(ticketNumber);
      setTicket(response.data);

      navigate(
        `/client/booking/${0}/${15}/${ticketNumber}/${response.data.id}`
      );
      setTicketNumber("");
      setSnackbarOpen(false);
      setError(null);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setTicket(null);
        setError("Ticket not found or not allowed to see");
        setSnackbarMessage(" Ticket not found !");
      } else {
        setSnackbarMessage("Ticket not found !");
        setError("Ticket not found !");
      }
      navigate(
        `/client/bookings/0/15/${JSON.stringify({
          status: "ALL_ACTIVE",
        })}/dateCreated/DESC`
      );
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleLogout = () => {
    AuthRepository.authLogout(currentUser?.userId)
      .then((res) => {
        AuthRepository.logout();
      })
      .catch((err) => {
        AuthRepository.logout();
      });
  };

  return (
    <React.Fragment>
      {redirectTo && <Navigate to={redirectTo} push />}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          display: { xs: "block", md: "none" },
          width: `calc(100% - ${openSidebar ? "230px" : "0px"})`,
          transition: (theme) =>
            theme.transitions.create(["margin", "width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          backgroundColor: theme.palette.background.default,
          boxShadow: (theme) => theme.shadows[2],
        }}
      >
        <Toolbar
          style={{
            minHeight: "63px",
          }}
        >
          <Grid
            container
            style={{
              height: "50px",
              alignItems: "center",
            }}
          >
            <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{
                  position: "fixed",
                  left: "12px",
                  border: "rgba(128, 128, 128, 0.5) 1px solid",
                  borderRadius: "4px",
                  width: "45px",
                  height: "45px",
                }}
                onClick={() => setOpenSidebar(!openSidebar)}
              >
                <MenuIcon
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: "24px",
                  }}
                />
              </IconButton>
            </Grid>

            <Grid item xs={4} style={{ textAlign: "center" }}>
              <img
                src={require("../../assets/img/Asta-Adria-logo.gif")}
                alt="Asta Adria"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {AuthRepository.getUserDetails() && (
                <>
                  <NotificationsMenu
                    notifications={notifications}
                    unreadNotifications={unreadNotifications}
                    getNotifications={getNotifications}
                    getUnreadNotifications={getUnreadNotifications}
                    fetchMoreNotifications={fetchMoreNotifications}
                    hasMore={hasMore}
                    setPage={setPage}
                  />
                </>
              )}
            </Grid>
          </Grid>
          {openSidebar && (
            <Drawer
              variant="permanent"
              open={open}
              sx={{
                display: { xs: "block", md: "none" },
                width: "100%",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: "100%",
                  boxSizing: "border-box",

                  backgroundColor: "#5bc4d0",
                  position: "fixed",
                },
              }}
              PaperProps={{
                sx: {
                  width: 240,
                  flexShrink: 0,
                  "& .MuiDrawer-paper": {
                    width: 240,
                    boxSizing: "border-box",
                    zIndex: (theme) => theme.zIndex.drawer,
                    position: "relative",
                  },
                },
              }}
            >
              <div style={{ flex: 1 }}>
                <Grid container direction="column" style={{ height: "100%" }}>
                  <Grid
                    container
                    style={{
                      height: "50px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid
                      item
                      xs={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <IconButton
                        sx={{
                          position: "absolute",
                          border: "white 1px solid",
                          borderRadius: "4px",
                          left: "12px",
                          width: "50px",
                          height: "50px",
                          top: "12px",
                          zIndex: 1201,
                        }}
                        onClick={() => setOpenSidebar(false)}
                      >
                        <CloseIcon
                          style={{ color: "white", fontSize: "24px" }}
                        />
                      </IconButton>
                    </Grid>

                    <Grid item xs={4} style={{ textAlign: "center" }}>
                      <Icon
                        style={{
                          width: "60px",
                          height: "70px",
                          marginTop: "5px",
                        }}
                      >
                        <svg
                          width="60"
                          height="60"
                          viewBox="0 0 52 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M25.9868 11.8759L22.1345 0.0090402L22.1283 0.00302124L17.6571 13.7865L17.6601 13.7835H3.18902H3.18579L13.2793 21.1281H22.9849L25.9868 11.8759Z"
                            fill="white"
                            style={{ fill: "white", fillOpacity: 1 }}
                          />
                          <path
                            d="M38.7122 21.128L48.8025 13.7865L48.8083 13.7804L34.334 13.7774L34.3402 13.7835L29.8658 -3.05176e-05L26.0138 11.8668L29.0186 21.128H38.7122Z"
                            fill="white"
                            style={{ fill: "white", fillOpacity: 1 }}
                          />
                          <path
                            d="M33.8773 36.106L43.9584 43.4416L43.9675 43.4446L39.4964 29.6611L39.4931 29.6641L51.2 21.1461L38.7572 21.1431L30.8812 26.8749L33.8773 36.106Z"
                            fill="white"
                            style={{ fill: "white", fillOpacity: 1 }}
                          />
                          <path
                            d="M18.1412 36.1451L14.295 47.9909V47.9999L26.0048 39.4819H25.9987L37.7087 47.9999H37.7117L33.8655 36.1481L26.0019 30.4253L18.1412 36.1451Z"
                            fill="white"
                            fillOpacity="0.75"
                            style={{ fill: "white", fillOpacity: 0.75 }}
                          />
                          <path
                            d="M12.5069 29.6642L8.03573 43.4476V43.4507L18.1198 36.1121L21.1188 26.875L13.249 21.1491H0.809146L0.800049 21.1522L12.5098 29.6702L12.5069 29.6642Z"
                            fill="white"
                            style={{ fill: "white", fillOpacity: 1 }}
                          />
                        </svg>
                      </Icon>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <TextField
                        value={ticketNumber || ""}
                        onChange={(e) => setTicketNumber(e.target.value)}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            setOpenSidebar(false);
                            setTicketNumber("");
                            fetchTicketByNumber(e.target.value);
                            setIsFocused(false);
                          }
                        }}
                        style={{
                          width: isFocused ? "200px" : "50px",
                          height: "50px",
                          marginRight: "12px",
                          marginTop: "-5px",
                          transition: "width 0.3s ease",
                        }}
                        variant="outlined"
                        InputProps={{
                          endAdornment: !ticketNumber ? (
                            <InputAdornment position="start">
                              <Icon
                                sx={{
                                  color: "white",
                                  fontSize: "24px",
                                }}
                              >
                                search
                              </Icon>
                            </InputAdornment>
                          ) : null,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "white",
                            },
                            "&:hover fieldset": {
                              borderColor: "white",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "white",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item style={{ marginTop: " 15px" }}>
                    <List>
                      {menuLinks.map(
                        (item, index) =>
                          AuthRepository.hasAnyRole(item.roles) && (
                            <React.Fragment key={index}>
                              {
                                <>
                                  <ListItem
                                    button
                                    key={index}
                                    onClick={() => {
                                      setSelectedItem(item.name);
                                      setOpenSidebar(false);
                                      navigate(item.path);
                                      setSubmenuExpanded(
                                        submenuExpanded.submenuPath == item.path
                                          ? { submenuPath: "" }
                                          : { submenuPath: item.path }
                                      );
                                    }}
                                    style={{
                                      backgroundColor: checkActiveTab(item)
                                        ? "#88d4dc"
                                        : "transparent",
                                      borderRadius: "8px",
                                      transition: "all 0.3s ease",
                                      transform: "scale(0.93)",
                                    }}
                                  >
                                    <ListItemIcon
                                      style={{
                                        minWidth: "40px",
                                        minHeight: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon
                                        sx={{
                                          fontSize: "24px",
                                          color: "white",
                                        }}
                                      >
                                        {item.icon}
                                      </Icon>
                                    </ListItemIcon>
                                    {openSidebar && (
                                      <>
                                        <ListItemText
                                          primary={
                                            <Typography
                                              sx={{
                                                fontWeight: "bold",
                                                color: "white",
                                                fontSize: "24px",
                                              }}
                                            >
                                              {item.name}
                                            </Typography>
                                          }
                                          style={{ marginLeft: "5px" }}
                                        />
                                      </>
                                    )}

                                    {openSidebar &&
                                    item.submenus &&
                                    item.submenus.length > 0 ? (
                                      submenuExpanded.submenuPath ==
                                      item.path ? (
                                        <ListItemIcon
                                          style={{ minWidth: "30px" }}
                                        >
                                          <ExpandLess />
                                        </ListItemIcon>
                                      ) : (
                                        <ListItemIcon
                                          style={{ minWidth: "30px" }}
                                        >
                                          <ExpandMore />
                                        </ListItemIcon>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </ListItem>
                                </>
                              }
                              {item.submenus && item.submenus.length > 0 ? (
                                <Collapse
                                  in={submenuExpanded.submenuPath == item.path}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {item.submenus.map(
                                    (submenu, index) =>
                                      AuthRepository.hasAnyRole(
                                        submenu.roles
                                      ) && (
                                        <>
                                          {submenu.name == "Clients" &&
                                          AuthRepository.getUserDetails()?.organization?.nameEn.includes(
                                            "ROMANIA"
                                          ) ? (
                                            ""
                                          ) : (
                                            <ListItem
                                              button
                                              key={index}
                                              onClick={() =>
                                                navigate(submenu.path)
                                              }
                                              style={{
                                                backgroundColor: checkActiveTab(
                                                  submenu
                                                )
                                                  ? "#d0d0d0"
                                                  : "transparent",

                                                paddingLeft: "20px",
                                                paddingRight: "5px",
                                              }}
                                            >
                                              <ListItemIcon
                                                style={{
                                                  minWidth: "30px",
                                                  minHeight: "25px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Icon>{submenu.icon}</Icon>
                                              </ListItemIcon>
                                              {openSidebar && (
                                                <ListItemText
                                                  primary={submenu.name}
                                                  style={{ marginLeft: "5px" }}
                                                />
                                              )}
                                            </ListItem>
                                          )}
                                        </>
                                      )
                                  )}
                                </Collapse>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          )
                      )}
                    </List>
                  </Grid>
                </Grid>
              </div>

              <div style={{ marginTop: "auto", marginBottom: "-15px" }}>
                <List>
                  {menuLinksProfile.map(
                    (item, index) =>
                      AuthRepository.hasAnyRole(item.roles) && (
                        <React.Fragment key={index}>
                          {" "}
                          {item.name == "Administration" &&
                          AuthRepository.getUserDetails()?.organization?.nameEn.includes(
                            "ROMANIA"
                          ) ? (
                            ""
                          ) : (
                            <>
                              <ListItem>
                                {" "}
                                <NotificationSettings />{" "}
                              </ListItem>
                              <ListItem
                                button
                                key={index}
                                onClick={() => {
                                  navigate(item.path);

                                  setSubmenuExpanded(
                                    submenuExpanded.submenuPath == item.path
                                      ? { submenuPath: "" }
                                      : { submenuPath: item.path }
                                  );
                                }}
                                style={{
                                  backgroundColor: checkActiveTab(item)
                                    ? "#d0d0d0"
                                    : "transparent",
                                  paddingLeft: "20px",
                                  paddingRight: "5px",
                                }}
                              >
                                <ListItemIcon
                                  style={{
                                    minWidth: "30px",
                                    minHeight: "25px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      borderColor: "white",
                                      borderRadius: "4px",
                                      width: "50px",
                                      height: "50px",
                                      minWidth: "30px",
                                      minHeight: "25px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderWidth: "1px",
                                      borderStyle: "solid",
                                      fontSize: "18px",
                                      backgroundColor: "white",
                                      fontWeight: "bold",
                                      color: "#202020",
                                      fontSize: "24px",
                                    }}
                                  >
                                    {getUserInitials(currentUser)}
                                  </div>
                                </ListItemIcon>
                                {openSidebar && (
                                  <>
                                    <ListItemText
                                      primary={
                                        <div>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              color: "white",
                                              opacity: 0.7,
                                              fontSize: "12px",
                                            }}
                                          >
                                            {currentUser?.organization?.nameEn}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              color: "white",
                                              marginTop: "-1px",
                                              marginBottom: "-1px",
                                              fontSize: "14px",
                                            }}
                                          >
                                            <b>
                                              {getUserFullName(currentUser)}
                                            </b>
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              color: "white",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {getUserEmail(currentUser)}
                                          </Typography>
                                        </div>
                                      }
                                      style={{ marginLeft: "5px" }}
                                    />
                                  </>
                                )}
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    style={{
                                      border: "white 1px solid",
                                      borderRadius: "4px",
                                      width: "50px",
                                      height: "50px",
                                      marginRight: "5px",
                                    }}
                                    onClick={() =>{handleLogout();}}
                                  >
                                    <LogoutIcon
                                      style={{
                                        color: "white",
                                        fontSize: "24px",
                                      }}
                                    />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            </>
                          )}
                        </React.Fragment>
                      )
                  )}
                </List>
              </div>

              <List>
                {menuLinksDown.map(
                  (item, index) =>
                    AuthRepository.hasAnyRole(item.roles) && (
                      <React.Fragment key={index}>
                        {" "}
                        {item.name == "Administration" &&
                        AuthRepository.getUserDetails()?.organization?.nameEn.includes(
                          "ROMANIA"
                        ) ? (
                          ""
                        ) : (
                          <>
                            <ListItem
                              button
                              key={index}
                              onClick={() => {
                                navigate(item.path);
                                setOpenSidebar(false);
                                setSubmenuExpanded(
                                  submenuExpanded.submenuPath == item.path
                                    ? { submenuPath: "" }
                                    : { submenuPath: item.path }
                                );
                              }}
                              style={{
                                backgroundColor: "#338e9b",
                                borderRadius: "8px",
                                transition: "all 0.3s ease",
                                transform: "scale(0.93)",
                                paddingLeft: "20px",
                                paddingRight: "5px",
                              }}
                            >
                              <ListItemIcon
                                style={{
                                  minWidth: "30px",
                                  minHeight: "25px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Icon
                                  sx={{
                                    color: "white",
                                    fontSize: "24px",
                                  }}
                                >
                                  {item.icon}
                                </Icon>
                              </ListItemIcon>
                              {openSidebar && (
                                <>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color: "white",
                                          fontSize: "24px",
                                        }}
                                      >
                                        {item.name}
                                      </Typography>
                                    }
                                    style={{ marginLeft: "5px" }}
                                  />
                                </>
                              )}
                              {open &&
                              item.submenus &&
                              item.submenus.length > 0 ? (
                                submenuExpanded.submenuPath == item.path ? (
                                  <ListItemIcon style={{ minWidth: "30px" }}>
                                    <ExpandLess />
                                  </ListItemIcon>
                                ) : (
                                  <ListItemIcon style={{ minWidth: "30px" }}>
                                    <ExpandMore />
                                  </ListItemIcon>
                                )
                              ) : (
                                ""
                              )}
                            </ListItem>
                          </>
                        )}
                        {item.submenus && item.submenus.length > 0 ? (
                          <Collapse
                            in={submenuExpanded.submenuPath == item.path}
                            timeout="auto"
                            unmountOnExit
                          >
                            {item.submenus.map(
                              (submenu, index) =>
                                AuthRepository.hasAnyRole(submenu.roles) && (
                                  <>
                                    {submenu.name == "Clients" &&
                                    AuthRepository.getUserDetails()?.organization?.nameEn.includes(
                                      "ROMANIA"
                                    ) ? (
                                      ""
                                    ) : (
                                      <ListItem
                                        button
                                        key={index}
                                        onClick={() => navigate(submenu.path)}
                                        style={{
                                          backgroundColor: checkActiveTab(
                                            submenu
                                          )
                                            ? "#d0d0d0"
                                            : "transparent",

                                          paddingLeft: "20px",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        <ListItemIcon
                                          style={{
                                            minWidth: "30px",
                                            minHeight: "25px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Icon>{submenu.icon}</Icon>
                                        </ListItemIcon>
                                        {open && (
                                          <ListItemText
                                            primary={submenu.name}
                                            style={{ marginLeft: "5px" }}
                                          />
                                        )}
                                      </ListItem>
                                    )}
                                  </>
                                )
                            )}
                          </Collapse>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    )
                )}
              </List>
            </Drawer>
          )}

          <Typography sx={{ flexGrow: 1 }}></Typography>
        </Toolbar>
      </AppBar>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: " #d93535",
          }}
          message={snackbarMessage}
        />
      </Snackbar>
    </React.Fragment>
  );
}
