import { Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export const RegistrationAdministratorForm = ({
  formData,
  handleChangeData,
  email,
  cities,
  countries,
  formDataErrors,
}) => {

  return (
    <>
      <Grid item xs={12} md={12}>
        <TextField
          name="email"
          value={formData?.email ? formData?.email : ""}
          disabled={true}
          label="Your username &amp; email address"
          fullWidth
          size="small"
          error={formDataErrors?.email}
          helperText={formDataErrors?.email}
        />
        
      </Grid>
    
      <Grid item xs={12} md={12}>
        <TextField
          value={formData?.firstName}
          onChange={(e) => {
            handleChangeData("firstName", e.target.value);
          }}
          name="firstName"
          error={formDataErrors?.firstName}
          helperText={formDataErrors?.firstName}
          label="First name"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          value={formData?.lastName}
          onChange={(e) => {
            handleChangeData("lastName", e.target.value);
          }}
          name="lastName"
          error={formDataErrors?.lastName}
          helperText={formDataErrors?.lastName}
          label="Last name"
          fullWidth
          size="small"
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <TextField
          value={formData?.password}
          onChange={(e) => {
            handleChangeData("password", e.target.value);
          }}
          name="password"
          error={formDataErrors?.password}
          helperText={formDataErrors?.password}
          label="Password"
          type="password"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          value={formData?.confirmPassword}
          onChange={(e) => {
            handleChangeData("confirmPassword", e.target.value);
          }}
          error={formDataErrors?.confirmPassword}
          helperText={formDataErrors?.confirmPassword}
          label="Confirm password"
          name="confirmPassword"
          type="password"
          fullWidth
          size="small"
        />
      </Grid>
    </>
  );
};
