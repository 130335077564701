import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const VehicleCategoriesRepository = {
  fetchAll: (payload) => {
    return Axios({
      url: SETTINGS.API_URL + "vehicleCategories",
      method: "GET",
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        searchParams: payload.searchParams,
      },
    });
  },

  fetchAllWithoutPaging: () => {
    return Axios({
      url: SETTINGS.API_URL + "vehicleCategories/all",
      method: "GET",
    });
  },
  getAllVehicleCategoriesByMainDestination: (fromCity, toCity) => {
    return Axios({
      url: `${SETTINGS.API_URL}vehicleCategories/allWithMainDestination`,
      method: "GET",
      params: {
        fromCity: fromCity,
        toCity: toCity,
      },
    });
  },

  allDefault: () => {
    return Axios.get(`${SETTINGS.API_URL}vehicleCategories/allDefault`);
  },

  get: (id) => {
    return Axios.get(`${SETTINGS.API_URL}vehicleCategories/${id}`);
  },

  create: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}vehicleCategories`,
      method: "POST",
      data: data,
    });
  },

  update: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}vehicleCategories`,
      method: "PUT",
      data: data,
    });
  },

  delete: (id) => {
    return Axios.delete(`${SETTINGS.API_URL}vehicleCategories/${id}`);
  },
};
