import { useTheme } from "@emotion/react";
import {
  DialogTitle,
  IconButton,
  Icon,
  DialogContent,
  DialogContentText,
  Grid,
  DialogActions,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { DateRangePicker } from "react-date-range";

export function DepartureDateCalendar({
  setOpenDatePicker,
  handleChangeDateRange,
  state,
  setState,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginTop: "10px", marginRight: "10px" }}>
          <IconButton size="small" onClick={() => setOpenDatePicker(false)}>
            <Icon>close</Icon>
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              handleChangeDateRange();
              setOpenDatePicker(false);
            }}
          >
            <Icon>search</Icon>
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <DialogContentText>
          <Grid item xs={2} md={2}>
            <>
              <DateRangePicker
                onChange={(item) => setState([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={isSmallScreen ? 1 : 2}
                ranges={state}
                direction={isSmallScreen ? "vertical" : "horizontal"}
                preventSnapRefocus={true}
                calendarFocus="backwards"
              />
            </>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions />
    </>
  );
}
