import {
  Alert,
  alpha,
  Autocomplete,
  Box,
  Chip,
  Divider,
  Grid,
  Icon,
  IconButton,
  Paper,
  Popover,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useTheme } from "@emotion/react";
import EastIcon from "@mui/icons-material/East";
import ReactCountryFlag from "react-country-flag";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TicketRepository } from "../../repositories/TicketRepository";
export default function AvailableDeparturesCalendarMobile({
  availableDepartures,
  data,
  handleChangeData,
  selectedDeparture,
  setSelectedDeparture,
  isDisabled,
  setIsDisabled,
  handleChangeSearchParams,
  handleChangeDataEdit,
  blankoTickets,
  checkForMissingSurchargeDisableEdit,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editedSalesPrice, setEditedSalesPrice] = useState();
  const [groupedBlankoTickets, setGroupedBlankoTickets] = useState([]);
  const [foundDepartures, setFoundDepartures] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment(data?.departureDay));
  const [surchargesTotal, setSurchargesTotal] = useState();
  const ticketSurchargeList = selectedDeparture?.ticketSurchargeList || [];
  const theme = useTheme();
  const monthHeader = currentDate.format("MMMM");
  const yearHeader = currentDate.format("YYYY");
  const params = useParams();
  const navigate = useNavigate();
  const [departuresLoading, setDeparturesLoading] = useState(false);
  const selectedDate = moment(data?.departureDay)?.format(
    "YYYY-MM-DD HH:mm:ss"
  );

  React.useEffect(() => {
    if (blankoTickets) {
      // Create an object with productDestinationId as the key and counts as the value
      const groupedCounts = blankoTickets?.reduce((counts, ticket) => {
        const productDestinationId = ticket?.productDestination?.id;
        const departureHour =
          ticket?.productDestinationDeparture?.departureHour; // Assuming departureHour is a property in your ticket object

        // Create a unique key combining productDestinationId and departureHour
        const key = `${productDestinationId}-${departureHour}`;

        // Increment the count for the unique key
        counts[key] = (counts[key] || 0) + 1;
        return counts;
      }, {});

      // Update the state with the new groupedCounts object
      setGroupedBlankoTickets(groupedCounts);
    }
  }, [blankoTickets]);

  useEffect(() => {
    let totalSurcharges = selectedDeparture?.ticketSurchargeList?.reduce(
      (sum, surcharge) => {
        return sum + surcharge.total;
      },
      0
    );
    setSurchargesTotal(totalSurcharges);
    handleChangeData("adjustedCalculatedPrice", undefined);
  }, [selectedDeparture]);

  const handleDateChange = (date) => {
    console.log("handleDateChange", date);
    handleChangeData(
      "departureDay",
      moment(date)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .utc(true)
    );
    if (typeof handleChangeDataEdit === "function") {
      handleChangeDataEdit(
        "departureDay",
        moment(date)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .utc(true)
      );
    }
    setSelectedDeparture(undefined);
  };

  const getAvailableDeparturesOneFormTicket = async (date) => {
    setDeparturesLoading(true);
    try {
      setFoundDepartures();
      const res = await TicketRepository.getAvailableDeparturesOneFormTicket({
        organizationId: data?.organization?.id,
        fromCityId: data?.fromCity?.id,
        toCityId: data?.toCity?.id,
        vehicleLength: data?.vehicleLength,
        cargoWeight: data?.cargoWeight,
        numberOfDrivers: data?.numberOfDrivers,
        loaded: data?.loaded == "2" ? true : false,
        dangerousGoods: data?.dangerousGoods,
        electricPlugin: data?.electricPlugin,
        liveAnimals: data?.liveAnimals,
        departureMonth: moment(date).utc().month(),
        selectedPriceListId: data?.selectedPriceListId,
        departureDate: moment(date).format("YYYY-MM-DD"),
        showPrice: data?.organization?.showPrice,
        showSupplier: data?.organization?.showSupplier,
        vehicleWidth: data?.vehicleWidth,
        vehicleHeight: data?.vehicleHeight,
        material: data?.material?.id,
        numberOfAxles: data?.numberOfAxles,
        isComplexProduct: false,
        complexProductId: null,
        departureDay:
          moment(date).utc().day() == 0 ? 7 : moment(date).utc().day(),
        extra: data?.extra,
        commentForCustomer: data?.commentForCustomer,
      });
      setDeparturesLoading(false);
      setFoundDepartures(res.data.body.productAvailableDeparturesDTOs);
      return res.data.body.productAvailableDeparturesDTOs;
    } catch (err) {
      setDeparturesLoading(false);
      setFoundDepartures();
      console.log(err);
      return null;
    }
  };

  const checkClosestNextDate = async (date) => {
    let found = false;
    for (let i = 1; i <= 30; i++) {
      const currentDate = moment(date)
        .add(i, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      const departures = await getAvailableDeparturesOneFormTicket(currentDate);
      if (departures && departures.length > 0) {
        handleDateChange(currentDate);
        found = true;
        return;
      }
    }
    if (!found) {
      handleDateChange(
        moment(date).add(30, "days").format("YYYY-MM-DD HH:mm:ss")
      );
    }
  };

  const checkClosestPreviousDate = async (date) => {
    let found = false;
    for (let i = 1; i <= 30; i++) {
      const currentDate = moment(date)
        .subtract(i, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      const departures = await getAvailableDeparturesOneFormTicket(currentDate);
      if (departures && departures.length > 0) {
        handleDateChange(currentDate);
        found = true;
        return;
      }
    }
    if (!found) {
      handleDateChange(
        moment(date).subtract(30, "days").format("YYYY-MM-DD HH:mm:ss")
      );
    }
  };

  const selectClickHandler = (departure) => {
    console.log("selectClickHandler", departure);
    setSelectedDeparture(departure);
    setEditedSalesPrice(undefined);
    if (typeof checkForMissingSurchargeDisableEdit === "function") {
      checkForMissingSurchargeDisableEdit(departure);
    }
    // handleChangeData("departureHour", departure?.departureTime);
  };

  const checkPlusMonth = () => {
    const futureDate = moment(data.departureDay)
      .clone()
      .add(30, "days")
      .format("YYYY-MM-DD HH:mm:ss");

    handleDateChange(futureDate);
  };

  const checkMinusMonth = () => {
    const previousDate = moment(data.departureDay)
      .clone()
      .subtract(30, "days")
      .format("YYYY-MM-DD HH:mm:ss");

    handleDateChange(previousDate);
  };

  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDateCalendar, setSelectedDateCalendar] = useState(
    data.departureDay ? moment(data.departureDay) : null
  );

  const handleDateChangeCalendar = (newValue) => {
    const date = moment(newValue);
    setSelectedDateCalendar(date);
    handleChangeData("departureDay", date);
    handleChangeSearchParams("date", date);
    setOpenCalendar(false); // Close the picker after selecting a date
  };

  let grandTotal;

  if (surchargesTotal) {
    if (data?.adjustedCalculatedPrice) {
      grandTotal = (
        parseFloat(data.adjustedCalculatedPrice) +
        surchargesTotal -
        (selectedDeparture?.surchargesIncludedInSalesPrice || 0)
      ).toFixed(2);
    } else {
      grandTotal = (
        (selectedDeparture?.salesPrice || 0) +
        surchargesTotal -
        (selectedDeparture?.surchargesIncludedInSalesPrice || 0)
      ).toFixed(2);
    }
  } else {
    grandTotal = data?.adjustedCalculatedPrice
      ? parseFloat(data.adjustedCalculatedPrice).toFixed(2)
      : (selectedDeparture?.salesPrice || 0).toFixed(2);
  }

  return (
    <>
      <Grid container alignItems="center" spacing={2} mb={8} mt={-10}>
        {data?.fromCity && (
          <Grid item xs={5} sx={{ marginLeft: "16px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <ReactCountryFlag
                countryCode={data.fromCity.country.code}
                svg
                style={{
                  width: "1.6em",
                  height: "1.6em",
                  borderRadius: "8px",
                  marginRight: "4px",
                }}
                title={data.fromCity.country.name}
              />
              <span style={{ fontSize: "15px" }}>{data.fromCity.name}</span>
            </Box>
          </Grid>
        )}

        {data?.fromCity && data?.toCity && (
          <Grid item xs={1}>
            <EastIcon />
          </Grid>
        )}

        {data?.toCity && (
          <Grid item xs={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <ReactCountryFlag
                countryCode={data.toCity.country.code}
                svg
                style={{
                  width: "1.6em",
                  height: "1.6em",
                  borderRadius: "8px",
                  marginRight: "4px",
                }}
                title={data.toCity.country.name}
              />
              <span style={{ fontSize: "15px" }}>{data.toCity.name}</span>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        spacing={2}
        mt={-8}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={1} sx={{ marginLeft: "8px" }}>
          <Typography variant="h6" style={{ padding: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Tooltip title="Select Date">
                <IconButton
                  onClick={() => setOpenCalendar(true)}
                  style={{
                    border: "solid 1px ",
                    borderColor: alpha(theme.palette.lightGray.main, 0.4),
                    borderRadius: "4px",
                  }}
                >
                  <CalendarMonthOutlinedIcon
                    style={{ fontSize: "21px", color: "black" }}
                  />
                </IconButton>
              </Tooltip>
              <DatePicker
                open={openCalendar}
                onClose={() => setOpenCalendar(false)}
                value={selectedDateCalendar}
                onChange={handleDateChangeCalendar}
                renderInput={(params) => <input type="hidden" {...params} />}
                sx={{
                  display: "none",
                  position: "absolute",
                  top: "100%",
                  left: "100px",
                  zIndex: 1300,
                }}
              />
            </LocalizationProvider>
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            style={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              ...theme.typography.h6,
            }}
          >
            <IconButton onClick={checkMinusMonth} style={{ paddingRight: 10 }}>
              <ArrowBackIosNewIcon style={{ fontSize: "14px" }} />
            </IconButton>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {monthHeader}
            </span>
            <IconButton onClick={checkPlusMonth} style={{ paddingLeft: 10 }}>
              <ArrowForwardIosOutlinedIcon style={{ fontSize: "14px" }} />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography variant="h6" style={{ marginTop: "3px" }}>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {" "}
              {yearHeader}
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ marginLeft: "16px", marginRight: "16px" }}>
        <Table
          elevation={1}
          style={{
            borderCollapse: "separate",
            borderSpacing: " 0 10px",
            marginTop: "-10px",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={20}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "96%",
                    borderBottom: `3px solid ${alpha(
                      theme.palette.lightGray.main,
                      0.2
                    )}`,
                  }}
                >
                  <Tabs
                    indicatorColor="transparent"
                    value={selectedDate}
                    onChange={(event, value) => {
                      handleDateChange(moment(value));
                      if (typeof setIsDisabled === "function") {
                        setIsDisabled(false);
                      }
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      clipPath: "inset(0 round 0.5em)",
                      position: "relative",
                      zIndex: 1,
                      padding: 0,
                    }}
                  >
                    {[-2, -1, 0, 1, 2].map((i) => {
                      const tabDate = moment(data?.departureDay)
                        ?.clone()
                        ?.add(i, "days")
                        ?.format("YYYY-MM-DD HH:mm:ss");

                      const isSelected = tabDate === selectedDate;
                      const isTwoBefore = i === -2;
                      const isTwoAfter = i === 2;

                      return (
                        <Tab
                          key={i}
                          label={moment(data?.departureDay)
                            ?.clone()
                            ?.add(i, "days")
                            ?.format("D dddd ")}
                          value={tabDate}
                          style={{
                            flex: "1 0 auto",
                            minWidth: 0,
                            color: isSelected
                              ? theme.palette.background.default
                              : isTwoBefore || isTwoAfter
                              ? "gray"
                              : "inherit",
                            opacity: isTwoBefore || isTwoAfter ? 0.8 : "",
                            backgroundColor: isSelected
                              ? theme.palette.primary.main
                              : "transparent",
                            borderRadius: "5px",
                            fontWeight: "bold",
                            height: "48px",
                            borderRight: "2px solid white",
                            opacity: isTwoBefore || isTwoAfter ? 0.8 : 1,
                            borderRadius: "0.5em",
                            margin: "0",
                            padding: "9px",
                            display: "flex",
                            fontSize: isSelected ? "11px" : "9px",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availableDepartures && availableDepartures.length > 0 ? (
              availableDepartures
                .slice()
                .sort((a, b) => {
                  const levelOrder = {
                    LOW: 1,
                    MEDIUM: 2,
                    HIGH: 3,
                  };
                  if (levelOrder[a.levelValue] !== levelOrder[b.levelValue]) {
                    return levelOrder[a.levelValue] - levelOrder[b.levelValue];
                  }

                  if (a.supplier?.id === b.supplier?.id) {
                    return (
                      new Date(`${a.departureDate}T${a.departureTime}`) -
                      new Date(`${b.departureDate}T${b.departureTime}`)
                    );
                  }

                  return a.supplier.id - b.supplier.id;
                })
                .map((departure, index) => (
                  <React.Fragment key={index}>
                    {departure?.productDestinationDepartureId ===
                    selectedDeparture?.productDestinationDepartureId ? (
                      // Render additional grid when this departure is selected
                      <TableRow
                        key={departure.productDestinationDepartureId}
                        style={{
                          height: "48px",
                          borderSpacing: "0 10px",
                          borderLeft: "0",
                          borderRight: "0",
                        }}
                      >
                        {/* Your additional grid content here */}
                        <TableCell colSpan={12} style={{ border: "none" }}>
                          <Grid container direction="column" spacing={2} mb={2}>
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  backgroundColor: "#88c078",
                                  color: "white",
                                  border: "none",
                                  boxShadow: "none",
                                  position: "relative",
                                  padding: "0 10px",
                                  borderRadius: "6px 6px  0 0",
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "#88c078",
                                    color: "white",
                                    padding: "0 15px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                      <Typography sx={{ opacity: "50%" }}>
                                        Departure
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          fontSize: "14px",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        {selectedDeparture?.departureTime}
                                      </Typography>
                                      <Typography
                                        style={{
                                          marginTop: "-5px",
                                        }}
                                      >
                                        {moment(
                                          selectedDeparture?.departureDate
                                        ).format("DD.MM.YYYY")}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      sx={{
                                        alignContent: "center",
                                        marginLeft: "-15px",
                                      }}
                                    >
                                      <Typography variant="h5">
                                        <EastIcon
                                          style={{ fontSize: "24px" }}
                                        />
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          opacity: "50%",
                                          marginLeft: "50px",
                                        }}
                                      >
                                        Arrival
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          marginLeft: "50px",
                                          fontSize: "14px",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        {selectedDeparture?.arrivalTime}
                                      </Typography>
                                      <Typography
                                        style={{
                                          marginLeft: "50px",
                                          marginTop: "-5px",
                                        }}
                                      >
                                        {selectedDeparture?.arrivalDay == "0"
                                          ? moment(
                                              selectedDeparture?.departureDate
                                            ).format("DD.MM.YYYY")
                                          : selectedDeparture?.arrivalDay == "1"
                                          ? moment(
                                              selectedDeparture?.departureDate
                                            )
                                              .add("days", 1)
                                              .format("DD.MM.YYYY")
                                          : selectedDeparture?.arrivalDay == "2"
                                          ? moment(
                                              selectedDeparture?.departureDate
                                            )
                                              .add("days", 2)
                                              .format("DD.MM.YYYY")
                                          : ""}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                      <Icon
                                        style={{
                                          verticalAlign: "bottom",
                                          fontSize: "30px",
                                          marginTop: "5px",
                                        }}
                                        color="white"
                                      >
                                        radio_button_checked
                                      </Icon>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    container
                                    style={{
                                      marginTop: "15px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        marginLeft: "-5px",
                                        marginTop: "3px",
                                      }}
                                    >
                                      <Tooltip title={departure?.level}>
                                        {departure?.levelValue === "MEDIUM" && (
                                          <Icon
                                            style={{
                                              verticalAlign: "middle",
                                              height: "24px",
                                              width: "24px",
                                            }}
                                          >
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g clipPath="url(#clip0_504_7214)">
                                                <rect
                                                  x="5.50012"
                                                  y="11"
                                                  width="3"
                                                  height="6"
                                                  rx="1"
                                                  fill="#E9B145"
                                                  style={{
                                                    fill: "#E9B145",
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="10.5001"
                                                  y="9"
                                                  width="3"
                                                  height="8"
                                                  rx="1"
                                                  fill="#E9B145"
                                                  style={{
                                                    fill: "#E9B145",
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="15.5001"
                                                  y="7"
                                                  width="3"
                                                  height="10"
                                                  rx="1"
                                                  fill="#E9B145"
                                                  fillOpacity="0.25"
                                                  style={{
                                                    fill: "#E9B145",
                                                    fill: "white",
                                                    fillOpacity: 0.25,
                                                  }}
                                                />
                                              </g>
                                              <rect
                                                x="0.500122"
                                                y="0.5"
                                                width="23"
                                                height="23"
                                                rx="3.5"
                                                stroke="#E9B145"
                                                style={{
                                                  stroke: "#E9B145",
                                                  stroke: "white",
                                                  strokeOpacity: 1,
                                                }}
                                              />
                                              <defs>
                                                <clipPath id="clip0_504_7214">
                                                  <rect
                                                    x="0.00012207"
                                                    width="24"
                                                    height="24"
                                                    rx="4"
                                                    fill="white"
                                                    style={{
                                                      fill: "white",
                                                      fillOpacity: 1,
                                                    }}
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </Icon>
                                        )}
                                      </Tooltip>
                                      <Tooltip title={departure?.level}>
                                        {" "}
                                        {departure?.levelValue === "HIGH" && (
                                          <Icon
                                            style={{
                                              verticalAlign: "middle",
                                              height: "24px",
                                              width: "24px",
                                            }}
                                          >
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g clipPath="url(#clip0_504_7228)">
                                                <rect
                                                  x="5.50012"
                                                  y="11"
                                                  width="3"
                                                  height="6"
                                                  rx="1"
                                                  fill="white"
                                                  style={{
                                                    fill: "white",
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="10.5001"
                                                  y="9"
                                                  width="3"
                                                  height="8"
                                                  rx="1"
                                                  fill="white"
                                                  style={{
                                                    fill: "white",
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="15.5001"
                                                  y="7"
                                                  width="3"
                                                  height="10"
                                                  rx="1"
                                                  fill="white"
                                                  style={{
                                                    fill: "white",
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                              </g>
                                              <rect
                                                x="0.500122"
                                                y="0.5"
                                                width="23"
                                                height="23"
                                                rx="3.5"
                                                stroke="white"
                                                style={{
                                                  stroke: "white",
                                                  stroke: "white",
                                                  strokeOpacity: 1,
                                                }}
                                              />
                                              <defs>
                                                <clipPath id="clip0_504_7228">
                                                  <rect
                                                    x="0.00012207"
                                                    width="24"
                                                    height="24"
                                                    rx="4"
                                                    fill="white"
                                                    style={{
                                                      fill: "white",
                                                      fillOpacity: 1,
                                                    }}
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </Icon>
                                        )}
                                      </Tooltip>
                                      <Tooltip title={departure?.level}>
                                        {departure?.levelValue === "LOW" && (
                                          <Icon
                                            style={{
                                              verticalAlign: "middle",
                                              height: "24px",
                                              width: "24px",
                                            }}
                                          >
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g clipPath="url(#clip0_504_7314)">
                                                <rect
                                                  x="5.5"
                                                  y="11"
                                                  width="3"
                                                  height="6"
                                                  rx="1"
                                                  fill="white"
                                                  style={{
                                                    fill: "white",
                                                    fillOpacity: 1,
                                                  }}
                                                />
                                                <rect
                                                  x="10.5"
                                                  y="9"
                                                  width="3"
                                                  height="8"
                                                  rx="1"
                                                  fill="white"
                                                  fillOpacity="0.25"
                                                  style={{
                                                    fill: "white",
                                                    fillOpacity: 0.25,
                                                  }}
                                                />
                                                <rect
                                                  x="15.5"
                                                  y="7"
                                                  width="3"
                                                  height="10"
                                                  rx="1"
                                                  fill="white"
                                                  fillOpacity="0.25"
                                                  style={{
                                                    fill: "white",
                                                    fillOpacity: 0.25,
                                                  }}
                                                />
                                              </g>
                                              <rect
                                                x="0.5"
                                                y="0.5"
                                                width="23"
                                                height="23"
                                                rx="3.5"
                                                stroke="white"
                                                style={{
                                                  stroke: "white",
                                                  strokeOpacity: 1,
                                                }}
                                              />
                                              <defs>
                                                <clipPath id="clip0_504_7314">
                                                  <rect
                                                    width="24"
                                                    height="24"
                                                    rx="4"
                                                    fill="white"
                                                    style={{
                                                      fill: "white",
                                                      fillOpacity: 1,
                                                    }}
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </Icon>
                                        )}
                                      </Tooltip>
                                    </Grid>
                                    <Grid item xs={7}>
                                      {data?.organization?.showSupplier && (
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            whiteSpace: "nowrap",
                                            overflow: "visible",
                                            flexShrink: 0,
                                            fontSize: "14px",
                                            marginLeft: "-12px",
                                          }}
                                        >
                                          {selectedDeparture?.operatorName
                                            ? selectedDeparture?.operatorName
                                            : selectedDeparture?.supplier?.name?.slice(
                                                0,
                                                20
                                              )}
                                        </Typography>
                                      )}
                                      <Typography
                                        sx={{
                                          fontSize: "10px",
                                          color: "white",
                                          marginTop: data?.organization
                                            ?.showSupplier
                                            ? "-7px"
                                            : "7px",
                                          marginLeft: "-12px",
                                        }}
                                      >
                                        {departure?.level}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      {(departure?.priceVisibility ==
                                        "DEFAULT" &&
                                        data?.organization?.showPrice) ||
                                      departure?.priceVisibility == "SHOW" ? (
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "17px",
                                            marginTop: "7px",
                                          }}
                                          align="center"
                                        >
                                          {surchargesTotal ? (
                                            data?.adjustedCalculatedPrice ? (
                                              <>
                                                {(
                                                  parseFloat(
                                                    data?.adjustedCalculatedPrice
                                                  ) +
                                                  surchargesTotal -
                                                  selectedDeparture?.surchargesIncludedInSalesPrice
                                                )?.toFixed(2)}
                                                &euro;
                                              </>
                                            ) : (
                                              <>
                                                {(
                                                  selectedDeparture?.salesPrice +
                                                  surchargesTotal -
                                                  selectedDeparture?.surchargesIncludedInSalesPrice
                                                )?.toFixed(2)}
                                                &euro;
                                              </>
                                            )
                                          ) : data?.adjustedCalculatedPrice ? (
                                            <>
                                              <>
                                                {parseFloat(
                                                  data?.adjustedCalculatedPrice
                                                )?.toFixed(2)}
                                                &euro;
                                              </>
                                            </>
                                          ) : (
                                            <>
                                              {selectedDeparture?.salesPrice?.toFixed(
                                                2
                                              )}
                                              &euro;
                                            </>
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "0 5px 0 20px",
                                  }}
                                >
                                  <Grid container spacing={1}>
                                    <Grid item md={12}>
                                      <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                          backgroundColor:
                                            "rgba(160, 205, 147, 0.1)",
                                          borderRadius: "5px",
                                          minWidth: "285px",
                                          overflow: "hidden",
                                          paddingBottom: "15px",
                                        }}
                                      >
                                        {ticketSurchargeList.map(
                                          (surcharge, index) => (
                                            <Grid
                                              item
                                              xs={12}
                                              key={index}
                                              sx={{
                                                height: "60px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                backgroundColor:
                                                  index % 2 !== 0
                                                    ? "rgba(160, 205, 147, 0.3)"
                                                    : "rgba(160, 205, 147, 0.6)",

                                                padding: "10px 0px 10px 10px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  flex: 1,
                                                  ...theme.typography.h6,
                                                }}
                                              >
                                                {surcharge.surchargeName}
                                              </Typography>
                                              {/* {data?.organization
                                                ?.showPrice && (
                                                <Typography
                                                  sx={{
                                                    marginLeft: "10px",
                                                    flex: 1,
                                                    textAlign: "center",
                                                    ...theme.typography.h6,
                                                  }}
                                                >
                                                  <input
                                                    disabled={
                                                      surcharge?.disabled
                                                    }
                                                    value={
                                                      selectedDeparture?.ticketSurchargeList?.find(
                                                        (s) =>
                                                          s.productSurcharge
                                                            .id ==
                                                          surcharge
                                                            .productSurcharge.id
                                                      )?.quantity
                                                        ? selectedDeparture?.ticketSurchargeList?.find(
                                                            (s) =>
                                                              s.productSurcharge
                                                                .id ==
                                                              surcharge
                                                                .productSurcharge
                                                                .id
                                                          )?.quantity
                                                        : surcharge?.quantity
                                                    }
                                                    onChange={(e) => {
                                                      let newDeparture = {
                                                        ...selectedDeparture,
                                                      };
                                                      let tmpSurcharge =
                                                        newDeparture.ticketSurchargeList.find(
                                                          (s) =>
                                                            s.productSurcharge
                                                              .id ==
                                                            surcharge
                                                              .productSurcharge
                                                              .id
                                                        );
                                                      if (tmpSurcharge) {
                                                        tmpSurcharge.quantity =
                                                          e.target.valueAsNumber;
                                                        tmpSurcharge.total =
                                                          e.target
                                                            .valueAsNumber *
                                                          surcharge.price;
                                                        newDeparture.ticketSurchargeList =
                                                          newDeparture.ticketSurchargeList.filter(
                                                            (s) =>
                                                              s.productSurcharge
                                                                .id !=
                                                              surcharge
                                                                .productSurcharge
                                                                .id
                                                          );
                                                        newDeparture.ticketSurchargeList.push(
                                                          tmpSurcharge
                                                        );
                                                      } else {
                                                        tmpSurcharge = {
                                                          ...surcharge,
                                                        };
                                                        tmpSurcharge.quantity =
                                                          e.target.valueAsNumber;
                                                        tmpSurcharge.total =
                                                          e.target
                                                            .valueAsNumber *
                                                          surcharge.price;
                                                        newDeparture.ticketSurchargeList.push(
                                                          tmpSurcharge
                                                        );
                                                      }
                                                      setSelectedDeparture(
                                                        newDeparture
                                                      );
                                                    }}
                                                    size="small"
                                                    type={"number"}
                                                    style={{
                                                      border: "none",
                                                      backgroundColor:
                                                        "transparent",
                                                      width: "30px",
                                                      ...theme.typography.h6,
                                                      color: "white",
                                                    }}
                                                  />
                                                </Typography>
                                              )}
                                              {data?.organization
                                                ?.showPrice && (
                                                <Typography
                                                  sx={{
                                                    flex: 1,
                                                    marginLeft: "-15px",
                                                    textAlign: "center",
                                                    ...theme.typography.h6,
                                                  }}
                                                >
                                                  x
                                                </Typography>
                                              )}
                                              {data?.organization
                                                ?.showPrice && (
                                                <Typography
                                                  sx={{
                                                    flex: 1,
                                                    textAlign: "center",
                                                    ...theme.typography.h6,
                                                  }}
                                                >
                                                  {surcharge.price?.toFixed(2)}
                                                  &euro;
                                                </Typography>
                                              )}
                                              {data?.organization
                                                ?.showPrice && (
                                                <Typography
                                                  sx={{
                                                    flex: 1,
                                                    textAlign: "center",
                                                    ...theme.typography.h6,
                                                  }}
                                                >
                                                  =
                                                </Typography>
                                              )} */}
                                              {data?.organization
                                                ?.showPrice && (
                                                <Typography
                                                  sx={{
                                                    flex: 1,
                                                    textAlign: "center",
                                                    ...theme.typography.h6,
                                                  }}
                                                >
                                                  {parseFloat(
                                                    surcharge.price *
                                                      surcharge.quantity
                                                  ).toFixed(2)}
                                                  &euro;
                                                </Typography>
                                              )}
                                            </Grid>
                                          )
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    backgroundColor: "#88c078",
                                    color: "white",
                                    border: "none",
                                    boxShadow: "none",
                                    padding: "15px 15px",
                                    borderRadius: "  0 0 6px 6px",
                                  }}
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    justifyContent="center"
                                  >
                                    <Grid item xs={4}>
                                      {(departure?.priceVisibility ==
                                        "DEFAULT" &&
                                        data?.organization?.showPrice) ||
                                      departure?.priceVisibility == "SHOW" ? (
                                        <Typography
                                          align="center"
                                          style={{
                                            marginLeft: "-30px",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Total Freight
                                        </Typography>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                    <Grid item xs={2}>
                                      {(departure?.priceVisibility ==
                                        "DEFAULT" &&
                                        data?.organization?.showPrice) ||
                                      departure?.priceVisibility == "SHOW" ? (
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "11px",
                                            marginLeft: "-37px",
                                            border: "1px solid white",
                                            height: "20px",
                                            width: "60px",
                                            borderRadius: "4px",
                                            backgroundColor: "white",
                                            padding: 1,
                                            color: "#88c078",
                                            // marginTop: 10,
                                          }}
                                          align="center"
                                        >
                                          {data?.adjustedCalculatedPrice ? (
                                            <>
                                              {parseFloat(
                                                data?.adjustedCalculatedPrice
                                              )?.toFixed(2)}{" "}
                                              &euro;
                                            </>
                                          ) : (
                                            <>
                                              {selectedDeparture?.salesPrice?.toFixed(
                                                2
                                              )}
                                              &euro;
                                            </>
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>

                                    <Grid item xs={4}>
                                      {(departure?.priceVisibility ==
                                        "DEFAULT" &&
                                        data?.organization?.showPrice) ||
                                      departure?.priceVisibility == "SHOW" ? (
                                        <Typography
                                          align="center"
                                          style={{
                                            marginLeft: "-7px",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Total Surcharges
                                        </Typography>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                    <Grid item xs={2}>
                                      {(departure?.priceVisibility ==
                                        "DEFAULT" &&
                                        data?.organization?.showPrice) ||
                                      departure?.priceVisibility == "SHOW" ? (
                                        <Typography
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "11px",
                                            marginLeft: "-15px",
                                            border: "1px solid white",
                                            borderRadius: "4px",
                                            height: "20px",
                                            width: "60px",
                                            backgroundColor: "white",
                                            padding: 1,
                                            color: "#88c078",
                                            // marginTop: 10,
                                          }}
                                          align="center"
                                        >
                                          {surchargesTotal ? (
                                            <>
                                              {" "}
                                              {surchargesTotal?.toFixed(2)}
                                              &euro;
                                            </>
                                          ) : (
                                            "/"
                                          )}
                                        </Typography>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                        key={departure.productDestinationDepartureId}
                        style={{
                          backgroundColor:
                            departure?.productDestinationDepartureId ==
                            selectedDeparture?.productDestinationDepartureId
                              ? "#4caf5033"
                              : alpha(theme.palette.lightGray.main, 0.1),
                          height: "48px",
                          borderSpacing: "0 10px",
                          borderLeft: "0",
                          borderRight: "0",
                        }}
                      >
                        <TableCell
                          style={{
                            borderRadius: "10px",
                          }}
                        >
                          {departure?.productDestinationDepartureId !=
                            selectedDeparture?.productDestinationDepartureId && (
                            <Grid container>
                              {isDisabled && (
                                <>
                                  <Grid item xs={1}>
                                    <Icon style={{ color: "#54B4D3" }}>
                                      info
                                    </Icon>
                                  </Grid>
                                  <Grid item xs={11}>
                                    <Typography
                                      style={{
                                        fontSize: "10px",
                                        color: "#54B4D3",
                                        marginLeft: "-5px",
                                      }}
                                    >
                                      Departure has departure hour offset.
                                      Please choose another date.
                                    </Typography>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          )}
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              padding: "10px",
                              alignContent: "center",
                              alignItems: "center",
                              verticalAlign: "center",
                            }}
                          >
                            <Grid
                              container
                              spacing={2}
                              style={{ marginLeft: "1px", marginTop: "1px" }}
                            >
                              <Grid item xs={5} style={{ marginTop: "-8px" }}>
                                <Typography>Departure </Typography>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  marginLeft: "66px",
                                  marginTop: "-8px",
                                }}
                              >
                                <Typography>Arrival</Typography>{" "}
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  marginTop: "-15px",
                                }}
                              >
                                {departure?.productDestinationDepartureId ==
                                  selectedDeparture?.productDestinationDepartureId && (
                                  <Icon
                                    style={{
                                      verticalAlign: "bottom",
                                      fontSize: "30px",
                                      marginLeft: "10px",
                                    }}
                                    color="success"
                                  >
                                    radio_button_checked
                                  </Icon>
                                )}
                                {departure?.productDestinationDepartureId !=
                                  selectedDeparture?.productDestinationDepartureId && (
                                  <>
                                    <IconButton
                                      disabled={isDisabled}
                                      style={{
                                        verticalAlign: "bottom",
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                      }}
                                      color="black"
                                      onClick={() => {
                                        selectClickHandler(departure);
                                      }}
                                    >
                                      <Icon style={{ fontSize: "30px" }}>
                                        radio_button_unchecked
                                      </Icon>
                                    </IconButton>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginLeft: "1px" }}
                            >
                              <Grid item xs={5} style={{ marginTop: "-8px" }}>
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    ...theme.typography.h6,
                                    marginTop: "-15px",
                                  }}
                                >
                                  {departure.departureTime}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} style={{ marginTop: "-8px" }}>
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    ...theme.typography.h6,
                                    marginTop: "-15px",
                                  }}
                                >
                                  <EastIcon
                                    style={{
                                      marginLeft: "-20px",
                                      fontSize: "20px",
                                    }}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  variant="subtitle1"
                                  style={{
                                    ...theme.typography.h6,
                                    marginTop: "-25px",
                                    marginLeft: " 15px",
                                  }}
                                >
                                  {departure.arrivalTime}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginLeft: "1px" }}
                            >
                              <Grid item xs={5}>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    ...theme.typography.h6,
                                    marginTop: "-9px",
                                  }}
                                >
                                  {moment(departure.departureDate).format(
                                    "DD.MM.YY"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    ...theme.typography.h6,
                                    marginTop: "-12px",
                                    marginLeft: "65px",
                                  }}
                                >
                                  {departure.arrivalDay === "0"
                                    ? moment(departure.departureDate).format(
                                        "DD.MM.YY"
                                      )
                                    : departure.arrivalDay === "1"
                                    ? moment(departure.departureDate)
                                        .add(1, "days")
                                        .format("DD.MM.YY")
                                    : departure.arrivalDay === "2"
                                    ? moment(departure.departureDate)
                                        .add(2, "days")
                                        .format("DD.MM.YY")
                                    : ""}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xs={1}
                              style={{ marginRight: "5px", marginTop: "-3px" }}
                            >
                              <Tooltip title={departure?.level}>
                                {departure?.levelValue === "MEDIUM" && (
                                  <Icon
                                    style={{
                                      verticalAlign: "middle",
                                      height: "24px",
                                      width: "24px",
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_504_7214)">
                                        <rect
                                          x="5.50012"
                                          y="11"
                                          width="3"
                                          height="6"
                                          rx="1"
                                          fill="#E9B145"
                                          style={{
                                            fill: "#E9B145",
                                            fill: "color(display-p3 0.9137 0.6941 0.2706)",
                                            fillOpacity: 1,
                                          }}
                                        />
                                        <rect
                                          x="10.5001"
                                          y="9"
                                          width="3"
                                          height="8"
                                          rx="1"
                                          fill="#E9B145"
                                          style={{
                                            fill: "#E9B145",
                                            fill: "color(display-p3 0.9137 0.6941 0.2706)",
                                            fillOpacity: 1,
                                          }}
                                        />
                                        <rect
                                          x="15.5001"
                                          y="7"
                                          width="3"
                                          height="10"
                                          rx="1"
                                          fill="#E9B145"
                                          fillOpacity="0.25"
                                          style={{
                                            fill: "#E9B145",
                                            fill: "color(display-p3 0.9137 0.6941 0.2706)",
                                            fillOpacity: 0.25,
                                          }}
                                        />
                                      </g>
                                      <rect
                                        x="0.500122"
                                        y="0.5"
                                        width="23"
                                        height="23"
                                        rx="3.5"
                                        stroke="#E9B145"
                                        style={{
                                          stroke: "#E9B145",
                                          stroke:
                                            "color(display-p3 0.9137 0.6941 0.2706)",
                                          strokeOpacity: 1,
                                        }}
                                      />
                                      <defs>
                                        <clipPath id="clip0_504_7214">
                                          <rect
                                            x="0.00012207"
                                            width="24"
                                            height="24"
                                            rx="4"
                                            fill="white"
                                            style={{
                                              fill: "white",
                                              fillOpacity: 1,
                                            }}
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </Icon>
                                )}
                              </Tooltip>
                              <Tooltip title={departure?.level}>
                                {" "}
                                {departure?.levelValue === "HIGH" && (
                                  <Icon
                                    style={{
                                      verticalAlign: "middle",
                                      height: "24px",
                                      width: "24px",
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_504_7228)">
                                        <rect
                                          x="5.50012"
                                          y="11"
                                          width="3"
                                          height="6"
                                          rx="1"
                                          fill="#E15D5D"
                                          style={{
                                            fill: "#E15D5D",
                                            fill: "color(display-p3 0.8824 0.3647 0.3647)",
                                            fillOpacity: 1,
                                          }}
                                        />
                                        <rect
                                          x="10.5001"
                                          y="9"
                                          width="3"
                                          height="8"
                                          rx="1"
                                          fill="#E15D5D"
                                          style={{
                                            fill: "#E15D5D",
                                            fill: "color(display-p3 0.8824 0.3647 0.3647)",
                                            fillOpacity: 1,
                                          }}
                                        />
                                        <rect
                                          x="15.5001"
                                          y="7"
                                          width="3"
                                          height="10"
                                          rx="1"
                                          fill="#E15D5D"
                                          style={{
                                            fill: "#E15D5D",
                                            fill: "color(display-p3 0.8824 0.3647 0.3647)",
                                            fillOpacity: 1,
                                          }}
                                        />
                                      </g>
                                      <rect
                                        x="0.500122"
                                        y="0.5"
                                        width="23"
                                        height="23"
                                        rx="3.5"
                                        stroke="#E15D5D"
                                        style={{
                                          stroke: "#E15D5D",
                                          stroke:
                                            "color(display-p3 0.8824 0.3647 0.3647)",
                                          strokeOpacity: 1,
                                        }}
                                      />
                                      <defs>
                                        <clipPath id="clip0_504_7228">
                                          <rect
                                            x="0.00012207"
                                            width="24"
                                            height="24"
                                            rx="4"
                                            fill="white"
                                            style={{
                                              fill: "white",
                                              fillOpacity: 1,
                                            }}
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </Icon>
                                )}
                              </Tooltip>
                              <Tooltip title={departure?.level}>
                                {departure?.levelValue === "LOW" && (
                                  <Icon
                                    style={{
                                      verticalAlign: "middle",
                                      height: "24px",
                                      width: "24px",
                                    }}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_504_7200)">
                                        <rect
                                          x="5.50012"
                                          y="11"
                                          width="3"
                                          height="6"
                                          rx="1"
                                          fill="#88C078"
                                          style={{
                                            fill: "#88C078",
                                            fill: "color(display-p3 0.5333 0.7529 0.4706)",
                                            fillOpacity: 1,
                                          }}
                                        />
                                        <rect
                                          x="10.5001"
                                          y="9"
                                          width="3"
                                          height="8"
                                          rx="1"
                                          fill="#88C078"
                                          fillOpacity="0.25"
                                          style={{
                                            fill: "#88C078",
                                            fill: "color(display-p3 0.5333 0.7529 0.4706)",
                                            fillOpacity: 0.25,
                                          }}
                                        />
                                        <rect
                                          x="15.5001"
                                          y="7"
                                          width="3"
                                          height="10"
                                          rx="1"
                                          fill="#88C078"
                                          fillOpacity="0.25"
                                          style={{
                                            fill: "#88C078",
                                            fill: "color(display-p3 0.5333 0.7529 0.4706)",
                                            fillOpacity: 0.25,
                                          }}
                                        />
                                      </g>
                                      <rect
                                        x="0.500122"
                                        y="0.5"
                                        width="23"
                                        height="23"
                                        rx="3.5"
                                        stroke="#88C078"
                                        style={{
                                          stroke: "#88C078",
                                          stroke:
                                            "color(display-p3 0.5333 0.7529 0.4706)",
                                          strokeOpacity: 1,
                                        }}
                                      />
                                      <defs>
                                        <clipPath id="clip0_504_7200">
                                          <rect
                                            x="0.00012207"
                                            width="24"
                                            height="24"
                                            rx="4"
                                            fill="white"
                                            style={{
                                              fill: "white",
                                              fillOpacity: 1,
                                            }}
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </Icon>
                                )}
                              </Tooltip>
                            </Grid>
                            <Grid item xs={6}>
                              {data?.organization?.showSupplier && (
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    ...theme.typography.h6,
                                    marginLeft: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  {departure?.operatorName
                                    ? departure?.operatorName
                                    : departure?.supplier?.name}
                                </Typography>
                              )}
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  marginLeft: "5px",
                                  color:
                                    departure?.levelValue === "LOW"
                                      ? "#88C078"
                                      : departure?.levelValue === "MEDIUM"
                                      ? "#E9B145"
                                      : "#E15D5D",
                                  marginTop: "-3px",
                                }}
                              >
                                {departure?.level}
                              </Typography>
                            </Grid>
                            {/* <Typography>
                          <Chip
                            size="small"
                            color={
                              departure?.productDestinationDepartureId ==
                              selectedDeparture?.productDestinationDepartureId
                                ? "success"
                                : "default"
                            }
                            label={<>&euro;{departure?.salesPrice?.toFixed(2)}</>}
                          />
                        </Typography> */}
                            <Grid item xs={4}>
                              {(departure?.priceVisibility == "DEFAULT" &&
                                data?.organization?.showPrice) ||
                              departure?.priceVisibility == "SHOW" ? (
                                <Typography
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    ...theme.typography.h6,
                                    marginLeft: "40px",
                                    fontSize: "16px",
                                  }}
                                >
                                  <div>
                                    {data?.adjustedCalculatedPrice != null &&
                                    departure?.productDestinationDepartureId ===
                                      selectedDeparture?.productDestinationDepartureId ? (
                                      <div>
                                        <Icon
                                          style={{ margin: 3, fontSize: 12 }}
                                        >
                                          note_alt
                                        </Icon>
                                        {parseFloat(
                                          data?.adjustedCalculatedPrice
                                        ).toFixed(2)}
                                        &euro;
                                      </div>
                                    ) : (
                                      <>
                                        {departure?.salesPrice?.toFixed(2)}
                                        &euro;
                                      </>
                                    )}
                                  </div>
                                </Typography>
                              ) : (
                                ""
                              )}
                            </Grid>
                            {departure?.productDestinationDeparture
                              ?.openTicket ? (
                              <Grid item>Open ticket</Grid>
                            ) : (
                              ""
                              // <Grid />
                            )}
                            <Grid item style={{ textAlign: "center" }}>
                              {groupedBlankoTickets &&
                                departure?.productDestinationId &&
                                departure?.productDestinationDeparture
                                  ?.departureHour &&
                                groupedBlankoTickets[
                                  `${departure?.productDestinationId}-${departure?.productDestinationDeparture?.departureHour}`
                                ] && (
                                  <Tooltip
                                    title="Number of blanko tickets"
                                    placement="top"
                                    arrow
                                  >
                                    <Chip
                                      size="small"
                                      color="warning"
                                      showZero={false}
                                      variant="outlined"
                                      label={
                                        groupedBlankoTickets &&
                                        departure?.productDestinationId &&
                                        departure?.productDestinationDeparture
                                          ?.departureHour &&
                                        groupedBlankoTickets[
                                          `${departure?.productDestinationId}-${departure?.productDestinationDeparture?.departureHour}`
                                        ]
                                      }
                                      style={{ marginRight: "5px" }}
                                    />
                                  </Tooltip>
                                )}
                              {departure?.numberOfStenaLineBlockBookings &&
                                departure?.numberOfStenaLineBlockBookings >
                                  0 && (
                                  <Tooltip
                                    title="Number of stena line block bookings"
                                    placement="top"
                                    arrow
                                  >
                                    <Chip
                                      size="small"
                                      color="success"
                                      showZero={false}
                                      variant="outlined"
                                      label={
                                        departure?.numberOfStenaLineBlockBookings &&
                                        departure?.numberOfStenaLineBlockBookings >
                                          0
                                          ? departure?.numberOfStenaLineBlockBookings
                                          : ""
                                      }
                                    />
                                  </Tooltip>
                                )}
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={10}
                  style={{
                    padding: "0px",
                    border: "none",
                    borderRadius: "5px",
                  }}
                >
                  <Alert
                    severity="info"
                    icon={false}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      margin: "0px",
                    }}
                  >
                    <Tooltip title="View Previous Available">
                      <IconButton
                        color="primary"
                        variant="contained"
                        size="small"
                        style={{ marginLeft: "-5px" }}
                        onClick={() => {
                          checkClosestPreviousDate(data?.departureDay);
                        }}
                      >
                        <ArrowBackIosNewIcon style={{ fontSize: "12px" }} />
                      </IconButton>
                    </Tooltip>
                    No departures available for the selected date
                    <Tooltip title="View Next Available">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          checkClosestNextDate(data?.departureDay);
                        }}
                      >
                        <ArrowForwardIosIcon style={{ fontSize: "12px" }} />
                      </IconButton>
                    </Tooltip>
                  </Alert>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
}
