import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  LinearProgress,
  Paper,
  Slide,
  TextField,
} from "@mui/material";
import React from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notifyShowSuccessMessage } from "../common/CommonActions";
import { ErrorMessageResolver } from "../common/ErrorMessageResolver";
import { LOCALE } from "../properties/Locale";
import { AuthRepository } from "../repositories/AuthRepository";
import { UsersRepository } from "../repositories/UsersRepository";
import { PasswordValidator } from "./PasswordValidator";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResetPasswordComponent({}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  const [data, setData] = React.useState({
    username: AuthRepository.getUserDetails().sub,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [networkErrorMessage, setNetworkErrorMessage] = React.useState("");
  const handleResetPassword = () => {
    setLoading(true);
    validateData(data);
    if (PasswordValidator.isValidSync(data)) {
      UsersRepository.resetPassword(data).then(
        (response) => {
          setLoading(false);
          dispatch(notifyShowSuccessMessage(LOCALE.password_reset_success));
          navigate("/");
        },
        (err) => {
          console.log(err);
          setNetworkErrorMessage(ErrorMessageResolver.resolve(err));
          setLoading(false);
        }
      );
    }
  };

  const validateData = (data) => {
    if (!PasswordValidator.isValidSync(data)) {
      var validationErrors = {};
      (async () => {
        await PasswordValidator.validate(data, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });

          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  return (
    <>
      <Dialog
        open={true}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth={"sm"}
      >
        <div style={{ minHeight: "4px", width: "100%" }}>
          {loading && <LinearProgress color="secondary" />}
        </div>
        <DialogTitle>{LOCALE.reset_password}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {networkErrorMessage && (
              <Grid item xs={12}>
                <Alert severity="error">{networkErrorMessage}</Alert>
              </Grid>
            )}
            <Grid item md={12}></Grid>
            <Grid item md={12}>
              <TextField
                type="password"
                label={LOCALE.old_password}
                name="old_password"
                variant="outlined"
                size="small"
                fullWidth
                value={data.oldPassword ? data.oldPassword : ""}
                onChange={(e) =>
                  setData({ ...data, oldPassword: e.target.value })
                }
                error={formDataErrors.oldPassword ? true : false}
                helperText={formDataErrors.oldPassword}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                type="password"
                label={LOCALE.new_password}
                name="new_password"
                variant="outlined"
                size="small"
                fullWidth
                value={data.newPassword ? data.newPassword : ""}
                onChange={(e) =>
                  setData({ ...data, newPassword: e.target.value })
                }
                error={formDataErrors.newPassword ? true : false}
                helperText={formDataErrors.newPassword}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                type="password"
                label={LOCALE.confirm_password}
                name="confirm_password"
                variant="outlined"
                size="small"
                fullWidth
                value={data.confirmPassword ? data.confirmPassword : ""}
                onChange={(e) =>
                  setData({ ...data, confirmPassword: e.target.value })
                }
                error={formDataErrors.confirmPassword ? true : false}
                helperText={formDataErrors.confirmPassword}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => navigate("/dashboard")}
          >
            <span style={{ fontWeight: "bold" }}>Cancel</span>
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleResetPassword()}
          >
            <span style={{ fontWeight: "bold" }}>Reset password</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
