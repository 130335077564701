import { useTheme } from "@emotion/react";
import {
  AppBar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Snackbar,
  SnackbarContent,
  Switch,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Client } from "@stomp/stompjs";
import { jwtDecode } from "jwt-decode";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import NotificationSettings from "../components/settings/NotificationSettings";
import { SETTINGS } from "../properties/Settings";
import { AuthRepository } from "../repositories/AuthRepository";
import { NotificationsRepository } from "../repositories/NotificationsRepository";
import { TicketRepository } from "../repositories/TicketRepository";
import { receiveNewMail, receiveNewTicket } from "./CommonActions";
import NotificationsMenu from "./NotificationsMenu";
import moment from "moment";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
        ...theme.applyStyles("dark", {
          backgroundColor: "#8796A5",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
    ...theme.applyStyles("dark", {
      backgroundColor: "#003892",
    }),
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#aab4be",
    borderRadius: 20 / 2,
    ...theme.applyStyles("dark", {
      backgroundColor: "#8796A5",
    }),
  },
}));
export default function Header({
  setOpen,
  open,
  setTypographyFontSize,
  typographyFontSize,
  setH6FontSize,
  setH5FontSize,
  setH4FontSize,
  h6FontSize,
  h5FontSize,
  h4FontSize,
  themeMode,
  setThemeMode,
}) {
  const [redirectTo, setRedirectTo] = React.useState();
  const location = useLocation();
  const globalState = useSelector((state) => state);
  const [currentUser, setCurrentUser] = React.useState();
  const [notifications, setNotifications] = React.useState([]);
  const [unreadNotifications, setUnreadNotifications] = React.useState([]);
  const subscriptionNotification = React.useRef();
  const clientNotification = React.useRef();
  const currentNotifications = React.useRef();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const navigate = useNavigate();
  const [ticket, setTicket] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [ticketNumber, setTicketNumber] = React.useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [hasMore, setHasMore] = React.useState(true);
  const notificationsRef = React.useRef([]);
  const [duration, setDuration] = React.useState(0);
  const [remainingTime, setRemainingTime] = React.useState(0);
  const [showMaintenanceDialog, setShowMaintenanceDialog] =
    React.useState(false);

  const handleClickProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    loadCurrentUser();
  }, []);

  React.useEffect(() => {
    setRedirectTo(undefined);
  }, [location]);

  const loadCurrentUser = () => {
    if (localStorage.getItem("jwt")) {
      setCurrentUser(jwtDecode(localStorage.getItem("jwt")));
      getNotifications(0);
      getUnreadNotifications();
    }
  };

  const getNotifications = (currentPage) => {
    NotificationsRepository.getUnreadPageable(currentPage, size).then((res) => {
      if (currentPage === 0) {
        currentNotifications.current = res.data.content;
        setNotifications(res.data.content);
      } else {
        notificationsRef.current = [
          ...notificationsRef.current,
          ...res.data.content,
        ];
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...res.data.content,
        ]);
      }
      res.data.content.length > 0 ? setHasMore(true) : setHasMore(false);
    });
  };

  const getUnreadNotifications = () => {
    NotificationsRepository.getUnread().then((res) => {
      setUnreadNotifications(res.data);
    });
  };

  const fetchMoreNotifications = () => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      getNotifications(nextPage);
      return nextPage;
    });
  };

  const pushScheduledMaintenanceAlert = (alert) => {
    setDuration(alert.approximateDuration);
    setRemainingTime(
      alert.alertCountdown * 60 -
        -moment(alert.scheduledMaintenanceTime)
          .utc(true)
          .diff(moment().utc(false), "seconds")
    );

    // put item in local storage to keep the countdown running even if the user refreshes the page
    localStorage.setItem("remainingTime", remainingTime);
    localStorage.setItem("duration", alert.approximateDuration);
    localStorage.setItem(
      "maintenanceStartTime",
      // alert.scheduleMaintenanceTime + alertCountdown
      moment(alert.scheduledMaintenanceTime)
        .utc(true)
        .add(alert.alertCountdown, "minutes")
        .format()
    );
    localStorage.setItem(
      "maintenanceEndTime",
      // alert.scheduleMaintenanceTime + alertCountdown + duration
      moment(alert.scheduledMaintenanceTime)
        .utc(true)
        .add(alert.alertCountdown + alert.approximateDuration, "minutes")
        .format()
    );
  };

  // get the remaining time and duration from local storage if the user refreshes the page
  React.useEffect(() => {
    if (localStorage.getItem("remainingTime")) {
      setRemainingTime(localStorage.getItem("remainingTime"));
    }
    if (localStorage.getItem("duration")) {
      setDuration(localStorage.getItem("duration"));
    }
    if (
      localStorage.getItem("maintenanceStartTime") &&
      localStorage.getItem("maintenanceEndTime")
    ) {
      // check if the maintenance has started and not ended yet
      if (
        moment()
          .utc(false)
          .isBetween(
            moment(localStorage.getItem("maintenanceStartTime")),
            moment(localStorage.getItem("maintenanceEndTime"))
          )
      ) {
        setShowMaintenanceDialog(true);
      }
      //if ended remove the items from local storage
      if (
        moment()
          .utc(false)
          .isAfter(moment(localStorage.getItem("maintenanceEndTime")))
      ) {
        localStorage.removeItem("remainingTime");
        localStorage.removeItem("duration");
        localStorage.removeItem("maintenanceStartTime");
        localStorage.removeItem("maintenanceEndTime");
      }
    }
  }, []);

  // update the remaining time every second, when the remaining time reaches 0, meinteance has started and the countdown stops dialog opens,
  // and the local storage is updated with with the new remaining time and duration, if the user refreshes the page dialog will hold the remaining time and duration
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (remainingTime !== null && remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
        localStorage.setItem("remainingTime", remainingTime - 1);
      } else {
        localStorage.removeItem("remainingTime");
        localStorage.removeItem("duration");
        setRemainingTime(0);
        clearInterval(interval);
        if (
          localStorage.getItem("maintenanceStartTime") &&
          localStorage.getItem("maintenanceEndTime")
        ) {
          setShowMaintenanceDialog(true);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  React.useEffect(() => {
    if (!isMobile) {
      if (
        currentUser &&
        currentUser.userId &&
        AuthRepository.hasAnyRole(["ROLE_CLIENT"])
      ) {
        if (subscriptionNotification.current) {
          subscriptionNotification.current.unsubscribe();
        }
        const onConnected = () => {
          subscriptionNotification.current =
            clientNotification.current.subscribe(
              `/user/notifications`,
              function (msg) {
                let tmpMsgParsed = JSON.parse(msg.body);

                if (tmpMsgParsed?.scheduledMaintenance) {
                  pushScheduledMaintenanceAlert(
                    tmpMsgParsed?.scheduledMaintenance
                  );
                }
                if (tmpMsgParsed?.newMailNotification) {
                  dispatch(receiveNewMail(tmpMsgParsed));
                }

                if (
                  tmpMsgParsed?.newMessageOnTicketNotification &&
                  currentUser.organization.id ==
                    tmpMsgParsed?.newMessageOnTicketNotification?.organization
                      .id
                ) {
                  dispatch(receiveNewTicket(tmpMsgParsed));
                  enqueueSnackbar(
                    `A new message has been left for booking ${tmpMsgParsed?.newMessageOnTicketNotification?.ticketNumber}`,
                    {
                      variant: "info",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      action: (key) => (
                        <>
                          <IconButton
                            onClick={() => {
                              navigate(
                                `/client/booking/0/15/%7B"status":"ALL_ACTIVE"%7D/${tmpMsgParsed?.newMessageOnTicketNotification?.id}`
                              );
                            }}
                            style={{ color: "white" }}
                            size="small"
                          >
                            <Icon>open_in_new</Icon>
                          </IconButton>
                          <IconButton
                            onClick={() => closeSnackbar(key)}
                            size="small"
                            style={{ color: "white" }}
                          >
                            <Icon>close</Icon>
                          </IconButton>
                        </>
                      ),
                      classes: { variantInfo: "rgb(45 152 162)" },
                    }
                  );
                }

                if (
                  tmpMsgParsed?.statusChangedOnTicketNotification &&
                  currentUser.organization.id ==
                    tmpMsgParsed?.statusChangedOnTicketNotification
                      ?.organization.id
                ) {
                  dispatch(receiveNewTicket(tmpMsgParsed));
                  enqueueSnackbar(
                    `Status changed to ${tmpMsgParsed?.statusChangedOnTicketNotification?.status} for booking ${tmpMsgParsed?.statusChangedOnTicketNotification?.ticketNumber}`,
                    {
                      variant:
                        tmpMsgParsed?.statusChangedOnTicketNotification?.status,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      autoHideDuration: null,

                      action: (key) => (
                        <>
                          <IconButton
                            onClick={() => {
                              navigate(
                                `/client/booking/0/15/%7B"status":"ALL_ACTIVE"%7D/${tmpMsgParsed?.statusChangedOnTicketNotification?.id}`
                              );
                            }}
                            style={{ color: "white" }}
                            size="small"
                          >
                            <Icon>open_in_new</Icon>
                          </IconButton>
                          <IconButton
                            onClick={() => closeSnackbar(key)}
                            size="small"
                            style={{ color: "white" }}
                          >
                            <Icon>close</Icon>
                          </IconButton>
                        </>
                      ),
                      classes: { variantInfo: "rgb(45 152 162)" },
                    }
                  );
                }
                setNotifications([]);
                setUnreadNotifications([]);
                getNotifications(0);
                getUnreadNotifications();
              },
              { id: currentUser.userId }
            );
        };
        let onDisconnected = () => {
          clientNotification.current.unsubscribe(`/user/notifications`, {
            id: currentUser.userId,
          });
        };
        clientNotification.current = new Client({
          brokerURL: SETTINGS.SOCKET_URL,
          reconnectDelay: 5000,
          heartbeatIncoming: 4000,
          heartbeatOutgoing: 4000,
          connectHeaders: {
            Authorization: `Bearer ${AuthRepository.getToken()}`,
          },
          onConnect: onConnected,
          onDisconnect: onDisconnected,
        });
        clientNotification.current.activate();
      }
    }
  }, [currentUser]);

  const fetchTicketByNumber = (ticketNumber) => {
    TicketRepository.getByTicketNumber(ticketNumber).then(
      (response) => {
        if (response.data) {
          navigate(
            `/client/booking/${0}/${15}/${ticketNumber}/${response.data.id}`
          );
          setTicketNumber("");
          setError(null);
        }
      },
      (error) => {
        if (error.response && error.response.status === 404) {
          setError("Booking not found or not allowed to see");
          setSnackbarMessage("Booking not found or not allowed to see");
        } else {
          setSnackbarMessage("Booking not found !");
          setError("Booking not found !");
        }
        navigate(
          `/client/bookings/0/15/${JSON.stringify({
            status: "ALL_ACTIVE",
          })}/dateCreated/DESC`
        );
        setSnackbarOpen(true);
      }
    );
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <React.Fragment>
      {redirectTo && <Navigate to={redirectTo} push />}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          display: { xs: "none", md: "block" },
          width: `calc(100% - ${open ? "230px" : "74px"})`,
          transition: (theme) =>
            theme.transitions.create(["margin", "width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Toolbar
          style={{
            minHeight: "50px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography sx={{ flexGrow: 1 }}></Typography>
          {
            // <>
            //   <FormControlLabel
            //     control={<MaterialUISwitch sx={{ m: 1 }} size="small" />}
            //     checked={themeMode === "dark" ? true : false}
            //     onChange={(e) => {
            //       setThemeMode(e.target.checked ? "dark" : "light");
            //     }}
            //   />
            //   <IconButton
            //     onClick={() => {
            //       //decrease typography font size by 0.2
            //       setTypographyFontSize(typographyFontSize - 0.5);
            //       setH6FontSize(h6FontSize - 0.5);
            //       setH5FontSize(h5FontSize - 0.5);
            //       setH4FontSize(h4FontSize - 0.5);
            //     }}
            //   >
            //     <Icon>zoom_out</Icon>
            //   </IconButton>
            //   <IconButton
            //     onClick={() => {
            //       //increase typography font size by 0.2
            //       setTypographyFontSize(typographyFontSize + 0.5);
            //       setH6FontSize(h6FontSize + 0.5);
            //       setH5FontSize(h5FontSize + 0.5);
            //       setH4FontSize(h4FontSize + 0.5);
            //     }}
            //   >
            //     <Icon>zoom_in</Icon>
            //   </IconButton>
            // </>
          }{" "}
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "15px",
              fontWeight: "bold",
              color: "#333",
            }}
          >
            {remainingTime !== null && remainingTime > 0 ? (
              <span
                style={{
                  color: "#D2C42D",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                <Icon style={{ fontSize: "15px", marginRight: "5px" }}>
                  handyman
                </Icon>
                Countdown to maintenance: {formatTime(remainingTime)},
                maintenance approximate duration: {duration} minutes.
              </span>
            ) : (
              <></>
            )}
          </Typography>
          <Grid item xs={2} mr={2}>
            <TextField
              value={ticketNumber || ""}
              onChange={(e) => setTicketNumber(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setTicketNumber("");

                  fetchTicketByNumber(e.target.value);
                }
              }}
              label={"Booking number"}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon sx={{ opacity: "0.6" }}>search</Icon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {AuthRepository.getUserDetails() && (
            <>
              <NotificationsMenu
                notifications={notifications}
                unreadNotifications={unreadNotifications}
                getNotifications={getNotifications}
                getUnreadNotifications={getUnreadNotifications}
                fetchMoreNotifications={fetchMoreNotifications}
                setPage={setPage}
                hasMore={hasMore}
              />
            </>
          )}
        </Toolbar>
      </AppBar>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: " #d93535",
          }}
          message={snackbarMessage}
        />
      </Snackbar>
      {showMaintenanceDialog && (
        <Dialog open={showMaintenanceDialog} fullWidth>
          <DialogTitle>System Maintenance</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The system is currently undergoing maintenance. We apologize for
              any inconvenience this may cause. The system is expected to be
              available at approximately{" "}
              <strong>
                {localStorage.getItem("maintenanceEndTime") &&
                  moment(localStorage.getItem("maintenanceEndTime"))
                    .utc(true)
                    .format("HH:mm")}
              </strong>{" "}
              .
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}
