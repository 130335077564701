import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { RegistrationAdministratorForm } from "./RegistrationAdministratorForm";
import { RegistrationOrganizationForm } from "./RegistrationOrganizationForm";
import { RegistrationUsersForm } from "./RegistrationUsersForm";
import _ from "lodash";
import { CitiesRepository } from "../../repositories/CitiesRepository";
import { CountriesRepository } from "../../repositories/CountriesRepository";
import { OrganizationsRepository } from "../../repositories/OrganizationsRepository";
import * as yup from "yup";
import { InvitationRegistrationRepository } from "../../repositories/InvitationRegistrationRepository";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../../common/CommonActions";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { LOCALE } from "../../properties/Locale";
import { useTheme } from "@emotion/react";

export const InvitationRegistration = () => {
  const { invitationCode } = useParams();
  const [redirectTo, setRedirectTo] = useState();
  const [formData, setFormData] = useState({
    organization: {},
    adminAccount: {},
    members: [],
  });
  const [formDataErrors, setFormDataErrors] = useState({ members: [] });
  const [globalError, setGlobalError] = useState();
  const [globalFormError, setGlobalFormError] = useState();
  const [step, setStep] = useState(0);
  const [cities, setCities] = useState([]);
  const [citiesSearch, setCitiesSearch] = useState("");
  const [countries, setCountries] = useState([]);
  const [countriesSearch, setCountriesSearch] = useState("");
  const [emailFieldError, setEmailFieldError] = useState();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    loadOrganizationByInvitationCode();
    getAllCountries();
  }, []);

  useEffect(() => {
    if (formData?.organization?.country?.id) {
      getAllCitiesByCountryId(formData.organization.country.id);
    }
  }, [formData.organization.country]);

  const handleSubmit = () => {
    setLoading(true);
    setGlobalFormError();
    let data = { ...formData };
    data.adminAccount.username = data.adminAccount.email;
    InvitationRegistrationRepository.registerOrganization(data)
      .then((res) => {
        setLoading(false);
        dispatch(
          notifyShowSuccessMessage("Organization successfully registered!")
        );
        navigate("/login");
      })
      .catch((err) => {
        setLoading(false);
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setGlobalFormError(ErrorMessageResolver.resolve(err));
        console.log(err);
      });
  };

  const loadOrganizationByInvitationCode = () => {
    setLoading(true);
    OrganizationsRepository.getOrganizationByInvitationCode(invitationCode)
      .then((res) => {
        if (!res.data) {
          setGlobalError(LOCALE.invalid_invitation_code_error_message);
        } else if (res?.data?.invitationStatus === "DONE") {
          setGlobalError(LOCALE.organization_is_already_registered);
        } else {
          let data = { ...formData };
          data.organization = res.data;
          setFormData(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // const addMember = (member) => {
  //   let data = { ...formData };
  //   data.members.push(member);
  //   setFormData(data);
  // };

  // const removeMember = (member) => {
  //   let data = { ...formData };
  //   data.members.splice(data.members.indexOf(member), 1);
  //   setFormData(data);
  // };

  // const editMember = (index, member) => {
  //   let data = { ...formData };
  //   console.log(index, member);
  //   data.members[index] = member;
  //   setFormData(data);
  // };

  const handleChangeData = (name, value) => {
    setFormData(_.set({ ...formData }, name, value));
  };

  const getAllCountries = () => {
    CountriesRepository.fetchAllWithoutPaging()
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const addEmailToOrganization = () => {
  //   let validation = yup.string().required().email();
  //   if (!validation.isValidSync(email)) {
  //     validation.validate(email, { abortEarly: false }).catch((err) => {
  //       setEmailFieldError(err.message);
  //     });
  //   } else {
  //     handleChangeData("organization.emails", [
  //       ...formData.organization.emails,
  //       email,
  //     ]);
  //     setEmail("");
  //   }
  // };

  // const handleDeleteEmail = (email) => {
  //   handleChangeData(
  //     "organization.emails",
  //     formData.organization.emails.filter((e) => e !== email)
  //   );
  // };

  const getAllCitiesByCountryId = (countryId) => {
    CitiesRepository.fetchAllByCountryId(countryId)
      .then((res) => {
        setCities(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleChangeStep = (newStep) => {
  //   setFormDataErrors({});
  //   if (step === 0) {
  //     let validation = yup.object().shape({
  //       nameEn: yup.string().required(LOCALE.required_field),
  //       addressEn: yup.string().required(),
  //       country: yup.object().required(LOCALE.required_field).nullable(),
  //       city: yup.object().required(LOCALE.required_field).nullable(),
  //     });
  //     if (!validation.isValidSync(formData.organization)) {
  //       let validationErrors = {};
  //       validation
  //         .validate(formData.organization, { abortEarly: false })
  //         .catch((err) => {
  //           err.inner.forEach((validationError) => {
  //             validationErrors[validationError.path] = {};
  //             validationErrors[validationError.path] = validationError.message;
  //           });
  //           console.log(validationErrors);
  //           setFormDataErrors({ organization: validationErrors });
  //         });
  //     } else {
  //       setStep(newStep);
  //     }
  //   }
  //   if (step === 1) {
  //     if (newStep === 0) {
  //       setStep(newStep);
  //     } else {
  //       let validation = yup.object().shape({
  //         email: yup.string().required(LOCALE.required_field).email(),
  //         firstName: yup.string().required(LOCALE.required_field),
  //         lastName: yup.string().required(LOCALE.required_field).nullable(),
  //         confirmPassword: yup
  //           .string()
  //           .required(LOCALE.required_field)
  //           .min(6)
  //           .max(20)
  //           .test(
  //             "passwords-match",
  //             LOCALE.passwords_must_match,
  //             function (value) {
  //               console.log(value, this.parent.password);
  //               return this.parent.password === value;
  //             }
  //           )
  //           .nullable(),
  //         password: yup
  //           .string()
  //           .required("Required field")
  //           .min(6)
  //           .max(20)
  //           .test(
  //             "passwords-match",
  //             LOCALE.passwords_must_match,
  //             function (value) {
  //               console.log(value, this.parent.confirmPassword);
  //               return this.parent.confirmPassword === value;
  //             }
  //           ),
  //       });
  //       if (!validation.isValidSync(formData.adminAccount)) {
  //         let validationErrors = {};
  //         validation
  //           .validate(formData.adminAccount, { abortEarly: false })
  //           .catch((err) => {
  //             err.inner.forEach((validationError) => {
  //               validationErrors[validationError.path] = {};
  //               validationErrors[validationError.path] =
  //                 validationError.message;
  //             });
  //             console.log(validationErrors);
  //             setFormDataErrors({ adminAccount: validationErrors });
  //           });
  //       } else {
  //         setStep(newStep);
  //       }
  //     }
  //   }

  //   if (step === 2) {
  //     if (newStep > step) {
  //       handleSubmit();
  //     } else {
  //       setStep(newStep);
  //     }
  //   }
  // };

  const theme = useTheme();

  return (
    <>
      {redirectTo && <Navigate to={redirectTo} push />}
      <Container
        sx={{
          height: "91vh",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={0} md={3}></Grid>
          <Grid item xs={0} md={6}>
            <Paper
              className="roundedBorder"
              square
              style={{
                padding: "20px",
                marginTop: "30px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Grid item xs={12} style={{ textAlign: "left" }}>
                    <img src={"/logo.png"} height={50} />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    variant="h6"
                    style={{ marginTop: "10px", textAlign: "right" }}
                  >
                    {LOCALE.invitationCode}: {invitationCode?.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              {globalError && (
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <Alert severity="error">{globalError}</Alert>
                </Grid>
              )}
              {!globalError && (
                <Grid container spacing={2}>
                  {loading && (
                    <Grid
                      item
                      xs={12}
                      style={{ marginTop: "50px", marginBottom: "50px" }}
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                  {globalFormError && (
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                      <Alert severity="error">{globalFormError}</Alert>
                    </Grid>
                  )}
                  {!loading && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        pt={2}
                        pb={4}
                        style={{ marginTop: "10px" }}
                      >
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} pb={2}>
                              <Typography
                                variant="h4"
                                style={{ textAlign: "center" }}
                              >
                                {LOCALE.create_an_organization_on_our_platform}
                              </Typography>
                            </Grid>
                            <RegistrationAdministratorForm
                              email={email}
                              handleChangeData={handleChangeData}
                              formData={formData}
                              formDataErrors={formDataErrors}
                            />
                          </Grid>
                          <Grid item xs={12} pt={2}>
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{ fontSize: "14px" }}
                              onClick={() => handleSubmit()}
                            >
                              Create
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </Paper>
          </Grid>

          <Grid item xs={0} md={3}></Grid>
        </Grid>
      </Container>
    </>
  );
};
