export const localeMk = {
  invoices: "Фактури",
  isRequired: "Задолжително поле",
  filter_by: "Филтер по",
  lastUpdated: "Последно ажурирано",
  status: "Статус",
  module: "Модул",
  registration: "Регистрирај се",
  login: "Најави се",
  forgotPassword: "Заборавена лозинка?",
  password: "Лозинка",
  username: "Корисничко име",
  logout: "Одјави се",
  administration: "Администрација",
  privileges: "Привилегии",
  users: "Корисници",
  mail_templates: "Шаблони на е-пошта",
  organizations: "Организации",
  groups: "Групи",
  roles: "Улоги",
  name: "Име",
  firstName: "Име",
  lastName: "Презиме",
  enabled: "Активен",
  email: "Е-пошта",
  phone: "Телефон",
  organization: "Организација",
  code: "Код",
  description: "Опис",
  address: "Адреса",
  city: "Град",
  country: "Држава",
  state: "Држава",
  zip: "Поштенски број",
  save: "Зачувај",
  cancel: "Откажи",
  delete: "Избриши",
  edit: "Измени",
  add: "Додади",
  search: "Пребарај",
  select: "Избери",
  dateCreated: "Датум на креирање",
  dateModified: "Датум на измена",
  actions: "Акции",
  system_settings_props_updated_success: "Подесувањата се успешно ажурирани",
  rows_per_page: "Редови по страна",
  addNewOrganization: "Додади нова организација",
  addNewPrivilege: "Додади нова привилегија",
  addNewSupplier: "Додади нов добавувач",
  addNewGroup: "Додади нова група",
  addNewUser: "Додади нов корисник",
  sendMail: "Испрати е-пошта",
  editGroup: "Измени група",
  editUser: "Измени корисник",
  editOrganization: "Измени организација",
  editPrivilege: "Измени привилегија",
  editSupplier: "Измени добавувач",
  deleteGroup: "Избриши група",
  deleteUser: "Избриши корисник",
  deleteOrganization: "Избриши организација",
  deletePrivilege: "Избриши привилегија",
  deleteSupplier: "Избриши добавувач",
  newOrganization: "Нова организација",
  user_not_enabled: "Корисникот не е овозможен",
  bad_credentials: "Погрешни податоци за најава",
  networkErrorMessage: "Проблем со конекција",
  organization_create_success_message: "Организацијата е успешно креирана",
  privilege_create_success_message: "Организацијата е успешно креирана",
  supplier_create_success_message: "Добавувачот е успешно креиран",
  group_create_success_message: "Групата е успешно креирана",
  user_create_success_message: "Корисникот е успешно креиран",
  group_update_success_message: "Групата е успешно изменета",
  user_update_success_message: "Корисникот е успешно изменет",
  privilege_update_success_message: "Привилегијата е успешно изменета",
  supplier_update_success_message: "Добавувачот е успешно изменет",
  group_deleted_success_message: "Групата е успешно избришана",
  user_deleted_success_message: "Корисникот е успешно избришан",
  group_delete_confirmation_text:
    "Дали сте сигурни дека сакате да ја избришете оваа група?",
  user_delete_confirmation_text:
    "Дали сте сигурни дека сакате да го избришете овој корисник?",
  organization_update_success_message: "Организацијата е успешно изменета",
  organization_delete_confirmation_text:
    "Дали сте сигурни дека сакате да ја избришете оваа организација?",
  organization_deleted_success_message: "Организацијата е успешно избришана",
  privilege_delete_confirmation_text:
    "Дали сте сигурни дека сакате да ја избришете оваа привилегија?",
  supplier_delete_confirmation_text:
    "Дали сте сигурни дека сакате да го избришете овој добавувач?",
  privilege_deleted_success_message: "Привилегијата е успешно избришана",
  supplier_deleted_success_message: "Добавувачот е успешно избришан",
  choose_mail_template: "Избери шаблон за е-пошта",
  country: "Држава",
  countries: "Држави",
  nameEn: "Име латиница",
  country_create_success_message: "Државата е успешно креирана",
  country_update_success_message: "Државата е успешно изменета",
  country_delete_confirmation_text:
    "Дали сте сигурни дека сакате да ја избришете оваа држава?",
  country_deleted_success_message: "Државата е успешно избришана",
  group_details: "Детали за групата",
  addNewCountry: "Додади нова држава",
  editCountry: "Измени држава",
  deleteCountry: "Избриши држава",
  mail_send_test_mail: "Испрати тест е-пошта",
  selected_users: "Селектирани корисници",
  selected_organizations: "Селектирани организации",
  selected_clients: "Селектирани клиенти",
  add_new_members: "Додади нови членови",
  members: "Членови",
  mails: "Е-пошти",
  cities: "Градови",
  city: "Град",
  city_create_success_message: "Градот е успешно креиран",
  city_update_success_message: "Градот е успешно изменет",
  city_delete_confirmation_text:
    "Дали сте сигурни дека сакате да го избришете овој град?",
  city_deleted_success_message: "Градот е успешно избришан",
  addNewCity: "Додади нов град",
  editCity: "Измени град",
  deleteCity: "Избриши град",
  postcode: "Поштенски код",
  clients: "Клиенти",
  address: "Адреса",
  addressEn: "Адреса латиница",
  edb: "ЕДБ",
  phone: "Телефон",
  addEmail: "Додади е-пошта",
  settings: "Подесувања",
  mail_log: "Лог на е-пошта",
  mail_all: "Сите",
  mail_success: "Успешни",
  mail_pending: "На чекање",
  mail_processing: "Процесирање",
  mail_failed: "Неуспешни",
  mail_canceled: "Откажани",
  mail_sender: "Испраќач",
  mail_receiver: "Примач",
  mail_createdAt: "Креирана на",
  mail_sentAt: "Испратена на",
  mail_status: "Статус",
  mail_details: "Детали",
  mail_requeued: "Препратени",
  mail_requeue: "Препрати",
  mail_content: "Содржина",
  mail_view_mail_content: "Содржина на е-меил пораката",
  mail_error_log: "Детали за грешката",
  mail_error_details: "Детали за грешка при испраќање",
  mail_subject: "Наслов/Предмет",
  mail_mail_details: "Детали за меил пораката",
  mail_template: "Урнеци за е-пошта",
  props: "Параметри",
  key: "Клуч",
  value: "Вредност",
  test_mail_server_header: "Тестирање на е-меил серверот",
  test_mail_server_subheader:
    "Во овој дел можете да го тестирате е-меил серверот",
  test_mail_server_description:
    "Моментално се подесени следните SMTP параметри",
  send_test_mail: "Испрати",
  for_testing_purposes_only:
    "Ова функционалност треба да се користи исклучиво за тестирање на е-меил (SMTP) серверот",
  mail_test_results_info_message:
    "Овде ќе го видите резултатот после тестирањето",
  mail_test_results_info_header: "Резултати од тестот",
  mail_sent_success_message: "Пораката е успешно испратена",
  SUBMITTED: "ПОДНЕСЕНО",
  REJECTED: "ОДБИЕНО",
  PENDING: "ЗА ОБРАБОТКА",
  PROCESSING: "ВО ПРОЦЕС НА ОБРАБОТКА",
  DONE: "ЗАВРШЕНО",
  FAILED: "НЕУСПЕШНО",
  CANCELED: "ОТКАЖАНО",
  SUCCESS: "УСПЕШНО",
  synchronization: "Синхронизација",
  edb: "ЕДБ",
  invitation: "Invitation",
  regions: "Региони",
  addNewRegion: "Додади нов регион",
  editRegion: "Измени регион",
  deleteRegion: "Избриши регион",
  subRegions: "Подрегиони",
  addNewSubRegion: "Додади нов подрегион",
  editSubRegion: "Измени подрегион",
  region_name: "Име на регион",
  destinations: "Дестинации",
  addNewDestination: "Додади нова дестинација",
  editDestination: "Измени дестинација",
  deleteDestination: "Избриши дестинација",
  destination_name: "Име на дестинација",
  destination_code: "Код на дестинација",
  destination_region: "Регион на дестинација",
  destination_sub_region: "Подрегион на дестинација",
  destination_country: "Држава на дестинација",
  destination_city: "Град на дестинација",
  from_to: "Од до",
  destination_cities: "Градови на дестинација",
  addNewDestinationCity: "Додади нов град на дестинација",
  editDestinationCity: "Измени град на дестинација",
  deleteDestinationCity: "Избриши град на дестинација",
  priceList: "Ценовник",
  addNewPriceList: "Додади нов ценовник",
  editPriceList: "Измени ценовник",
  deletePriceList: "Избриши ценовник",
  supplier: "Добавувач",
  destination: "Дестинација",
  price: "Цена",
  purchasePrice: "Набавна цена",
  salePrice: "Продажна цена",
  from_country: "Од држава",
  from_city: "Од град",
  to_country: "До држава",
  to_city: "До град",
  from_port: "Од порт",
  to_port: "До порт",
  departure_date: "Датум на поаѓање",
  suppliers: "Добавувачи",
  official_name: "Официјално име",
  website: "Веб страна",
  send_invitational_mail: "Испрати е-пошта со покана за регистрирање",
  destinationRegion: "Регион на дестинација",
  destinationSubRegion: "Подрегион на дестинација",
  invitationCode: "Код на покана",
  create_an_organization_on_our_platform:
    "Креирај организација на нашата платформа",
    create_an_account_on_our_platform:
 "Креирај корисник на нашата платформа",
  organization: "Клиент",
  administrator: "Администратор",
  users: "Корисници",
  next: "Следно",
  previous: "Претходно",
  passwords_must_match: "Лозинките мора да се совпаѓаат",
  required_field: "Задолжително поле",
  invalid_invitation_code_error_message:
    "Невалиден код на покана. Контактирајте го администраторот.",
  organization_is_already_registered: "Клиентот е веќе регистриран",
  at_least_one_email_per_organization:
    "Мора да има најмалку една е-пошта за секој клиент",
  at_least_one_organization: "Селектирајте барем еден клиент",
  active: "Активен",
};
