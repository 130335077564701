import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const MessageTemplateRepository = {
  fetchAll: (payload) => {
    return Axios({
      url: SETTINGS.API_URL + "messageTemplates",
      method: "GET",
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        searchParams: payload.searchParams,
      },
    });
  },

  fetchAllWithoutPaging: () => {
    return Axios({
      url: SETTINGS.API_URL + "messageTemplates/all",
      method: "GET",
    });
  },

  get: (id) => {
    return Axios.get(`${SETTINGS.API_URL}messageTemplates/${id}`);
  },

  create: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}messageTemplates`,
      method: "POST",
      data: data,
    });
  },

  update: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}messageTemplates`,
      method: "PUT",
      data: data,
    });
  },

  delete: (id) => {
    return Axios.delete(`${SETTINGS.API_URL}messageTemplates/${id}`);
  },

  getAllTemplateTypes: () => {
    return Axios({
      url: SETTINGS.API_URL + "messageTemplates/templateTypes",
      method: "GET",
    });
  },

  getAllTemplatePriorities: () => {
    return Axios({
      url: SETTINGS.API_URL + "messageTemplates/templatePriorities",
      method: "GET",
    });
  },

  getAllTemplateFor: () => {
    return Axios({
      url: SETTINGS.API_URL + "messageTemplates/templateFors",
      method: "GET",
    });
  },

  getAllTicketStatuses: () => {
    return Axios({
      url: SETTINGS.API_URL + "messageTemplates/ticketStatuses",
      method: "GET",
    });
  },

  getAllTemplatesByTicketStatus: (ticketStatus) => {
    return Axios({
      url: `${SETTINGS.API_URL}messageTemplates/all/status?ticketStatus=${ticketStatus}`,
      method: "GET",
    });
  },
};
