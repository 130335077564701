import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  alpha,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  PaginationItem,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/system";
import { addDays, set } from "date-fns";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { moment } from "../../App";
import {
  clearReceiveNewTicket,
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../../common/CommonActions";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { getTicketStatusColor } from "../../common/VisualElementsHelper";
import { LOCALE } from "../../properties/Locale";
import { AuthRepository } from "../../repositories/AuthRepository";
import { DestinationsRepository } from "../../repositories/DestinationsRepository";
import { RegionsRepository } from "../../repositories/RegionsRepository";
import { SuppliersRepository } from "../../repositories/SuppliersRepository";
import { TicketGroupRepository } from "../../repositories/TicketGroupRepository";
import { TicketRepository } from "../../repositories/TicketRepository";
import { ClientTicketListTableBody } from "./ClientTicketListTableBody";
import { DateCreatedCalendar } from "./DateCreatedCalendar";
import { DepartureDateCalendar } from "./DepartureDateCalendar";
import { CalendarMonth, KeyboardArrowDown } from "@mui/icons-material";
import { ClientTicketListTableBodyMobile } from "../mobile/ClientTicketListTableBodyMobile";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const ClientTicketList = ({}) => {
  const [searchParams, setSearchParams] = useState({});
  const [data, setData] = useState();
  const [groupData, setGroupData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);

  const [destinationsSearch, setDestinationsSearch] = useState("");
  const [destinations, setDestinations] = useState([]);
  const [ticketStatuses, setTicketStatuses] = useState();
  const [activeStatus, setActiveStatus] = useState(undefined);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);
  const [expanded, setExpanded] = useState(false);
  const [alternativeTickets, setAlternativeTickets] = useState([]);
  const theme = useTheme();
  const [hoveredRow, setHoveredRow] = useState();
  const [showAlternativeTickets, setShowAlternativeTickets] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState([]);

  const [
    showSelectTicketsCheckboxContactSupplier,
    setShowSelectTicketsCheckboxContactSupplier,
  ] = useState(false);
  const [
    showSelectTicketsCheckboxContactSupplier2,
    setShowSelectTicketsCheckboxContactSupplier2,
  ] = useState(false);

  const [showTicketGroups, setShowTicketGroups] = useState(false);
  const [ticketGroupPage, setTicketGroupPage] = useState(0);
  const [ticketGroupSize, setTicketGroupSize] = useState(5);
  const [suppliers, setSuppliers] = useState([]);
  const [users, setUsers] = useState();
  const [supplierSearchString, setSupplierSearchString] = useState("");

  const [regions, setRegions] = useState([]);
  const [newArrivedTickets, setNewArrivedTickets] = useState([]);
  const params = useParams();
  const [currentUser, setCurrentUser] = useState(
    AuthRepository.getUserDetails()
  );
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDateCreatedCalendar, setOpenDateCreatedCalendar] = useState(false);
  const [selectedContactSupplier, setSelectedContactSupplier] = React.useState(
    []
  );
  const [regionsSearch, setRegionsSearch] = useState("");
  const [newlyAddedTicketNumber, setNewlyAddedTicketNumber] = useState();
  const [totalsPerPage, setTotalsPerPage] = useState(true);
  const [loadingGrandTotalPrices, setLoadingGrandTotalPrices] = useState(false);
  const [grandTotalTicketsPrice, setGrandTotalTicketsPrice] = useState();
  const [grandTotalSurcharges, setGrandTotalSurcharges] = useState();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleDatePicker = () => {
    setOpenDatePicker(true);
  };

  const handleDateCreatedCalendar = () => {
    setOpenDateCreatedCalendar(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(
        `/client/bookings/${params?.page}/${params?.size}/${JSON.stringify(
          searchParams
        )}/${params?.orderValue}/${params?.orderDirection}`
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [searchParams]);

  useEffect(() => {
    if (currentUser && currentUser.userId) {
    }
  }, [currentUser]);

  useEffect(() => {
    if (globalState.receiveNewTicket?.newTicketNotification) {
      handleNewTicketArrived(
        globalState.receiveNewTicket?.newTicketNotification
      );
      dispatch(clearReceiveNewTicket());
    }

    if (globalState.receiveNewTicket?.statusChangedOnTicketNotification) {
      const updatedTicketDTO =
        globalState.receiveNewTicket.statusChangedOnTicketNotification;

      setNewlyAddedTicketNumber(updatedTicketDTO.ticketNumber);

      setData((prevData) => {
        if (prevData?.content && Array.isArray(prevData.content)) {
          const ticketExists = prevData.content.some(
            (ticket) => ticket.ticketNumber === updatedTicketDTO.ticketNumber
          );

          if (!ticketExists) {
            return prevData;
          }

          const updatedContent = prevData.content.map((ticket) => {
            if (ticket.ticketNumber === updatedTicketDTO.ticketNumber) {
              return { ...ticket, ...updatedTicketDTO, isHighlighted: true };
            }
            return ticket;
          });

          const ticketToMove = updatedContent.find(
            (ticket) => ticket.ticketNumber === updatedTicketDTO.ticketNumber
          );
          const remainingTickets = updatedContent.filter(
            (ticket) => ticket.ticketNumber !== updatedTicketDTO.ticketNumber
          );

          return {
            ...prevData,
            content: [ticketToMove, ...remainingTickets],
          };
        }
        return prevData;
      });

      dispatch(clearReceiveNewTicket());
    }
  }, [globalState.receiveNewTicket]);

  useEffect(() => {
    loadAllTicketStatuses();
  }, []);

  useEffect(() => {
    if (params && params.page && params.size) {
      loadData(
        params?.page,
        params?.size,
        params?.params,
        params?.orderValue,
        params?.orderDirection
      );
    }
    setSearchParams(JSON.parse(params.params));
  }, [
    params?.page,
    params?.size,
    params?.params,
    params?.orderValue,
    params?.orderDirection,
  ]);

  useEffect(() => {
    if (showTicketGroups) {
      loadTicketGroupData();
    }
    return () => {};
  }, [ticketGroupPage, ticketGroupSize, searchParams]);

  useEffect(() => {
    if (showTicketGroups) {
      loadTicketGroupData();
    }
  }, [showTicketGroups]);

  const loadData = (
    providedPage,
    providedSize,
    filterParams,
    orderValue,
    orderDirection
  ) => {
    setLoading(true);
    setNewlyAddedTicketNumber();
    let tmp = filterParams ? filterParams : JSON.stringify({ ...searchParams });
    let tmpObj = JSON.parse(tmp);
    if (tmpObj.status == "ALL") {
      delete tmpObj.status;
    }
    tmp = JSON.stringify(tmpObj);
    let tmpPage = providedPage ? providedPage : page;
    let tmpSize = providedSize ? providedSize : size;

    TicketRepository.getAll(tmpPage, tmpSize, tmp, orderValue, orderDirection)
      .then((res) => {
        setData(res.data);
        setGrandTotalSurcharges();
        setGrandTotalTicketsPrice();
        setTotalsPerPage(true);
        setNewArrivedTickets([]);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setLoading(false);
      });
  };

  const loadTicketGroupData = () => {
    TicketGroupRepository.getAll(
      ticketGroupPage,
      ticketGroupSize,
      {},
      "dateCreated",
      "DESC"
    )
      .then((response) => {
        setGroupData(response.data);
      })
      .catch((error) => {});
  };

  const handleChangePage = (event, newPage) => {
    navigate(
      `/client/bookings/${newPage}/${params?.size}/${JSON.stringify(
        JSON.parse(params.params)
      )}/${params?.orderValue}/${params?.orderDirection}`
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
    navigate(
      `/client/bookings/${0}/${event.target.value}/${JSON.stringify(
        JSON.parse(params.params)
      )}/${params?.orderValue}/${params?.orderDirection}`
    );
  };

  const handleChange = (name, value) => {
    let tmp = { ...searchParams };
    tmp[name] = value;
    setSearchParams(tmp);
  };

  const loadAllTicketStatuses = () => {
    TicketRepository.getAllTicketStatuses()
      .then((res) => {
        setTicketStatuses(res.data);
      })
      .catch((err) => console.log(err));
  };

  const checkNumberOfFilters = () => {
    let numberOfFilters = 0;
    Object.keys(searchParams).forEach((key) => {
      if (
        searchParams[key] !== undefined &&
        searchParams[key] !== "" &&
        searchParams[key] !== null
      ) {
        numberOfFilters++;
      }
    });
    return numberOfFilters;
  };

  const handleAssignToCurrentUser = (ticketId) => {
    setLoading(true);
    TicketRepository.assignToUser(
      ticketId,
      AuthRepository.getUserDetails().userId
    ).then((res) => {
      loadData();
      setLoading(false);
    });
  };

  useEffect(() => {
    loadAllRegions();
  }, []);

  const loadAllRegions = () => {
    RegionsRepository.getAllWithoutPaging()
      .then((res) => {
        setRegions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //if searchString size is 3 or more, search for suppliers
    if (supplierSearchString.length >= 3) {
      SuppliersRepository.getBySearchString(supplierSearchString)
        .then((res) => {
          setSuppliers(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [supplierSearchString]);

  useEffect(() => {
    if (destinationsSearch?.length >= 3) {
      DestinationsRepository.getBySearchString(destinationsSearch)
        .then((res) => {
          setDestinations(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [destinationsSearch]);

  const handleNewTicketArrived = (msg) => {
    //push parsedTicket to new arrived tickets array
    setNewArrivedTickets((prevState) => {
      return [msg, ...prevState];
    });
    // dispatch(
    //   notifyShowInfoMessage(
    //     `New ticket created for client ${msg?.organization?.nameEn} for destiantion ${msg?.destination?.name}`
    //   )
    // );
  };

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const handleChangeDateRange = () => {
    {
      let fromDay = "fromDay";
      let toDay = "toDay";
      state.map((state) => {
        let start = moment(state.startDate).toString();
        let end = moment(state.endDate).toString();
        let tmp = { ...searchParams };
        tmp[fromDay] = start;
        tmp[toDay] = end;
        setSearchParams(tmp);
      });
    }
  };

  const [dateCreatedState, setDateCreatedState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const handleDateCreated = () => {
    {
      let fromDayCreated = "fromDayCreated";
      let toDayCreated = "toDayCreated";
      dateCreatedState.map((dateCreatedState) => {
        let start = moment(dateCreatedState.startDate).toString();
        let end = moment(dateCreatedState.endDate).toString();
        let tmp = { ...searchParams };
        tmp[fromDayCreated] = start;
        tmp[toDayCreated] = end;
        setSearchParams(tmp);
      });
    }
  };

  const fromDateCreated = new Date(searchParams.fromDayCreated);
  const formattedFromDateCreated = fromDateCreated.toString().substring(4, 15);
  const toDateCreated = new Date(searchParams.toDayCreated);
  const formattedToDateCreated = toDateCreated.toString().substring(4, 15);

  const fromDepartureDay = new Date(searchParams.fromDay);
  const day = String(fromDepartureDay.getDate()).padStart(2, "0");
  const month = String(fromDepartureDay.getMonth() + 1).padStart(2, "0");
  const year = fromDepartureDay.getFullYear();
  const formattedFromDepartureDay = `${day}.${month}.${year}`;

  const toDepartureDay = new Date(searchParams.toDay);
  const dayTo = String(toDepartureDay.getDate()).padStart(2, "0");
  const monthTo = String(toDepartureDay.getMonth() + 1).padStart(2, "0");
  const yearTo = toDepartureDay.getFullYear();
  const formattedToDepartureDay = `${dayTo}.${monthTo}.${yearTo}`;

  const handleClickChangeSortBy = (event) => {
    let orderValue = event.target.value;
    if (params?.orderDirection === "ASC") {
      navigate(
        `/client/bookings/${0}/${params.size}/${JSON.stringify(
          JSON.parse(params.params)
        )}/${orderValue}/DESC`
      );
    } else {
      navigate(
        `/client/bookings/${0}/${params.size}/${JSON.stringify(
          JSON.parse(params.params)
        )}/${orderValue}/ASC`
      );
    }
  };

  const handleClickChangeOrderBy = (event) => {
    navigate(
      `/client/bookings/${0}/${params.size}/${JSON.stringify(
        JSON.parse(params.params)
      )}/${params.orderValue || "dateCreated"}/${event.target.value}`
    );
  };

  const handleRemoveTicketFromGroup = (ticketId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to remove this ticket from group?"
    );

    if (!isConfirmed) {
      return;
    }
    TicketGroupRepository.removeTicketFromGroup(ticketId)
      .then((res) => {
        loadTicketGroupData();
        dispatch(
          notifyShowSuccessMessage("Ticket removed from group successfully")
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRemoveTicketGroup = (ticketGroupId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete the ticket group?"
    );

    if (!isConfirmed) {
      return;
    }
    TicketGroupRepository.removeTicketGroup(ticketGroupId)
      .then((res) => {
        loadTicketGroupData();
        dispatch(notifyShowSuccessMessage("Ticket group removed successfully"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isEmpty = (value) =>
    value === "" || value === undefined || value === null;

  const [selectAllChecked, setSelectAllChecked] = React.useState(false);

  const handleSelectAll = () => {
    if (selectAllChecked) {
      setSelectedTickets([]);
    } else {
      setSelectedTickets(data?.content);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const content = data?.content || [];

  const totalSurcharges = useMemo(() => {
    if (content) {
      return content.reduce((total, item) => {
        if (item?.organization?.showPrice) {
          return (
            total + (+item?.calculatedPriceWithFees - +item?.calculatedPrice)
          );
        }
        return total;
      }, 0);
    }
  }, [content]);

  const totalPrices = useMemo(() => {
    return content.reduce((total, item) => {
      if (item?.organization?.showPrice) {
        return (
          total +
          (item?.adjustedCalculatedPrice
            ? +item?.adjustedCalculatedPrice
            : +item?.calculatedPriceWithFees)
        );
      }
      return total;
    }, 0);
  }, [content]);

  const getGrandTotalPrices = () => {
    setLoadingGrandTotalPrices(true);
    let tmp = params?.params
      ? params?.params
      : JSON.stringify({ ...searchParams });
    TicketRepository.grandTotalPricesForAllTickets(
      0,
      data?.totalElements + 1,
      tmp,
      params?.orderValue,
      params?.orderDirection
    )
      .then((res) => {
        setLoadingGrandTotalPrices(false);
        setGrandTotalTicketsPrice(
          parseFloat(res.data.body.grandTotalPrices)?.toFixed(2)
        );
        setGrandTotalSurcharges(
          parseFloat(res.data.body.grandTotalSurcharges)?.toFixed(2)
        );
      })
      .catch((err) => {
        dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        setLoadingGrandTotalPrices(false);
      });
  };

  useEffect(() => {
    if (!totalsPerPage && !grandTotalSurcharges && !grandTotalTicketsPrice) {
      getGrandTotalPrices();
    }
  }, [totalsPerPage]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Stack
            direction="row"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="h5"
              key="2"
              color="text.primary"
              sx={{
                fontWeight: "bold",
                typography: { xs: "h4", md: "h5" },
                alignContent: "center",
                marginLeft: { xs: "8px", md: "0px" },
              }}
            >
              Your Bookings
              <IconButton
                onClick={() => loadData()}
                sx={{
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  padding: "1px",
                  minWidth: "auto",
                  height: "auto",
                  marginLeft: "5px",
                  display: { xs: "none", md: "inline-flex" },
                }}
              >
                <Icon
                  style={{ color: theme.palette.darkContrast.main }}
                  fontSize="20"
                >
                  refresh
                </Icon>
              </IconButton>
            </Typography>
            <Box
              sx={{
                display: { xs: "inline-flex", md: "none", marginRight: "11px" },
              }}
            >
              <IconButton
                onClick={() => loadData()}
                sx={{
                  border: "1px solid lightGray",
                  borderRadius: "5px",
                  padding: "1px",
                  minWidth: 55,
                  height: 55,
                  marginLeft: "5px",
                }}
              >
                <Icon
                  style={{
                    color: theme.palette.darkContrast.main,
                    fontSize: "28px",
                  }}
                >
                  refresh
                </Icon>
              </IconButton>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            style={{
              boxShadow: "none",
              backgroundColor: "transparent",
            }}
            expanded={expanded}
          >
            <AccordionSummary
              style={{
                cursor: "default",
                backgroundColor: theme.palette.dashboardBackground.main,
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                lg={5}
                xl={4.5}
                style={{
                  textAlign: "left",
                  marginLeft: isSmallScreen ? "-8px" : "-15px",
                  marginTop: "-10px",
                }}
              >
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={8} md={4}>
                    <Typography>Booking status</Typography>
                    <FormControl fullWidth>
                      <Select
                        labelId="select_status"
                        value={searchParams?.status ? searchParams?.status : ""}
                        onChange={(event) => {
                          const index = ticketStatuses.findIndex(
                            (tStatus) => tStatus === event.target.value
                          );
                          setActiveStatus(index);
                          handleChange("status", event.target.value);
                        }}
                        color="lightGray"
                        disabled={loading}
                        style={{
                          width: "100%",
                          fontSize: "12px",
                          borderRadius: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        <MenuItem
                          value="ALL"
                          style={{
                            fontWeight:
                              searchParams?.status === "" ? "bold" : "normal",
                          }}
                        >
                          ALL BOOKINGS
                        </MenuItem>
                        <MenuItem
                          value="ALL_ACTIVE"
                          style={{
                            fontWeight:
                              searchParams?.status === "ALL_ACTIVE"
                                ? "bold"
                                : "normal",
                          }}
                        >
                          ACTIVE BOOKINGS
                        </MenuItem>
                        {ticketStatuses?.map(
                          (tStatus, index) =>
                            !(
                              tStatus == "CANCELLED" ||
                              tStatus == "BLANKO" ||
                              tStatus == "TEST"
                            ) && (
                              <MenuItem
                                key={index}
                                value={tStatus}
                                style={{
                                  color: getTicketStatusColor(tStatus),
                                  fontWeight:
                                    activeStatus === index ? "bold" : "normal",
                                }}
                              >
                                {tStatus.replaceAll("_", " ")}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  {isSmallScreen ? (
                    <Grid item xs={4} sx={{ display: "flex" }}>
                      <Box sx={{ width: "100%", position: "relative" }}>
                        <Button
                          fullWidth
                          color="primary"
                          variant={expanded ? "contained" : "outlined"}
                          onClick={() => setExpanded(!expanded)}
                          sx={{
                            height: "50px",
                            fontSize: "15px",
                            marginTop: "16px",
                            width: "100%",
                          }}
                          endIcon={
                            expanded ? (
                              <Tooltip
                                title={"Clear filters"}
                                arrow
                                placement="right"
                              >
                                <IconButton
                                  size="small"
                                  sx={{
                                    color:
                                      theme.palette.dashboardBackground.main,
                                    fontSize: "15px",
                                    width: "10px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() => {
                                    setSearchParams({ status: "ALL_ACTIVE" });
                                    setActiveStatus(undefined);
                                    setShowSelectTicketsCheckboxContactSupplier(
                                      false
                                    );
                                    setShowSelectTicketsCheckboxContactSupplier2(
                                      false
                                    );
                                    navigate(
                                      `/client/bookings/${params.page}/${
                                        params.size
                                      }/${JSON.stringify(
                                        JSON.parse(params.params)
                                      )}/${params?.orderValue}/${
                                        params?.orderDirection
                                      }`
                                    );
                                  }}
                                >
                                  <Icon>clear</Icon>
                                </IconButton>
                              </Tooltip>
                            ) : null
                          }
                        >
                          F
                          <span
                            style={{
                              textTransform: "lowercase",
                            }}
                          >
                            ilters
                          </span>
                        </Button>
                        {checkNumberOfFilters() > 0 && (
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: -8,
                              right: -8,
                              backgroundColor: theme.palette.primary.main,
                              color: "white",
                              borderRadius: 1,
                              fontSize: "12px",
                              padding: "0 6px",
                              minWidth: "20px",
                              height: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "1px solid",
                              borderColor: expanded
                                ? theme.palette.dashboardBackground.main
                                : "none",
                            }}
                          >
                            {checkNumberOfFilters()}
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item md={3}>
                      <Button
                        color="primary"
                        variant={expanded ? "contained" : "outlined"}
                        onClick={() => setExpanded(!expanded)}
                        style={{
                          transition: "width 0.3s ease",
                          width: expanded ? "125px" : "100px",
                          height: "50px",
                          fontSize: "15px",
                          marginTop: "16px",
                        }}
                        endIcon={
                          expanded ? (
                            <Tooltip
                              title={"Clear filters"}
                              arrow
                              placement="right"
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  color: theme.palette.dashboardBackground.main,
                                  fontSize: "15px",
                                  width: "10px",
                                  marginLeft: "5px",
                                }}
                                onClick={() => {
                                  setSearchParams({ status: "ALL_ACTIVE" });
                                  setActiveStatus(undefined);
                                  setShowSelectTicketsCheckboxContactSupplier(
                                    false
                                  );
                                  setShowSelectTicketsCheckboxContactSupplier2(
                                    false
                                  );

                                  navigate(
                                    `/client/bookings/${params.page}/${
                                      params.size
                                    }/${JSON.stringify(
                                      JSON.parse(params.params)
                                    )}/${params?.orderValue}/${
                                      params?.orderDirection
                                    }`
                                  );
                                }}
                              >
                                <Icon>clear</Icon>
                              </IconButton>
                            </Tooltip>
                          ) : (
                            ""
                          )
                        }
                      >
                        F
                        <span
                          style={{
                            textTransform: "lowercase",
                          }}
                        >
                          ilters
                        </span>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              style={{
                // marginLeft: isSmallScreen ? "-6px" : "-15px",
                paddingLeft: isSmallScreen ? "9px" : "1px",
                marginRight: isSmallScreen ? "-5px" : "0px",
                backgroundColor: theme.palette.dashboardBackground.main,
                marginTop: "-18px",
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={1.2}>
                  <TextField
                    onChange={(e) =>
                      handleChange("numberOrReservation", e.target.value)
                    }
                    label={"Number or Reservation"}
                    value={
                      searchParams?.numberOrReservation
                        ? searchParams?.numberOrReservation
                        : ""
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={1.5} lg={1.2}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TextField
                      autoComplete="off"
                      label={"Departure"}
                      {...params}
                      helperText={null}
                      fullWidth
                      value={
                        searchParams.fromDay && searchParams.toDay
                          ? formattedFromDepartureDay +
                            " to " +
                            formattedToDepartureDay
                          : ""
                      }
                      onClick={() => handleDatePicker()}
                      InputProps={{
                        endAdornment: <CalendarMonth />,
                      }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6} md={1.5} lg={1.2}>
                  <Autocomplete
                    noOptionsText={LOCALE.noOptionsText}
                    autoComplete={false}
                    value={
                      searchParams?.destination ? searchParams?.destination : ""
                    }
                    onChange={(event, newValue) => {
                      handleChange("destination", newValue);
                    }}
                    inputValue={destinationsSearch ? destinationsSearch : ""}
                    onInputChange={(event, newInputValue) => {
                      setDestinationsSearch(newInputValue);
                    }}
                    options={destinations?.map(
                      (destination) => destination.name
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={LOCALE.destination}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                {AuthRepository.getUserDetails()?.organization
                  ?.showSupplier && (
                  <Grid item xs={6} md={1.2}>
                    <Autocomplete
                      noOptionsText={LOCALE.noOptionsText}
                      autoComplete={false}
                      value={
                        searchParams["supplier"]
                          ? suppliers?.find(
                              (supplier) =>
                                supplier.id === searchParams["supplier"]
                            )
                          : null
                      }
                      onChange={(event, newValue) => {
                        handleChange(
                          "supplier",
                          newValue?.id ? newValue?.id : ""
                        );
                      }}
                      inputValue={
                        supplierSearchString ? supplierSearchString : ""
                      }
                      onInputChange={(event, newInputValue) => {
                        setSupplierSearchString(newInputValue);
                      }}
                      options={suppliers}
                      getOptionLabel={(option) => option?.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Supplier"
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={1.2}>
                  <TextField
                    onChange={(e) =>
                      handleChange(
                        "truckPlateNumber",
                        e.target.value.trim().replace(/[^a-z0-9]/gi, "")
                      )
                    }
                    label={"Truck plate number"}
                    value={
                      searchParams?.truckPlateNumber
                        ? searchParams?.truckPlateNumber
                        : ""
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={1.7} lg={1.2}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TextField
                      autoComplete="off"
                      label={LOCALE.date_created}
                      {...params}
                      helperText={null}
                      fullWidth
                      value={
                        searchParams.fromDayCreated && searchParams.toDayCreated
                          ? formattedFromDateCreated +
                            " to " +
                            formattedToDateCreated
                          : ""
                      }
                      onClick={() => handleDateCreatedCalendar()}
                      InputProps={{
                        endAdornment: <CalendarMonth />,
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={1.2}>
                  <Autocomplete
                    noOptionsText={LOCALE.noOptionsText}
                    autoComplete={false}
                    value={
                      searchParams["region"]
                        ? regions?.find(
                            (region) => region.id === searchParams["region"]
                          )
                        : null
                    }
                    onChange={(event, newValue) => {
                      handleChange("region", newValue?.id ? newValue?.id : "");
                    }}
                    inputValue={regionsSearch ? regionsSearch : ""}
                    onInputChange={(event, newInputValue) => {
                      setRegionsSearch(newInputValue);
                    }}
                    options={regions}
                    getOptionLabel={(option) => option?.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Region"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={8} sx={{ display: { xs: "block", md: "none" } }}>
                  <InputLabel id="sort-by-label">Sort by</InputLabel>
                  <FormControl fullWidth>
                    <Select
                      value={params?.orderValue || "dateCreated"}
                      onChange={(e) => handleClickChangeSortBy(e)}
                    >
                      <MenuItem value="dateCreated">Day Created</MenuItem>
                      <MenuItem value="departureDay">Departure Day</MenuItem>
                      <MenuItem value="destination">Destination</MenuItem>
                      <MenuItem value="productDestination.supplier">
                        Supplier
                      </MenuItem>
                      <MenuItem value="ticketNumber">Booking Number</MenuItem>
                      <MenuItem value="reservationCode">
                        Reservation Code
                      </MenuItem>
                      <MenuItem value="truckPlateNumber">
                        Truck Plate Number
                      </MenuItem>
                      <MenuItem value="vehicleLength">Vehicle Length</MenuItem>
                      <MenuItem value="calculatedPriceWithFees">Price</MenuItem>
                      <MenuItem value="assignedTo">Assigned To</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ display: { xs: "block", md: "none" } }}>
                  <InputLabel id="order-by-label">Order</InputLabel>
                  <FormControl fullWidth>
                    <Select
                      value={params?.orderDirection || "DESC"}
                      onChange={(e) => handleClickChangeOrderBy(e)}
                    >
                      <MenuItem value="ASC">Ascending</MenuItem>
                      <MenuItem value="DESC">Descending</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
            height: "60vh",
            overflowY: "auto",
            scrollbarWidth: "none", // For Firefox
            "&::-webkit-scrollbar": {
              display: "none", // For Webkit browsers (Chrome, Safari, Edge)
            },
          }}
        >
          <Table
            sx={{
              borderSpacing: "8px",
              borderCollapse: "separate",
              paddingBottom: expanded ? "150px" : "10px",
            }}
          >
            <TableBody>
              <ClientTicketListTableBodyMobile
                showSelectTicketsCheckboxContactSupplier={
                  showSelectTicketsCheckboxContactSupplier
                }
                showSelectTicketsCheckboxContactSupplier2={
                  showSelectTicketsCheckboxContactSupplier2
                }
                loading={loading}
                data={data?.content}
                setHoveredRow={setHoveredRow}
                hoveredRow={hoveredRow}
                theme={theme}
                selectedTickets={selectedTickets}
                setSelectedTickets={setSelectedTickets}
                handleAssignToCurrentUser={handleAssignToCurrentUser}
                showAlternativeTickets={showAlternativeTickets}
                alternativeTickets={alternativeTickets}
                setAlternativeTickets={setAlternativeTickets}
                navigate={navigate}
                size={size}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                setShowAlternativeTickets={setShowAlternativeTickets}
                showTicketGroups={showTicketGroups}
                selectedContactSupplier={selectedContactSupplier}
                handleRemoveTicketFromGroup={handleRemoveTicketFromGroup}
                setSelectedContactSupplier={setSelectedContactSupplier}
                setSelectAllChecked={setSelectAllChecked}
                selectAllChecked={selectAllChecked}
                newlyAddedTicketNumber={newlyAddedTicketNumber}
                handleClickChangeOrderBy={handleClickChangeOrderBy}
                params={params}
                expandedFilters={expanded}
              />
            </TableBody>
            <TableFooter
              style={{
                position: "fixed",
                bottom: 0,
                zIndex: 5,
                backgroundColor: theme.palette.background.paper,
                width: "100%",
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TableRow>
                <TableCell colSpan={20} sx={{ borderBottom: "none" }}>
                  <Grid item xs={12} justifyContent="center">
                    {AuthRepository.getUserDetails()?.organization
                      ?.showPrice && (
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "5px 0 15px 0",
                        }}
                      >
                        <Grid item xs={2}>
                          <Tooltip
                            title={
                              <Typography variant="subtitle2">
                                Refresh page
                              </Typography>
                            }
                          >
                            <IconButton
                              onClick={() => loadData()}
                              sx={{
                                border: "1px solid",
                                borderColor: theme.palette.lightGray.main,
                                borderRadius: "5px",
                                padding: "0px",
                                width: 48,
                                height: 48,
                                minWidth: "auto",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: "20px",
                              }}
                              size="large"
                            >
                              <Icon
                                style={{
                                  color: theme.palette.darkContrast.main,
                                  fontSize: "28px",
                                }}
                              >
                                {" "}
                                refresh
                              </Icon>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Tooltip
                            title={
                              <Typography variant="subtitle2">
                                {totalsPerPage
                                  ? "Total surcharges per page"
                                  : "Total surcharges for all pages"}
                              </Typography>
                            }
                          >
                            <Button
                              sx={{
                                color: theme.palette.primary.main,
                                fontSize: "18px",
                                height: 48,
                                width: 105,
                              }}
                              variant="outlined"
                              onClick={() => {
                                setTotalsPerPage(!totalsPerPage);
                              }}
                            >
                              {totalsPerPage
                                ? totalSurcharges?.toFixed(2)
                                : grandTotalSurcharges}
                              €
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title={
                              <Typography variant="subtitle2">
                                {totalsPerPage
                                  ? "Grand total per page"
                                  : "Grand total for all pages"}
                              </Typography>
                            }
                          >
                            <Button
                              sx={{
                                color: "white",
                                backgroundColor: theme.palette.primary.main,
                                marginLeft: "10px",
                                marginRight: "0px",
                                fontSize: "18px",
                                height: 48,
                                width: 115,
                              }}
                              variant="contained"
                              onClick={() => setTotalsPerPage(!totalsPerPage)}
                            >
                              {totalsPerPage
                                ? totalPrices?.toFixed(2)
                                : grandTotalTicketsPrice}
                              €
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Divider sx={{ marginBottom: "10px" }} />
                  <Grid
                    container
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Pagination
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          "& .MuiPagination-ul": {
                            display: "flex",
                            justifyContent: "center",
                            flexGrow: 1,
                          },
                          "& .MuiPaginationItem-root": {
                            fontSize: "24px",
                          },
                          "& .MuiPaginationItem-page.Mui-selected": {
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.5
                            ),
                            color: "white",
                            height: 48,
                            width: 48,
                          },
                          "& .MuiPaginationItem-previousNext": {
                            border: "1px solid",
                            borderColor: theme.palette.lightGray.main,
                            height: 48,
                            width: 48,
                            "& svg": {
                              fontSize: "24px",
                            },
                          },
                        }}
                        count={
                          (data?.totalElements % size === 0
                            ? data?.totalElements / size
                            : Math.floor(data?.totalElements / size) + 1) || 0
                        }
                        shape="rounded"
                        size="small"
                        page={data?.number + 1 || 0}
                        onChange={(event, value) => {
                          handleChangePage(event, value - 1);
                        }}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          sx={{
            height: expanded ? "65vh" : "73vh",
            overflowY: "auto",
            display: { xs: "none", md: "block" },
          }}
        >
          <Table
            sx={{
              borderCollapse: "separate",
              borderSpacing: " 0 8px",
            }}
          >
            <TableHead
              sx={{
                position: "sticky",
                zIndex: 5,
                top: 0,
                backgroundColor: theme.palette.dashboardBackground.main,
                width: "100%",
              }}
            >
              <TableRow>
                {showTicketGroups && <TableCell></TableCell>}
                {(showSelectTicketsCheckboxContactSupplier ||
                  showSelectTicketsCheckboxContactSupplier2) && (
                  <TableCell>
                    <Checkbox
                      color="warning"
                      checked={selectAllChecked}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                )}

                <TableCell
                  style={{
                    align: "left",
                    width: "100px",
                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Status
                    <IconButton
                      style={{
                        width: "20px",
                        height: "20px",
                        padding: "0px",
                        color: theme.palette.primary.main,
                        opacity: "50%",
                      }}
                      value={"status"}
                      onClick={handleClickChangeOrderBy}
                    >
                      {params?.orderValue === "status" ? (
                        params?.orderDirection == "DESC" ? (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        ) : (
                          <Icon style={{ transform: "rotate(-90deg)" }}>
                            play_arrow
                          </Icon>
                        )
                      ) : (
                        <Icon style={{ transform: "rotate(90deg)" }}>
                          play_arrow
                        </Icon>
                      )}
                    </IconButton>
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    align: "left",
                    width: "100px",
                    color: "#8f8f8f",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Number
                    <IconButton
                      style={{
                        width: "20px",
                        height: "20px",
                        padding: "0px",
                        color: theme.palette.primary.main,
                        opacity: "50%",
                      }}
                      value={"ticketNumber"}
                      onClick={handleClickChangeOrderBy}
                    >
                      {params?.orderValue === "ticketNumber" ? (
                        params?.orderDirection == "DESC" ? (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        ) : (
                          <Icon style={{ transform: "rotate(-90deg)" }}>
                            play_arrow
                          </Icon>
                        )
                      ) : (
                        <Icon style={{ transform: "rotate(90deg)" }}>
                          play_arrow
                        </Icon>
                      )}
                    </IconButton>
                  </Typography>
                </TableCell>

                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Reservation
                    <IconButton
                      style={{
                        width: "20px",
                        height: "20px",
                        padding: "0px",
                        color: theme.palette.primary.main,
                        opacity: "50%",
                      }}
                      value={"reservationCode"}
                      onClick={handleClickChangeOrderBy}
                    >
                      {params?.orderValue === "reservationCode" ? (
                        params?.orderDirection == "DESC" ? (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        ) : (
                          <Icon style={{ transform: "rotate(-90deg)" }}>
                            play_arrow
                          </Icon>
                        )
                      ) : (
                        <Icon style={{ transform: "rotate(90deg)" }}>
                          play_arrow
                        </Icon>
                      )}
                    </IconButton>
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Destination
                    <IconButton
                      style={{
                        width: "20px",
                        height: "20px",
                        padding: "0px",
                        color: theme.palette.primary.main,
                        opacity: "50%",
                      }}
                      value={"destination"}
                      onClick={handleClickChangeOrderBy}
                    >
                      {params?.orderValue === "destination" ? (
                        params?.orderDirection == "DESC" ? (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        ) : (
                          <Icon style={{ transform: "rotate(-90deg)" }}>
                            play_arrow
                          </Icon>
                        )
                      ) : (
                        <Icon style={{ transform: "rotate(90deg)" }}>
                          play_arrow
                        </Icon>
                      )}
                    </IconButton>
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",

                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Departure
                    <IconButton
                      style={{
                        width: "20px",
                        height: "20px",
                        padding: "0px",
                        color: theme.palette.primary.main,
                        opacity: "50%",
                      }}
                      value={"departureDay"}
                      onClick={handleClickChangeOrderBy}
                    >
                      {params?.orderValue === "departureDay" ? (
                        params?.orderDirection == "DESC" ? (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        ) : (
                          <Icon style={{ transform: "rotate(-90deg)" }}>
                            play_arrow
                          </Icon>
                        )
                      ) : (
                        <Icon style={{ transform: "rotate(90deg)" }}>
                          play_arrow
                        </Icon>
                      )}
                    </IconButton>
                  </Typography>
                </TableCell>
                {AuthRepository.getUserDetails()?.organization
                  ?.showSupplier && (
                  <TableCell
                    style={{
                      textAlign: "left",
                      color: "#8f8f8f",
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      noWrap
                      variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                    >
                      Supplier
                      <IconButton
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0px",
                          color: theme.palette.primary.main,
                          opacity: "50%",
                        }}
                        value={"productDestination.supplier"}
                        onClick={handleClickChangeOrderBy}
                      >
                        {params?.orderValue ===
                        "productDestination.supplier" ? (
                          params?.orderDirection == "DESC" ? (
                            <Icon style={{ transform: "rotate(90deg)" }}>
                              play_arrow
                            </Icon>
                          ) : (
                            <Icon style={{ transform: "rotate(-90deg)" }}>
                              play_arrow
                            </Icon>
                          )
                        ) : (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        )}
                      </IconButton>
                    </Typography>
                  </TableCell>
                )}
                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",

                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Length
                    <IconButton
                      style={{
                        width: "20px",
                        height: "20px",
                        padding: "0px",
                        color: theme.palette.primary.main,
                        opacity: "50%",
                      }}
                      value={"vehicleLength"}
                      onClick={handleClickChangeOrderBy}
                    >
                      {params?.orderValue === "vehicleLength" ? (
                        params?.orderDirection == "DESC" ? (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        ) : (
                          <Icon style={{ transform: "rotate(-90deg)" }}>
                            play_arrow
                          </Icon>
                        )
                      ) : (
                        <Icon style={{ transform: "rotate(90deg)" }}>
                          play_arrow
                        </Icon>
                      )}
                    </IconButton>
                  </Typography>
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Plate
                    <IconButton
                      style={{
                        width: "20px",
                        height: "20px",
                        padding: "0px",
                        color: theme.palette.primary.main,
                        opacity: "50%",
                      }}
                      value={"truckPlateNumber"}
                      onClick={handleClickChangeOrderBy}
                    >
                      {params?.orderValue === "truckPlateNumber" ? (
                        params?.orderDirection == "DESC" ? (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        ) : (
                          <Icon style={{ transform: "rotate(-90deg)" }}>
                            play_arrow
                          </Icon>
                        )
                      ) : (
                        <Icon style={{ transform: "rotate(90deg)" }}>
                          play_arrow
                        </Icon>
                      )}
                    </IconButton>
                  </Typography>
                </TableCell>

                <TableCell
                  style={{
                    textAlign: "left",
                    color: "#8f8f8f",
                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Contributors
                    <IconButton
                      style={{
                        width: "20px",
                        height: "20px",
                        padding: "0px",
                        color: theme.palette.primary.main,
                        opacity: "50%",
                      }}
                      value={"assignedTo"}
                      onClick={handleClickChangeOrderBy}
                    >
                      {params?.orderValue === "assignedTo" ? (
                        params?.orderDirection == "DESC" ? (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        ) : (
                          <Icon style={{ transform: "rotate(-90deg)" }}>
                            play_arrow
                          </Icon>
                        )
                      ) : (
                        <Icon style={{ transform: "rotate(90deg)" }}>
                          play_arrow
                        </Icon>
                      )}
                    </IconButton>
                  </Typography>
                </TableCell>

                <TableCell
                  style={{
                    textAlign: "center",
                    width: 100,
                    color: "#8f8f8f",

                    borderBottom: "none",
                  }}
                >
                  <Typography
                    noWrap
                    variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                  >
                    Flag
                  </Typography>
                </TableCell>
                {/* <TableCell
                    style={{
                      textAlign: "right",
                      color: "#8f8f8f",
                    }}
                  >
                    <Typography variant="subtitle1">
                      Date created
                      <IconButton
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0px",
                        }}
                        value={"dateCreated"}
                        onClick={handleClickChangeOrderBy}
                      >
                        {params?.orderValue === "dateCreated" ? (
                          params?.orderDirection == "DESC" ? (
                            <Icon>keyboard_arrow_up</Icon>
                          ) : (
                            <Icon>keyboard_arrow_down</Icon>
                          )
                        ) : (
                          <Icon>unfold_more</Icon>
                        )}
                      </IconButton>
                    </Typography>
                  </TableCell> */}
                {AuthRepository.getUserDetails()?.organization?.showPrice && (
                  <>
                    <TableCell
                      style={{
                        textAlign: "right",
                        color: "#8f8f8f",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        noWrap
                        variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                      >
                        Surcharges
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "right",
                        color: "#8f8f8f",
                        borderBottom: "none",
                      }}
                    >
                      <Typography
                        noWrap
                        variant={isMediumScreen ? "subtitle2" : "subtitle1"}
                      >
                        Total price
                        <IconButton
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "0px",
                            color: theme.palette.primary.main,
                            opacity: "50%",
                          }}
                          value={"calculatedPriceWithFees"}
                          onClick={handleClickChangeOrderBy}
                        >
                          {params?.orderValue === "calculatedPriceWithFees" ? (
                            params?.orderDirection == "DESC" ? (
                              <Icon style={{ transform: "rotate(90deg)" }}>
                                play_arrow
                              </Icon>
                            ) : (
                              <Icon style={{ transform: "rotate(-90deg)" }}>
                                play_arrow
                              </Icon>
                            )
                          ) : (
                            <Icon style={{ transform: "rotate(90deg)" }}>
                              play_arrow
                            </Icon>
                          )}
                        </IconButton>
                      </Typography>
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            {!showTicketGroups && (
              <>
                <TableBody>
                  <ClientTicketListTableBody
                    showSelectTicketsCheckboxContactSupplier={
                      showSelectTicketsCheckboxContactSupplier
                    }
                    showSelectTicketsCheckboxContactSupplier2={
                      showSelectTicketsCheckboxContactSupplier2
                    }
                    loading={loading}
                    data={data?.content}
                    setHoveredRow={setHoveredRow}
                    hoveredRow={hoveredRow}
                    theme={theme}
                    selectedTickets={selectedTickets}
                    setSelectedTickets={setSelectedTickets}
                    handleAssignToCurrentUser={handleAssignToCurrentUser}
                    showAlternativeTickets={showAlternativeTickets}
                    alternativeTickets={alternativeTickets}
                    setAlternativeTickets={setAlternativeTickets}
                    navigate={navigate}
                    size={size}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    setShowAlternativeTickets={setShowAlternativeTickets}
                    showTicketGroups={showTicketGroups}
                    selectedContactSupplier={selectedContactSupplier}
                    handleRemoveTicketFromGroup={handleRemoveTicketFromGroup}
                    setSelectedContactSupplier={setSelectedContactSupplier}
                    setSelectAllChecked={setSelectAllChecked}
                    selectAllChecked={selectAllChecked}
                    newlyAddedTicketNumber={newlyAddedTicketNumber}
                    expanded={expanded}
                  />
                </TableBody>
              </>
            )}
            {showTicketGroups && (
              <>
                <TableBody>
                  {groupData?.content?.map((item, index) => (
                    <>
                      <TableRow
                        style={{
                          backgroundColor: theme.palette.success.light,
                          color: "white",
                        }}
                      >
                        <TableCell
                          colSpan={9}
                          style={{
                            color: "white",
                          }}
                        >
                          <Typography variant="body1">{`Group name: ${item.name}  Group reason: ${item.reason}`}</Typography>
                        </TableCell>
                        <TableCell colSpan={20} style={{ textAlign: "right" }}>
                          <Tooltip title="Remove group" placement="top" arrow>
                            <IconButton
                              style={{ padding: 0, color: "white" }}
                              onClick={() => {
                                handleRemoveTicketGroup(item.id);
                              }}
                            >
                              <Icon>delete</Icon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      <ClientTicketListTableBody
                        showSelectTicketsCheckboxContactSupplier={
                          showSelectTicketsCheckboxContactSupplier
                        }
                        showSelectTicketsCheckboxContactSupplier2={
                          showSelectTicketsCheckboxContactSupplier2
                        }
                        loading={loading}
                        data={item.tickets}
                        setHoveredRow={setHoveredRow}
                        hoveredRow={hoveredRow}
                        theme={theme}
                        selectedTickets={selectedTickets}
                        setSelectedTickets={setSelectedTickets}
                        handleAssignToCurrentUser={handleAssignToCurrentUser}
                        showAlternativeTickets={showAlternativeTickets}
                        alternativeTickets={alternativeTickets}
                        setAlternativeTickets={setAlternativeTickets}
                        navigate={navigate}
                        size={size}
                        handleChangePage={handleChangePage}
                        handleRemoveTicketFromGroup={
                          handleRemoveTicketFromGroup
                        }
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        setShowAlternativeTickets={setShowAlternativeTickets}
                        showTicketGroups={showTicketGroups}
                      />
                    </>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      sx={{
                        borderBottom: "none",
                      }}
                      rowsPerPageOptions={[5, 10, 25]}
                      count={groupData?.totalElements}
                      rowsPerPage={ticketGroupSize}
                      page={groupData?.number}
                      labelRowsPerPage={LOCALE.rows_per_page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": LOCALE.rows_per_page,
                        },
                        native: true,
                      }}
                      onPageChange={(event, newPage) => {
                        setTicketGroupPage(newPage);
                      }}
                      onRowsPerPageChange={(event) => {
                        setTicketGroupSize(parseInt(event.target.value, 10));
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </>
            )}

            <TableFooter
              style={{
                position: "fixed",
                bottom: 0,
                zIndex: 5,
                backgroundColor: theme.palette.dashboardBackground.main,
                width: "100%",
              }}
            >
              <Grid container spacing={1} sx={{ display: "flex" }}>
                <Grid item xs={4}>
                  <TablePagination
                    sx={{
                      borderBottom: "none",
                      "& .MuiTablePagination-select": {
                        fontSize: isMediumScreen ? "12px" : "18px",
                        border: "1px solid",
                        borderColor: theme.palette.lightGray.main,
                        padding: isMediumScreen ? "5px 8px" : "5px 15px",
                        borderRadius: "4px",
                      },
                      "& .MuiTablePagination-displayedRows": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiTablePagination-actions button": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiIconButton-root": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiSelect-icon": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiTablePagination-displayedRows": {
                        marginLeft: "-15px",
                        fontSize: isMediumScreen ? "12px" : "15px",
                        color: theme.palette.lightGray.main,
                      },
                    }}
                    rowsPerPageOptions={
                      AuthRepository.hasAnyRole(["ROLE_ADMINISTRATION"])
                        ? [5, 10, 15, 25, 50, 75, 100]
                        : [5, 10, 15, 25]
                    }
                    rowsPerPage={params?.size ? params?.size : size}
                    labelDisplayedRows={() => `of ${data?.totalElements}`}
                    labelRowsPerPage={
                      <span
                        style={{
                          fontSize: isMediumScreen ? "12px" : "15px",
                          marginLeft: "-20px",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        {LOCALE.rows_per_page}
                      </span>
                    }
                    SelectProps={{
                      inputProps: {
                        fontSize: isMediumScreen ? "15px" : "20px",
                        "aria-label": LOCALE.rows_per_page,
                      },
                      native: true,
                    }}
                    onRowsPerPageChange={(event, value) => {
                      handleChangeRowsPerPage(event);
                    }}
                    page={data?.number || 0}
                    count={data?.totalElements || 0}
                    size="large"
                    ActionsComponent={() => null}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Pagination
                    sx={{
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "center",
                      "& .MuiPaginationItem-root": {
                        fontSize: isMediumScreen ? "15px" : "20px",
                      },
                      "& .MuiPaginationItem-page.Mui-selected": {
                        backgroundColor: theme.palette.secondary.main,
                        opacity: "50%",
                        color: "white",
                      },
                      "& .MuiPaginationItem-previousNext": {
                        border: "1px solid",
                        borderColor: theme.palette.lightGray.main,
                      },
                    }}
                    count={
                      (data?.totalElements % size === 0
                        ? data?.totalElements / size
                        : Math.floor(data?.totalElements / size) + 1) || 0
                    }
                    shape="rounded"
                    size={isMediumScreen ? "medium" : "large"}
                    page={data?.number + 1 || 0}
                    onChange={(event, value) => {
                      handleChangePage(event, value - 1);
                    }}
                  />
                </Grid>

                <Grid item xs={3} lg={3.3}>
                  {AuthRepository.getUserDetails()?.organization?.showPrice && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Tooltip
                        title={
                          <Typography variant="subtitle2">
                            {totalsPerPage
                              ? "Total surcharges per page"
                              : "Total surcharges for all pages"}
                          </Typography>
                        }
                      >
                        <Button
                          sx={{
                            color: theme.palette.primary.main,
                            fontSize: isMediumScreen ? "15px" : "20px",
                          }}
                          variant="outlined"
                          onClick={() => setTotalsPerPage(!totalsPerPage)}
                        >
                          {totalsPerPage
                            ? totalSurcharges?.toFixed(2)
                            : grandTotalSurcharges}
                          €
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          <Typography variant="subtitle2">
                            {totalsPerPage
                              ? "Grand total per page"
                              : "Grand total for all pages"}
                          </Typography>
                        }
                      >
                        <Button
                          sx={{
                            color: "white",
                            backgroundColor: theme.palette.primary.main,
                            marginLeft: "10px",
                            marginRight: "10px",
                            fontSize: isMediumScreen ? "15px" : "20px",
                          }}
                          variant="contained"
                          onClick={() => setTotalsPerPage(!totalsPerPage)}
                        >
                          {totalsPerPage
                            ? totalPrices?.toFixed(2)
                            : grandTotalTicketsPrice}
                          €
                        </Button>
                      </Tooltip>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </TableFooter>
          </Table>
        </Grid>
      </Grid>

      <Dialog
        open={openDatePicker}
        onClose={() => {
          setOpenDatePicker(false);
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth={isSmallScreen ? "xs" : "lg"}
        hideBackdrop={true}
        style={{
          textAlign: "right",
          marginTop: "90px",
        }}
      >
        <DepartureDateCalendar
          setOpenDatePicker={setOpenDatePicker}
          handleChangeDateRange={handleChangeDateRange}
          state={state}
          setState={setState}
        ></DepartureDateCalendar>
      </Dialog>
      <Dialog
        open={openDateCreatedCalendar}
        onClose={() => {
          setOpenDateCreatedCalendar(false);
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth={isSmallScreen ? "xs" : "lg"}
        hideBackdrop={true}
        style={{ textAlign: "right", marginTop: "90px" }}
      >
        <DateCreatedCalendar
          setOpenDateCreatedCalendar={setOpenDateCreatedCalendar}
          openDateCreatedCalendar={openDateCreatedCalendar}
          handleDateCreated={handleDateCreated}
          dateCreatedState={dateCreatedState}
          setDateCreatedState={setDateCreatedState}
        ></DateCreatedCalendar>
      </Dialog>
    </>
  );
};
