import { useTheme } from "@emotion/react";
import CheckIcon from "@mui/icons-material/Check";
import EastIcon from "@mui/icons-material/East";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router";
import { ProductRepository } from "../repositories/ProductRepository";
export default function ComplexPricing({
  data,
  availableComplexDepartures,
  complexTickets,
  setComplexTickets,
  setAvailableComplexDepartures,
  selectedProductDestinations,
  setSelectedProductDestinations,
  selectedDepartures,
  selectedComplexDepartureKey,
  editTicketFlag,
  ticketGroup,
  tmpData,
  changeTicket,
  setChangeTicket,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const availableComplexDeparturesRef = React.useRef({});
  const [selectedValue, setSelectedValue] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    let tmpComplexTickets = { ...complexTickets };
    let tmp = { ...data };
    Object.keys(tmpComplexTickets).map((key) => {
      tmpComplexTickets[key].cargoWeight = tmp.cargoWeight;
      tmpComplexTickets[key].dangerousGoods = tmp.dangerousGoods;
      tmpComplexTickets[key].electricPlugin = tmp.electricPlugin;
      tmpComplexTickets[key].liveAnimals = tmp.liveAnimals;
      tmpComplexTickets[key].loaded = tmp.loaded;
      tmpComplexTickets[key].numberOfAxles = tmp.numberOfAxles;
      tmpComplexTickets[key].numberOfDrivers = tmp.numberOfDrivers;
      tmpComplexTickets[key].organization = tmp.organization;
      tmpComplexTickets[key].vehicleLength = tmp.vehicleLength;
      tmpComplexTickets[key].vehicleWidth = tmp.vehicleWidth;
      tmpComplexTickets[key].truckPlateNumber = tmp.truckPlateNumber;
      tmpComplexTickets[key].hasAdjustedPrice = tmp.hasAdjustedPrice;
      tmpComplexTickets[key].adjustedCalculatedPrice =
        tmp.adjustedCalculatedPrice;
      tmpComplexTickets[key].adjustedPrice = tmp.adjustedPrice;
    });
    setComplexTickets(tmpComplexTickets);
  }, [data]);

  useEffect(() => {
    if (
      selectedProductDestinations?.length == 0 ||
      selectedProductDestinations == undefined
    ) {
      prepopulateDefaultSelectedProductDestinations("firstLoad");
    } else if (changeTicket) {
      prepopulateDefaultSelectedProductDestinations();
    }
  }, []);

  useEffect(() => {
    if (
      ticketGroup?.reason === "Complex ticket" &&
      selectedComplexDepartureKey
    ) {
      fetchDataSequentially();
    }
  }, [selectedComplexDepartureKey]);

  const fetchDataSequentially = async () => {
    if (complexTickets && Object.keys(complexTickets).length > 0) {
      Object.keys(complexTickets).map((key) => {
        getAvailableComplexDepartures(
          complexTickets[key]?.departureDay,
          complexTickets[key]?.productDestination?.destination?.code
        );
      });
    } else {
      for (const ticket of ticketGroup.tickets) {
        await getAvailableComplexDepartures(
          ticket.departureDay,
          ticket.productDestination.destination.code
        );
      }
    }
  };

  const prepopulateDefaultSelectedProductDestinations = (load) => {
    // prepopulate selectedProductDestinations with first legs of all availableComplexDepartures
    let tmp = [];
    let tmpComplexTickets = {};
    let firstLeg = 1;
    let tmpDepartures = { ...selectedDepartures };

    Object.keys(availableComplexDepartures).map((key) => {
      if (
        availableComplexDepartures[key]?.legNumber
          .toString()
          .startsWith(firstLeg.toString())
      ) {
        if (editTicketFlag) {
          if (
            ticketGroup?.reason === "Complex ticket" &&
            ticketGroup?.tickets?.find((x) => x.destination.code == key)
          ) {
            tmp.push({
              key: key,
              legNumber: availableComplexDepartures[key].legNumber,
            });
            tmpComplexTickets[key] = { ...tmpData };
            tmpComplexTickets[key].id = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).id;
            tmpComplexTickets[key].status = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).status;
            tmpComplexTickets[key].reservationCode = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).reservationCode;
            tmpComplexTickets[key].ticketNumber = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).ticketNumber;

            tmpComplexTickets[key].fromCity = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).fromCity;
            tmpComplexTickets[key].toCity = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).toCity;
            if (!tmpComplexTickets[key].departureDay) {
              tmpComplexTickets[key].departureDay = ticketGroup?.tickets?.find(
                (x) => x.destination.code === key
              ).departureDay;
            }
            let surcharges = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).ticketSurcharges;
            tmpComplexTickets[key].ticketSurchargeList = surcharges;
            tmpComplexTickets[key].ticketSurcharges = surcharges;
            let departuresList =
              availableComplexDepartures[key].productAvailableDeparturesDTOList;

            let departure = departuresList?.find(
              (departure) =>
                departure.productDestinationDepartureId ===
                ticketGroup?.tickets?.find((x) => x.destination.code === key)
                  .productDestinationDeparture.id
            );
            if (departure) {
              tmpDepartures[key] = departure;
              tmpDepartures[key].ticketSurchargeList =
                departure.ticketSurchargeList;
            }
            tmpComplexTickets[key].hasAdjustedPrice =
              ticketGroup?.tickets?.find(
                (x) => x.destination.code === key
              ).hasAdjustedPrice;
            tmpComplexTickets[key].adjustedCalculatedPrice =
              ticketGroup?.tickets?.find(
                (x) => x.destination.code === key
              ).adjustedCalculatedPrice;
            tmpComplexTickets[key].adjustedPrice = ticketGroup?.tickets?.find(
              (x) => x.destination.code === key
            ).adjustedPrice;

            firstLeg = firstLeg + 1;
          }
        } else {
          tmp.push({
            key: key,
            legNumber: availableComplexDepartures[key].legNumber,
          });
          tmpComplexTickets[key] = { ...data };
          tmpComplexTickets[key].departureDay =
            availableComplexDepartures[
              key
            ]?.productAvailableDeparturesDTOList[0]?.departureDate;
          firstLeg = firstLeg + 1;
        }
      }
    });
    if (load === "firstLoad") {
      // setSelectedDepartures(tmpDepartures);
    }
    setComplexTickets(tmpComplexTickets);
    setSelectedProductDestinations(tmp);
    if (data?.id) {
      setChangeTicket(false);
    }
  };

  const handleChangeSelectedProductDestinations = (key) => {
    let tmp = [...selectedProductDestinations];
    let tmpComplexTickets = { ...complexTickets };
    // if key is already in array, remove it else add it
    if (
      tmp?.find((item) => item.key == key) != undefined &&
      tmp?.find((item) => item.key == key) != null
    ) {
      tmp = tmp.filter((item) => item.key !== key);
      delete tmpComplexTickets[key];
    } else {
      // check if any of the keys in tmp are in the same legNumber (same legNumber is if the legNumber starts with same digit) as key and remove them from tmp
      Object.keys(availableComplexDepartures).map((item) => {
        if (
          availableComplexDepartures[item].legNumber
            .toString()
            .startsWith(
              availableComplexDepartures[key].legNumber.toString().charAt(0)
            )
        ) {
          // check if item is in tmp and remove it
          if (
            tmp?.find((item2) => item2.key == item) != undefined &&
            tmp?.find((item2) => item2.key == item) != null
          ) {
            tmp = tmp.filter((item3) => item3.key !== item);
            delete tmpComplexTickets[item];
          }
        }
      });
      tmp.push({
        key: key,
        legNumber: availableComplexDepartures[key].legNumber,
      });
      tmpComplexTickets[key] = { ...data };
    }
    setSelectedProductDestinations(tmp);
    setComplexTickets(tmpComplexTickets);
  };

  const getAvailableComplexDepartures = async (date, itemKey) => {
    let loadedValue;
    if (data?.loaded === "2") {
      loadedValue = true;
    } else if (data?.loaded === "1") {
      loadedValue = false;
    } else {
      loadedValue = data?.loaded;
    }
    await ProductRepository.getAvailableComplexDepartures({
      vehicleLength: data?.vehicleLength,
      vehicleWidth: data?.vehicleWidth,
      vehicleHeight: data?.vehicleHeight,
      numberOfDrivers: data?.numberOfDrivers,
      cargoWeight: data?.cargoWeight,
      material: data?.material?.id,
      loaded: loadedValue,
      dangerousGoods: data?.dangerousGoods,
      electricPlugin: data?.electricPlugin,
      liveAnimals: data?.liveAnimals,
      fromCity: data?.product?.isComplex
        ? data?.product?.mainDestination?.fromCity?.id
        : data?.fromCity?.id,
      toCity: data?.product?.isComplex
        ? data?.product?.mainDestination?.toCity?.id
        : data?.toCity?.id,
      departureDate: moment(date).format("YYYY-MM-DD"),
      numberOfAxles: data?.numberOfAxles,
      organizationId: data?.organization?.id,
      ticketStatus: data?.status,
    })
      .then(async (res) => {
        availableComplexDeparturesRef.current[itemKey] =
          res.data[selectedComplexDepartureKey][itemKey];
        if (
          Object.keys(availableComplexDeparturesRef.current)?.length ==
          ticketGroup?.tickets?.length
        ) {
          setAvailableComplexDepartures(availableComplexDeparturesRef.current);
          prepopulateDefaultSelectedProductDestinations();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const [selectedValue, setSelectedValue] = useState("");

  // // Extract and filter options
  // const filteredOptions = Object.keys(availableComplexDepartures).map(
  //   (key) => ({
  //     value: key,
  //     label: availableComplexDepartures[key]?.destination?.name,
  //     disabled: availableComplexDepartures[key]?.isMandatory,
  //   })
  // );

  // const enabledOptions = filteredOptions.filter((option) => !option.disabled);
  // const selectableOptions =
  //   enabledOptions.length > 0 ? enabledOptions : filteredOptions;

  // // Handle selection change
  // const handleChange = (event) => {
  //   const newValue = event.target.value;
  //   setSelectedValue(newValue);
  //   handleChangeSelectedProductDestinations(newValue);
  // };

  // Initialize the selected value based on the props
  useEffect(() => {
    const nonMandatoryKeys = Object.keys(
      availableComplexDepartures || {}
    ).filter((key) => !availableComplexDepartures[key]?.isMandatory);
    const initialSelection =
      selectedProductDestinations[0]?.key &&
      availableComplexDepartures[selectedProductDestinations[0]?.key]
        ? selectedProductDestinations[0]?.key
        : nonMandatoryKeys[0] || null;
    setSelectedValue(initialSelection);
  }, [availableComplexDepartures, selectedProductDestinations]);

  // Helper function to get the lowest price from the list
  const getLowestPrice = (departures) => {
    if (!departures || departures.length === 0) return "N/A";
    const lowest = departures.reduce((prev, current) =>
      prev.salesPrice < current.salesPrice ? prev : current
    );
    return lowest.salesPrice?.toFixed(2);
  };

  // Helper function to concatenate prices
  const getCombinedPrice = (mandatoryDeparture, optionalDeparture) => {
    if (!mandatoryDeparture || !optionalDeparture) return "N/A";
    const mandatoryPrice = getLowestPrice(
      mandatoryDeparture.productAvailableDeparturesDTOList || []
    );
    const optionalPrice = getLowestPrice(
      optionalDeparture.productAvailableDeparturesDTOList || []
    );
    return (parseFloat(mandatoryPrice) + parseFloat(optionalPrice)).toFixed(2);
  };

  // Handle selection changes
  const handleChange = (event) => {
    const newValue = event.target.value;

    // Prevent changing the selected value if the new value is mandatory
    if (availableComplexDepartures[newValue]?.isMandatory) {
      return;
    }
    const selectedOption = options.find((option) => option.key === newValue);
    if (selectedOption) {
      setSelectedLabel(selectedOption.label);
    }
    // Update the selected value and notify parent
    setSelectedValue(newValue);
    handleChangeSelectedProductDestinations(newValue);
  };

  // Determine if all options are mandatory
  const allMandatory = Object.keys(availableComplexDepartures || {}).every(
    (key) => availableComplexDepartures[key]?.isMandatory
  );

  const options = allMandatory
    ? [] // No options if all are mandatory
    : Object.keys(availableComplexDepartures || {})
        .map((key) => {
          const departure = availableComplexDepartures[key];
          const isMandatory = departure?.isMandatory;

          if (!isMandatory) {
            const availableDestination =
              availableComplexDepartures[selectedValue]?.destination?.name ||
              "";
            const departureDestination = departure.destination?.name || "";

            // Split the destinations into segments
            const availableSegments = availableDestination.split("-");
            const departureSegments = departureDestination.split("-");

            // Check if the last segment of availableDestination matches the first segment of departureDestination
            if (
              availableSegments.length > 0 &&
              departureSegments.length > 0 &&
              availableSegments[availableSegments.length - 1] ===
                departureSegments[0]
            ) {
              // Remove the duplicate segment from the departureSegments
              departureSegments.shift();
            }

            // Construct the label
            const label = `${availableSegments.join(
              "-"
            )}-${departureSegments.join("-")}`;

            // Check if the label is concatenated with itself
            if (availableDestination === departureDestination) {
              return null; // Skip this option
            }

            return {
              key,
              label,
              price: getCombinedPrice(
                availableComplexDepartures[selectedValue],
                departure
              ),
            };
          }
          return null;
        })
        .filter((option) => option !== null);

  const getLegLabel = (index) => {
    switch (index) {
      case 0:
        return "First Leg";
      case 1:
        return "Second Leg";
      case 2:
        return "Third Leg";
      default:
        return `Leg ${index + 1}`;
    }
  };

  return (
    <>
      {selectedDepartures && (
        <Box sx={{ marginTop: "20px" }}>
          <Grid container spacing={2} justifyContent="center">
            {Object.keys(selectedDepartures).map((key, index) => {
              const departure = selectedDepartures[key];
              const adjustedPrice = complexTickets[key]?.adjustedCalculatedPrice
                ? parseFloat(complexTickets[key]?.adjustedCalculatedPrice)
                : departure?.salesPrice;
              const totalSurcharges = departure?.ticketSurchargeList
                ? departure?.ticketSurchargeList
                    .map((surcharge) => surcharge?.total)
                    .reduce((a, b) => a + b, 0)
                : 0;
              const totalPrice = adjustedPrice + totalSurcharges;

              return (
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Typography sx={{ mt: 2, mb: 1 }} variant="h6">
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bold",
                            }}
                          >
                            <ReactCountryFlag
                              countryCode={
                                availableComplexDepartures[key]?.destination
                                  ?.fromCountry?.code
                              }
                              svg
                              style={{
                                width: "1.6em",
                                height: "1.6em",
                                borderRadius: "5px",
                                marginRight: "4px",
                              }}
                              title={
                                availableComplexDepartures[key]?.destination
                                  ?.fromCountry?.code
                              }
                            />
                            <span style={{ fontSize: "18px" }}>
                              {
                                availableComplexDepartures[key]?.destination
                                  ?.fromCity?.name
                              }
                            </span>

                            <EastIcon
                              style={{ marginLeft: "5px", marginRight: "5px" }}
                            />

                            <ReactCountryFlag
                              countryCode={
                                availableComplexDepartures[key]?.destination
                                  ?.toCountry?.code
                              }
                              svg
                              style={{
                                width: "1.6em",
                                height: "1.6em",
                                borderRadius: "5px",
                                marginRight: "4px",
                              }}
                              title={
                                availableComplexDepartures[key]?.destination
                                  ?.toCountry?.code
                              }
                            />
                            <span style={{ fontSize: "18px" }}>
                              {
                                availableComplexDepartures[key]?.destination
                                  ?.toCity?.name
                              }
                            </span>
                          </Box>
                        </Grid>
                        <Grid item xs={7}>
                          <Divider
                            style={{
                              borderBottomWidth: "2px",
                              marginLeft: "100px",
                            }}
                          />
                        </Grid>
                        <Grid item xs={2} container justifyContent="flex-end">
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            <Chip
                              label={getLegLabel(index)}
                              icon={
                                <CheckIcon
                                  style={{
                                    color: "white",
                                  }}
                                />
                              } // Display the icon before the label
                              sx={{
                                backgroundColor: "#88c078",
                                color: "white",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Table
                      elevation={1}
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: " 0 10px",
                        marginTop: "-10px",
                      }}
                    >
                      <TableBody>
                        <TableRow
                          key={departure.productDestinationDepartureId}
                          style={{
                            height: "48px",
                            borderSpacing: "0 10px",
                            borderLeft: "0",
                            borderRight: "0",
                          }}
                        >
                          <TableCell colSpan={10} style={{ border: "none" }}>
                            <Grid container direction="column" spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    backgroundColor: "#88c078",
                                    color: "white",
                                    border: "none",
                                    boxShadow: "none",
                                    position: "relative",
                                    padding: "0 10px",
                                    borderRadius: "6px 6px  0 0",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: "#88c078",
                                      color: "white",
                                      padding: "0 15px",
                                      paddingTop: "10px",
                                      paddingBottom: "10px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="h4"
                                      sx={{
                                        fontWeight: "bold",
                                        whiteSpace: "nowrap",
                                        overflow: "visible",
                                        flexShrink: 0,
                                      }}
                                    >
                                      &nbsp;
                                      {departure?.operatorName
                                        ? departure?.operatorName
                                        : departure?.supplier?.name}
                                    </Typography>

                                    <Grid
                                      container
                                      spacing={2}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        sx={{ marginBottom: "15px" }}
                                      >
                                        <Grid container spacing={2}>
                                          <Grid item xs={3}>
                                            <Typography
                                              variant="h6"
                                              sx={{ opacity: "50%" }}
                                            >
                                              Departure
                                            </Typography>
                                            <Typography
                                              variant="h5"
                                              sx={{
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {departure?.departureTime}
                                            </Typography>
                                            <Typography variant="h6">
                                              {moment(
                                                departure?.departureDate
                                              ).format("DD.MM.YYYY")}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{
                                              alignContent: "center",
                                            }}
                                          >
                                            <Typography variant="h5">
                                              <EastIcon />
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                            <Typography
                                              variant="h6"
                                              sx={{ opacity: "50%" }}
                                            >
                                              Arrival
                                            </Typography>
                                            <Typography
                                              variant="h5"
                                              sx={{
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {departure?.arrivalTime}
                                            </Typography>
                                            <Typography variant="h6">
                                              {departure?.arrivalDay == "0"
                                                ? moment(
                                                    departure?.departureDate
                                                  ).format("DD.MM.YYYY")
                                                : departure?.arrivalDay == "1"
                                                ? moment(
                                                    departure?.departureDate
                                                  )
                                                    .add("days", 1)
                                                    .format("DD.MM.YYYY")
                                                : departure?.arrivalDay == "2"
                                                ? moment(
                                                    departure?.departureDate
                                                  )
                                                    .add("days", 2)
                                                    .format("DD.MM.YYYY")
                                                : ""}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box sx={{ padding: "0 10px 0 20px" }}>
                                    <Grid container spacing={2}>
                                      <Grid item md={12}>
                                        <Grid
                                          container
                                          spacing={2}
                                          sx={{
                                            backgroundColor:
                                              "rgba(160, 205, 147, 0.1)",
                                            borderRadius: "5px",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {departure?.productDestinationDepartureId ==
                                            selectedDepartures[key]
                                              ?.productDestinationDepartureId &&
                                            departure?.ticketSurchargeList?.map(
                                              (surcharge, index) => (
                                                <Grid
                                                  container
                                                  spacing={2}
                                                  key={
                                                    surcharge?.productDestinationDepartureId
                                                  }
                                                  sx={{
                                                    height: "60px",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    backgroundColor:
                                                      index % 2 !== 0
                                                        ? "rgba(160, 205, 147, 0.3)"
                                                        : "rgba(160, 205, 147, 0.6)",
                                                    padding: "10px",
                                                  }}
                                                >
                                                  <Grid item xs={10}>
                                                    <Typography
                                                      sx={{
                                                        flex: 1,
                                                        ...theme.typography.h6,
                                                      }}
                                                    >
                                                      {surcharge.surchargeName}
                                                    </Typography>
                                                  </Grid>
                                                  {/* {data?.organization
                                                    ?.showPrice && (
                                                    <Typography
                                                      sx={{
                                                        flex: 1,
                                                        textAlign: "center",
                                                        ...theme.typography.h6,
                                                      }}
                                                    >
                                                      <input
                                                        disabled={
                                                          surcharge?.disabled
                                                        }
                                                        value={
                                                          selectedDepartures[
                                                            keyframes
                                                          ]?.ticketSurchargeList?.find(
                                                            (s) =>
                                                              s.productSurcharge
                                                                .id ==
                                                              surcharge
                                                                .productSurcharge
                                                                .id
                                                          )?.quantity
                                                            ? selectedDepartures[
                                                                key
                                                              ]?.ticketSurchargeList?.find(
                                                                (s) =>
                                                                  s
                                                                    .productSurcharge
                                                                    .id ==
                                                                  surcharge
                                                                    .productSurcharge
                                                                    .id
                                                              )?.quantity
                                                            : surcharge?.quantity
                                                        }
                                                        onChange={(e) => {
                                                          let newDeparture = {
                                                            ...selectedDepartures[
                                                              key
                                                            ],
                                                          };
                                                          newDeparture.ticketSurchargeList.find(
                                                            (s) =>
                                                              s.productSurcharge
                                                                .id ==
                                                              surcharge
                                                                .productSurcharge
                                                                .id
                                                          ).quantity =
                                                            e.target.valueAsNumber;
                                                          newDeparture.ticketSurchargeList.find(
                                                            (s) =>
                                                              s.productSurcharge
                                                                .id ==
                                                              surcharge
                                                                .productSurcharge
                                                                .id
                                                          ).total =
                                                            e.target
                                                              .valueAsNumber *
                                                            surcharge.price;
                                                          let tmp = {
                                                            ...selectedDepartures,
                                                          };
                                                          tmp[key] =
                                                            newDeparture;
                                                          setSelectedDepartures(
                                                            tmp
                                                          );
                                                        }}
                                                        size="small"
                                                        type={"number"}
                                                        style={{
                                                          border: "none",
                                                          backgroundColor:
                                                            "transparent",
                                                          width: "30px",
                                                          color: "white",
                                                        }}
                                                      />
                                                    </Typography>
                                                  )}
                                                  {data?.organization
                                                    ?.showPrice && (
                                                    <Typography
                                                      sx={{
                                                        flex: 1,
                                                        textAlign: "center",
                                                        ...theme.typography.h6,
                                                      }}
                                                    >
                                                      x
                                                    </Typography>
                                                  )}
                                                  {data?.organization
                                                    ?.showPrice && (
                                                    <Typography
                                                      sx={{
                                                        flex: 1,
                                                        textAlign: "center",
                                                        ...theme.typography.h6,
                                                      }}
                                                    >
                                                      {surcharge.price?.toFixed(
                                                        2
                                                      )}
                                                      &euro;
                                                    </Typography>
                                                  )}
                                                  {data?.organization
                                                    ?.showPrice && (
                                                    <Typography
                                                      sx={{
                                                        flex: 1,
                                                        textAlign: "center",
                                                        ...theme.typography.h6,
                                                      }}
                                                    >
                                                      =
                                                    </Typography>
                                                  )} */}
                                                  {(departure?.priceVisibility ==
                                                    "DEFAULT" &&
                                                    data?.organization
                                                      ?.showPrice) ||
                                                  departure?.priceVisibility ==
                                                    "SHOW" ? (
                                                    <Grid item xs={2}>
                                                      <Typography
                                                        sx={{
                                                          flex: 1,
                                                          textAlign: "center",
                                                          ...theme.typography
                                                            .h6,
                                                        }}
                                                      >
                                                        {parseFloat(
                                                          surcharge.price *
                                                            surcharge.quantity
                                                        ).toFixed(2)}
                                                        &euro;
                                                      </Typography>
                                                    </Grid>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Grid>
                                              )
                                            )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Box>
                                <Grid item>
                                  <Box
                                    sx={{
                                      backgroundColor: "#88c078",
                                      color: "white",
                                      border: "none",
                                      boxShadow: "none",
                                      padding: "10px 15px",
                                      borderRadius: "  0 0 6px 6px",
                                    }}
                                  >
                                    {(departure?.priceVisibility == "DEFAULT" &&
                                      data?.organization?.showPrice) ||
                                    departure?.priceVisibility == "SHOW" ? (
                                      <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                      >
                                        <Grid item md={4}>
                                          <Typography
                                            sx={{
                                              opacity: "50%",
                                              fontSize: "12px",
                                            }}
                                            align="left"
                                          >
                                            Total Freight
                                          </Typography>
                                          <Typography
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "20px",
                                              // marginTop: 10,
                                            }}
                                            align="left"
                                          >
                                            {data?.adjustedCalculatedPrice ? (
                                              <>
                                                {parseFloat(
                                                  data?.adjustedCalculatedPrice
                                                )?.toFixed(2)}{" "}
                                                &euro;
                                              </>
                                            ) : (
                                              <>
                                                {departure?.salesPrice?.toFixed(
                                                  2
                                                )}
                                                &euro;
                                              </>
                                            )}
                                          </Typography>
                                        </Grid>
                                        <Grid item md={4}>
                                          <Typography
                                            sx={{
                                              opacity: "50%",
                                              fontSize: "12px",
                                            }}
                                            align="center"
                                          >
                                            Total Surcharges
                                          </Typography>
                                          <Typography
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "20px",
                                              // marginTop: 10,
                                            }}
                                            align="center"
                                          >
                                            <>
                                              {" "}
                                              {parseFloat(
                                                totalSurcharges
                                              ).toFixed(2)}
                                              &euro;
                                            </>
                                          </Typography>
                                        </Grid>
                                        {/* {selectedDeparture?.surchargesIncludedInSalesPrice >
                            0 && (
                            <Grid item md={4}>
                              <Paper
                                elevation={0}
                                style={{
                                  borderRadius: "10px",
                                  padding: "5px",
                                  backgroundColor: "#edf7ed",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    // marginTop: 10,
                                  }}
                                  align="center"
                                >
                                  Surch. included in price
                                </Typography>
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                  align="center"
                                >
                                  {selectedDeparture?.surchargesIncludedInSalesPrice?.toFixed(
                                    2
                                  )}
                                </Typography>
                              </Paper>
                            </Grid>
                          )} */}
                                        <Grid item md={4}>
                                          <Typography
                                            sx={{
                                              opacity: "50%",
                                              fontSize: "12px",
                                            }}
                                            align="right"
                                          >
                                            Total Price
                                          </Typography>
                                          <Typography
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "20px",
                                            }}
                                            align="right"
                                          >
                                            {data?.adjustedCalculatedPrice ? (
                                              <>
                                                {(
                                                  parseFloat(
                                                    data.adjustedCalculatedPrice
                                                  ) +
                                                  totalSurcharges -
                                                  (departure?.surchargesIncludedInSalesPrice ||
                                                    0)
                                                ).toFixed(2)}
                                                &euro;
                                              </>
                                            ) : (
                                              <>
                                                {(
                                                  (departure?.salesPrice || 0) +
                                                  totalSurcharges -
                                                  (departure?.surchargesIncludedInSalesPrice ||
                                                    0)
                                                ).toFixed(2)}
                                                &euro;
                                              </>
                                            )}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}

      {/* {!editTicketFlag && (
        <>
          {data?.organization?.showPrice && (
            <Grid item md={12} mt={15}>
              <Box
                sx={{
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  padding: "10px 15px",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                {selectedDepartures && (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid
                      item
                      xs={4}
                      style={{
                        border: "solid 1px ",
                        borderColor: alpha(theme.palette.lightGray.main, 0.4),
                        paddingBottom: "6px",
                        alignContent: "left",
                        borderRight: "none",
                        borderRadius: "5px 0 0 5px",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "normal",
                          fontSize: "14px",
                          color: alpha(theme.palette.lightGray.main, 0.8),
                          marginLeft: "10px",
                        }}
                        align="center"
                      >
                        Total freight
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: theme.palette.darkContrast.main,
                          marginLeft: "10px",
                        }}
                        align="center"
                      >
                        {
                          //sum of all sales prices in selected departures in complex ticket
                          Object.keys(selectedDepartures)
                            .map((key) =>
                              complexTickets[key]?.adjustedCalculatedPrice
                                ? parseFloat(
                                    complexTickets[key]?.adjustedCalculatedPrice
                                  )
                                : selectedDepartures[key]?.salesPrice
                            )
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2)
                        }
                        &euro;
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        border: "solid 1px ",
                        borderColor: alpha(theme.palette.lightGray.main, 0.4),
                        paddingBottom: "6px",
                        alignContent: "center",
                        borderRight: "none",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "normal",
                          fontSize: "14px",
                          color: alpha(theme.palette.lightGray.main, 0.8),
                        }}
                        align="center"
                      >
                        Total surcharges
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: theme.palette.darkContrast.main,
                        }}
                        align="center"
                      >
                        &euro;
                        {
                          //sum of all surcharges in selected departures in complex ticket
                          Object.keys(selectedDepartures)
                            .map((key) =>
                              selectedDepartures[key]?.ticketSurchargeList
                                ? selectedDepartures[key]?.ticketSurchargeList
                                    ?.map((surcharges) => surcharges?.total)
                                    ?.reduce((a, b) => a + b, 0)
                                : 0
                            )
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2)
                        }
                        &euro;
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        border: `1px solid ${alpha(
                          theme.palette.lightGray.main,
                          0.4
                        )}`,
                        paddingBottom: "6px",
                        alignContent: "right",
                        borderRadius: "0 5px 5px 0",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "normal",
                          fontSize: "14px",
                          color: alpha(theme.palette.lightGray.main, 0.8),
                          marginRight: "20px",
                        }}
                        align="center"
                      >
                        Total Price
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: theme.palette.darkContrast.main,
                          marginRight: "20px",
                        }}
                        align="center"
                      >
                        {(
                          +Object.keys(selectedDepartures)
                            .map((key) =>
                              complexTickets[key]?.adjustedCalculatedPrice
                                ? parseFloat(
                                    complexTickets[key]?.adjustedCalculatedPrice
                                  )
                                : selectedDepartures[key]?.salesPrice
                            )
                            .reduce((a, b) => a + b, 0) +
                          +Object.keys(selectedDepartures)
                            .map((key) =>
                              selectedDepartures[key]?.ticketSurchargeList
                                ? selectedDepartures[key]?.ticketSurchargeList
                                    ?.map((surcharges) => surcharges?.total)
                                    ?.reduce((a, b) => a + b, 0)
                                : 0
                            )
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2)
                        ).toFixed(2)}
                        &euro;
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          )}
        </>
      )} */}
    </>
  );
}
