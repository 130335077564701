import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const TicketRepository = {
  get: (id) => {
    return Axios.get(`${SETTINGS.API_URL}ticket/${id}`);
  },

  getByMailboxId: (id) => {
    return Axios.get(`${SETTINGS.API_URL}ticket/mailbox/${id}`);
  },

  getLifeCycle: (id) => {
    return Axios.get(`${SETTINGS.API_URL}ticket/${id}/lifecycle`);
  },

  getCommunication: (id) => {
    return Axios.get(`${SETTINGS.API_URL}ticket/${id}/communication`);
  },

  createMessage: (data) => {
    return Axios.post(`${SETTINGS.API_URL}ticket/message`, data);
  },

  createMessageWithAttachment: (data, chatDocument) => {
    const formData = new FormData();
    formData.append("message", JSON.stringify(data));
    formData.append("chatDocument", chatDocument);

    return Axios({
      url: `${SETTINGS.API_URL}ticket/messageWithAttachment`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  },

  getAllMessageAttachments: (ticketId) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/getAllMessageAttachments/${ticketId}`,
      method: "GET",
    });
  },

  updatePrice: (ticketId, priceListId) => {
    return Axios.put(
      `${SETTINGS.API_URL}ticket/updatePrice/${ticketId}/${priceListId}`
    );
  },

  updateTicketFees: (ticketId, ticketFees) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/updateTicketFees/${ticketId}`,
      method: "PUT",
      data: ticketFees,
    });
  },

  assignToUser: (ticketId, userId) => {
    return Axios.put(`${SETTINGS.API_URL}ticket/assign/${ticketId}/${userId}`);
  },

  sendReservationCode: (approveTicketDto) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/sendReservationCode`,
      method: "PUT",
      data: approveTicketDto,
    });
  },

  sendMultipleReservationCodes: (ticketDto) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/sendMultipleReservationCodes`,
      method: "PUT",
      data: ticketDto,
    });
  },

  newReservationCode: (
    ticketId,
    reservationCode,
    codeWaitingList,
    changeReservationCodeInWarehouse
  ) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/newReservationCode/${ticketId}`,
      method: "PUT",
      params: {
        reservationCode: reservationCode,
        codeWaitingList: codeWaitingList,
        changeReservationCodeInWarehouse: changeReservationCodeInWarehouse,
      },
    });
  },

  updateViberTicketMessages: (ticketId, viberEvents) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/updateViberTicketMessages`,
      method: "PUT",
      data: viberEvents,
      params: {
        ticketId: ticketId,
      },
    });
  },

  updateWhatsAppTicketMessages: (ticketId, whatsAppEvents) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/updateWhatsAppTicketMessages`,
      method: "PUT",
      data: whatsAppEvents,
      params: {
        ticketId: ticketId,
      },
    });
  },

  escalateTicket: (ticket) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/escalate`,
      method: "PUT",
      data: ticket,
    });
  },

  noShowTicket: (ticketId, ticketsDto) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/noShow/${ticketId}`,
      method: "PUT",
      data: ticketsDto,
    });
  },

  forceNoShowTicket: (ticket) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/noShow/force`,
      method: "PUT",
      data: ticket,
    });
  },

  updateAndNoShowTicket: (ticket) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/noShow/update`,
      method: "PUT",
      data: ticket,
    });
  },

  removeEscalateFlag: (ticketId, alternativeTickets) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/removeEscalateFlag/${ticketId}`,
      method: "PUT",
      data: alternativeTickets,
    });
  },

  waitingListTicket: (ticketId) => {
    return Axios.put(`${SETTINGS.API_URL}ticket/waitingList/${ticketId}`);
  },

  removeFromWaitingList: (ticketId, alternativeTickets) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/removeFromWaitingList/${ticketId}`,
      method: "PUT",
      data: alternativeTickets,
    });
  },

  rejectTicket: (ticketId) => {
    return Axios.put(`${SETTINGS.API_URL}ticket/reject/${ticketId}`);
  },

  // completeTicket: (ticketId) => {
  //   return Axios.put(`${SETTINGS.API_URL}ticket/complete/${ticketId}`);
  // },

  completeTicket: (ticketId, ticketsDto) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/complete/${ticketId}`,
      method: "PUT",
      data: ticketsDto,
    });
  },

  forceCompleteTicket: (ticketId) => {
    return Axios.put(`${SETTINGS.API_URL}ticket/complete/${ticketId}/force`);
  },

  updateAndCompleteTicket: (ticketId) => {
    return Axios.put(`${SETTINGS.API_URL}ticket/complete/${ticketId}/update`);
  },

  cancelTicket: (ticketId) => {
    return Axios.put(`${SETTINGS.API_URL}ticket/cancel/${ticketId}`);
  },

  takeoverCase: (ticketId, userId) => {
    return Axios.put(
      `${SETTINGS.API_URL}ticket/takeover/${ticketId}/${userId}`
    );
  },

  getAll: (
    page,
    size,
    filterParams,
    orderBy = "dateCreated",
    orderDirection = "DESC"
  ) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket`,
      method: "GET",
      params: {
        page: page,
        size: size,
        filterParams: filterParams,
        orderBy: orderBy,
        orderDirection: orderDirection,
      },
    });
  },

  getAllWithoutPaging: (filterParams) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/allWithoutPaging`,
      method: "GET",
      params: {
        filterParams: filterParams,
      },
    });
  },

  getByTicketNumber: (ticketNumber) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/number/${ticketNumber}`,
    });
  },

  create: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket`,
      method: "POST",
      data: data,
    });
  },

  createMultiple: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/multiple`,
      method: "POST",
      data: data,
    });
  },

  createByAgent: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/agent`,
      method: "POST",
      data: data,
    });
  },
  update: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket`,
      method: "PUT",
      data: data,
    });
  },

  delete: (id) => {
    return Axios.delete(`${SETTINGS.API_URL}ticket/${id}`);
  },

  mostBookedDestinations: (
    size,
    filterParams,
    orderBy = "dateCreated",
    orderDirection = "DESC"
  ) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/mostBookedDestinations`,
      method: "GET",
      params: {
        size: size,
        filterParams: filterParams,
        orderBy: orderBy,
        orderDirection: orderDirection,
      },
    });
  },

  getAllTicketStatuses: () => {
    return Axios({
      url: SETTINGS.API_URL + "ticket/ticketStatuses",
      method: "GET",
    });
  },

  getTicketDirections: () => {
    return Axios({
      url: SETTINGS.API_URL + "ticket/ticketDirections",
      method: "GET",
    });
  },

  getAllTicketSources: () => {
    return Axios({
      url: SETTINGS.API_URL + "ticket/ticketSources",
      method: "GET",
    });
  },

  getAllTicketEscalateReasons: () => {
    return Axios({
      url: SETTINGS.API_URL + "ticket/escalateReasons",
      method: "GET",
    });
  },

  setTimer: (ticketId, customTime, userId) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/setTimer`,
      method: "PUT",
      params: {
        ticketId: ticketId,
        customTime: customTime,
        userId: userId,
      },
    });
  },

  getRevisions: (ticketId) => {
    return Axios({
      url: SETTINGS.API_URL + `ticket/revisions/${ticketId}`,
      method: "GET",
    });
  },

  compareRevisions: (firstRevisionNumber, secondRevisionNumber, id) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/compareRevisions`,
      method: "GET",
      params: {
        firstRevisionNumber: firstRevisionNumber,
        secondRevisionNumber: secondRevisionNumber,
        id: id,
      },
    });
  },

  removeReservationCode: (id) => {
    return Axios({
      url: SETTINGS.API_URL + `ticket/removeReservationCode/${id}`,
      method: "PUT",
    });
  },

  checkIfReadyForInvoice: (id) => {
    return Axios({
      url: SETTINGS.API_URL + `ticket/checkIfReadyForInvoice/${id}`,
      method: "GET",
    });
  },

  getAllTicketsByViberEvent: (id) => {
    return Axios({
      url: SETTINGS.API_URL + `ticket/getAllByViberEvent/${id}`,
      method: "GET",
    });
  },

  getAllTicketsByWhatsAppEvent: (id) => {
    return Axios({
      url: SETTINGS.API_URL + `ticket/getAllByWhatsAppEvent/${id}`,
      method: "GET",
    });
  },

  getAllTicketsByNoteEvent: (id) => {
    return Axios({
      url: SETTINGS.API_URL + `ticket/getAllTicketsByNoteEvent/${id}`,
      method: "GET",
    });
  },

  removeAdjustedTicketPrices: (ticketId) => {
    return Axios({
      url: SETTINGS.API_URL + `ticket/removeAdjustedTicketPrices`,
      method: "PUT",
      params: {
        ticketId: ticketId,
      },
    });
  },

  getAvailableDeparturesOneFormTicket: (oneFormTicketDTO) => {
    return Axios({
      url: `${SETTINGS.API_URL}product/getAvailableDeparturesOneFormTicket`,
      method: "POST",
      data: oneFormTicketDTO,
    });
  },

  notifyEpayForTicket: (customerReferenceNumber, status, brn) => {
    return Axios({
      url: `https://epay.astaadria.com/changedfromoutside`,
      method: "GET",
      params: {
        id: customerReferenceNumber,
        status: status,
        brn: brn,
      },
    });
  },

  getInProgressTicketsFromTicketGroup: (ticketGroupId) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/getInProgressTicketsFromTicketGroup/${ticketGroupId}`,
      method: "GET",
    });
  },

  getApprovedTicketsFromTicketGroup: (ticketGroupId) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/getApprovedTicketsFromTicketGroup/${ticketGroupId}`,
      method: "GET",
    });
  },

  editRemarkInTicket: (ticketId, remark) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/editRemarkInTicket/${ticketId}`,
      method: "PUT",
      params: {
        remark: remark,
      },
    });
  },

  getTicketsByIdAndPage: (page, size, ticketIds) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/getTicketsByIdAndPage`,
      method: "GET",
      params: {
        page: page,
        size: size,
        ticketIds: ticketIds,
      },
    });
  },

  getAllMessages: (page, size) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/allMessages`,
      method: "GET",
      params: {
        page: page,
        size: size,
      },
    });
  },

  grandTotalPricesForAllTickets: (
    page,
    size,
    filterParams,
    orderBy = "dateCreated",
    orderDirection = "DESC"
  ) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticket/grandTotalPricesForAllTickets`,
      method: "GET",
      params: {
        page: page,
        size: size,
        filterParams: filterParams,
        orderBy: orderBy,
        orderDirection: orderDirection,
      },
    });
  },
};
