import * as React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import {
  Divider,
  useMediaQuery,
  FormControlLabel,
  Switch,
  Button,
  ListItemText,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Settings } from "@mui/icons-material";
import { NotificationsRepository } from "../../repositories/NotificationsRepository";
import { useDispatch } from "react-redux";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
} from "../../common/CommonActions";
import { alpha } from "@mui/system";

export default function NotificationSettings() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [notificationSettingsData, setNotificationSettingsData] =
    React.useState();
  var _ = require("lodash");

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    if (!notificationSettingsData) {
      loadData();
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const loadData = () => {
    NotificationsRepository.getSettings()
      .then((res) => {
        setNotificationSettingsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (name, value) => {
    setNotificationSettingsData(
      _.set({ ...notificationSettingsData }, name, value)
    );
  };

  const handleSave = () => {
    NotificationsRepository.saveSettings(notificationSettingsData)
      .then((res) => {
        dispatch(
          notifyShowSuccessMessage(
            "Notifications Settings updated successfully!"
          )
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          notifyShowErrorMessage(
            "Notifications Settings update failed! Try again!"
          )
        );
      });
  };

  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "5px",
        }}
      >
        <Tooltip title="Settings">
          <IconButton
            onClick={handleClick}
            style={{
              width: isSmallScreen && "45px",
              height: isSmallScreen && "45px",
              border: isSmallScreen
                ? `1px solid white`
                : `1px solid rgba(128, 128, 128, 0.5)`,
              borderRadius: "4px",
              padding: "5px",
              backgroundColor: !isMenuOpen
                ? "transparent"
                : theme.palette.primary.main,
            }}
          >
            {isMenuOpen ? (
              <CloseIcon
                sx={{
                  fontSize: isSmallScreen ? "30px" : "23px",
                }}
              />
            ) : (
              <>
                <Settings
                  sx={{
                    fontSize: isSmallScreen ? "30px" : "27px",
                    marginRight: isSmallScreen && "10px",
                    marginLeft: isSmallScreen && "10px",
                    color: isSmallScreen && "white",
                  }}
                />
                {isSmallScreen && (
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: alpha(theme.palette.darkContrast.main, 0.6),
                          fontSize: "18px",
                          color: "white",
                        }}
                      >
                        Settings
                      </Typography>
                    }
                  />
                )}
              </>
            )}
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            overflowX: "hidden",
            // maxHeight: "600px",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            width: isSmallScreen ? "100vw" : "auto",
            height: isSmallScreen ? "50vh" : "auto",
            position: isSmallScreen ? "fixed" : "absolute",
            left: isSmallScreen ? 0 : "auto",
            top: isSmallScreen ? 0 : "auto",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Typography
          variant="h6"
          key="2"
          color="text.primary"
          style={{ fontWeight: "bold", padding: "10px", textAlign: "end" }}
        >
          Notification Settings
        </Typography>
        <Divider variant="inset" />
        <MenuItem sx={{ justifyContent: "flex-end" }}>
          <FormControlLabel
            label="Receive Email Notifications"
            labelPlacement="start"
            control={
              <Switch
                checked={
                  notificationSettingsData?.receiveEmailNotifications ?? false
                }
                onChange={(e) =>
                  handleChange("receiveEmailNotifications", e.target.checked)
                }
                color="primary"
              />
            }
          />
        </MenuItem>
        <MenuItem sx={{ justifyContent: "flex-end" }}>
          <FormControlLabel
            label="Receive Email Ticket Messages Notifications"
            labelPlacement="start"
            disabled={
              notificationSettingsData?.receiveEmailNotifications ? false : true
            }
            control={
              <Switch
                checked={
                  notificationSettingsData?.receiveEmailMessagesNotifications ??
                  false
                }
                onChange={(e) =>
                  handleChange(
                    "receiveEmailMessagesNotifications",
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
          />
        </MenuItem>
        <MenuItem sx={{ justifyContent: "flex-end" }}>
          <FormControlLabel
            label="Receive Email Booking Request Notifications"
            labelPlacement="start"
            disabled={
              notificationSettingsData?.receiveEmailNotifications ? false : true
            }
            control={
              <Switch
                checked={
                  notificationSettingsData?.receiveBookingRequestEmailNotifications ??
                  false
                }
                onChange={(e) =>
                  handleChange(
                    "receiveBookingRequestEmailNotifications",
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
          />
        </MenuItem>
        <MenuItem sx={{ justifyContent: "flex-end" }}>
          <FormControlLabel
            label="Receive Email Booking Approval Notifications"
            labelPlacement="start"
            disabled={
              notificationSettingsData?.receiveEmailNotifications ? false : true
            }
            control={
              <Switch
                checked={
                  notificationSettingsData?.receiveBookingApprovalEmailNotifications ??
                  false
                }
                onChange={(e) =>
                  handleChange(
                    "receiveBookingApprovalEmailNotifications",
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
          />
        </MenuItem>
        <MenuItem sx={{ justifyContent: "flex-end" }}>
          <FormControlLabel
            label="Receive Email Booking Rejection Notifications"
            labelPlacement="start"
            disabled={
              notificationSettingsData?.receiveEmailNotifications ? false : true
            }
            control={
              <Switch
                checked={
                  notificationSettingsData?.receiveBookingRejectionEmailNotifications ??
                  false
                }
                onChange={(e) =>
                  handleChange(
                    "receiveBookingRejectionEmailNotifications",
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
          />
        </MenuItem>
        <Divider variant="inset" />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              handleSave();
              handleClose();
            }}
            sx={{ marginRight: "15px" }}
          >
            Save
          </Button>
        </div>
      </Menu>
    </React.Fragment>
  );
}
