import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { ExpandMore } from "@mui/icons-material";

export default function TicketPreviewAvailableDeparturesCalendar({
  availableDepartures,
  data,
  handleChangeData,
  selectedDeparture,
  setSelectedDeparture,
  isDisabled,
  setIsDisabled,
  setData,
}) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          position: "relative",
          zIndex: 10,
          marginTop: "1px",
          // marginTop:
          //   selectedDeparture?.ticketSurchargeList?.length > 0
          //     ? `${selectedDeparture.ticketSurchargeList.length * 2}px`
          //     : "0px",
        }}
      >
        <Accordion
          elevation={0}
          sx={{
            backgroundColor: "#88c078",
            color: "white",
            border: "none",
            boxShadow: "none",
            position: "absolute",
            top: 0,
            transition: "all 0.3 ease",
            "&.Mui-expanded": {
              top:
                selectedDeparture?.ticketSurchargeList?.length > 0
                  ? `${
                      -100 + selectedDeparture.ticketSurchargeList.length * -45
                    }px`
                  : "-120px",
              bottom: "120px",
            },
            width: "100%",
            zIndex: 11,
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMore style={{ color: "white", fontSize: "35px" }} />
            }
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "bold", paddingLeft: "10px" }}
            >
              Payment Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} sx={{ padding: "0 0 0 20px" }}>
              <Grid item xs={12} md={12}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    backgroundColor: "#a0cd93",
                    borderRadius: "5px",
                  }}
                >
                  <Grid
                    item
                    xs={3.2}
                    lg={3.8}
                    xl={4}
                    style={{
                      alignContent: "center",
                      marginBottom: "15px",
                    }}
                  >
                    {data?.organization?.showSupplier &&
                      data?.productDestination?.supplier?.name && (
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            typography: { xs: "h6", md: "h5", lg: "h4" },
                          }}
                        >
                          {data?.productDestination?.operator
                            ? data?.productDestination?.operator?.name
                            : data?.productDestination?.supplier?.name}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={6} sx={{ marginBottom: "15px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="h6" sx={{ opacity: "50%" }}>
                          Departure
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "bold",
                            typography: { xs: "h6", md: "h5", lg: "h4" },
                          }}
                        >
                          {selectedDeparture?.departureTime}
                        </Typography>
                        <Typography
                          sx={{ typography: { xs: "h6", md: "h5", lg: "h4" } }}
                        >
                          {moment(selectedDeparture?.departureDate).format(
                            "DD.MM.YYYY"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sx={{ alignContent: "center" }}>
                        <Typography
                          sx={{ typography: { xs: "h6", md: "h5", lg: "h4" } }}
                        >
                          ➜
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h6" sx={{ opacity: "50%" }}>
                          Arrival
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            typography: { xs: "h6", md: "h5", lg: "h4" },
                          }}
                        >
                          {selectedDeparture?.arrivalTime}
                        </Typography>
                        <Typography
                          sx={{ typography: { xs: "h6", md: "h5", lg: "h4" } }}
                        >
                          {selectedDeparture?.arrivalDay == "0"
                            ? moment(selectedDeparture?.departureDate).format(
                                "DD.MM.YYYY"
                              )
                            : selectedDeparture?.arrivalDay == "1"
                            ? moment(selectedDeparture?.departureDate)
                                .add("days", 1)
                                .format("DD.MM.YYYY")
                            : selectedDeparture?.arrivalDay == "2"
                            ? moment(selectedDeparture?.departureDate)
                                .add("days", 2)
                                .format("DD.MM.YYYY")
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{ textAlign: "right" }}
                    sx={{
                      alignContent: "center",
                      paddingRight: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        typography: { xs: "h6", md: "h5", lg: "h4" },
                      }}
                    >
                      {data?.adjustedCalculatedPrice
                        ? data?.adjustedCalculatedPrice?.toFixed(2)
                        : data?.calculatedPrice?.toFixed(2)}
                      &euro;
                    </Typography>
                  </Grid>
                  {data?.calculatedPrice != null &&
                    data?.calculatedPriceWithFees != null &&
                    selectedDeparture?.ticketSurchargeList?.map(
                      (surcharge, index) => (
                        <React.Fragment key={index}>
                          <Grid
                            item
                            xs={6}
                            key={surcharge?.productDestinationDepartureId}
                            sx={{
                              textAlign: "left",
                              backgroundColor: "#94c686",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                typography: { xs: "h6", md: "h5" },
                              }}
                            >
                              {surcharge?.surchargeName}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sx={{
                              textAlign: "right",
                              backgroundColor: "#94c686",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                paddingRight: "10px",
                                typography: { xs: "h6", md: "h5" },
                              }}
                            >
                              {surcharge.total?.toFixed(2)}&euro;
                            </Typography>
                          </Grid>
                        </React.Fragment>
                      )
                    )}
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#88c078",
            color: "white",
            padding: "0 15px 0 15px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            position: "relative",
            paddingTop:
              selectedDeparture?.ticketSurchargeList?.length == 0
                ? "60px"
                : selectedDeparture?.ticketSurchargeList?.length > 0
                ? `${70 + selectedDeparture.ticketSurchargeList.length - 4}px`
                : "60px",
          }}
        >
          <Grid container spacing={2}>
            <>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  style={{ padding: "0 15px 8px 15px" }}
                >
                  <Grid item xs={3.5}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        typography: { xs: "h6", md: "h5" },
                      }}
                    >
                      Total Freight
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        typography: { xs: "h6", md: "h5" },
                      }}
                    >
                      Total Surcharges
                    </Typography>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Typography
                      sx={{
                        textAlign: "right",
                        typography: { xs: "h6", md: "h5" },
                      }}
                    >
                      Total Price
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {data?.calculatedPrice != null &&
                  data?.calculatedPriceWithFees != null && (
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        padding: {
                          xs: "0 15px 45px 15px",
                          lg: "0 15px 25px 15px",
                        },
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontWeight: "bold",
                            opacity: "50%",
                            typography: { xs: "h4", lg: "h3" },
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {data?.adjustedCalculatedPrice
                              ? data?.adjustedCalculatedPrice?.toFixed(2)
                              : data?.calculatedPrice?.toFixed(2)}
                            &euro;
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            opacity: "50%",
                            typography: { xs: "h4", lg: "h3" },
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {data?.ticketSurcharges
                              ?.reduce((acc, fee) => {
                                return acc + fee.total;
                              }, 0)
                              .toFixed(2)}
                            &euro;
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            textAlign: "right",
                            fontWeight: "bold",
                            typography: { xs: "h4", lg: "h3" },
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {data?.adjustedCalculatedPrice
                              ? (
                                  data?.adjustedCalculatedPrice +
                                  data?.ticketSurcharges?.reduce((acc, fee) => {
                                    return acc + fee.total;
                                  }, 0)
                                )?.toFixed(2)
                              : data?.calculatedPriceWithFees?.toFixed(2)}
                            &euro;
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
