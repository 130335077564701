import {
  Divider,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Popover,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Tooltip,
  Chip,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { moment } from "../../App";
import { LOCALE } from "../../properties/Locale";
import { TicketRepository } from "../../repositories/TicketRepository";
import { useTheme } from "@emotion/react";
import {
  AgricultureOutlined,
  AirportShuttleOutlined,
  Dehaze,
  DirectionsCarFilledOutlined,
  DirectionsRailwayFilledOutlined,
  DirectionsRailwayOutlined,
  ForestOutlined,
  KeyboardBackspace,
  LocalShippingOutlined,
  RvHookupOutlined,
  TimeToLeaveOutlined,
  TrainOutlined,
} from "@mui/icons-material";
import { VehicleCategoriesRepository } from "../../repositories/VehicleCategoriesRepository";
import styled from "@emotion/styled";

export function ClientTicketContentMobile({
  data,
  loading,
  setData,
  readyForInvoice,
  checkIfLocked,
  invoiced,
  selectedDeparture,
  availableDepartures,
}) {
  const theme = useTheme();
  const statusColors = {
    APPROVED: theme.palette.secondary.main,
    PENDING: theme.palette.extra3.main,
    IN_PROGRESS: theme.palette.in_progress.main,
    REJECTED: theme.palette.rejected.main,
    CANCELLED: theme.palette.canceled.main,
    DONE: theme.palette.done.main,
  };

  const getVehicleCategory = () => {
    let category = "";
    if (data?.vehicleLength > 0 && data?.vehicleLength <= 6) {
      category = "Van";
    } else if (data?.vehicleLength == 7) {
      category = "Solo tractor";
    } else if (data?.vehicleLength > 7 && data?.vehicleLength < 15) {
      category = "Lorry";
    } else if (data?.vehicleLength >= 15 && data?.vehicleLength < 17) {
      category = "Artic";
    } else if (data?.vehicleLength >= 17 && data?.vehicleLength < 19) {
      category = "Road train";
    } else if (data?.vehicleLength >= 19) {
      category = "Train";
    }
    return category;
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 0,
          ...theme.applyStyles("dark", {
            backgroundColor: theme.palette.primary.main,
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...theme.applyStyles("dark", {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...theme.applyStyles("dark", {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...theme.applyStyles("dark", {
        backgroundColor: "#39393D",
      }),
    },
  }));

  return (
    <>
      <Grid container>
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <Chip
            style={{
              fontWeight: "normal",
              height: 30,
              width: 140,
              backgroundColor: statusColors[data?.status],
              textTransform: "capitalize",
              color: "#f5f5f5",
              borderRadius: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.9rem",
            }}
            variant="contained"
            label={data?.status?.replaceAll("_", " ").toLowerCase()}
          />
        </Grid>

        <Grid item xs={3} sx={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{
                color: theme.palette.lightGray.main,
                fontWeight: 400,
              }}
            >
              Booking
            </Typography>
            <Typography
              variant="h4"
              style={{
                color: theme.palette.darkContrast.main,
              }}
            >
              {data?.ticketNumber}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4.5} sx={{ textAlign: "end" }}>
          {data?.reservationCode && (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  color: theme.palette.lightGray.main,
                  fontWeight: 400,
                }}
              >
                Reservation code
              </Typography>
              <Typography
                variant="h4"
                style={{
                  color: theme.palette.darkContrast.main,
                }}
              >
                {data?.reservationCode}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ padding: "10px 0px 5px 0px" }}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container sx={{ paddingBottom: "15px" }}>
        {data ? (
          <>
            <Grid
              item
              xs={12}
              style={{ marginBottom: "5px", marginTop: "10px" }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  color: theme.palette.lightGray.main,
                }}
              >
                <Grid item xs={2}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                    }}
                  >
                    {data?.assignedTo && "Assignee"}
                  </Typography>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "right",
                      fontWeight: "normal",
                    }}
                  >
                    Booking Created
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "10px" }}>
              <Grid
                container
                spacing={2}
                sx={{
                  color: theme.palette.lightGray.main,
                }}
              >
                <Grid item xs={5}>
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: "left",
                      color: theme.palette.lightGray.main,
                    }}
                    component="div"
                  >
                    {data?.assignedTo
                      ? data?.assignedTo.firstName +
                        " " +
                        data?.assignedTo.lastName
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      textAlign: "right",
                      typography: "h5",
                    }}
                  >
                    {moment(data?.dateCreated).format("DD.MM.YYYY HH:mm")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "5px" }}>
              <Grid container spacing={0.5}>
                <Grid item xs={9}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        From
                      </Typography>
                    </Grid>
                    <Grid item xs={7.3}></Grid>
                    <Grid item xs={2}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        To
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "-5px" }}>
                  <Grid container alignItems="center">
                    <Grid
                      item
                      xs={5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: 0,
                      }}
                    >
                      <ReactCountryFlag
                        countryCode={data?.fromCity?.country?.code}
                        svg
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: "6px",
                          marginRight: "5px",
                        }}
                        title={data?.fromCity?.country?.nameEn}
                      />
                      <Typography
                        variant="h5"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data?.fromCity?.name}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      style={{
                        flexShrink: 0,
                      }}
                    >
                      <KeyboardBackspace
                        style={{
                          transform: "rotate(180deg)",
                          fontSize: "26px",
                          marginTop: "5px",
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        minWidth: 0,
                      }}
                    >
                      <ReactCountryFlag
                        countryCode={data?.toCity?.country?.code}
                        svg
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: "6px",
                          marginRight: "5px",
                        }}
                        title={data?.toCity?.country?.nameEn}
                      />
                      <Typography
                        variant="h5"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data?.toCity?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                          marginBottom: "5px",
                        }}
                      >
                        Departure Date
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{
                          fontWeight: "bold",
                          color: theme.palette.darkContrast.main,
                        }}
                      >
                        {moment(data?.departureDay).format("DD.MM.YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                          textAlign: "left",
                          marginBottom: "5px",
                        }}
                      >
                        Departure Hour
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          color: theme.palette.darkContrast.main,
                          textAlign: "left",
                        }}
                      >
                        {data?.departureHour}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      {data?.organization?.showSupplier && (
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "normal",
                            color: theme.palette.lightGray.main,
                            textAlign: "left",
                            marginBottom: "5px",
                          }}
                        >
                          Supplier
                        </Typography>
                      )}
                      {data?.organization?.showSupplier && (
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "bold",
                            color: theme.palette.darkContrast.main,
                            textAlign: { xs: "left", md: "right" },
                          }}
                        >
                          {data?.productDestination?.operator?.name
                            ? data?.productDestination?.operator?.name
                            : data?.productDestination?.supplier?.name}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ padding: "15px 0px 15px 0px", marginBottom: "15px" }}
            >
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "normal",
                      color: theme.palette.lightGray.main,
                      marginBottom: "5px",
                    }}
                  >
                    Vehicle Category
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={data?.vehicleCategory?.name || getVehicleCategory()}
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    InputProps={{
                      style: {
                        color: theme.palette.darkContrast.main,
                        fontSize: "18px",
                        fontWeight: "bold",
                      },
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{
                            color: theme.palette.darkContrast.main,
                          }}
                        >
                          {data?.vehicleCategory?.name.startsWith("Van") ||
                          (data?.vehicleLength > 0 &&
                            data?.vehicleLength <= 6) ? (
                            <AirportShuttleOutlined
                              style={{
                                fontSize: "28px",
                              }}
                            />
                          ) : data?.vehicleCategory?.name.startsWith(
                              "Solo tractor"
                            ) || data?.vehicleLength == 7 ? (
                            <AgricultureOutlined
                              style={{
                                fontSize: "28px",
                              }}
                            />
                          ) : data?.vehicleCategory?.name.startsWith("Lorry") ||
                            (data?.vehicleLength > 7 &&
                              data?.vehicleLength < 15) ? (
                            <LocalShippingOutlined
                              style={{
                                fontSize: "28px",
                              }}
                            />
                          ) : data?.vehicleCategory?.name.startsWith("Artic") ||
                            (data?.vehicleLength >= 15 &&
                              data?.vehicleLength < 17) ? (
                            <RvHookupOutlined
                              style={{
                                fontSize: "28px",
                              }}
                            />
                          ) : data?.vehicleCategory?.name.startsWith(
                              "Road train"
                            ) ||
                            (data?.vehicleLength >= 17 &&
                              data?.vehicleLength < 19) ? (
                            <DirectionsRailwayOutlined
                              style={{
                                fontSize: "28px",
                              }}
                            />
                          ) : data?.vehicleCategory?.name.startsWith("Train") ||
                            data?.vehicleLength >= 19 ? (
                            <TrainOutlined
                              style={{
                                fontSize: "28px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid item xs={3.5}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                          marginBottom: "5px",
                        }}
                      >
                        Length
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={data?.vehicleLength || ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: theme.palette.darkContrast.main,
                                  opacity: "25%",
                                }}
                              >
                                m
                              </span>
                            </InputAdornment>
                          ),
                          sx: {
                            color: theme.palette.darkContrast.main,
                            fontSize: "18px",
                            fontWeight: "bold",
                            input: {
                              textAlign: "center",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                          marginBottom: "5px",
                        }}
                      >
                        Width
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={data?.vehicleWidth || ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: theme.palette.darkContrast.main,
                                  opacity: "25%",
                                }}
                              >
                                m
                              </span>
                            </InputAdornment>
                          ),
                          sx: {
                            color: theme.palette.darkContrast.main,
                            fontSize: "18px",
                            fontWeight: "bold",
                            input: {
                              textAlign: "center",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                          marginBottom: "5px",
                        }}
                      >
                        Height
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={data?.vehicleHeight || ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <span
                                style={{
                                  color: theme.palette.darkContrast.main,
                                  opacity: "25%",
                                }}
                              >
                                m
                              </span>
                            </InputAdornment>
                          ),
                          sx: {
                            color: theme.palette.darkContrast.main,
                            fontSize: "18px",
                            fontWeight: "bold",
                            input: {
                              textAlign: "center",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: "0px",
                  marginBottom: "5px",
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "normal",
                      color: theme.palette.lightGray.main,
                    }}
                  >
                    Truck Plate Number
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  {data?.trailerPlateNumber && (
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "normal",
                        color: theme.palette.lightGray.main,
                      }}
                    >
                      Trailer Plate Number
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "bold",
                      color: theme.palette.darkContrast.main,
                      height: "20px",
                    }}
                  >
                    {data?.truckPlateNumber}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  {data?.trailerPlateNumber && (
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: "bold",
                        color: theme.palette.darkContrast.main,
                        height: "20px",
                      }}
                    >
                      {data?.trailerPlateNumber}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: "10px 0 10px 0" }}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginBottom: "10px", marginTop: "5px" }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "normal",
                      color: theme.palette.lightGray.main,
                      marginBottom: "5px",
                    }}
                  >
                    Driver Full Name
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "bold",
                      color: theme.palette.darkContrast.main,
                      height: "24px",
                    }}
                  >
                    {data?.driverName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sx={{ margin: "10px 0 10px 0" }}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={8}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "normal",
                      color: theme.palette.lightGray.main,
                      marginBottom: "5px",
                    }}
                  >
                    Cargo Type
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={
                      data?.loaded && data?.material?.name
                        ? data?.material?.name
                        : "UNKNOWN"
                    }
                    InputProps={{
                      style: {
                        color: theme.palette.darkContrast.main,
                        fontSize: "18px",
                        fontWeight: "bold",
                      },
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{
                            color: theme.palette.darkContrast.main,
                          }}
                        >
                          {data?.material?.name == "Cars" ? (
                            <TimeToLeaveOutlined
                              style={{
                                fontSize: "28px",
                              }}
                            />
                          ) : data?.material?.name == "Paper" ? (
                            <Icon
                              style={{
                                fontSize: "28px",
                              }}
                            >
                              <svg
                                width="18"
                                height="20"
                                viewBox="0 0 18 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.5 1.5L15 0L13.5 1.5L12 0L10.5 1.5L9 0L7.5 1.5L6 0L4.5 1.5L3 0V14H0V17C0 18.66 1.34 20 3 20H15C16.66 20 18 18.66 18 17V0L16.5 1.5ZM12 18H3C2.45 18 2 17.55 2 17V16H12V18ZM16 17C16 17.55 15.55 18 15 18C14.45 18 14 17.55 14 17V14H5V3H16V17Z"
                                  fill="#202020"
                                  style={{
                                    fill: "#202020",
                                    fill: "color(display-p3 0.1255 0.1255 0.1255)",
                                    fillOpacity: "1",
                                  }}
                                />
                              </svg>
                            </Icon>
                          ) : data?.material?.name == "Wood" ? (
                            <ForestOutlined
                              style={{
                                fontSize: "28px",
                              }}
                            />
                          ) : data?.material?.name == "Metal" ? (
                            <Icon
                              style={{
                                fontSize: "28px",
                              }}
                            >
                              <svg
                                width="15"
                                height="20"
                                viewBox="0 0 15 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.5 4.5H1V1H14V4.5H10.5H9.5V5.5V14.5V15.5H10.5H14V19H1V15.5H4.5H5.5V14.5V5.5V4.5H4.5Z"
                                  stroke="#202020"
                                  style={{
                                    stroke: "#202020",
                                    stroke:
                                      "color(display-p3 0.1255 0.1255 0.1255)",
                                    strokeOpacity: "1",
                                  }}
                                  stroke-width="2"
                                />
                              </svg>
                            </Icon>
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "normal",
                      color: theme.palette.lightGray.main,
                      marginBottom: "5px",
                    }}
                  >
                    Weight
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={
                      data?.loaded && data?.cargoWeight
                        ? data?.cargoWeight
                        : "0"
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span
                            style={{
                              color: theme.palette.darkContrast.main,
                              opacity: "25%",
                            }}
                          >
                            {data?.loaded && "t"}
                          </span>
                        </InputAdornment>
                      ),
                      sx: {
                        color: theme.palette.darkContrast.main,
                        fontSize: "18px",
                        fontWeight: "bold",
                        input: {
                          textAlign: "center",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px 0 10px 10px",
                }}
              >
                <Grid item xs={4.5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        Dangerous Goods
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <IOSSwitch checked={data?.dangerousGoods || false} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        Electric Plugin
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IOSSwitch checked={data?.electricPlugin || false} />
                      </div>
                      {data?.temperature && (
                        <Typography
                          variant="h4"
                          style={{
                            marginLeft: "5px",
                            marginTop: "5px",
                            display: "inline-block",
                            color: theme.palette.lightGray.main,
                            fontWeight: "lighter",
                          }}
                        >
                          {data.temperature}°C
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3.5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        Live Animals
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <IOSSwitch checked={data?.liveAnimals || false} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {(data?.product?.priceVisibility == "DEFAULT" &&
              data?.organization?.showPrice) ||
            data?.product?.priceVisibility == "SHOW" ? (
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: "40px",
                  padding: "0 10px 0 20px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#557a46" : "#88c078",
                    color: "white",
                    clipPath: "inset(0 round 0.5em)",
                    padding: "5px 20px 15px 5px",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="h4">Payment Details</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingBottom: "15px" }}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography
                          variant="h5"
                          sx={{ opacity: "50%", fontWeight: "normal" }}
                        >
                          Departure
                        </Typography>
                        <Typography variant="h4">
                          {selectedDeparture?.departureTime}
                        </Typography>
                        <Typography variant="h5" sx={{ fontWeight: "normal" }}>
                          {moment(selectedDeparture?.departureDate).format(
                            "DD.MM.YYYY"
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          textAlign: "center",
                          alignContent: "center",
                        }}
                      >
                        <KeyboardBackspace
                          sx={{
                            transform: "rotate(180deg)",
                            fontSize: "26px",
                            marginTop: "5px",
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ textAlign: "left" }}>
                        <Typography
                          variant="h5"
                          sx={{ opacity: "50%", fontWeight: "normal" }}
                        >
                          Arrival
                        </Typography>
                        <Typography variant="h4">
                          {selectedDeparture?.arrivalTime}
                        </Typography>
                        <Typography variant="h5" sx={{ fontWeight: "normal" }}>
                          {selectedDeparture?.arrivalDay == "0"
                            ? moment(selectedDeparture?.departureDate).format(
                                "DD.MM.YYYY"
                              )
                            : selectedDeparture?.arrivalDay == "1"
                            ? moment(selectedDeparture?.departureDate)
                                .add(1, "days")
                                .format("DD.MM.YYYY")
                            : moment(selectedDeparture?.departureDate)
                                .add(2, "days")
                                .format("DD.MM.YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {selectedDeparture?.ticketSurchargeList?.map(
                    (surcharge, index) => (
                      <React.Fragment key={index}>
                        <Grid
                          container
                          key={surcharge?.productDestinationDepartureId}
                          sx={{
                            padding: "0 0 0 15px",
                          }}
                        >
                          <Grid
                            item
                            xs={8}
                            sx={{
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? index % 2 === 0
                                    ? "#608a50" // Darker shade for even rows in dark mode
                                    : "#5b824b" // Darker shade for odd rows in dark mode
                                  : index % 2 === 0
                                  ? "#94c686" // Lighter shade for even rows in light mode
                                  : "#8ec37f", // Lighter shade for odd rows in light mode
                              padding: "15px 5px 15px 10px",
                            }}
                          >
                            <Typography variant="h5" fontWeight="normal">
                              {surcharge?.surchargeName}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? index % 2 === 0
                                    ? "#608a50" // Darker shade for even rows in dark mode
                                    : "#5b824b" // Darker shade for odd rows in dark mode
                                  : index % 2 === 0
                                  ? "#94c686" // Lighter shade for even rows in light mode
                                  : "#8ec37f", // Lighter shade for odd rows in light mode
                              padding: "15px 0 0 0",
                            }}
                          >
                            <Typography variant="h5">
                              {surcharge?.total?.toFixed(2)}&euro;
                            </Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )
                  )}
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        paddingRight: "20px",
                      }}
                    >
                      <Grid item xs={4}>
                        <Typography
                          variant="h5"
                          sx={{
                            textAlign: "center",
                            fontWeight: "normal",
                          }}
                        >
                          Total Freight
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="h5"
                          sx={{
                            textAlign: "center",
                            fontWeight: "normal",
                          }}
                        >
                          Total Surcharges
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="h5"
                          sx={{
                            textAlign: "center",
                            fontWeight: "normal",
                            marginRight: "-10px",
                          }}
                        >
                          Total Price
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="h5"
                          sx={{ opacity: "50%", textAlign: "center" }}
                        >
                          {data?.adjustedCalculatedPrice
                            ? data?.adjustedCalculatedPrice?.toFixed(2)
                            : data?.calculatedPrice?.toFixed(2)}
                          &euro;
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant="h5"
                          sx={{ opacity: "50%", textAlign: "center" }}
                        >
                          {data?.ticketSurcharges
                            ?.reduce((acc, fee) => acc + fee.total, 0)
                            .toFixed(2)}
                          &euro;
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ textAlign: "center" }}>
                        <Typography variant="h5">
                          {data?.adjustedCalculatedPrice
                            ? (
                                data?.adjustedCalculatedPrice +
                                data?.ticketSurcharges?.reduce((acc, fee) => {
                                  return acc + fee.total;
                                }, 0)
                              )?.toFixed(2)
                            : data?.calculatedPriceWithFees?.toFixed(2)}
                          &euro;
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </>
        ) : (
          <>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={200} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
