import {
  alpha,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Popover,
  Select,
  Skeleton,
  Stack,
  styled,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  withStyles,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { moment } from "../../App";
import { TableRowEmptyData } from "../../common/TableRowEmptyData";
import {
  getTicketPriorityColor,
  getTicketSourceIcon,
  getTicketStatusColor,
} from "../../common/VisualElementsHelper";
import { LOCALE } from "../../properties/Locale";
import {
  Api,
  Autorenew,
  CheckBox,
  Close,
  CreditCardOffOutlined,
  DangerousOutlined,
  EvStationOutlined,
  HourglassEmpty,
  HourglassTop,
  HourglassTopOutlined,
  MarkEmailUnreadOutlined,
  Pending,
  PendingOutlined,
  PersonOutlineOutlined,
  ReceiptLongOutlined,
  TextSnippetOutlined,
  VerifiedOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { AuthRepository } from "../../repositories/AuthRepository";

export const ClientTicketListTableBodyMobile = ({
  showSelectTicketsCheckbox,
  showSelectTicketsCheckboxContactSupplier,
  showSelectTicketsCheckboxContactSupplier2,
  loading,
  data,
  setHoveredRow,
  hoveredRow,
  selectedTickets,
  setSelectedTickets,
  handleOpenEscalateDialog,
  handleOpenWaitingListDialog,
  handleAssignToCurrentUser,
  showAlternativeTickets,
  alternativeTickets,
  setAlternativeTickets,
  getAllAlternativeTicketsByTicketId,
  navigate,
  handleChangeTicketWithAlternativeTicket,
  setShowAlternativeTickets,
  showTicketGroups,
  handleRemoveTicketFromGroup,
  handleAddReservationCode,
  handleRemoveReservationCode,
  handleRemoveAlternativeTicket,
  selectedContactSupplier,
  setSelectedContactSupplier,
  setSelectAllChecked,
  selectAllChecked,
  newlyAddedTicketNumber,
  handleClickChangeOrderBy,
  params,
  expandedFilters,
}) => {
  const [
    openAlternativeTicketReservationCodeDialog,
    setOpenAlternativeTicketReservationCodeDialog,
  ] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [reservationCode, setReservationCode] = React.useState("");
  const [codeWaitingList, setCodeWaitingList] = React.useState(false);
  const [reservationCodes, setReservationCodes] = React.useState([]);
  const [selectedAlternativeTicket, setSelectedAlternativeTicket] =
    React.useState();
  const [selectedTicket, setSelectedTicket] = React.useState();
  const handleCloseAlternativeTicketReservationCodeDialog = () => {
    setOpenAlternativeTicketReservationCodeDialog(false);
  };
  const [newlyAddedTickets, setNewlyAddedTickets] = useState([]);
  const theme = useTheme();
  const statusColors = {
    APPROVED: theme.palette.secondary.main,
    PENDING: theme.palette.extra3.main,
    IN_PROGRESS: theme.palette.in_progress.main,
    REJECTED: theme.palette.rejected.main,
    CANCELLED: theme.palette.canceled.main,
    DONE: theme.palette.done.main,
    NO_SHOW: theme.palette.mutedRed.main,
  };

  useEffect(() => {
    if (newlyAddedTicketNumber) {
      setNewlyAddedTickets((prevTickets) => [
        ...prevTickets,
        newlyAddedTicketNumber,
      ]);
    }
  }, [newlyAddedTicketNumber]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setReservationCode("");
  };

  const getIconsForItem = (item, theme) => {
    const iconList = [];

    if (item?.onWaitingList) {
      iconList.push({
        component: (
          <PendingOutlined
            style={{
              opacity: "80%",
              fontSize: "24px",
              color: theme.palette.extra1.main,
            }}
          />
        ),
        title: "Waiting list",
      });
    }

    if (item?.missingMandatorySurcharge) {
      iconList.push({
        component: (
          <CreditCardOffOutlined
            style={{
              fontSize: "24px",
              color: theme.palette.error.main,
              opacity: "80%",
            }}
          />
        ),
        title: "Missing mandatory surcharge",
      });
    }

    if (item?.electricPlugin == true) {
      iconList.push({
        component: (
          <EvStationOutlined
            color={theme.palette.darkContrast.main}
            style={{
              fontSize: "24px",
              color: theme.palette.darkContrast.main,
            }}
          />
        ),
        title: "Electricity plug-in",
      });
    }

    if (item?.dangerousGoods == true) {
      iconList.push({
        component: (
          <DangerousOutlined
            style={{
              fontSize: "24px",
              color: theme.palette.error.main,
              opacity: "80%",
            }}
          />
        ),
        title: "Dangerous goods",
      });
    }

    if (item?.liveAnimals == true) {
      iconList.push({
        component: (
          <Icon
            style={{
              fontSize: "24px",
              color: theme.palette.darkContrast.main,
            }}
          >
            <svg
              width="16"
              height="24"
              viewBox="0 0 14 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 12C11.76 11.76 11.56 11.51 11.35 11.25C12.51 9.5 14 6.56 14 3C14 1.05 13.26 0 12 0C10.46 0 8.04 2.06 7 5.97C5.96 2.06 3.54 0 2 0C0.74 0 0 1.05 0 3C0 6.56 1.49 9.5 2.65 11.25C2.44 11.51 2.24 11.76 2 12C1.75 12.25 0 13.39 0 15.5C0 17.98 2.02 20 4.5 20C6 20 7 19.5 7 19.5C7 19.5 8 20 9.5 20C11.98 20 14 17.98 14 15.5C14 13.39 12.25 12.25 12 12ZM11.88 2.03C11.94 2.2 12 2.51 12 3C12 5.84 10.89 8.24 9.93 9.78C9.55 9.52 9.1 9.3 8.53 9.16C8.77 4.64 10.97 2.33 11.88 2.03ZM2 3C2 2.51 2.06 2.2 2.12 2.03C3.03 2.33 5.23 4.64 5.48 9.16C4.9 9.3 4.45 9.51 4.08 9.78C3.11 8.24 2 5.84 2 3ZM9.5 18C8.5 18 7.7 17.67 7.28 17.44C7.7 17.26 8 16.73 8 16.5C8 16.22 7.55 16 7 16C6.45 16 6 16.22 6 16.5C6 16.73 6.3 17.26 6.72 17.44C6.3 17.67 5.5 18 4.5 18C3.12 18 2 16.88 2 15.5C2 14.8 2.43 14.26 3 13.77C3.44 13.41 3.61 13.25 4.3 12.4C5.06 11.45 5.39 11 7 11C8.61 11 8.94 11.45 9.7 12.4C10.39 13.25 10.56 13.41 11 13.77C11.57 14.26 12 14.8 12 15.5C12 16.88 10.88 18 9.5 18ZM9 14C9 14.41 8.78 14.75 8.5 14.75C8.22 14.75 8 14.41 8 14C8 13.59 8.22 13.25 8.5 13.25C8.78 13.25 9 13.59 9 14ZM6 14C6 14.41 5.78 14.75 5.5 14.75C5.22 14.75 5 14.41 5 14C5 13.59 5.22 13.25 5.5 13.25C5.78 13.25 6 13.59 6 14Z"
                fill={`${theme.palette.darkContrast.main}`}
                style={{
                  fill: theme.palette.darkContrast.main,
                  fillOpacity: 1,
                }}
              />
            </svg>
          </Icon>
        ),
        title: "Live animals",
      });
    }

    if (item?.material != null) {
      iconList.push({
        component: (
          <Icon
            style={{
              fontSize: "24px",
              color: theme.palette.darkContrast.main,
            }}
          >
            <svg
              width="16"
              height="24"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 8C7.9 8 6.875 7.79583 5.925 7.3875C4.975 6.97917 4.13333 6.41667 3.4 5.7L5.2 4.625C5.6 4.95833 6.03333 5.23333 6.5 5.45C6.96667 5.66667 7.46667 5.81667 8 5.9V0.6L9 0L10 0.6V5.9C10.5333 5.81667 11.0333 5.66667 11.5 5.45C11.9667 5.23333 12.4 4.95833 12.8 4.625L14.6 5.7C13.8667 6.41667 13.025 6.97917 12.075 7.3875C11.125 7.79583 10.1 8 9 8ZM7.775 17.375L5.925 16.4C5.95833 16.25 5.97917 16.1 5.9875 15.95C5.99583 15.8 6 15.65 6 15.5C6 15.1167 5.96667 14.7375 5.9 14.3625C5.83333 13.9875 5.73333 13.6333 5.6 13.3L1.025 16.05L0 15.5V14.325L4.55 11.6C4.21667 11.2167 3.83333 10.875 3.4 10.575C2.96667 10.275 2.5 10.0333 2 9.85V7.75C3.73333 8.2 5.16667 9.1375 6.3 10.5625C7.43333 11.9875 8 13.6333 8 15.5C8 15.8333 7.98333 16.1542 7.95 16.4625C7.91667 16.7708 7.85833 17.075 7.775 17.375ZM10.225 17.375C10.1417 17.075 10.0833 16.7708 10.05 16.4625C10.0167 16.1542 10 15.8333 10 15.5C10 13.6333 10.5667 11.9875 11.7 10.5625C12.8333 9.1375 14.2667 8.2 16 7.75V9.85C15.5 10.0333 15.0333 10.275 14.6 10.575C14.1667 10.875 13.7833 11.2167 13.45 11.6L18 14.325V15.5L16.975 16.05L12.4 13.3C12.2667 13.6333 12.1667 13.9875 12.1 14.3625C12.0333 14.7375 12 15.1167 12 15.5C12 15.65 12.0042 15.8 12.0125 15.95C12.0208 16.1 12.0417 16.25 12.075 16.4L10.225 17.375Z"
                fill={`${theme.palette.darkContrast.main}`}
                style={{
                  fill: theme.palette.darkContrast.main,
                  fillOpacity: "1",
                }}
              />
            </svg>
          </Icon>
        ),
        title: "Material: " + item?.material?.name,
      });
    }
    if (item?.invoiceId != null) {
      iconList.push({
        component: (
          <ReceiptLongOutlined
            style={{
              opacitiy: "80%",
              fontSize: "24px",
              color: theme.palette.extra1.main,
            }}
          />
        ),
        title: "Invoiced",
      });
    }

    if (item?.waitingForDfdsAcknowledgement) {
      iconList.push({
        component: (
          <HourglassEmpty
            style={{
              opacitiy: "80%",
              fontSize: "24px",
              color: theme.palette.extra1.main,
            }}
          />
        ),
        title: "Waiting for DFDS acknowledgement",
      });
    }

    if (item?.waitingForDfdsAcknowledgement) {
      iconList.push({
        component: (
          <HourglassEmpty
            style={{
              opacitiy: "80%",
              fontSize: "24px",
              color: theme.palette.extra1.main,
            }}
          />
        ),
        title: "Waiting for Scandlines acknowledgement",
      });
    }

    if (item?.checkedIn == true) {
      iconList.push({
        component: (
          <VerifiedOutlined
            style={{
              opacitiy: "80%",
              fontSize: "24px",
              color: theme.palette.success.main,
            }}
          />
        ),
        title: "Checked-in",
      });
    }
    return iconList;
  };

  const rowsToRender = data ? data.length : 0;
  const emptyRows = 5 - rowsToRender;

  return (
    <>
      {!loading && data && data?.length === 0 && <TableRowEmptyData />}
      {(loading || !data) && (
        <>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={70} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={70} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={70} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={70} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={70} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={70} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={20}>
              <Skeleton variant="react" height={70} />
            </TableCell>
          </TableRow>
        </>
      )}
      {!loading &&
        data &&
        data.map((item, index) => (
          <React.Fragment key={index}>
            {/* {index === 0 && expandedFilters && (
              <>
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item xs={3.5}>
                    <Typography
                      variant="h6"
                      sx={{
                        border:
                          params?.orderValue === "destination"
                            ? "1px solid"
                            : `1px solid #C4C4C4`,
                        color:
                          params?.orderValue === "destination"
                            ? theme.palette.dashboardBackground.main
                            : alpha(theme.palette.darkContrast.main, 0.5),
                        backgroundColor:
                          params?.orderValue === "destination"
                            ? theme.palette.secondary.main
                            : "inherit",
                        borderRadius: "5px",
                        fontWeight: "light",
                        textAlign: "center",
                        padding: "5px 0 5px 0",
                      }}
                    >
                      Destination
                      <IconButton
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0px",
                          color:
                            params?.orderValue === "destination"
                              ? "white"
                              : alpha(theme.palette.primary.main, 0.5),
                        }}
                        value={"destination"}
                        onClick={handleClickChangeOrderBy}
                      >
                        {params?.orderValue === "destination" ? (
                          params?.orderDirection == "DESC" ? (
                            <Icon
                              style={{
                                transform: "rotate(90deg)",
                              }}
                            >
                              play_arrow
                            </Icon>
                          ) : (
                            <Icon style={{ transform: "rotate(-90deg)" }}>
                              play_arrow
                            </Icon>
                          )
                        ) : (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {AuthRepository.getUserDetails()?.organization
                      ?.showSupplier && (
                      <Typography
                        variant="h6"
                        sx={{
                          border:
                            params?.orderValue === "productDestination.supplier"
                              ? "1px solid"
                              : `1px solid #C4C4C4`,
                          color:
                            params?.orderValue === "productDestination.supplier"
                              ? theme.palette.dashboardBackground.main
                              : alpha(theme.palette.darkContrast.main, 0.5),
                          backgroundColor:
                            params?.orderValue === "productDestination.supplier"
                              ? theme.palette.secondary.main
                              : "inherit",
                          borderRadius: "5px",
                          fontWeight: "light",
                          textAlign: "center",
                          padding: "5px 0 5px 0",
                        }}
                      >
                        Supplier
                        <IconButton
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "0px",
                            color:
                              params?.orderValue ===
                              "productDestination.supplier"
                                ? "white"
                                : alpha(theme.palette.primary.main, 0.5),
                          }}
                          value={"productDestination.supplier"}
                          onClick={handleClickChangeOrderBy}
                        >
                          {params?.orderValue ===
                          "productDestination.supplier" ? (
                            params?.orderDirection == "DESC" ? (
                              <Icon style={{ transform: "rotate(90deg)" }}>
                                play_arrow
                              </Icon>
                            ) : (
                              <Icon style={{ transform: "rotate(-90deg)" }}>
                                play_arrow
                              </Icon>
                            )
                          ) : (
                            <Icon style={{ transform: "rotate(90deg)" }}>
                              play_arrow
                            </Icon>
                          )}
                        </IconButton>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3.5}>
                    <Typography
                      variant="h6"
                      sx={{
                        border:
                          params?.orderValue === "departureDay"
                            ? "1px solid"
                            : `1px solid #C4C4C4`,
                        color:
                          params?.orderValue === "departureDay"
                            ? theme.palette.dashboardBackground.main
                            : alpha(theme.palette.darkContrast.main, 0.5),
                        backgroundColor:
                          params?.orderValue === "departureDay"
                            ? theme.palette.secondary.main
                            : "inherit",
                        borderRadius: "5px",
                        fontWeight: "light",
                        textAlign: "center",
                        padding: "5px 0 5px 0",
                      }}
                    >
                      Departure
                      <IconButton
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0px",
                          color:
                            params?.orderValue === "departureDay"
                              ? "white"
                              : alpha(theme.palette.primary.main, 0.5),
                        }}
                        value={"departureDay"}
                        onClick={handleClickChangeOrderBy}
                      >
                        {params?.orderValue === "departureDay" ? (
                          params?.orderDirection == "DESC" ? (
                            <Icon style={{ transform: "rotate(90deg)" }}>
                              play_arrow
                            </Icon>
                          ) : (
                            <Icon style={{ transform: "rotate(-90deg)" }}>
                              play_arrow
                            </Icon>
                          )
                        ) : (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )} */}
            <TableRow
              key={index}
              onMouseEnter={() => {
                setHoveredRow(item?.id);
              }}
              onMouseLeave={() => {
                setHoveredRow(null);
              }}
              style={{
                backgroundColor: newlyAddedTickets.includes(item.ticketNumber)
                  ? alpha(statusColors[item?.status], 0.25)
                  : item.priority === "CRITICAL" || item.priority === "HIGH"
                  ? "#ffc107"
                  : theme.palette.tableRow.main,
                ...(hoveredRow === item.id && {
                  backgroundColor: `${
                    statusColors[item?.status] || "#f2f2f2"
                  }50`,
                }),
                transition: "background-color 0.3s ease",
                zIndex: "100",
                cursor: "pointer",
                height: "120px",
                clipPath: "inset(0 round 2em)",
                borderSpacing: 2,
              }}
              onClick={() => {
                item.productPrice
                  ? navigate(
                      `/client/booking/${params?.page}/${params?.size}/${params?.params}/${item.id}`
                    )
                  : navigate(
                      `/client/booking/${params?.page}/${params?.size}/${params?.params}/${item.id}`
                    );
              }}
            >
              <TableCell colSpan={20} sx={{ borderBottom: "none" }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: "10px",
                    alignContent: "center",
                    alignItems: "center",
                    verticalAlign: "center",
                  }}
                >
                  <Grid item xs={4}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: statusColors[item?.status],
                        textTransform: "capitalize",
                        ...(hoveredRow === item.id && {
                          color: "white",
                        }),
                        alignContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item?.status === "DONE" && (
                        <VerifiedOutlined
                          sx={{
                            paddingRight: "5px",
                            fontSize: "25px",
                          }}
                        />
                      )}
                      {item?.status === "CANCELED" && (
                        <HourglassTopOutlined
                          sx={{
                            paddingRight: "5px",
                            fontSize: "25px",
                          }}
                        />
                      )}
                      {item?.status === "REJECTED" && (
                        <Close
                          sx={{
                            paddingRight: "5px",
                            fontSize: "25px",
                          }}
                        />
                      )}
                      {item?.status?.replaceAll("_", " ").toLowerCase()}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{ textAlign: "right" }}>
                    <Typography
                      variant="h5"
                      style={{
                        marginRight: "5px",
                        color:
                          params?.orderValue === "departureDay"
                            ? theme.palette.secondary.main
                            : theme.palette.darkContrast.main,
                        ...(hoveredRow === item.id && {
                          color: "white",
                        }),
                      }}
                    >
                      {`${moment(item?.departureDay).format("DD.MM.YYYY")} 
                    ${
                      item?.priceList
                        ? item?.priceList?.departureHour
                        : item?.departureHour
                        ? item?.departureHour
                        : ""
                    }`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      marginTop: "15px",
                      marginBottom: "5px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        ...(hoveredRow === item.id && {
                          color: "white",
                        }),
                        color:
                          params?.orderValue === "destination"
                            ? theme.palette.secondary.main
                            : theme.palette.darkContrast.main,
                      }}
                    >
                      {item?.product?.directionsRange === "ONE_WAY"
                        ? item?.destination?.name?.split("-").join(" ➜ ")
                        : item?.product?.directionsRange === "RETURN"
                        ? item?.destination?.name?.split("-").join(" ⇄ ")
                        : item?.destination?.name}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        color:
                          params?.orderValue === "productDestination.supplier"
                            ? theme.palette.secondary.main
                            : theme.palette.lightGray.main,
                      }}
                    >
                      {item?.organization?.showSupplier && (
                        <>
                          {item?.productDestination ? (
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              {item?.productDestination?.operator?.name
                                ? item?.productDestination?.operator?.name
                                : item?.productDestination?.supplier?.name}
                            </Typography>
                          ) : (
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold" }}
                            >
                              {`Price list not selected`}
                            </Typography>
                          )}
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="h6"
                      sx={{
                        color:
                          params?.orderValue === "ticketNumber"
                            ? theme.palette.secondary.main
                            : alpha(theme.palette.darkContrast.main, 0.75),
                        ...(hoveredRow === item.id && {
                          color: "white",
                        }),
                        borderRight: `1px solid ${alpha(
                          theme.palette.darkContrast.main,
                          0.15
                        )}`,
                        textAlign: "left",
                      }}
                    >
                      {item?.ticketNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "left" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        ...(hoveredRow === item.id && {
                          color: "white",
                        }),
                        color:
                          params?.orderValue === "reservationCode"
                            ? theme.palette.secondary.main
                            : theme.palette.darkContrast.main,
                      }}
                    >
                      {item?.reservationCode ? item?.reservationCode : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign: "right" }}>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                        color:
                          params?.orderValue === "truckPlateNumber"
                            ? theme.palette.secondary.main
                            : theme.palette.darkContrast.main,
                      }}
                    >
                      {item?.truckPlateNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{ textAlign: "right" }}>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                        color:
                          params?.orderValue === "vehicleLength"
                            ? theme.palette.secondary.main
                            : theme.palette.darkContrast.main,
                      }}
                    >
                      {item?.vehicleLength}m
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={2}>
                    {item?.assignedTo ? (
                      <Box
                        sx={{
                          width: 28,
                          height: 28,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor:
                            theme.palette.dashboardBackground.main,
                          border:
                            theme.palette.mode === "light"
                              ? `2px solid${theme.palette.darkContrast.main}`
                              : "none",
                          borderRadius: "4px",
                          fontWeight: "bold",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          sx={{ color: theme.palette.darkContrast.main }}
                        >
                          {item?.assignedTo?.firstName[0] +
                            item?.assignedTo?.lastName[0]}
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ width: 28, height: 28 }}></Box>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "2px",
                      }}
                    >
                      {getIconsForItem(item, theme)
                        .slice(0, 1)
                        .map(({ component, title }, i) => (
                          <Box
                            key={i}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {component}
                          </Box>
                        ))}

                      {getIconsForItem(item, theme).length > 1 && (
                        <>
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              backgroundColor: theme.palette.primary.main,
                              color: "white",
                              border: `2px solid rgba(87,155,165, 0.5)`,
                              borderRadius: "4px",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "16px",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              {`+${getIconsForItem(item, theme).length - 1}`}
                            </Typography>
                          </Box>
                        </>
                      )}
                      {item?.numberOfDrivers > 1 && (
                        <>
                          <Box
                            sx={{
                              width: 40,
                              height: 24,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: theme.palette.primary.main,
                              color: "white",
                              border: `2px solid rgba(87,155,165, 0.5)`,
                              borderRadius: "4px",
                              fontWeight: "bold",
                            }}
                          >
                            <PersonOutlineOutlined
                              style={{
                                padding: 0,
                                fontSize: "24px",
                              }}
                            />
                            <Typography
                              variant="subtitle2"
                              style={{ fontSize: "16px" }}
                            >
                              {item?.numberOfDrivers}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign: "right" }}>
                    {(item?.product?.priceVisibility == "DEFAULT" &&
                      item?.organization?.showPrice) ||
                    item?.product?.priceVisibility == "SHOW" ? (
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        <strong>{`${(
                          +item?.calculatedPriceWithFees -
                          +item?.calculatedPrice
                        ).toFixed(2)}€`}</strong>
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign: "right" }}>
                    {(item?.product?.priceVisibility == "DEFAULT" &&
                      item?.organization?.showPrice) ||
                    item?.product?.priceVisibility == "SHOW" ? (
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "bold",
                          color:
                            params?.orderValue === "calculatedPriceWithFees"
                              ? theme.palette.secondary.main
                              : theme.palette.darkContrast.main,
                        }}
                      >
                        {item?.adjustedCalculatedPrice ? (
                          <Tooltip title="Adjusted price">
                            <div style={{ textAlign: "right" }}>
                              <span
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "-webkit-baseline-middle",
                                }}
                              >
                                <Icon
                                  style={{ fontSize: "13px", color: "#FF6B1A" }}
                                >
                                  published_with_changes
                                </Icon>
                              </span>
                              <strong
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              >
                                {`${+item?.adjustedCalculatedPrice.toFixed(
                                  2
                                )}€ `}
                              </strong>
                            </div>
                          </Tooltip>
                        ) : (
                          <strong>{`${(+item?.calculatedPriceWithFees).toFixed(
                            2
                          )}€ `}</strong>
                        )}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            {/* {showAlternativeTickets &&
              alternativeTickets &&
              alternativeTickets.length > 0 &&
              alternativeTickets[0].ticket.id == item.id &&
              alternativeTickets.map((altTicket, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: "rgb(226 244 246)",
                  }}
                  onClick={() => console.log(altTicket)}
                >
                  <TableCell style={{ textAlign: "left" }}>
                    <IconButton
                      color="error"
                      onClick={() =>
                        handleRemoveAlternativeTicket(altTicket.id, item.id)
                      }
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell />
                  <TableCell style={{ textAlign: "left" }}>
                    <Typography variant="subtitle2">
                      <Typography variant="subtitle2">
                        {`${moment(altTicket?.departureDate)
                          .utc()
                          .format("DD.MM.YYYY")} `}
                        {altTicket?.priceList != null
                          ? `${altTicket?.priceList?.departureHour}`
                          : `${altTicket?.productDestinationDeparture?.departureHour}`}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell />
                  <TableCell style={{ textAlign: "left" }}>
                    <Typography variant="subtitle2">
                      {altTicket?.priceList != null
                        ? `${altTicket?.priceList?.supplier?.name}`
                        : `${altTicket?.productDestination?.supplier?.name}`}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ textAlign: "left", whiteSpace: "nowrap" }}
                  >
                    <Typography variant="subtitle2">
                      {altTicket.destination.name}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={4} style={{ textAlign: "left" }}>
                    <Typography variant="subtitle2">
                      {altTicket?.priceList != null
                        ? `Price list name: ${altTicket?.priceList?.name}`
                        : `Price level: ${altTicket?.productPrice?.level}`}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2} style={{ textAlign: "left" }}>
                    <Typography variant="subtitle2">
                      {`Reservation Code: ${
                        altTicket?.reservationCode
                          ? altTicket?.reservationCode
                          : "/"
                      } `}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: "right" }} colSpan={3}>
                    {!altTicket?.reservationCode && (
                      <Button
                        size="small"
                        color="success"
                        variant="contained"
                        onClick={() => {
                          setSelectedAlternativeTicket(altTicket);
                          setSelectedTicket(item);
                          setOpenAlternativeTicketReservationCodeDialog(true);
                        }}
                        disableElevation
                      >
                        <span
                          style={{
                            textTransform: "none",
                            fontSize: "11px",
                            color: "#ffffff",
                          }}
                        >
                          Add code
                        </span>
                      </Button>
                    )}
                    {altTicket?.reservationCode && (
                      <Button
                        size="small"
                        color="error"
                        variant="contained"
                        onClick={() => {
                          handleRemoveReservationCode(altTicket.id, item.id);
                        }}
                        disableElevation
                      >
                        <span
                          style={{
                            textTransform: "none",
                            fontSize: "11px",
                            color: "#ffffff",
                          }}
                        >
                          Remove code
                        </span>
                      </Button>
                    )}
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        handleChangeTicketWithAlternativeTicket(
                          item.id,
                          altTicket.id,
                          item
                        )
                      }
                      style={{ marginLeft: "5px" }}
                      disableElevation
                    >
                      <span
                        style={{
                          textTransform: "none",
                          fontSize: "11px",
                          color: "#ffffff",
                        }}
                      >
                        Choose
                      </span>
                    </Button>
                  </TableCell>
                </TableRow>
              ))} */}
            {/* {index === 0 && expandedFilters && (
              <>
                <Grid
                  container
                  spacing={1}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item xs={2.8}>
                    <Typography
                      variant="h6"
                      sx={{
                        border:
                          params?.orderValue === "ticketNumber"
                            ? "1px solid"
                            : `1px solid #C4C4C4`,
                        color:
                          params?.orderValue === "ticketNumber"
                            ? theme.palette.dashboardBackground.main
                            : alpha(theme.palette.darkContrast.main, 0.5),
                        backgroundColor:
                          params?.orderValue === "ticketNumber"
                            ? theme.palette.secondary.main
                            : "inherit",
                        borderRadius: "4px",
                        fontWeight: "light",
                        textAlign: "center",
                        padding: "5px 0 5px 0",
                      }}
                    >
                      Number
                      <IconButton
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0px",
                          color:
                            params?.orderValue === "ticketNumber"
                              ? "white"
                              : alpha(theme.palette.primary.main, 0.5),
                        }}
                        value={"ticketNumber"}
                        onClick={handleClickChangeOrderBy}
                      >
                        {params?.orderValue === "ticketNumber" ? (
                          params?.orderDirection == "DESC" ? (
                            <Icon style={{ transform: "rotate(90deg)" }}>
                              play_arrow
                            </Icon>
                          ) : (
                            <Icon style={{ transform: "rotate(-90deg)" }}>
                              play_arrow
                            </Icon>
                          )
                        ) : (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Typography
                      variant="h6"
                      sx={{
                        border:
                          params?.orderValue === "reservationCode"
                            ? "1px solid"
                            : `1px solid #C4C4C4`,
                        color:
                          params?.orderValue === "reservationCode"
                            ? theme.palette.dashboardBackground.main
                            : alpha(theme.palette.darkContrast.main, 0.5),
                        backgroundColor:
                          params?.orderValue === "reservationCode"
                            ? theme.palette.secondary.main
                            : "inherit",
                        borderRadius: "5px",
                        fontWeight: "light",
                        textAlign: "center",
                        padding: "5px 0 5px 0",
                      }}
                    >
                      Reservation
                      <IconButton
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0px",
                          color:
                            params?.orderValue === "reservationCode"
                              ? "white"
                              : alpha(theme.palette.primary.main, 0.5),
                        }}
                        value={"reservationCode"}
                        onClick={handleClickChangeOrderBy}
                      >
                        {params?.orderValue === "reservationCode" ? (
                          params?.orderDirection == "DESC" ? (
                            <Icon style={{ transform: "rotate(90deg)" }}>
                              play_arrow
                            </Icon>
                          ) : (
                            <Icon style={{ transform: "rotate(-90deg)" }}>
                              play_arrow
                            </Icon>
                          )
                        ) : (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid item xs={2.3}>
                    <Typography
                      variant="h6"
                      sx={{
                        border:
                          params?.orderValue === "truckPlateNumber"
                            ? "1px solid"
                            : `1px solid #C4C4C4`,
                        color:
                          params?.orderValue === "truckPlateNumber"
                            ? theme.palette.dashboardBackground.main
                            : alpha(theme.palette.darkContrast.main, 0.5),
                        backgroundColor:
                          params?.orderValue === "truckPlateNumber"
                            ? theme.palette.secondary.main
                            : "inherit",
                        borderRadius: "5px",
                        fontWeight: "light",
                        textAlign: "center",
                        padding: "5px 0 5px 0",
                      }}
                    >
                      Plate
                      <IconButton
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0px",
                          color:
                            params?.orderValue === "truckPlateNumber"
                              ? "white"
                              : alpha(theme.palette.primary.main, 0.5),
                        }}
                        value={"truckPlateNumber"}
                        onClick={handleClickChangeOrderBy}
                      >
                        {params?.orderValue === "truckPlateNumber" ? (
                          params?.orderDirection == "DESC" ? (
                            <Icon style={{ transform: "rotate(90deg)" }}>
                              play_arrow
                            </Icon>
                          ) : (
                            <Icon style={{ transform: "rotate(-90deg)" }}>
                              play_arrow
                            </Icon>
                          )
                        ) : (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid item xs={2.8}>
                    <Typography
                      variant="h6"
                      sx={{
                        border:
                          params?.orderValue === "vehicleLength"
                            ? "1px solid"
                            : `1px solid #C4C4C4`,
                        color:
                          params?.orderValue === "vehicleLength"
                            ? theme.palette.dashboardBackground.main
                            : alpha(theme.palette.darkContrast.main, 0.5),
                        backgroundColor:
                          params?.orderValue === "vehicleLength"
                            ? theme.palette.secondary.main
                            : "inherit",
                        borderRadius: "5px",
                        fontWeight: "light",
                        textAlign: "center",
                        padding: "5px 0 5px 0",
                      }}
                    >
                      Length
                      <IconButton
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0px",
                          color:
                            params?.orderValue === "vehicleLength"
                              ? "white"
                              : alpha(theme.palette.primary.main, 0.5),
                        }}
                        value={"vehicleLength"}
                        onClick={handleClickChangeOrderBy}
                      >
                        {params?.orderValue === "vehicleLength" ? (
                          params?.orderDirection == "DESC" ? (
                            <Icon style={{ transform: "rotate(90deg)" }}>
                              play_arrow
                            </Icon>
                          ) : (
                            <Icon style={{ transform: "rotate(-90deg)" }}>
                              play_arrow
                            </Icon>
                          )
                        ) : (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Typography
                      variant="h6"
                      sx={{
                        border:
                          params?.orderValue === "assignedTo"
                            ? "1px solid"
                            : `1px solid #C4C4C4`,
                        color:
                          params?.orderValue === "assignedTo"
                            ? theme.palette.dashboardBackground.main
                            : alpha(theme.palette.darkContrast.main, 0.5),
                        backgroundColor:
                          params?.orderValue === "assignedTo"
                            ? theme.palette.secondary.main
                            : "inherit",
                        borderRadius: "5px",
                        fontWeight: "light",
                        textAlign: "center",
                        padding: "5px 0 5px 0",
                      }}
                    >
                      Assigned
                      <IconButton
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0px",
                          color:
                            params?.orderValue === "assignedTo"
                              ? "white"
                              : alpha(theme.palette.primary.main, 0.5),
                        }}
                        value={"assignedTo"}
                        onClick={handleClickChangeOrderBy}
                      >
                        {params?.orderValue === "assignedTo" ? (
                          params?.orderDirection == "DESC" ? (
                            <Icon style={{ transform: "rotate(90deg)" }}>
                              play_arrow
                            </Icon>
                          ) : (
                            <Icon style={{ transform: "rotate(-90deg)" }}>
                              play_arrow
                            </Icon>
                          )
                        ) : (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5}></Grid>
                  <Grid item xs={2.5}></Grid>
                  <Grid item xs={3.5}>
                    <Typography
                      variant="h6"
                      sx={{
                        border:
                          params?.orderValue === "calculatedPriceWithFees"
                            ? "1px solid"
                            : `1px solid #C4C4C4`,
                        color:
                          params?.orderValue === "calculatedPriceWithFees"
                            ? theme.palette.dashboardBackground.main
                            : alpha(theme.palette.darkContrast.main, 0.5),
                        backgroundColor:
                          params?.orderValue === "calculatedPriceWithFees"
                            ? theme.palette.secondary.main
                            : "inherit",
                        borderRadius: "5px",
                        fontWeight: "light",
                        textAlign: "center",
                        padding: "5px 0 5px 0",
                      }}
                    >
                      Total price
                      <IconButton
                        style={{
                          width: "20px",
                          height: "20px",
                          padding: "0px",
                          color:
                            params?.orderValue === "calculatedPriceWithFees"
                              ? "white"
                              : alpha(theme.palette.primary.main, 0.5),
                        }}
                        value={"calculatedPriceWithFees"}
                        onClick={handleClickChangeOrderBy}
                      >
                        {params?.orderValue === "calculatedPriceWithFees" ? (
                          params?.orderDirection == "DESC" ? (
                            <Icon style={{ transform: "rotate(90deg)" }}>
                              play_arrow
                            </Icon>
                          ) : (
                            <Icon style={{ transform: "rotate(-90deg)" }}>
                              play_arrow
                            </Icon>
                          )
                        ) : (
                          <Icon style={{ transform: "rotate(90deg)" }}>
                            play_arrow
                          </Icon>
                        )}
                      </IconButton>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )} */}
          </React.Fragment>
        ))}
      {/* Render empty rows to make up a total of 5 rows */}
      {Array.from({ length: emptyRows }).map((_, index) => (
        <TableRow
          key={`empty-${index}`}
          style={{ height: "48px", border: "none" }}
        >
          <TableCell colSpan={30} sx={{ borderBottom: "none" }} />
        </TableRow>
      ))}
      <Dialog
        open={openAlternativeTicketReservationCodeDialog}
        onClose={handleCloseAlternativeTicketReservationCodeDialog}
        maxWidth={"sm"}
        fullWidth
        disableScrollLock
      >
        <DialogTitle style={{ fontSize: 20 }}>
          {LOCALE.add_reservation_code}
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Choose reservation code</Typography>
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography>Enter new reservation code</Typography>
            </Stack>
          </Grid>
          {checked && (
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="reservation-code"
                label="Reservation code"
                type="text"
                fullWidth
                variant="standard"
                inputProps={{
                  style: { textTransform: "uppercase" },
                  maxLength: 30,
                }}
                size="large"
                value={reservationCode ? reservationCode : ""}
                onChange={(e) =>
                  setReservationCode(e.target.value.toUpperCase())
                }
              ></TextField>
              <FormControl margin="dense">
                <FormControlLabel
                  label="On waiting list"
                  control={
                    <Switch
                      size="small"
                      color="warning"
                      checked={codeWaitingList}
                      disabled={loading}
                      onChange={(e) => setCodeWaitingList(e.target.checked)}
                    />
                  }
                />
              </FormControl>
            </Grid>
          )}
          {!checked && (
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <FormControl fullWidth size="small">
                <InputLabel>{LOCALE.reservation_codes}</InputLabel>
                <Select
                  value={reservationCode ? reservationCode : ""}
                  label={LOCALE.reservation_codes}
                  onChange={(event) => {
                    setReservationCode(event.target.value.toUpperCase());
                  }}
                  id="grouped-select"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .available:hover": {
                          backgroundColor: "#EAFAF1",
                        },
                        "& .onWaitingList:hover": {
                          backgroundColor: "#FEF5E7",
                        },
                        "& .available.Mui-selected": {
                          backgroundColor: "#EAFAF1",
                        },
                        "& .onWaitingList.Mui-selected": {
                          backgroundColor: "#FEF5E7",
                        },
                      },
                      style: {
                        maxHeight: 48 * 4.5 + 20,
                      },
                    },
                  }}
                >
                  <ListSubheader style={{ color: "#6CA46F" }}>
                    {LOCALE.available_codes}
                  </ListSubheader>
                  {reservationCodes &&
                    reservationCodes?.map(
                      (code, index) =>
                        !code.onWaitingList && (
                          <MenuItem
                            value={code.reservationCode}
                            className={"available"}
                          >
                            {code?.reservationCode}
                          </MenuItem>
                        )
                    )}
                  <ListSubheader style={{ color: "#EC6C02" }}>
                    {LOCALE.on_waiting_list}
                  </ListSubheader>
                  {reservationCodes &&
                    reservationCodes?.map(
                      (code, index) =>
                        code.onWaitingList && (
                          <MenuItem
                            value={code.reservationCode}
                            className={"onWaitingList"}
                          >
                            {code?.reservationCode}
                          </MenuItem>
                        )
                    )}
                </Select>
              </FormControl>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlternativeTicketReservationCodeDialog}>
            Cancel
          </Button>
          <Button
            style={{ verticalAlign: "bottom", margin: "0 10px" }}
            size="small"
            color="primary"
            disabled={loading}
            variant="contained"
            onClick={() => {
              handleAddReservationCode(
                selectedAlternativeTicket?.id,
                reservationCode,
                codeWaitingList,
                selectedTicket?.id
              );
              handleCloseAlternativeTicketReservationCodeDialog();
            }}
          >
            <span
              style={{
                color: "#f5f5f5",
                fontWeight: "bold",
              }}
            >
              {LOCALE.save}
            </span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
