import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Stack,
  Breadcrumbs,
  Icon,
  Typography,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthRepository } from "../repositories/AuthRepository";
import { TicketRepository } from "../repositories/TicketRepository";
import { HomeComponentBookingListBody } from "./HomeComponentBookingListBody";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import {
  clearReceiveNewTicket,
  notifyShowErrorMessage,
} from "../common/CommonActions";
import { ErrorMessageResolver } from "../common/ErrorMessageResolver";
import { FastBookTicket } from "./tickets/FastBookTicket";
import { HomeComponentTicketCommunication } from "./HomeComponentTicketCommunication";
import { HomeComponentBookingListBodyMobile } from "./mobile/HomeComponentBookingListBodyMobile";

export default function HomeComponent() {
  const [searchParams, setSearchParams] = useState({});
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(6);
  const theme = useTheme();
  const [hoveredRow, setHoveredRow] = useState();
  const [selectedTickets, setSelectedTickets] = useState([]);

  const [newArrivedTickets, setNewArrivedTickets] = useState([]);
  const params = useParams();
  const [newlyAddedTicketNumber, setNewlyAddedTicketNumber] = useState();

  useEffect(() => {
    navigate(
      `/client/bookings/0/15/${JSON.stringify({
        status: "ALL_ACTIVE",
      })}/dateCreated/DESC`,
      { replace: true }
    );
    // loadData();
  }, []);

  // const loadData = (
  //   providedPage,
  //   providedSize,
  //   filterParams,
  //   orderValue,
  //   orderDirection
  // ) => {
  //   setLoading(true);
  //   setNewlyAddedTicketNumber();
  //   let tmp = filterParams ? filterParams : JSON.stringify({ ...searchParams });
  //   let tmpPage = providedPage ? providedPage : page;
  //   let tmpSize = providedSize ? providedSize : size;

  //   TicketRepository.getAll(tmpPage, tmpSize, tmp, orderValue, orderDirection)
  //     .then((res) => {
  //       setData(res.data);
  //       setNewArrivedTickets([]);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
  //       setLoading(false);
  //     });
  // };

  // const handleNewTicketArrived = (msg) => {
  //   setNewArrivedTickets((prevState) => {
  //     return [msg, ...prevState];
  //   });
  // };

  // useEffect(() => {
  //   if (globalState.receiveNewTicket?.newTicketNotification) {
  //     handleNewTicketArrived(
  //       globalState.receiveNewTicket?.newTicketNotification
  //     );
  //     dispatch(clearReceiveNewTicket());
  //   }

  //   if (globalState.receiveNewTicket?.statusChangedOnTicketNotification) {
  //     const updatedTicketDTO =
  //       globalState.receiveNewTicket.statusChangedOnTicketNotification;

  //     setNewlyAddedTicketNumber(updatedTicketDTO.ticketNumber);

  //     setData((prevData) => {
  //       if (prevData?.content && Array.isArray(prevData.content)) {
  //         const ticketExists = prevData.content.some(
  //           (ticket) => ticket.ticketNumber === updatedTicketDTO.ticketNumber
  //         );

  //         if (!ticketExists) {
  //           return prevData;
  //         }

  //         const updatedContent = prevData.content.map((ticket) => {
  //           if (ticket.ticketNumber === updatedTicketDTO.ticketNumber) {
  //             return { ...ticket, ...updatedTicketDTO, isHighlighted: true };
  //           }
  //           return ticket;
  //         });

  //         const ticketToMove = updatedContent.find(
  //           (ticket) => ticket.ticketNumber === updatedTicketDTO.ticketNumber
  //         );
  //         const remainingTickets = updatedContent.filter(
  //           (ticket) => ticket.ticketNumber !== updatedTicketDTO.ticketNumber
  //         );

  //         return {
  //           ...prevData,
  //           content: [ticketToMove, ...remainingTickets],
  //         };
  //       }
  //       return prevData;
  //     });

  //     dispatch(clearReceiveNewTicket());
  //   }
  // }, [globalState.receiveNewTicket]);

  // const handleChangeData = (name, value) => {
  //   setData(_.set({ ...data }, name, value));
  // };

  return (
    <>
      {/* <Container maxWidth={false} style={{ padding: "25px 10px 5px 25px" }}>
        <Grid container spacing={2}>
          <Paper sx={{ width: "100%", padding: 2.5 }}>
            <Grid item xs={12} md={4}>
              <Stack
                direction="row"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  variant="h5"
                  key="2"
                  color="text.primary"
                  sx={{
                    fontWeight: "bold",
                    typography: { xs: "h4", md: "h5" },
                    alignContent: "center",
                  }}
                >
                  Your Bookings
                  <IconButton
                    onClick={() => loadData()}
                    sx={{
                      border: "1px solid lightGray",
                      borderRadius: "5px",
                      padding: "1px",
                      minWidth: "auto",
                      height: "auto",
                      marginLeft: "5px",
                      display: { xs: "none", md: "inline-flex" },
                    }}
                  >
                    <Icon
                      style={{ color: theme.palette.darkContrast.main }}
                      fontSize="20"
                    >
                      refresh
                    </Icon>
                  </IconButton>
                </Typography>
                <Box sx={{ display: { xs: "inline-flex", md: "none" } }}>
                  <IconButton
                    onClick={() => loadData()}
                    sx={{
                      border: "1px solid lightGray",
                      borderRadius: "5px",
                      padding: "1px",
                      minWidth: 55,
                      height: 55,
                      marginLeft: "5px",
                    }}
                  >
                    <Icon
                      style={{
                        color: theme.palette.darkContrast.main,
                        fontSize: "28px",
                      }}
                    >
                      refresh
                    </Icon>
                  </IconButton>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{ display: { xs: "none", md: "block" } }}>
              <Table
                sx={{ borderCollapse: "separate", borderSpacing: "0px 8px" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "left", borderBottom: "none" }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "#8f8f8f", fontWeight: "light" }}
                      >
                        Status
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "left", borderBottom: "none" }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "#8f8f8f", fontWeight: "light" }}
                      >
                        Number
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{ textAlign: "left", borderBottom: "none" }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "#8f8f8f", fontWeight: "light" }}
                      >
                        Reservation code
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "left", borderBottom: "none" }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "#8f8f8f", fontWeight: "light" }}
                      >
                        Destination
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "left", borderBottom: "none" }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "#8f8f8f", fontWeight: "light" }}
                      >
                        Plate
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "right", borderBottom: "none" }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "#8f8f8f", fontWeight: "light" }}
                      >
                        Departure
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <HomeComponentBookingListBody
                    loading={loading}
                    data={data?.content}
                    setHoveredRow={setHoveredRow}
                    hoveredRow={hoveredRow}
                    theme={theme}
                    selectedTickets={selectedTickets}
                    setSelectedTickets={setSelectedTickets}
                    navigate={navigate}
                    size={size}
                    newlyAddedTicketNumber={newlyAddedTicketNumber}
                  />
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
              <HomeComponentBookingListBodyMobile
                loading={loading}
                data={data?.content}
                setHoveredRow={setHoveredRow}
                hoveredRow={hoveredRow}
                theme={theme}
                selectedTickets={selectedTickets}
                setSelectedTickets={setSelectedTickets}
                navigate={navigate}
                size={size}
                newlyAddedTicketNumber={newlyAddedTicketNumber}
              />
            </Grid>
          </Paper>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 2, md: 5 }}>
              <Grid item xs={12} md={6}>
                <FastBookTicket />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomeComponentTicketCommunication />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container> */}
    </>
  );
}
