import React from "react";
import { Icon } from "@mui/material";
import { Icon as IconWhatsApp } from "@iconify/react";
import { Icon as IconViber } from "@iconify/react";
import { Icon as IconSkype } from "@iconify/react";

export const getTicketSourceIcon = (src) => {
  if (src === "WEB") return <Icon>language</Icon>;
  else if (src === "PHONE") return <Icon>phone</Icon>;
  else if (src === "MAIL") return <Icon>mail</Icon>;
  else if (src === "SMS") return <Icon>sms</Icon>;
  else if (src === "VIBER")
    return (
      <IconViber
        icon="fa-brands:viber"
        style={{
          color: "#8875F0",
          fontSize: "20px",
          verticalAlign: "bottom",
        }}
      >
        phone
      </IconViber>
    );
  else if (src === "WHATS_APP")
    return (
      <IconWhatsApp
        style={{
          color: "#2ccc47",
          fontSize: "20px",
          verticalAlign: "bottom",
        }}
        icon="fa-brands:whatsapp"
      >
        phone
      </IconWhatsApp>
    );
  else if (src === "SKYPE") {
    return (
      <IconSkype
        icon="fa-brands:skype"
        style={{
          color: "#00aff0",
          fontSize: "20px",
          verticalAlign: "bottom",
        }}
      >
        phone
      </IconSkype>
    );
  } else if (src === "NOTES")
    return <Icon sx={{ color: "#0076ff", fontSize: "20px" }}>note_alt</Icon>;
  else if (src === "OTHER") return <Icon>device_unknown</Icon>;
  else if (src === "AUTOLIST") return <Icon>playlist_add_check_circle</Icon>;
  else return "";
};

export const getTicketStatusColor = (status) => {
  if (status === "PENDING") return "info";
  if (status === "IN_PROGRESS") return "secondary";
  if (status === "APPROVED") return "primary";
  if (status === "REJECTED") return "warning";
  if (status === "DONE") return "success";
  if (status === "CANCELLED") return "error";
  if (status == "ESCALATED") return "info";
  if (status == "NO_SHOW") return "error";
  if (status == "STORNO") return "error";
  if (status == "STORNED") return "warning";
  else return "primary";
};

export const getCommunicationStatusColor = (status, type) => {
  if (type == "agent") {
    if (status === "WAITING_FOR_CUSTOMER") return "default";
    if (status === "RECEIVED_RESPONSE") return "warning";
  } else if (type == "client") {
    if (status === "WAITING_FOR_CUSTOMER") return "warning";
    if (status === "RECEIVED_RESPONSE") return "default";
  } else return "primary";
};

export const getTicketPriorityColor = (priority) => {
  if (priority === "LOW") return "info";
  if (priority === "MEDIUM") return "default";
  if (priority === "HIGH") return "error";
  if (priority === "CRITICAL") return "error";
  else return "primary";
};

export const getPotentialClientStatus = (status) => {
  if (status === "NEW") return "info";
  if (status === "CONTACTED") return "default";
  if (status === "QUALIFIED") return "primary";
  if (status === "UNQUALIFIED") return "secondary";
  if (status === "CUSTOMER") return "success";
  else return "primary";
};

export const getOfferStatusColor = (status) => {
  if (status === "DRAFT") return "info";
  if (status === "PROPOSAL_SENT") return "default";
  else return "primary";
};

export const getOfferItemStatusColor = (status) => {
  if (status === "NEW") return "info";
  if (status === "WON") return "success";
  if (status === "LOST") return "error";
  if (status === "NEGOTIATED") return "primary";
  else return "default";
};

export const getTaskStatusColor = (status) => {
  if (status === "TO_DO") return "info";
  if (status === "DONE") return "success";
  else return "default";
};

export const getTaskPriorityIcon = (priority) => {
  if (priority === "LOW") return "keyboard_double_arrow_down";
  if (priority === "MEDIUM") return "menu";
  if (priority === "HIGH") return "keyboard_double_arrow_up";
};

export const getTaskPriorityIconColor = (priority) => {
  if (priority === "LOW") return "#2684FF";
  if (priority === "MEDIUM") return "#FFAB00";
  if (priority === "HIGH") return "#FF5630";
};
