import { Alert, Icon, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COMMON_ACTIONS } from "./CommonActions";

export default function GlobalNotificationSnackbar() {
  const globalState = useSelector((state) => state);
  const dispatch = useDispatch();
  const audioPlayer = useRef(null);
  const audioPlayerError = useRef(null);
  const audioPlayerSuccess = useRef(null);

  function playAudio() {
    audioPlayer.current.play();
  }

  function playAudioError() {
    audioPlayerError.current.play();
  }

  function playAudioSuccess() {
    audioPlayerSuccess.current.play();
  }

  const handleClose = () => {
    if (globalState.open) {
      dispatch({
        type: COMMON_ACTIONS.CLEAR_NOTIFICATIONS,
        payload: null,
      });
    }
  };

  useEffect(() => {
    if (globalState.open) {
      if (globalState.variant === "error") {
        // playAudioError();
      } else if (globalState.variant === "success") {
        // playAudioSuccess();
      } else if (globalState.variant === "info") {
        playAudio();
      }
    }
  }, [globalState.open]);

  return (
    <React.Fragment>
      {globalState.open &&
        globalState?.message &&
        // check if type of message is string
        typeof globalState?.message === "string" &&
        !globalState?.message.includes("null") && (
          <Snackbar
            open={true}
            autoHideDuration={globalState.variant == "error" ? null : 3000}
            onClose={globalState.variant == "error" ? null : handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: globalState.variant == "primary" ? "center" : "right",
            }}
          >
            <Alert
              onClose={handleClose}
              severity={globalState.variant}
              sx={{ width: "100%", color: "white" }}
              variant="filled"
            >
              <Typography variant="body2" sx={{ color: "white" }}>
                {globalState.message}
              </Typography>
              {globalState?.link && (
                <Typography variant="body2" sx={{ color: "white" }}>
                  <a href={globalState.link} target="_blank">
                    <Icon>link</Icon>
                  </a>
                </Typography>
              )}
            </Alert>
          </Snackbar>
        )}
    </React.Fragment>
  );
}
