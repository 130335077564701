import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const AttachmentRepository = {
  download: (attachmentId) => {
    return Axios({
      url: `${SETTINGS.API_URL}attachment/download/${attachmentId}`,
      method: "GET",
      responseType: "blob",
    });
  },

  downloadMessageAttachment: (attachmentId) => {
    return Axios({
      url: `${SETTINGS.API_URL}attachment/messages/download/${attachmentId}`,
      method: "GET",
      responseType: "blob",
    });
  },

  downloadAll: (ticketId) => {
    return Axios({
      url: `${SETTINGS.API_URL}attachment/downloadAll/${ticketId}`,
      method: "POST",
      responseType: "blob",
    });
  },
  downloadAllMessageAttachments: (ticketId) => {
    return Axios({
      url: `${SETTINGS.API_URL}attachment/messages/downloadAll/${ticketId}`,
      method: "POST",
      responseType: "blob",
    });
  },
};
