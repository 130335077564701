import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  AgricultureOutlined,
  AirportShuttleOutlined,
  DirectionsRailwayOutlined,
  ForestOutlined,
  KeyboardBackspace,
  LocalShippingOutlined,
  RvHookupOutlined,
  TimeToLeaveOutlined,
  TrainOutlined,
} from "@mui/icons-material";
import {
  Divider,
  Grid,
  Icon,
  InputAdornment,
  Skeleton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { moment } from "../../App";
import { useDispatch } from "react-redux";

export function ClientTicketContent({
  data,
  loading,
  setData,
  readyForInvoice,
  checkIfLocked,
  invoiced,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const getVehicleCategory = () => {
    let category = "";
    if (data?.vehicleLength > 0 && data?.vehicleLength <= 6) {
      category = "Van";
    } else if (data?.vehicleLength == 7) {
      category = "Solo tractor";
    } else if (data?.vehicleLength > 7 && data?.vehicleLength < 15) {
      category = "Lorry";
    } else if (data?.vehicleLength >= 15 && data?.vehicleLength < 17) {
      category = "Artic";
    } else if (data?.vehicleLength >= 17 && data?.vehicleLength < 19) {
      category = "Road train";
    } else if (data?.vehicleLength >= 19) {
      category = "Train";
    }
    return category;
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 0,
          ...theme.applyStyles("dark", {
            backgroundColor: theme.palette.primary.main,
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...theme.applyStyles("dark", {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...theme.applyStyles("dark", {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...theme.applyStyles("dark", {
        backgroundColor: "#39393D",
      }),
    },
  }));

  return (
    <>
      <Grid container>
        {data ? (
          <>
            <Grid
              item
              xs={12}
              style={{ marginBottom: "5px", marginTop: "10px" }}
            >
              <Grid
                container
                spacing={2}
                style={{
                  padding: "0 10px 0 15px",
                  color: theme.palette.lightGray.main,
                }}
              >
                <Grid item xs={2}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                    }}
                  >
                    {data?.assignedTo && "Assignee"}
                  </Typography>
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "right",
                      fontWeight: "normal",
                    }}
                  >
                    Booking Created
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "10px" }}>
              <Grid
                container
                spacing={2}
                sx={{
                  padding: { xs: "0 0 0 15px", md: "0 10px 0 15px" },
                  color: theme.palette.lightGray.main,
                }}
              >
                <Grid item xs={5}>
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: "left",
                      color: theme.palette.darkContrast.main,
                    }}
                    component="div"
                  >
                    {data?.assignedTo
                      ? data?.assignedTo.firstName +
                        " " +
                        data?.assignedTo.lastName
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      textAlign: "right",
                      typography: { xs: "h5", md: "h4" },
                    }}
                  >
                    {moment(data?.dateCreated).format("DD.MM.YYYY HH:mm")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10} style={{ marginBottom: "5px" }}>
              <Grid
                container
                spacing={0.5}
                style={{ padding: "0 15px 0 15px" }}
              >
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={2} md={3} lg={3}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        From
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} lg={4}></Grid>
                    <Grid item xs={2} md={3} lg={3}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        To
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4} md={3}>
                      <Grid container>
                        <Grid item xs={3.5} lg={3} sx={{ marginRight: "5px" }}>
                          <ReactCountryFlag
                            countryCode={data?.fromCity?.country?.code}
                            svg
                            style={{
                              width: 24,
                              height: 24,
                              borderRadius: "6px",
                              marginRight: "5px",
                            }}
                            title={data?.fromCity?.country?.nameEn}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            alignContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              typography: { xs: "h5", md: "h4" },
                            }}
                          >
                            {data?.fromCity?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      lg={4}
                      style={{
                        textAlign: "center",
                        alignContent: "center",
                      }}
                    >
                      <KeyboardBackspace
                        style={{
                          transform: "rotate(180deg)",
                          fontSize: "30px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid item xs={3.5} lg={3} sx={{ marginRight: "5px" }}>
                          <ReactCountryFlag
                            countryCode={data?.toCity?.country?.code}
                            svg
                            style={{
                              width: 24,
                              height: 24,
                              borderRadius: "6px",
                            }}
                            title={data?.toCity?.country?.nameEn}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          md={6}
                          style={{
                            alignContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              typography: { xs: "h5", md: "h4" },
                            }}
                          >
                            {data?.toCity?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} style={{ padding: "0 15px 0 15px" }}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                          marginBottom: "5px",
                        }}
                      >
                        Departure Date
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{
                          fontWeight: "bold",
                          color: theme.palette.darkContrast.main,
                        }}
                      >
                        {moment(data?.departureDay).format("DD.MM.YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                          textAlign: { xs: "right", md: "left" },
                          marginBottom: "5px",
                        }}
                      >
                        Departure Hour
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          color: theme.palette.darkContrast.main,
                          textAlign: { xs: "right", md: "left" },
                        }}
                      >
                        {data?.departureHour}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      {data?.organization?.showSupplier && (
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "normal",
                            color: theme.palette.lightGray.main,
                            textAlign: { xs: "left", md: "right" },
                            marginBottom: "5px",
                          }}
                        >
                          Supplier
                        </Typography>
                      )}
                      {data?.organization?.showSupplier && (
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "bold",
                            color: theme.palette.darkContrast.main,
                            textAlign: { xs: "left", md: "right" },
                          }}
                        >
                          {data?.productDestination?.operator?.name
                            ? data?.productDestination?.operator?.name
                            : data?.productDestination?.supplier?.name}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container p={1}>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "normal",
                      color: theme.palette.lightGray.main,
                      marginBottom: "5px",
                    }}
                  >
                    Vehicle Category
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={data?.vehicleCategory?.name || getVehicleCategory()}
                    sx={{
                      width: { md: "95%", xs: "100%" },
                    }}
                    size="small"
                    InputProps={{
                      style: {
                        color: theme.palette.darkContrast.main,
                        fontSize: "15px",
                        fontWeight: "bold",
                      },
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{
                            color: theme.palette.darkContrast.main,
                          }}
                        >
                          {data?.vehicleCategory?.name.startsWith("Van") ||
                          (data?.vehicleLength > 0 &&
                            data?.vehicleLength <= 6) ? (
                            <AirportShuttleOutlined
                              style={{
                                fontSize: "20px",
                              }}
                            />
                          ) : data?.vehicleCategory?.name.startsWith(
                              "Solo tractor"
                            ) || data?.vehicleLength == 7 ? (
                            <AgricultureOutlined
                              style={{
                                fontSize: "20px",
                              }}
                            />
                          ) : data?.vehicleCategory?.name.startsWith("Lorry") ||
                            (data?.vehicleLength > 7 &&
                              data?.vehicleLength < 15) ? (
                            <LocalShippingOutlined
                              style={{
                                fontSize: "20px",
                              }}
                            />
                          ) : data?.vehicleCategory?.name.startsWith("Artic") ||
                            (data?.vehicleLength >= 15 &&
                              data?.vehicleLength < 17) ? (
                            <RvHookupOutlined
                              style={{
                                fontSize: "20px",
                              }}
                            />
                          ) : data?.vehicleCategory?.name.startsWith(
                              "Road train"
                            ) ||
                            (data?.vehicleLength >= 17 &&
                              data?.vehicleLength < 19) ? (
                            <DirectionsRailwayOutlined
                              style={{
                                fontSize: "20px",
                              }}
                            />
                          ) : data?.vehicleCategory?.name.startsWith("Train") ||
                            data?.vehicleLength >= 19 ? (
                            <TrainOutlined
                              style={{
                                fontSize: "20px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "normal",
                      color: theme.palette.lightGray.main,
                      marginBottom: "5px",
                    }}
                  >
                    Length
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={data?.vehicleLength || ""}
                    style={{
                      width: "95%",
                    }}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span
                            style={{
                              color: theme.palette.darkContrast.main,
                              opacity: "25%",
                            }}
                          >
                            m
                          </span>
                        </InputAdornment>
                      ),
                      sx: {
                        color: theme.palette.darkContrast.main,
                        fontSize: "15px",
                        fontWeight: "bold",
                        input: {
                          textAlign: "center",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "normal",
                      color: theme.palette.lightGray.main,
                      marginBottom: "5px",
                    }}
                  >
                    Width
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={data?.vehicleWidth || ""}
                    style={{
                      width: "95%",
                    }}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span
                            style={{
                              color: theme.palette.darkContrast.main,
                              opacity: "25%",
                            }}
                          >
                            m
                          </span>
                        </InputAdornment>
                      ),
                      sx: {
                        color: theme.palette.darkContrast.main,
                        fontSize: "15px",
                        fontWeight: "bold",
                        input: {
                          textAlign: "center",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "normal",
                      color: theme.palette.lightGray.main,
                      marginBottom: "5px",
                    }}
                  >
                    Height
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={data?.vehicleHeight || ""}
                    style={{
                      width: "95%",
                      display: "flex",
                    }}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <span
                            style={{
                              color: theme.palette.darkContrast.main,
                              opacity: "25%",
                            }}
                          >
                            m
                          </span>
                        </InputAdornment>
                      ),
                      sx: {
                        color: theme.palette.darkContrast.main,
                        fontSize: "15px",
                        fontWeight: "bold",
                        input: {
                          textAlign: "center",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                style={{
                  padding: "0 15px 0 15px",
                  marginTop: "0px",
                  marginBottom: "5px",
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "normal",
                      color: theme.palette.lightGray.main,
                    }}
                  >
                    Truck Plate Number
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "right", marginLeft: "-18px" }}
                >
                  {data?.trailerPlateNumber && (
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "normal",
                        color: theme.palette.lightGray.main,
                      }}
                    >
                      Trailer Plate Number
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ padding: "0 15px 0 15px" }}>
                <Grid item xs={6}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "bold",
                      color: theme.palette.darkContrast.main,
                      height: "20px",
                    }}
                  >
                    {data?.truckPlateNumber}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ textAlign: "right", marginLeft: "-18px" }}
                >
                  {data?.trailerPlateNumber && (
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: "bold",
                        color: theme.palette.darkContrast.main,
                        height: "20px",
                      }}
                    >
                      {data?.trailerPlateNumber}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "10px" }}>
              <Grid container style={{ padding: "0 15px 0 15px" }}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "normal",
                      color: theme.palette.lightGray.main,
                    }}
                  >
                    Driver Full Name
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "bold",
                      color: theme.palette.darkContrast.main,
                      height: "24px",
                    }}
                  >
                    {data?.driverName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: "0 10px 0px 10px" }}>
              <Divider />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "10px" }}>
              <Grid container spacing={2} style={{ padding: "0 15px 0 15px" }}>
                <Grid item xs={12}>
                  <Grid container spacing={1} style={{ marginTop: "5px" }}>
                    <Grid item md={5} lg={4} xl={3}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                          marginBottom: "5px",
                        }}
                      >
                        Cargo Type
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={
                          data?.loaded && data?.material?.name
                            ? data?.material?.name
                            : "UNKNOWN"
                        }
                        InputProps={{
                          style: {
                            color: theme.palette.darkContrast.main,
                            fontSize: "15px",
                            fontWeight: "bold",
                          },
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{
                                color: theme.palette.darkContrast.main,
                              }}
                            >
                              {data?.material?.name == "Cars" ? (
                                <TimeToLeaveOutlined
                                  style={{
                                    fontSize: "20px",
                                  }}
                                />
                              ) : data?.material?.name == "Paper" ? (
                                <Icon
                                  style={{
                                    fontSize: "20px",
                                  }}
                                >
                                  <svg
                                    width="18"
                                    height="20"
                                    viewBox="0 0 18 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.5 1.5L15 0L13.5 1.5L12 0L10.5 1.5L9 0L7.5 1.5L6 0L4.5 1.5L3 0V14H0V17C0 18.66 1.34 20 3 20H15C16.66 20 18 18.66 18 17V0L16.5 1.5ZM12 18H3C2.45 18 2 17.55 2 17V16H12V18ZM16 17C16 17.55 15.55 18 15 18C14.45 18 14 17.55 14 17V14H5V3H16V17Z"
                                      fill="#202020"
                                      style={{
                                        fill: "#202020",
                                        fill: "color(display-p3 0.1255 0.1255 0.1255)",
                                        fillOpacity: "1",
                                      }}
                                    />
                                  </svg>
                                </Icon>
                              ) : data?.material?.name == "Wood" ? (
                                <ForestOutlined
                                  style={{
                                    fontSize: "20px",
                                  }}
                                />
                              ) : data?.material?.name == "Metal" ? (
                                <Icon
                                  style={{
                                    fontSize: "20px",
                                  }}
                                >
                                  <svg
                                    width="15"
                                    height="20"
                                    viewBox="0 0 15 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.5 4.5H1V1H14V4.5H10.5H9.5V5.5V14.5V15.5H10.5H14V19H1V15.5H4.5H5.5V14.5V5.5V4.5H4.5Z"
                                      stroke="#202020"
                                      style={{
                                        stroke: "#202020",
                                        stroke:
                                          "color(display-p3 0.1255 0.1255 0.1255)",
                                        strokeOpacity: "1",
                                      }}
                                      stroke-width="2"
                                    />
                                  </svg>
                                </Icon>
                              ) : (
                                ""
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item md={3} lg={2} xl={1.5}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                          marginBottom: "5px",
                        }}
                      >
                        Weight
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={
                          data?.loaded && data?.cargoWeight
                            ? data?.cargoWeight
                            : "0"
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <span
                                style={{
                                  color: theme.palette.darkContrast.main,
                                  opacity: "25%",
                                }}
                              >
                                {data?.loaded && "t"}
                              </span>
                            </InputAdornment>
                          ),
                          sx: {
                            color: theme.palette.darkContrast.main,
                            fontSize: "15px",
                            fontWeight: "bold",
                            input: {
                              textAlign: "center",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ paddingBottom: data?.ticketAttachments ? "10px" : "0px" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4} md={3}>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      padding: "0 15px 0 15px",
                      marginTop: "-10px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        Dangerous Goods
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <IOSSwitch checked={data?.dangerousGoods || false} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} md={3}>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      padding: "0 15px 0 15px",
                      marginTop: "-10px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        Electric Plugin
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <IOSSwitch checked={data?.electricPlugin || false} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{
                        display: "flex",
                        alignContent: "center",
                        marginTop: "3px",
                      }}
                    >
                      {data?.temperature && (
                        <Typography
                          variant="h4"
                          sx={{
                            display: "inline-block",
                            color: theme.palette.lightGray.main,
                            fontWeight: "light",
                            marginBottom: "5px",
                          }}
                        >
                          {data.temperature}°C
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} md={3}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      padding: "0 15px 0 15px",
                      marginTop: { xs: "0px", md: "-10px" },
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "normal",
                          color: theme.palette.lightGray.main,
                        }}
                      >
                        Live Animals
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <IOSSwitch checked={data?.liveAnimals || false} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: "20px 10px 5px 10px" }}></Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
            <Grid item xs={12} style={{ padding: "0 20px 0 20px" }}>
              <Skeleton height={100} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
