import {
  alpha,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { moment } from "../App";
import { TableRowEmptyData } from "../common/TableRowEmptyData";
import {
  getTicketPriorityColor,
  getTicketSourceIcon,
  getTicketStatusColor,
} from "../common/VisualElementsHelper";
import { LOCALE } from "../properties/Locale";
import { useTheme } from "@emotion/react";
import { HourglassTopOutlined, VerifiedOutlined } from "@mui/icons-material";

export const HomeComponentBookingListBody = ({
  loading,
  data,
  setHoveredRow,
  hoveredRow,
  navigate,
  newlyAddedTicketNumber,
}) => {
  const params = useParams();
  const [newlyAddedTickets, setNewlyAddedTickets] = useState([]);
  const theme = useTheme();
  const statusColors = {
    APPROVED: theme.palette.secondary.main,
    PENDING: theme.palette.extra3.main,
    IN_PROGRESS: theme.palette.in_progress.main,
    REJECTED: theme.palette.rejected.main,
    CANCELLED: theme.palette.canceled.main,
    DONE: theme.palette.done.main,
    NO_SHOW: theme.palette.mutedRed.main,
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (newlyAddedTicketNumber) {
      setNewlyAddedTickets((prevTickets) => [
        ...prevTickets,
        newlyAddedTicketNumber,
      ]);
    }
  }, [newlyAddedTicketNumber]);

  return (
    <>
      {!loading && data && data?.length === 0 && <TableRowEmptyData />}
      {(loading || !data) && (
        <>
          <TableRow>
            <TableCell
              colSpan={20}
              style={{
                borderBottom: "none",
              }}
            >
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={20}
              style={{
                borderBottom: "none",
              }}
            >
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={20}
              style={{
                borderBottom: "none",
              }}
            >
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={20}
              style={{
                borderBottom: "none",
              }}
            >
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={20}
              style={{
                borderBottom: "none",
              }}
            >
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={20}
              style={{
                borderBottom: "none",
              }}
            >
              <Skeleton variant="react" height={30} />
            </TableCell>
          </TableRow>
        </>
      )}

      {!loading &&
        data &&
        data.map((item, index) => (
          <React.Fragment key={index}>
            <TableRow
              key={index}
              onMouseEnter={() => {
                setHoveredRow(item?.id);
              }}
              onMouseLeave={() => {
                setHoveredRow(null);
              }}
              style={{
                backgroundColor: newlyAddedTickets.includes(item.ticketNumber)
                  ? alpha(statusColors[item?.status], 0.25)
                  : item.priority === "CRITICAL" || item.priority === "HIGH"
                  ? "#ffc107"
                  : theme.palette.tableRow.main,
                ...(hoveredRow === item.id && {
                  backgroundColor: statusColors[item?.status] || "#f2f2f2",
                }),
                transition: "background-color 0.3s ease",
                zIndex: "100",
                cursor: "pointer",
                height: "48px",
                clipPath: "inset(0 round 0.5em)",
              }}
              onClick={() => {
                navigate(`/client/booking/0/15/%7B%7D/${item.id}`);
              }}
            >
              <TableCell
                sx={{
                  textAlign: "left",
                  width: { xs: "auto", md: "100px" },
                  verticalAlign: "middle",
                  borderBottom: "none",
                }}
              >
                <Typography
                  variant={isMobile ? "subtitle2" : "h6"}
                  sx={{
                    color: statusColors[item?.status],
                    textTransform: "capitalize",
                    ...(hoveredRow === item.id && {
                      color: "white",
                    }),
                    alignContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item?.status === "DONE" && (
                    <VerifiedOutlined
                      sx={{
                        paddingRight: "5px",
                      }}
                    />
                  )}
                  {item?.status === "REJECTED" && (
                    <HourglassTopOutlined
                      sx={{
                        paddingRight: "5px",
                      }}
                    />
                  )}
                  {item?.status?.replaceAll("_", " ").toLowerCase()}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: { xs: "auto", md: "70px" },
                  borderBottom: "none",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    marginRight: "5px",
                    color: theme.palette.lightGray.main,
                    ...(hoveredRow === item.id && {
                      color: "white",
                    }),
                  }}
                >
                  {item?.ticketNumber}
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  width: "5px",
                  padding: "10px 0",
                  position: "relative",
                  borderLeft: "none",
                  borderBottom: "none",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    bottom: "10px",
                    left: "50%",
                    width: "1px",
                    backgroundColor: "#d3d3d3",
                    height: "calc(100% - 16px)",
                  }}
                />
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  width: { xs: "auto", md: "200px" },
                  borderBottom: "none",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    fontWeight: "bold",
                    ...(hoveredRow === item.id && {
                      color: "white",
                    }),
                    typography: { xs: "h6", md: "h5" },
                  }}
                >
                  {item?.reservationCode ? item?.reservationCode : "-"}
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  width: isMobile ? "auto" : "400px",
                  borderBottom: "none",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    ...(hoveredRow === item.id && {
                      color: "white",
                    }),
                    typography: { xs: "h6", md: "h5" },
                  }}
                >
                  {item?.product?.directionsRange === "ONE_WAY"
                    ? item?.destination?.name?.split("-").join(" ➜ ")
                    : item?.product?.directionsRange === "RETURN"
                    ? item?.destination?.name?.split("-").join(" ⇄ ")
                    : item?.destination?.name}
                </Typography>
              </TableCell>
              <TableCell style={{ textAlign: "left", borderBottom: "none" }}>
                <Typography
                  variant="h6"
                  sx={{
                    ...(hoveredRow === item.id && {
                      color: "white",
                    }),
                  }}
                >
                  {item?.truckPlateNumber}
                </Typography>
              </TableCell>
              <TableCell style={{ textAlign: "right", borderBottom: "none" }}>
                <Typography
                  variant={isMobile ? "subtitle1" : "h5"}
                  style={{
                    marginRight: "5px",
                    fontWeight: "bold",
                    color: theme.palette.lightGray.main,
                    ...(hoveredRow === item.id && {
                      color: alpha("#FFFFFF", 0.75),
                    }),
                  }}
                >
                  {`${moment(item?.departureDay).format("DD.MM.YYYY")} 
                  ${
                    item?.priceList
                      ? item?.priceList?.departureHour
                      : item?.departureHour
                      ? item?.departureHour
                      : ""
                  }`}
                </Typography>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
    </>
  );
};
