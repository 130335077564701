import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const TicketGroupRepository = {
  create: (ticketGroup) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticketGroup/create`,
      method: "POST",
      data: ticketGroup,
    });
  },

  getAll: (
    page,
    size,
    filterParams,
    orderBy = "dateCreated",
    orderDirection = "DESC"
  ) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticketGroup`,
      method: "GET",
      params: {
        page: page,
        size: size,
        filterParams: JSON.stringify(filterParams),
        orderBy: orderBy,
        orderDirection: orderDirection,
      },
    });
  },

  addTicketsToGroup: (ticketGroupId, ticketIds) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticketGroup/addTicketsToGroup/${ticketGroupId}`,
      method: "POST",
      data: ticketIds,
    });
  },

  removeTicketFromGroup: (ticketId) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticketGroup/removeTicketFromGroup/${ticketId}`,
      method: "POST",
    });
  },

  removeTicketGroup: (ticketGroupId) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticketGroup/delete/${ticketGroupId}`,
      method: "PUT",
    });
  },
  findByID: (groupId) => {
    return Axios({
      url: `${SETTINGS.API_URL}ticketGroup/findByGroupId/${groupId}`,
      method: "GET",
    });
  },
};
