export const COMMON_ACTIONS = {
  API_CALL_START: "API_CALL_START",
  API_CALL_FINISH: "API_CALL_FINISH",
  CLEAR_NOTIFICATIONS: "CLEAR_NOTIFICATIONS",
  SHOW_MESSAGE: "SHOW_MESSAGE",
  CLEAR_NOTIFY_MESSAGES: "CLEAR_NOTIFY_MESSAGES",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  RERENDER: "RERENDER",
  RECEIVE_VIBER_MESSAGE: "RECEIVE_VIBER_MESSAGE",
  RECEIVE_WHATSAPP_MESSAGE: "RECEIVE_WHATSAPP_MESSAGE",
  RECEIVE_NEW_MAIL: "RECEIVE_NEW_MAIL",
  RECEIVE_NEW_TICKET: "RECEIVE_NEW_TICKET",
  RECEIVE_NEW_NOTIFICATION: "RECEIVE_NEW_NOTIFICATION",
  CLEAR_RECEIVE_NEW_TICKET: "CLEAR_RECEIVE_NEW_TICKET",
};

export const notifyLoaderApiCallStart = () => {
  return { type: COMMON_ACTIONS.API_CALL_START };
};

export const notifyLoaderApiCallFinish = () => {
  return { type: COMMON_ACTIONS.API_CALL_FINISH };
};

export const notifyFormValidationFailedMessage = (validationResponse) => {
  if (validationResponse.valid === true) return;
  let errorMessage = "";
  validationResponse.reasons.forEach((reason) => {
    errorMessage += reason + "\r\n";
  });
  return {
    type: COMMON_ACTIONS.SHOW_MESSAGE,
    payload: {
      open: true,
      variant: "error",
      message: errorMessage,
    },
  };
};

export const notifyShowSuccessMessage = (message) => {
  return {
    type: COMMON_ACTIONS.SHOW_MESSAGE,
    payload: {
      open: true,
      variant: "success",
      message: message,
    },
  };
};

export const notifyShowErrorMessage = (message) => {
  return {
    type: COMMON_ACTIONS.SHOW_MESSAGE,
    payload: {
      open: true,
      variant: "error",
      message: message,
    },
  };
};

export const notifyShowWarningMessage = (message) => {
  return {
    type: COMMON_ACTIONS.SHOW_MESSAGE,
    payload: {
      open: true,
      variant: "warning",
      message: message,
    },
  };
};

export const notifyShowInfoMessage = (message, link, autoHide) => {
  return {
    type: COMMON_ACTIONS.SHOW_MESSAGE,
    payload: {
      open: true,
      variant: "info",
      message: message,
      link: link,
      autoHide: autoHide,
    },
  };
};

export const notifyShowNewTicketMessage = (
  message,
  link,
  autoHide,
  variant
) => {
  return {
    type: COMMON_ACTIONS.SHOW_MESSAGE,
    payload: {
      open: true,
      variant: variant,
      message: message,
      link: link,
      autoHide: autoHide,
    },
  };
};

export const triggerRerender = () => {
  return {
    type: COMMON_ACTIONS.RERENDER,
    payload: {
      triggerRerender: true,
    },
  };
};

export const receiveViberConversation = (message) => {
  return {
    type: COMMON_ACTIONS.RECEIVE_VIBER_MESSAGE,
    payload: {
      receiveViberConversation: message,
    },
  };
};

export const receiveWhatsAppConversation = (message) => {
  return {
    type: COMMON_ACTIONS.RECEIVE_WHATSAPP_MESSAGE,
    payload: {
      receiveWhatsAppConversation: message,
    },
  };
};

export const receiveNewMail = (message) => {
  return {
    type: COMMON_ACTIONS.RECEIVE_NEW_MAIL,
    payload: {
      receiveNewMail: message,
    },
  };
};

export const receiveNewTicket = (message) => {
  return {
    type: COMMON_ACTIONS.RECEIVE_NEW_TICKET,
    payload: {
      receiveNewTicket: message,
    },
  };
};

export const clearReceiveNewTicket = () => {
  return {
    type: COMMON_ACTIONS.CLEAR_RECEIVE_NEW_TICKET,
    payload: {
      receiveNewTicket: [],
    },
  };
};

export const receiveNewNotification = (message) => {
  return {
    type: COMMON_ACTIONS.RECEIVE_NEW_NOTIFICATION,
    payload: {
      receiveNewNotification: message,
    },
  };
};

export const setViberConversations = (conversations) => ({
  type: "SET_VIBER_CONVERSATIONS",
  payload: conversations,
});

export const setWhatsAppConversations = (conversations) => ({
  type: "SET_WHATSAPP_CONVERSATIONS",
  payload: conversations,
});
