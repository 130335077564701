import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const ProductRepository = {
  getAvailableComplexDepartures: async (params) => {
    return await Axios({
      url:
        SETTINGS.API_URL + `product/ticket/complexAvailableDeparturesWithPrice`,
      method: "GET",
      params: {
        vehicleLength: params.vehicleLength,
        vehicleWidth: params.vehicleWidth,
        vehicleHeight: params.vehicleHeight,
        numberOfDrivers: params.numberOfDrivers,
        cargoWeight: params.cargoWeight,
        material: params.material,
        loaded: params.loaded,
        dangerousGoods: params.dangerousGoods,
        electricPlugin: params.electricPlugin,
        liveAnimals: params.liveAnimals,
        fromCity: params.fromCity,
        toCity: params.toCity,
        departureDate: params.departureDate,
        numberOfAxles: params.numberOfAxles,
        organizationId: params.organizationId,
        ticketStatus: params.ticketStatus ? params.ticketStatus : null,
      },
    });
  },

  createTicket: (data) => {
    const formData = new FormData();

    formData.append("ticket", JSON.stringify(data.ticket));

    formData.append("productDestinationId", data.productDestinationId);
    formData.append("priceId", data.priceId);
    formData.append(
      "productDestinationDepartureId",
      data.productDestinationDepartureId
    );

    if (data.dangerousGoodsDocument) {
      formData.append("dangerousGoodsDocument", data.dangerousGoodsDocument);
    }

    if (data.additionalDocuments && data.additionalDocuments.length > 0) {
      data.additionalDocuments.forEach((file) => {
        if (file.file instanceof File) {
          formData.append("additionalDocuments", file.file);
        }
      });
    }

    return Axios({
      url: `${SETTINGS.API_URL}product/ticket/new`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  },

  //createcomplex ticket
  createComplexTickets: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}product/ticket/complex/new`,
      method: "POST",

      data: data,
    });
  },

  getProductDestination: (id) => {
    return Axios.get(`${SETTINGS.API_URL}product/getProductDestination/${id}`);
  },

  getProductDestinationById: (id) => {
    return Axios({
      url: `${SETTINGS.API_URL}product/getProductDestinationById/${id}`,
      method: "GET",
    });
  },
};
