export const localeEn = {
  isRequired: "This field is required",
  invoices: "Invoices",
  no_recent_bookings: "You haven't made any bookings yet",
  most_booked_destinations: "Most booked destinations",
  source: "Source",
  my_tickets: "My Bookings",
  new_ticket: "New Booking",
  not_avalilable_departure_hours:
    "There are no available departure hours for this specification",
  ticket_create_success_message: "Booking created successfully",
  ticket_create_error_message:
    "Unable to process your booking request due to a network issue. Please try again in a few minutes. If the problem persists, please contact your account representative.",
  ticket_update_success_message: "Booking updated successfully",
  book_now: "Book now",
  enter_available_slots: "Slots Available",
  slots_status: "Available Slots Status",
  slots_available_create_success_message: "Slots updated successfully",
  slots_available_update_error_message: "Error while updating available slots",
  slots_available_delete_success_message: "Slots deleted successfully",
  departure_hour: "Departure hour",
  check_availability: "Check availability",
  filter_by: "Filter by",
  apply_filter: "Apply Filters",
  lastUpdated: "Last updated at",
  status: "Status",
  module: "Module",
  registration: "Registration",
  login: "Sign in",
  forgotPassword: "Forgot password",
  password: "Password",
  username: "Username",
  logout: "Sign out",
  administration: "Administration",
  privileges: "Privileges",
  users: "Users",
  mail_templates: "E-mail templates",
  organizations: "Clients",
  groups: "Groups",
  roles: "Roles",
  apis: "Apis",
  stenaline: "Stena Line",
  eurotunnel: "Eurotunnel",
  routes: "Routes",
  vehicle_types: "Vehicle types",
  goods_types: "Goods types",
  name: "Name",
  firstName: "First name",
  lastName: "Last name",
  enabled: "Active",
  email: "E-mail",
  phone: "Phone",
  organization: "Client",
  code: "Code",
  description: "Description",
  address: "Address",
  city: "City",
  country: "Country",
  state: "State",
  zip: "Postcode",
  save: "Save",
  cancel: "Cancel",
  delete: "Delete",
  edit: "Edit",
  add: "Add",
  search: "Search",
  select: "Select",
  dateCreated: "Created at",
  dateModified: "Modified at",
  actions: "Actions",
  system_settings_props_updated_success:
    "System settings properties are updated successfully",
  rows_per_page: "Rows per page",
  addNewOrganization: "Add new client",
  addNewPrivilege: "Add new privilege",
  addNewSupplier: "Add new supplier",
  addNewGroup: "Add new group",
  addNewUser: "Add new user",
  sendMail: "Send email",
  editAvailableSlots: "Edit available slots",
  editGroup: "Edit group",
  editUser: "Edit user",
  editOrganization: "Edit client",
  editPrivilege: "Edit privilege",
  editSupplier: "Edit supplier",
  deleteGroup: "Delete group",
  deleteUser: "Delete user",
  deleteOrganization: "Delete client",
  deletePrivilege: "Delete privilege",
  deleteSupplier: "Delete supplier",
  deleteAvailableSlots: "Delete available slots",
  newOrganization: "New client",
  user_not_enabled: "User is not enabled",
  bad_credentials: "Bad credentials",
  networkErrorMessage: "Network error message",
  organization_create_success_message: "Client is created successfully",
  privilege_create_success_message: "Privilege is created successfully",
  supplier_create_success_message: "Supplier is created successfully",
  group_create_success_message: "Group is created successfully",
  user_create_success_message: "User is created successfully",
  group_update_success_message: "Group is updated successfully",
  user_update_success_message: "User is updated successfully",
  privilege_update_success_message: "Privilege is updated successfully",
  supplier_update_success_message: "Supplier is updated successfully",
  group_deleted_success_message: "Group is deleted successfully",
  user_deleted_success_message: "User is deleted successfully",
  group_delete_confirmation_text: "Are you sure you want to delete this group?",
  user_delete_confirmation_text: "Are you sure you want to delete this user?",
  organization_update_success_message: "Client is updated successfully",
  organization_delete_confirmation_text:
    "Are you sure you want to delete this client?",
  organization_deleted_success_message: "Client is deleted successfully",
  privilege_delete_confirmation_text:
    "Are you sure you want to delete this privilege?",
  supplier_delete_confirmation_text:
    "Are you sure you want to delete this supllier?",
  privilege_deleted_success_message: "Privilege is deleted successfully",
  supplier_deleted_success_message: "Supplier is deleted successfully",
  choose_mail_template: "Please choose an email template",
  country: "Country",
  countries: "Countries",
  nameEn: "Name (latin)",
  country_create_success_message: "Country is created successfully",
  country_update_success_message: "Country is modified successfully",
  country_delete_confirmation_text:
    "Are you sure you want to delete this country?",
  country_deleted_success_message: "Country is deleted successfully",
  group_details: "Group details",
  addNewCountry: "Add new country",
  editCountry: "Edit country",
  deleteCountry: "Delete country",
  mail_send_test_mail: "Send a test email message",
  selected_users: "Selected users",
  selected_organizations: "Selected clients",
  selected_clients: "Selected clients",
  selected: "selected",
  set_selected: "Set selected data for",
  add_new_members: "Add new members",
  members: "Members",
  mails: "Email addresses",
  cities: "Cities",
  city: "City",
  city_create_success_message: "City is created successfully",
  city_update_success_message: "City is modified successfully",
  city_delete_confirmation_text: "Are you sure you want to delete this city?",
  city_deleted_success_message: "City is deleted successfully",
  addNewCity: "Add new city",
  editCity: "Edit city",
  deleteCity: "Delete city",
  postcode: "Postcode",
  clients: "Clients",
  address: "Address",
  addressEn: "Address (latin)",
  edb: "Tax number (EDB)",
  phone: "Phone",
  addEmail: "Add new email address",
  settings: "Settings",
  mail_log: "Email log",
  mail_all: "All",
  mail_success: "Successfully sent",
  mail_pending: "Pending",
  mail_processing: "Processing",
  mail_failed: "Failed",
  mail_canceled: "Canceled",
  mail_sender: "Sender",
  mail_receiver: "Receiver",
  mail_createdAt: "Created at",
  mail_sentAt: "Sent at",
  mail_status: "Status",
  mail_details: "Details",
  mail_requeued: "Requeued",
  mail_requeue: "Requeue",
  mail_content: "Content",
  mail_view_mail_content: "Mail content",
  mail_error_log: "Error details",
  mail_error_details: "Error details",
  mail_subject: "Subject",
  mail_mail_details: "Mail details",
  mail_template: "Mail templates",
  props: "Properties",
  key: "Key",
  value: "Value",
  test_mail_server_header: "Test mail server",
  test_mail_server_subheader:
    "In this section you can test your email server (SMTP)",
  test_mail_server_description: "Currently these SMTP parameters are in place",
  send_test_mail: "Send",
  for_testing_purposes_only:
    "This feature should only be used for testing the Mail Server (SMTP)",
  mail_test_results_info_message: "Here you will see the result after the test",
  mail_test_results_info_header: "Test results",
  mail_sent_success_message: "Mail is sent successfully",
  mail_sent_error_message: "Sending mail failed",
  SUBMITTED: "SUBMITTED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  DONE: "DONE",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
  SUCCESS: "SUCCESS",
  synchronization: "Synchronization",
  edb: "Tax number (EDB)",
  invitation: "Invitation",
  regions: "Regions",
  addNewRegion: "Add new region",
  editRegion: "Edit region",
  deleteRegion: "Delete region",
  subRegions: "Sub-regions",
  addNewSubRegion: "Add new sub-region",
  editSubRegion: "Edit sub-region",
  region_name: "Region name",
  destinations: "Destinations",
  addNewDestination: "Add new destination",
  editDestination: "Edit destination",
  deleteDestination: "Delete destination",
  destination_name: "Destination name",
  destination_code: "Destination code",
  destination_region: "Destination region",
  destination_sub_region: "Destination sub-region",
  destination_country: "Destination country",
  destination_city: "Destination city",
  from_to: "From - to",
  destination_cities: "Destination cities",
  addNewDestinationCity: "Add new destination city",
  editDestinationCity: "Edit destination city",
  deleteDestinationCity: "Delete destination city",
  priceList: "Price list",
  addNewPriceList: "Add new pricelist",
  editPriceList: "Edit price list",
  deletePriceList: "Delete price list",
  supplier: "Supplier",
  destination: "Destination",
  price: "Price",
  purchasePrice: "Purchase price",
  salePrice: "Sale price",
  from_country: "From country",
  from_city: "From city",
  to_country: "To country",
  to_city: "To city",
  from_port: "From port",
  to_port: "To port",
  departure_date: "Departure date",
  suppliers: "Suppliers",
  official_name: "Official name",
  website: "Website",
  send_invitational_mail: "Send an invitation mail",
  destinationRegion: "Destination region",
  destinationSubRegion: "Destination sub-region",
  invitationCode: "Invitation code",
  create_an_organization_on_our_platform:
    "Create an organization on our platform",
  create_an_account_on_our_platform: "Create user account on our platform",
  organization: "Client",
  administrator: "Administrator",
  users: "Users",
  next: "Next",
  previous: "Previous",
  passwords_must_match: "Passwords must match",
  required_field: "Required field",
  invalid_invitation_code_error_message:
    "Invalid invitation code. Please contact the administrator.",
  organization_is_already_registered:
    "Organization is already registered. Please contact your administrator.",
  at_least_one_email_per_organization:
    "You must enter at least one email per client",
  at_least_one_organization: "You must select at least one client",
  active: "Active",
  vehicle_category: "Vehicle category",
  truck_plate_number: "Truck plate number",
  trailer_plate_number: "Trailer plate number",
  number_of_drivers: "Number of drivers",
  cargo_weight: "Cargo weight",
  dangerous_goods: "Dangerous goods",
  electric_plugin: "Electric plugin",
  live_animals: "Live animals",
  new_tickets: "New bookings",
  all_tickets: "All bookings",
  tickets: "Bookings",
  completed_tickets: "Completed Bookings",
  open: "Open",
  longitude: "Longitude",
  latitude: "Latitude",
  fees: "Fees",
  fee_update_success_message: "Fee is modified successfully",
  fee_deleted_success_message: "Fee is deleted successfully",
  deleteFee: "Delete fee",
  fee_delete_confirmation_text: "Are you sure you want to delete this fee?",
  editFee: "Edit fee",
  feeCategories: "Fee categories",
  feeCategory_update_success_message: "Fee category is modified successfully",
  feeCategory_deleted_success_message: "Fee category is deleted successfully",
  editFeeCategory: "Edit fee category",
  feeCategory_delete_confirmation_text:
    "Are you sure you want to delete this fee category?",
  deleteFeeCateogory: "Delete fee category",
  feePrices: "Fee prices",
  year: "Year",
  price1: "Price 1",
  feePrice_update_success_message: "Fee price is modified successfully",
  editFeePrice: "Edit fee price",
  feePrice_deleted_success_message: "Fee price is deleted successfully",
  deleteFeePrice: "Delete fee price",
  feePrice_delete_confirmation_text:
    "Are you sure you want to delete this fee price?",
  priceListCategories: "Price list categories",
  category: "Category",
  priceListCategory_update_success_message:
    "Price list category is modified successfully",
  editPriceListCategory: "Edit price list category",
  priceListCategory_deleted_success_message:
    "Price list category is deleted successfully",
  deletePriceListCategory: "Delete price list category",
  priceListCategory_delete_confirmation_text:
    "Are you sure you want to delete this price list category?",
  priceListsPerOrganization: "Price lists per client",
  organization1: "Organization",
  priceListType: "Price list type",
  totalPrice: "Total price",
  priceListPerOrganization_update_success_message:
    "Price list per organization is modified successfully",
  editPriceListPerOrganization: "Edit price list per client",
  priceListPerOrganization_deleted_success_message:
    "Price list per organization is deleted successfully",
  deletePriceListPerOrganization: "Delete price list per client",
  priceListPerOrganization_delete_confirmation_text:
    "Are you sure you want to delete this price list per client?",
  fromMeter: "From meter",
  toMeter: "To meter",
  feeCategory: "Fee category",
  price2: "Price 2",
  price3: "Price 3",
  price4: "Price 4",
  price5: "Price 5",
  price6: "Price 6",
  price7: "Price 7",
  price8: "Price 8",
  price9: "Price 9",
  price10: "Price 10",
  price11: "Price 11",
  price12: "Price 12",
  your_recent_bookings: "Your recent bookings",
  region_deleted_success_message: "Region is deleted successfully",
  region_delete_confirmation_text:
    "Are you sure you want to delete this region?",
  region_update_success_message: "Region is modified successfully",
  subRegion_update_success_message: "Sub-region is modified successfully",
  subRegion_deleted_success_message: "Fee category is deleted successfully",
  deleteSubRegion: "Delete sub-region",
  subRegion_delete_confirmation_text:
    "Are you sure you want to delete this sub-region?",
  destination_update_success_message: "Destination is modified successfully",
  destination_deleted_success_message: "Destination is deleted successfully",
  destination_delete_confirmation_text:
    "Are you sure you want to delete this destination?",
  priceList_update_success_message: "Price list is modified successfully",
  priceList_deleted_success_message: "Price list is deleted successfully",
  priceList_delete_confirmation_text:
    "Are you sure you want to delete this price list?",
  mailbox: "Mailbox",
  chooseClient: "Choose client",
  arrival_hour: "Arrival hour",
  departure_day: "Departure day",
  arrival_day: "Arrival day",
  priceListLevel: "Price list level",
  from_meter: "From meter",
  to_meter: "To meter",
  max_number_of_drivers: "Max number of drivers",
  specific_tax_1: "Specific tax 1",
  specific_tax_2: "Specific tax 2",
  specific_tax_3: "Specific tax 3",
  specific_tax_4: "Specific tax 4",
  incoming_emails: "Incoming Emails",
  content: "Content",
  viber: "Viber",
  create_ticket: "Create Booking",
  linked_tickets: "Linked bookings",
  viber_messages: "Viber messages",
  calculatedPriceWithFees: "Calculated price",
  calculatedPrice: "Calculated price",
  calculatedPurchasePrice: "Calculated purchase price",
  profit: "Profit",
  mailboxTickets: "Mailbox bookings",
  typeAMessage: "Type a message...",
  fromCity: "From",
  toCity: "To",
  departure: "Departure",
  not_found: "Not Found Any",
  no_mail_selected: "No Mail Selected",
  no_destination_selected: "No Destination Selected",
  select_mail_message: "Select a Mail to See the Linked Bookings",
  select_viber_message:
    "Select a Viber conversation to See the Linked Bookings",
  communications: "Communications",
  add_comment: "Add comment",
  add_note: "Add note",
  mailbox_content: "Mail Content",
  fromSender: "From",
  contact: "Contact",
  message: "Message",
  client: "Client",
  note: "Note",
  mail: "Mail",
  select_message_type: "Select message type",
  no_subject: "No subject",
  vehicle_categories: "Vehicle categories",
  available_slots: "Available Slots",
  available: "Available",
  default_length: "Default length",
  minLength: "Minimum length",
  maxLength: "Maximum length",
  edit_vehicle_category: "Edit vehicle category",
  vehicle_category_deleted_success_message:
    "Vehicle category is deleted successfully",
  delete_vehicle_cateogory: "Delete vehicle category",
  vehicle_category_delete_confirmation_text:
    "Are you sure you want to delete this vehicle category?",
  vehicle_category_update_success_message:
    "Vehicle category is modified successfully",
  add_new_vehicle_category: "Add new vehicle category",
  vehicle_category_create_success_message:
    "Vehicle category is created successfully",
  dashboard: "Dashboard",
  select_region: "Select region",
  channel_nord_sea: "Channel-Nord Sea Region",
  baltic_scandinavia: "Baltic-Scandinavia Region",
  inter_europe: "Inter Europe Region",
  adriatic_ionian_aegean: "Adriatic-Ionian-Aegean Region",
  mediteran: "Mediteran Region",
  available_slots_delete_confirmation_text:
    "Are you sure you want to delete the available slots?",
  pending_status: "Pending",
  assigned_status: "Assigned",
  in_progress_status: "In Progress",
  approved_status: "Approved",
  done_status: "Done",
  rejected_status: "Rejected",
  web: "Web",
  SMS: "SMS",
  whats_app: "WhatsApp",
  other: "Other",
  message_templates: "Message templates",
  message_template_update_success_message:
    "Message template is modified successfully",
  edit_message_template: "Edit message template",
  message_template_deleted_success_message:
    "Message template is deleted successfully",
  delete_message_template: "Delete message template",
  message_template_delete_confirmation_text:
    "Are you sure you want to delete this message template?",
  no_price_lists_found: "No available ferry bookings found",
  ticket_lifecycle: "Booking Lifecycle",
  message_template_localizations: "Message template localizations",
  message_template_localizataion_update_success_message:
    "Message template localization is modified successfully",
  edit_message_template_localization: "Edit message template localization",
  message_template_localization_deleted_success_message:
    "Message template localization is deleted successfully",
  delete_message_template_localization: "Delete message template localization",
  message_template_localization_delete_confirmation_text:
    "Are you sure you want to delete this message template localization?",
  language: "Language",
  localizations: "Localizations",
  no_localizations_available: "No localizations available",
  delete_localization: "Delete localization",
  add_new_localization: "Add new template localization",
  localization_delete_success_message: "Localization successfully deleted!",
  total: "Total",
  select_message_template: "Select message template",
  mark_as_complete: "Mark as complete",
  link_with_ticket: "Link with booking",
  link_with_different_ticket: "Link with different booking",
  template_type: "Template type",
  template_priority: "Template priority",
  template_for: "Template for",
  ticket_status: "Booking status",
  message_template_create_success_message:
    "Message template is created successfully",
  add_new_message_template: "Add new message template",
  add_new_localization_language_and_content:
    "Add new localization language and content",
  define_message_template: "Define the message template",
  organization_tickets: "Client bookings",
  priority: "Priority",
  ticket_number: "Booking number",
  required_field: "Required field",
  assign_to_me: "Assign to me",
  reject_ticket: "Reject booking",
  approve_ticket: "Approve booking",
  takeover_case: "Takeover case",
  assignee: "Assignee",
  assigned_to: "Assigned to",
  alternative_destinations: "Alternative Destinations",
  driver_name: "Driver name",
  distance: "Distance",
  estimated_time: "Estimated time",
  create_alternative_destination_success:
    "Alternative destination created successfully",
  alternative_destination: "Alternative Destination",
  update_alternative_destination_success:
    "Alternative destination updated successfully",
  update_alternative_destination_error:
    "Error while updating alternative destination",
  editAlternativeDestinations: "Edit alternative destination",
  vehicle: "Vehicle",
  escalate: "Escalate",
  ticket_source: "Booking source",
  delete_alternative_destination_success:
    "Alternative destination deleted successfully",
  delete_alternative_destination: "Delete alternative destination",
  alternative_destination_delete_confirmation_text:
    "Are you sure you want to delete the alternative destination?",
  additional_info: "Additional info",
  inactive: "Inactive",
  create_alternative_tickets_success:
    "Temporary reservations created successfully",
  make_temporary_reservation: "Make temporary reservation",
  no_additional_info: "No additional info for this booking",
  no_price_lists_available: "No price lists available",
  loaded: "Loaded",
  port_from_to: "Port (from - to)",
  cargo_weight_t: "Cargo weight (t)",
  vehicle_length: "Vehicle length",
  show_price_for_client: "Show price for client",
  alternative_tickets: "Alternative bookings",
  save_as_final: "Save as final booking",
  alt_tickets_for: "Alternative bookings for",
  no_alt_tickets_available: "There are no alternative bookings available",
  submit: "Submit",
  alternative_destinations_for: "Alternative destinations for",
  choose_alternative_destination: "Create an Alternative Destination for",
  choose_destination: "Choose a Destination",
  alt_tickets: "Alternative Bookings",
  alarm: "Alarm",
  set_an_alarm: "Set an alarm",
  ticket_changed_success:
    "Booking destination and price list changed successfully",
  set: "Set",
  escalate_without_alt: "Escalate without selecting alternative",
  escalate_ticket: "Escalate booking",
  alternative_ticket_status: "AL",
  escalated_ticket_status: "WL",
  all_regions: "All regions",
  add_plate_number: "Add new plate number",
  viber: "Viber",
  waiting_list: "Waiting List",
  reason_for_escalation: "Reason for escalation",
  escalation_reason: "Escalation reason",
  reservation_codes: "Reservation Codes",
  add_reservation_code: "Add reservation code",
  link_approve_ticket: "Link approved booking",
  export_excel: "Export to Excel",
  available_codes: "Available codes:",
  on_waiting_list: "On waiting list:",
  error_slot_details: "This slot doesn't have any reservation codes",
  go_to_ticket: "Go to booking",
  mailConfigurationPath: "Mail attachments path",
  mailConfigurationIncomingUsername: "Incoming mail username",
  mailConfigurationIncomingPassword: "Incoming mail password",
  mailConfigurationOutgoingUsername: "Outgoing mail username",
  mailConfigurationOutgoingPassword: "Outgoing mail password",
  reservation_code_update_success: "Reservation code updated successfully",
  whatsAppToken: "WhatsApp token",
  whatsAppUrl: "WhatsApp url",
  createTicket: "Create booking",
  delete_reservation_code: "Delete Reservation Code",
  remove_escalate_flag_success: "Escalate flag removed",
  remove_from_waiting_list_success: "Removed from waiting list",
  noOptionsText: "No options available",
  compose_new_mail: "Compose new mail",
  no_show: "No show",
  completed: "Completed",
  attach_files: "Attach files",
  mailboxFolders: "Mailbox folders",
  mailboxfolder_create_success_message: "Mailbox folder created successfully",
  addNewMailboxFolder: "Add new mailbox folder",
  mailboxFolder_deleted_success_message: "Mailbox folder deleted successfully",
  deleteMailboxFolder: "Delete mailbox folder",
  mailboxFolder_delete_confirmation_text:
    "Are you sure you want to delete this mailbox folder?",
  editMailboxFolder: "Edit mailbox folder",
  mailboxfolder_edit_success_message: "Mailbox folder modified successfully",
  reservation_code: "Reservation Code",
  send_confirmation_email: "Send Confirmation Email",
  reset_password: "Reset password",
  old_password: "Old password",
  new_password: "New password",
  confirm_password: "Confirm password",
  password_reset_success: "Password reset successfully",
  next: "Next",
  forgotPasswordSuccessCheckYourEmail:
    "Check your email for the password reset link",
  forgotPasswordLinkExpired: "Password reset link invalid or expired",
  whatsapp: "WhatsApp",
  select_whatsapp_message:
    "Select a WhatsApp conversation to See the Linked Bookings",
  expiring_codes_config: "Configure expiring codes",
  office: "Office",
  origin_country: "Country of origin",
  office_country: "Office country",
  customer_reference_number: "Customer reference number",
  supplier_reference_number: "Supplier reference number",
  send_multiple_codes: "Send multiple codes",
  send_multiple_reservation_codes: "Send multiple reservation codes",
  date_created: "Date created",
  mailbox_tags: "Mailbox tags",
  mailboxtag_create_success_message: "Mailbox tag created successfully",
  addNewMailboxTag: "Add new mailbox tag",
  mailboxTag_deleted_success_message: "Mailbox tag deleted successfully",
  deleteMailboxTag: "Delete mailbox tag",
  mailboxTag_delete_confirmation_text:
    "Are you sure you want to delete this mailbox tag?",
  mailboxtag_edit_success_message: "Mailbox tag modified successfully",
  tag_mail: "Tag mail",
  set_tag: "Set tag",
  tags: "Tags",
  customer_range_configurator: "Customer Volume Range Configurator",
  customer_range_configurator_details:
    "Customer Volume Range Configurator Details",
  crm: "CRM",
  add_new_customer_configuration: "Add new customer configuration template",
  from: "From",
  to: "To",
  configuration_template_name: "Configuration template name",
  category_of_template: "Category of template",
  add_new_price_calculation_configuration_template:
    "Add new price calculation template",
  define_price_calculation_template: "Define price calculation template",
  define_customer_volume_range_template:
    "Define customer volume range configurator template",
  customer_configuration_template_deleted_success_message:
    "Customer configuration template is deleted successfully",
  delete_customer_configuration_template:
    "Delete customer configuration template",
  customer_configuration_template_delete_confirmation_text:
    "Are you sure you want to delete this customer configuration template?",
  edit_customer_configuration_template: "Edit customer configuration template",
  customer_configuration_template_update_success_message:
    "Customer configuration template is modified successfully",
  price_calculation_configurator: "Price Calculator Configurator",
  price_calculation_configurator_template_details:
    "Price Calculator Configurator Template Details",
  rank_details: "Rank Details",
  edit_price_calculation_configuration_template:
    "Edit price calculation template data",
  template_deleted: "Template deleted successfully",
  template_updated: "Template updated successfully",
  template_created: "Template created successfully",
  delete_price_calculation_configurator_template:
    "Delete price calculation configurator template",
  delete_price_calculation_configurator_confirmation:
    "Are you sure you want to delete this template?",
  template_name: "Template name",
  template_category: "Category of Template",
  details: "Details",
  rank: "Rank",
  lower_rank: "lower rank",
  higher_rank: "higher rank",
  rank_range: "Rank Range",
  organization_groups: "Client Groups",
  add_new_organization_group: "Add new client group",
  edit_organization_group: "Edit client group",
  organization_group_create_success: "Client group is created successfully",
  organization_group_update_success: "Client group is updated successfully",
  organization_group_delete_success: "Client group deleted successfully",
  delete_organization_group: "Delete client group",
  delete_organization_group_confirmation:
    "Are you sure you want to delete this client group?",
  ranking: "Ranking",
  client_details: "Organization details",
  latin: "Latin",
  cyrillic: "Cyrillic",
  delivery_address: "Delivery address",
  delivery_postcode: "Delivery postcode",
  delivery_country: "Delivery country",
  vat_no: "VAT No.",
  telephone: "Telephone",
  credit_limit_invoice: "Credit Limit- Invoice  [EUR]",
  payment_period: "Payment Period [days]",
  fax: "Fax",
  payment_type: "Payment Type",
  finance_id: "Finance ID",
  web_all_caps: "WEB",
  invocing_type: "Invoicing Type",
  customer_type: "Customer Type",
  invoice_delivery_email: "Invoice Delivery E-mail",
  payment_instruction: "Payment Instruction",
  invoice_delivery_mail: "Invoice Delivery Mail",
  e_invoice_email_address: "E-Invoice E-mail Address",
  e_invoice_email_template: "E-Invoice E-mail Template",
  sbalance_debit: "SBalance Debit EUR/DOM",
  sbalance_credit: "SBalance Credit EUR/DOM",
  blocked_status: "Blocked Status",
  active_status: "Active Status",
  customer_supervisor: "Customer Supervisor",
  org: "Organization",
  department: "Department",
  id: "ID",
  general_info: "General info",
  geo_sales_configuration: "Geo sales configuration",
  add_new_geo_sales_configurator: "New geo sales configurator",
  add_new_group: "Add new group",
  name_group: "Name of group",
  edit_geo_sales_configurator: "Edit geo sales configurator",
  delete_geo_sales_configurator: "Delete geo sales configurator",
  geo_sales_configuration_delete_confirmation_text:
    "Are you sure you want to delete this geo sales configuration?",
  group_name: "Group name",
  add_configuration_group: "Add geo sales configuration group",
  product: "Product",
  product_destination: "Product Destination",
  product_destinations: "Product Destinations",
  add_new_product: "Add new product",
  define_product: "Define product",
  product_created: "Product created successfully",
  delete_product: "Delete product",
  delete_product_confirmation: "Are you sure you want to delete this product?",
  product_deleted: "Product deleted successfully",
  product_edited: "Product updated successfully",
  product_edit: "Edit product",
  product_transport_mode: "Transport mode",
  product_directions_range: "Direction",
  product_packet: "Product packet",
  materials: "Materials",
  add_new_material: "Add new material",
  edit_material: "Edit material",
  delete_material: "Delete material",
  material_delete_confirmation_text:
    "Are you sure you want to delete this material?",
  products: "Products",
  configuration: "Configuration",
  open_configuration: "Open configuration",
  delete_geo_sales_group_success_message:
    "Geo sales configuration group deleted successfully",
  remove_product_price_config: "Remove product price configuration",
  price_variations: "Price Variations",
  price_variation_rule: "Price Variation Rule",
  add_new_price_variation: "Add new price variation",
  price_variation_created: "Price Variation created successfully",
  price_variation_updated: "Price Variation updated successfully",
  price_variation_deleted: "Price Variation deleted successfully",
  define_price_variation_name: "Define the price variation name",
  define_price_variation_rule: "Define the price variation rule",
  delete_price_variation: "Delete price variation",
  delete_price_variation_confirmation:
    "Are you sure you want to delete this price variation?",
  configurations: "Configurations management",
  label: "Label",
  direction: "Direction",
  is_complex: "Complex",
  main_destination: "Main destination",
  number_of_axles: "Number of axles",
  send_multiple_vehicles_codes: "Send multiple vehicle codes",
  offer_numbers: "Offer numbers",
  add_new_offer_number: "Add new offer number",
  offer_number: "Offer number",
  edit_offer_number: "Edit offer number",
  offer_number_update_success_message: "Offer number is modified successfully",
  delete_offer_number: "Delete offer number",
  offer_number_delete_confirmation_text:
    "Are you sure you want to delete this offer number?",
  offer_number_deleted_success_message: "Offer number is deleted successfully",
  notes: "Notes",
  add_new_note_contact: "Add new note contact",
  phone_number: "Phone number",
  contact_update_success_message: "User has been updated successfully",
  custom_product_configuration: "Custom product configuration",
  activate_product: "Activate product",
  deactivate_product: "Deactivate product",
  all_products: "All",
  active_products: "Active",
  inactive_products: "Inactive",
  filter_products: "Filter products",
  region: "Region",
  vehicle_height: "Vehicle height",
  custom_prices: "Custom prices",
  validity_period: "Validity period",
  potential_clients: "Potential clients",
  new_potential_client: "Add new potential client",
  potential_client_created: "Potential client created successfully",
  channel_communication: "Channel communication",
  organization_communication: "Organization sales communication",
  extras: "Extras",
  product_name: "Product name",
  scheduled_maintenance: "Scheduled Maintenance",
  temperature: "Temperature",
  operator: "Operator",
  operators: "Operators",
  send: "send",
  dangerous_goods_document_required: "Dangerours Goods Document is required",
  additional_documents_required:
    "Additional documents are required for this departure",
};
