import { useTheme } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Chip,
  Grid,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import { getTicketStatusColor } from "../../common/VisualElementsHelper";
import { LOCALE } from "../../properties/Locale";

export const TicketGroupDetailsMobile = ({
  data,
  ticketGroup,
  loading,
  setTmpData,
  setData,
  setChangeTicket,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const statusColors = {
    APPROVED: theme.palette.secondary.main,
    PENDING: theme.palette.extra3.main,
    IN_PROGRESS: theme.palette.in_progress.main,
    REJECTED: theme.palette.rejected.main,
    CANCELLED: theme.palette.canceled.main,
    DONE: theme.palette.done.main,
  };

  return (
    <>
      <Accordion
        elevation={0}
        style={{
          border: `1px solid ${alpha(theme.palette.success.main, 0.5)}`,
          clipPath: "inset(0 round 1em)",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          style={{
            backgroundColor: alpha(theme.palette.success.main, 0.8),
            color: "white",
            height: 30,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "normal" }}
              >{`Group name: ${ticketGroup?.name} `}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "normal" }}
              >{`Group reason: ${ticketGroup?.reason}`}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            marginTop: "15px",
            marginBottom: "-25px",
          }}
        >
          {ticketGroup &&
            ticketGroup?.tickets
              ?.sort((a, b) => a.ticketNumber - b.ticketNumber)
              .map((ticket, index) => (
                <Grid
                  container
                  key={index}
                  spacing={2}
                  sx={{
                    backgroundColor:
                      data?.id == ticket?.id
                        ? alpha(theme.palette.success.main, 0.45)
                        : alpha(theme.palette.success.main, 0.1),
                    clipPath: "inset(0 round 1em)",
                    padding: "10px 0 10px 0",
                    marginBottom: "20px",
                    marginLeft: "-10px",
                  }}
                  onClick={() => {
                    setData(ticket);
                    setTmpData(ticket);
                    setChangeTicket(true);
                    navigate(
                      `/client/booking/${0}/${10}/${JSON.stringify({})}/${
                        ticket?.id
                      }`
                    );
                  }}
                >
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: alpha(theme.palette.darkContrast.main, 0.8),
                        fontWeight: "normal",
                      }}
                    >
                      {LOCALE.ticket_number}
                    </Typography>
                    <Typography variant="h5">{ticket?.ticketNumber}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: alpha(theme.palette.darkContrast.main, 0.8),
                        fontWeight: "normal",
                      }}
                    >
                      {LOCALE.departure_date}
                    </Typography>
                    <Typography variant="h5">
                      {moment(ticket?.departureDay).utc().format("DD/MM/YYYY")}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: alpha(theme.palette.darkContrast.main, 0.8),
                        fontWeight: "normal",
                      }}
                    >
                      {LOCALE.destination}
                    </Typography>
                    <Typography variant="h5">
                      <ReactCountryFlag
                        countryCode={ticket?.destination?.fromCountry?.code}
                        svg
                        style={{
                          marginRight: "3px",
                          width: "1.6em",
                          height: "1.1em",
                        }}
                        title={ticket?.destination?.fromCountry?.nameEn}
                      />
                      {ticket?.destination?.name?.split("-").join(" ➜ ")}
                      <ReactCountryFlag
                        countryCode={ticket?.destination?.toCountry?.code}
                        svg
                        style={{
                          marginLeft: "3px",
                          width: "1.6em",
                          height: "1.1em",
                        }}
                        title={ticket?.destination?.toCountry?.nameEn}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: alpha(theme.palette.darkContrast.main, 0.8),
                        fontWeight: "normal",
                      }}
                    >
                      {LOCALE.vehicle}
                    </Typography>
                    <Typography variant="h5">
                      {ticket?.truckPlateNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: alpha(theme.palette.darkContrast.main, 0.8),
                        fontWeight: "normal",
                      }}
                    >
                      {data?.organization?.showSupplier && LOCALE.supplier}
                    </Typography>
                    {ticket?.productDestination ? (
                      <Typography variant="h5" component="div">
                        {data?.organization?.showSupplier &&
                        ticket?.productDestination?.operator?.name
                          ? ticket?.productDestination?.operator?.name
                          : ticket?.productDestination?.supplier?.name}
                        {data?.organization?.showSupplier && " - "}
                        {
                          ticket?.productDestinationDeparture?.departureHour
                        }{" "}
                        <Typography variant="h6" component="span">
                          (Arrive{" "}
                          {ticket?.productDestinationDeparture?.arrivalDay ===
                          "0"
                            ? "same day"
                            : ticket?.productDestinationDeparture
                                ?.arrivalDay === "1"
                            ? "next day"
                            : "after 2 days"}{" "}
                          at {ticket?.productDestinationDeparture?.arrivalHour})
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography variant="subtitle2">
                        {`Departure hour: ${ticket?.departureHour}`}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: alpha(theme.palette.darkContrast.main, 0.8),
                        fontWeight: "normal",
                      }}
                    >
                      {LOCALE.status}
                    </Typography>
                    <Chip
                      variant="contained"
                      style={{
                        width: 100,
                        borderRadius: 4,
                        cursor: "pointer",
                        height: 22,
                        backgroundColor: statusColors[ticket?.status],
                      }}
                      size="small"
                      label={
                        <span style={{ fontWeight: "bold", color: "white" }}>
                          {ticket?.status?.replaceAll("_", " ")}
                        </span>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {data?.id != ticket?.id && (
                      <Typography
                        variant="h6"
                        sx={{
                          color: alpha(theme.palette.darkContrast.main, 0.8),
                          fontWeight: "normal",
                        }}
                      >
                        {LOCALE.go_to_ticket}
                      </Typography>
                    )}

                    {data?.id == ticket?.id ? (
                      <Typography>{""}</Typography>
                    ) : (
                      <Tooltip
                        arrow={true}
                        title={
                          <Typography variant="h5">{LOCALE.open}</Typography>
                        }
                      >
                        <IconButton
                          style={{ padding: 0, paddingRight: 5 }}
                          color="primary"
                          onClick={() => {
                            setData(ticket);
                            setTmpData(ticket);
                            setChangeTicket(true);
                            navigate(
                              `/client/booking/${0}/${10}/${JSON.stringify(
                                {}
                              )}/${ticket?.id}`
                            );
                          }}
                        >
                          <Icon>launch</Icon>
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
