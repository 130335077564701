import {
  Button,
  Drawer,
  Grid,
  Icon,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { UsersRepository } from "../../repositories/UsersRepository";
import { AuthRepository } from "../../repositories/AuthRepository";
import { triggerRerender } from "../../common/CommonActions";
import { LOCALE } from "../../properties/Locale";

export default function ForgotPasswordMobile() {
  const [email, setEmail] = useState("");
  const [globalFormError, setGlobalFormError] = React.useState();
  const [loading, setLoading] = useState();
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleForgotPassword = () => {
    setLoading(true);
    UsersRepository.forgotPasswordRequest(email).then(
      (res) => {
        setSuccess(true);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  };
  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "block", md: "none" },
          width: "100%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "100%",
            boxSizing: "border-box",

            backgroundColor: "white",
            position: "fixed",
          },
        }}
        PaperProps={{
          sx: {
            width: 240,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 240,
              boxSizing: "border-box",
              zIndex: (theme) => theme.zIndex.drawer,
              position: "relative",
            },
          },
        }}
      >
        <div style={{ flex: 1 }}>
          <Grid container direction="column" style={{ height: "100%" }}>
            <Grid
              container
              style={{
                height: "50px",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginTop: "10px" }}
              >
                <Icon
                  style={{
                    width: "60px",
                    height: "70px",
                  }}
                >
                  <svg
                    width="52"
                    height="48"
                    viewBox="0 0 52 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.9868 11.8759L22.1345 0.00907072L22.1283 0.00305176L17.6571 13.7865L17.6601 13.7835H3.18902H3.18579L13.2793 21.1281H22.9849L25.9868 11.8759Z"
                      fill="#338E9B"
                      style={{
                        fill: "#338E9B",
                        fill: "color(display-p3 0.2000 0.5569 0.6078)",
                        fillOpacity: 1,
                      }}
                    />
                    <path
                      d="M38.7121 21.1281L48.8024 13.7865L48.8083 13.7805L34.334 13.7775L34.3401 13.7835L29.8657 0L26.0137 11.8669L29.0186 21.1281H38.7121Z"
                      fill="#338E9B"
                      style={{
                        fill: "#338E9B",
                        fill: "color(display-p3 0.2000 0.5569 0.6078)",
                        fillOpacity: 1,
                      }}
                    />
                    <path
                      d="M33.8771 36.106L43.9583 43.4416L43.9674 43.4446L39.4963 29.6611L39.493 29.6641L51.1999 21.1461L38.7571 21.1431L30.8811 26.8749L33.8771 36.106Z"
                      fill="#338E9B"
                      style={{
                        fill: "#338E9B",
                        fill: "color(display-p3 0.2000 0.5569 0.6078)",
                        fillOpacity: 1,
                      }}
                    />
                    <path
                      d="M18.1411 36.1451L14.295 47.9909V47.9999L26.0048 39.4819H25.9986L37.7087 47.9999H37.7116L33.8655 36.1481L26.0018 30.4253L18.1411 36.1451Z"
                      fill="#25B0C0"
                      style={{
                        fill: "#25B0C0",
                        fill: "color(display-p3 0.1451 0.6902 0.7529)",
                        fillOpacity: 1,
                      }}
                    />
                    <path
                      d="M12.5068 29.6642L8.03567 43.4477V43.4507L18.1198 36.1121L21.1188 26.875L13.2489 21.1492H0.809084L0.799988 21.1522L12.5098 29.6702L12.5068 29.6642Z"
                      fill="#338E9B"
                      style={{
                        fill: "#338E9B",
                        fill: "color(display-p3 0.2000 0.5569 0.6078)",
                        fillOpacity: 1,
                      }}
                    />
                  </svg>
                </Icon>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    marginTop: "-75px",
                  }}
                >
                  ASTA ADRIA
                </Typography>
              </Grid>
            </Grid>

            <Grid item style={{ marginTop: " 200px" }}>
              {" "}
              {globalFormError && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Alert
                    severity="error"
                    sx={{ textAlign: "center", width: "350px" }}
                  >
                    {globalFormError}
                  </Alert>
                </Grid>
              )}
              {success && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Alert
                    severity="success"
                    sx={{ textAlign: "center", width: "350px" }}
                  >
                    {LOCALE.forgotPasswordSuccessCheckYourEmail}
                  </Alert>
                </Grid>
              )}
              {!success && (
                <>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <TextField
                      fullWidth
                      label={LOCALE.email}
                      value={email}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleForgotPassword();
                        }
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        height: "48px",
                        borderRadius: "4px",
                        width: "350px",
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", marginBottom: "10px" }}
          >
            <a
              style={{
                cursor: "pointer",
                color: theme.palette.primary.main,
                textDecoration: "underline",
              }}
              onClick={() => {
                navigate("/login");
              }}
            >
              {LOCALE.login}
            </a>
          </Grid>
          {!success && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  handleForgotPassword();
                }}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ height: "48px", width: "350px", marginBottom: "10px" }}
              >
                <Typography
                  variant="h5"
                  sx={{ textTransform: "capitalize", width: "350px" }}
                >
                  {LOCALE.next.toLowerCase()}
                </Typography>
              </Button>
            </Grid>
          )}
        </div>
      </Drawer>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography sx={{ flexGrow: 1 }}></Typography>
    </React.Fragment>
  );
}
