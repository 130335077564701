import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  CardContent,
  Card,
  IconButton,
  useMediaQuery,
  alpha,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { defaultStyles, FileIcon } from "react-file-icon";
import { FileDownload } from "@mui/icons-material";

export function ClientTicketAttachments({
  data,
  handleDownloadAttachment,
  handleDownloadMessageAttachment,
  downloadAttachments,
  downloadMessageAttachments,
  messageAttachments,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} p={4}>
        {data?.ticketAttachments?.length == 0 &&
          messageAttachments?.length == 0 && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "light",
                        color: theme.palette.lightGray.main,
                      }}
                    >
                      No Attachments Available
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        {data?.ticketAttachments && data?.ticketAttachments.length > 0 && (
          <>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "light",
                        color: theme.palette.lightGray.main,
                      }}
                    >
                      Download All Attachments
                    </Typography>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => downloadAttachments()}
                    >
                      <FileDownload sx={{ fontSize: "24px" }} />
                    </IconButton>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    overflowY: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 1,
                    }}
                  >
                    {data?.ticketAttachments?.map((attachment) => (
                      <Box
                        sx={{
                          position: "relative",
                          transition: "transform 0.2s, box-shadow 0.2s",
                          cursor: "pointer",
                          "&:hover": {
                            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.15)",
                          },
                        }}
                      >
                        {attachment.attachmentType === "DANGEROUS_GOODS" && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              backgroundColor: alpha(
                                theme.palette.error.main,
                                0.8
                              ),
                              color: theme.palette.error.contrastText,
                              textAlign: "center",
                              py: 0.2,
                              fontSize: "0.65rem",
                              fontWeight: "light",
                              borderTopLeftRadius: 5,
                              borderTopRightRadius: 5,
                              zIndex: 2,
                            }}
                          >
                            Dangerous Goods
                          </Box>
                        )}
                        <Card
                          key={attachment.id}
                          sx={{
                            width: "270px",
                            border: "1px solid",
                            borderColor:
                              attachment.attachmentType === "DANGEROUS_GOODS"
                                ? theme.palette.error.main
                                : theme.palette.divider,
                            borderRadius: 2,
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDownloadAttachment(attachment)}
                        >
                          <CardContent>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  width: 40,
                                  height: 40,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: theme.palette.grey[200],
                                  borderRadius: "50%",
                                }}
                              >
                                <FileIcon
                                  extension={attachment.fileExtension.replace(
                                    ".",
                                    ""
                                  )}
                                  {...defaultStyles[
                                    attachment.fileExtension.replace(".", "")
                                  ]}
                                />
                              </Box>

                              <Box sx={{ minWidth: 0, flex: 1 }}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontWeight: "medium",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={attachment.fileName}
                                >
                                  {attachment.fileName.split(".")[0].length > 20
                                    ? `${attachment.fileName
                                        .split(".")[0]
                                        .substring(0, 20)}...`
                                    : attachment.fileName.split(".")[0]}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  {attachment.fileExtension
                                    .replace(".", "")
                                    .toUpperCase()}
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {messageAttachments && messageAttachments?.length > 0 && (
          <>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "light",
                        color: theme.palette.lightGray.main,
                      }}
                    >
                      Download All Message Attachments
                    </Typography>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => downloadMessageAttachments()}
                    >
                      <FileDownload sx={{ fontSize: "24px" }} />
                    </IconButton>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    overflowY: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 1,
                    }}
                  >
                    {messageAttachments?.map((attachment) => (
                      <Box
                        sx={{
                          position: "relative",
                          transition: "transform 0.2s, box-shadow 0.2s",
                          cursor: "pointer",
                          "&:hover": {
                            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.15)",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            backgroundColor: alpha(
                              theme.palette.mutedBlue.main,
                              0.7
                            ),
                            color: theme.palette.error.contrastText,
                            textAlign: "center",
                            py: 0.2,
                            fontSize: "0.65rem",
                            fontWeight: "light",
                            borderTopLeftRadius: 5,
                            borderTopRightRadius: 5,
                            zIndex: 2,
                          }}
                        >
                          {attachment?.messagePreview
                            ? `${attachment?.messagePreview?.slice(0, 40)}...`
                            : "Message Preview"}
                        </Box>
                        <Card
                          key={attachment.id}
                          sx={{
                            width: "270px",
                            border: "1px solid",
                            borderColor:
                              attachment.attachmentType === "DANGEROUS_GOODS"
                                ? theme.palette.error.main
                                : theme.palette.divider,
                            borderRadius: 2,
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleDownloadMessageAttachment(attachment)
                          }
                        >
                          <CardContent>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  width: 40,
                                  height: 40,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: theme.palette.grey[200],
                                  borderRadius: "50%",
                                }}
                              >
                                <FileIcon
                                  extension={attachment.fileExtension.replace(
                                    ".",
                                    ""
                                  )}
                                  {...defaultStyles[
                                    attachment.fileExtension.replace(".", "")
                                  ]}
                                />
                              </Box>

                              <Box sx={{ minWidth: 0, flex: 1 }}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontWeight: "medium",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={attachment.fileName}
                                >
                                  {attachment.fileName.split(".")[0].length > 20
                                    ? `${attachment.fileName
                                        .split(".")[0]
                                        .substring(0, 20)}...`
                                    : attachment.fileName.split(".")[0]}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  {attachment.fileExtension
                                    .replace(".", "")
                                    .toUpperCase()}
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
