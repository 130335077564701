import React, { useState, useEffect, useRef, createRef } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import GeoJSON from "ol/format/GeoJSON";
import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import Popup from "ol-popup";
import { DestinationsRepository } from "../../repositories/DestinationsRepository";
import ReactCountryFlag from "react-country-flag";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";
import { Box, Button, Grid } from "@mui/material";
import { Fill, Icon, Stroke, Style } from "ol/style";
import { createRoot } from "react-dom/client";
var _ = require("lodash");

export default function ClientTicketPreviewOpenLayer({
  handleChangeData,
  data,
  setData,
  fromCity,
  toCity,
  toCityCallback,
  setToCityCallback,
}) {
  let mapElement = createRef();

  const popupElementRef = useRef(null);
  const vectorLayerRef = useRef(null);
  const rootRef = useRef(null);

  const popupElementToCityRef = useRef(null);
  const vectorLayerToCityRef = useRef(null);
  const rootToCityRef = useRef(null);

  let vectorLayer = new VectorLayer({
    format: new GeoJSON(),
  });

  vectorLayer.setSource(
    new VectorSource({
      loader: function (extent, resolution, projection) {
        // /maps/base.geo.json
        var url = `/map/hq.geo.json`;
        fetch(url)
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            var features = new GeoJSON().readFeatures(json, {
              featureProjection: projection,
            });

            features.forEach((feature) => {
              feature.setStyle(
                new Style({
                  fill: new Fill({
                    color: "#d5d5d5",
                  }),
                  stroke: new Stroke({
                    color: "#949494",
                    width: 0.5,
                  }),
                })
              );
            });
            vectorLayer.getSource().addFeatures(features);
          });
      },
    })
  );

  const [map] = useState(
    new Map({
      layers: [vectorLayer],
      controls: [],
      target: mapElement.current,
      view: new View({
        center: fromLonLat([17, 49]),
        zoom: 4,
        minZoom: 1,
        maxZoom: 10,
      }),
    })
  );

  const popup = new Popup({ offset: [10, 10] });
  map.addOverlay(popup);

  var addEventMove = function (map, evt) {
    map.forEachFeatureAtPixel(
      evt.pixel,
      function (feature, layer) {
        if (
          layer &&
          (layer.get("name") === "fromCity" ||
            layer.get("name") === "toCity" ||
            layer.get("name") === "route")
        ) {
          if (layer.get("name") === "route") {
            popup.show(
              evt.coordinate,
              `<div style="background-color: white; border-radius: 10px; border: 1px solid #cccccc; padding: 10px;">${ReactDOMServer.renderToStaticMarkup(
                feature.values_.routeElement
              )}</div>`
            );
          } else if (feature.values_.cityElement) {
            popup.show(
              evt.coordinate,
              `<div style="background-color: white; border-radius: 10px; border: 1px solid #cccccc; padding: 10px;">${ReactDOMServer.renderToStaticMarkup(
                feature.values_.cityElement
              )}</div>`
            );
          }
        }
      },
      {
        hitTolerance: 0,
      }
    );
  };

  useEffect(() => {
    if (map && data.fromCity) {
      removeLayerByName("fromCities");
      setFromCityPoint();
    } else {
      if (popupElementRef.current) {
        let overlayContainer = document.querySelector(
          ".ol-overlaycontainer-stopevent"
        );
        if (
          overlayContainer &&
          overlayContainer.contains(popupElementRef.current)
        ) {
          if (rootRef.current) {
            const root = rootRef.current;
            setTimeout(() => {
              root?.unmount();
            }, 500);
          }
          overlayContainer.removeChild(popupElementRef.current);
        }
        popupElementRef.current = null;
        rootRef.current = null;
      }
      if (vectorLayerRef.current) {
        map.removeLayer(vectorLayerRef.current);
        vectorLayerRef.current = null;
      }
    }
  }, [data?.fromCity]);

  useEffect(() => {
    if (map && data.toCity) {
      removeLayerByName("toCities");
      setToCityPoint();
    } else {
      if (popupElementToCityRef.current) {
        let overlayContainer = document.querySelector(
          ".ol-overlaycontainer-stopevent"
        );
        if (
          overlayContainer &&
          overlayContainer.contains(popupElementToCityRef.current)
        ) {
          if (rootToCityRef.current) {
            const root = rootToCityRef.current;
            setTimeout(() => {
              root?.unmount();
            }, 500);
          }
          overlayContainer.removeChild(popupElementToCityRef.current);
        }
        popupElementToCityRef.current = null;
        rootToCityRef.current = null;
      }
      if (vectorLayerToCityRef.current) {
        map.removeLayer(vectorLayerToCityRef.current);
        vectorLayerToCityRef.current = null;
      }
    }
  }, [data?.toCity]);

  const setFromCityPoint = () => {
    if (vectorLayerRef.current) {
      map.removeLayer(vectorLayerRef.current);
      vectorLayerRef.current = null;
    }

    if (data?.fromCity.latitude && data?.fromCity.longitude) {
      const cityElement = (
        <Box
          component="div"
          sx={{
            "& > img": { mr: 2, flexShrink: 0 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <ReactCountryFlag
            countryCode={fromCity.country.code}
            svg
            style={{
              width: "1.8em",
              borderRadius: "8px",
              height: "1.8em",
              marginBottom: "5px",
              marginRight: "5px",
            }}
            title={fromCity.country.name}
          />
          <b
            style={{
              fontSize: "16px",
              color: "#202020",
            }}
          >
            {fromCity.name}
          </b>
        </Box>
      );

      const fromPoint = new Feature({
        geometry: new Point(
          fromLonLat([fromCity.longitude, fromCity.latitude])
        ),
        id: fromCity.id,
      });

      const vectorLayer = new VectorLayer({
        name: "fromCity",
        source: new VectorSource({
          features: [fromPoint],
        }),
        style: getPinStyle(map.getView().getZoom()),
      });

      vectorLayerRef.current = vectorLayer;
      map.addLayer(vectorLayer);

      if (popupElementRef.current) {
        const overlayContainer = document.querySelector(
          ".ol-overlaycontainer-stopevent"
        );
        if (
          overlayContainer &&
          overlayContainer.contains(popupElementRef.current)
        ) {
          if (rootRef.current) {
            const root = rootRef.current;
            setTimeout(() => {
              root?.unmount();
            }, 500);
          }
          overlayContainer.removeChild(popupElementRef.current);
        }
        popupElementRef.current = null;
        rootRef.current = null;
      }

      const popupElement = document.createElement("div");
      popupElement.className = "popup";
      popupElement.style.position = "absolute";
      popupElement.style.backgroundColor = "white";
      popupElement.style.border = "1px solid #ccc";
      popupElement.style.borderRadius = "4px";
      popupElement.style.padding = "2px 5px 2px 5px";
      popupElement.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.3)";
      popupElement.style.pointerEvents = "none";

      const root = createRoot(popupElement);
      root.render(cityElement);
      rootRef.current = root;
      popupElementRef.current = popupElement;

      const updatePopupPosition = () => {
        const coordinate = fromLonLat([fromCity.longitude, fromCity.latitude]);
        const pixel = map.getPixelFromCoordinate(coordinate);

        if (pixel) {
          popupElement.style.position = "absolute";
          popupElement.style.top = `${pixel[1] - 50}px`; // Adjust vertical position
          popupElement.style.left = `${pixel[0] - 30}px`; // Adjust horizontal position
        }
      };

      const overlayContainer = document.querySelector(
        ".ol-overlaycontainer-stopevent"
      );
      if (overlayContainer) {
        overlayContainer.appendChild(popupElement);
        updatePopupPosition();

        map.on("moveend", updatePopupPosition);
      } else {
        setTimeout(setFromCityPoint, 100);
      }

      return () => {
        if (popupElementRef.current) {
          const overlayContainer = document.querySelector(
            ".ol-overlaycontainer-stopevent"
          );
          if (
            overlayContainer &&
            overlayContainer.contains(popupElementRef.current)
          ) {
            root.unmount();
            overlayContainer.removeChild(popupElementRef.current);
          }
          popupElementRef.current = null;
        }
        if (vectorLayerRef.current) {
          map.removeLayer(vectorLayerRef.current);
          vectorLayerRef.current = null;
        }
      };
    }
  };

  const setToCityPoint = () => {
    if (vectorLayerToCityRef.current) {
      map.removeLayer(vectorLayerToCityRef.current);
      vectorLayerToCityRef.current = null;
    }

    if (data?.toCity.latitude && data?.toCity.longitude) {
      const cityElement = (
        <Box
          component="div"
          sx={{
            "& > img": { mr: 2, flexShrink: 0 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <ReactCountryFlag
            countryCode={toCity.country.code}
            svg
            style={{
              width: "1.8em",
              borderRadius: "8px",
              height: "1.8em",
              marginBottom: "5px",
              marginRight: "5px",
            }}
            title={toCity.country.name}
          />
          <b
            style={{
              fontSize: "16px",
              color: "#202020",
            }}
          >
            {toCity.name}
          </b>
        </Box>
      );
      const toPoint = new Feature({
        geometry: new Point(fromLonLat([toCity.longitude, toCity.latitude])),
        id: toCity.id,
        cityElement: cityElement,
      });

      const vectorLayer = new VectorLayer({
        name: "toCity",
        source: new VectorSource({
          features: [toPoint],
        }),
        style: getPinStyle(map.getView().getZoom()),
      });

      vectorLayerToCityRef.current = vectorLayer;
      map.addLayer(vectorLayer);

      if (popupElementToCityRef.current) {
        const overlayContainer = document.querySelector(
          ".ol-overlaycontainer-stopevent"
        );

        if (
          overlayContainer &&
          overlayContainer.contains(popupElementToCityRef.current)
        ) {
          if (rootToCityRef.current) {
            const root = rootToCityRef.current;
            setTimeout(() => {
              root?.unmount();
            }, 500);
          }
          overlayContainer.removeChild(popupElementToCityRef.current);
        }
      }

      const popupElement = document.createElement("div");
      popupElement.className = "popup";
      popupElement.className = "popup";
      popupElement.style.position = "absolute";
      popupElement.style.backgroundColor = "white";
      popupElement.style.border = "1px solid #ccc";
      popupElement.style.borderRadius = "4px";
      popupElement.style.padding = "2px 5px 2px 5px";
      popupElement.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.3)";
      popupElement.style.pointerEvents = "none";

      const root = createRoot(popupElement);
      root.render(cityElement);
      rootToCityRef.current = root;
      popupElementToCityRef.current = popupElement;

      const updatePopupPosition = () => {
        const coordinate = fromLonLat([toCity.longitude, toCity.latitude]);
        const pixel = map.getPixelFromCoordinate(coordinate);

        if (pixel) {
          popupElement.style.position = "absolute";
          popupElement.style.top = `${pixel[1] - 50}px`; // Adjust vertical position
          popupElement.style.left = `${pixel[0] - 30}px`; // Adjust horizontal position
        }
      };

      const overlayContainer = document.querySelector(
        ".ol-overlaycontainer-stopevent"
      );
      if (overlayContainer) {
        overlayContainer.appendChild(popupElement);
        updatePopupPosition();

        map.on("moveend", updatePopupPosition);
      } else {
        setTimeout(setToCityPoint, 100);
      }

      return () => {
        if (popupElementToCityRef.current) {
          const overlayContainer = document.querySelector(
            ".ol-overlaycontainer-stopevent"
          );
          if (
            overlayContainer &&
            overlayContainer.contains(popupElementToCityRef.current)
          ) {
            root.unmount();
            overlayContainer.removeChild(popupElementToCityRef.current);
          }
          popupElementToCityRef.current = null;
        }
        if (vectorLayerToCityRef.current) {
          map.removeLayer(vectorLayerToCityRef.current);
          vectorLayerToCityRef.current = null;
        }
      };
    }
  };

  const getPinStyle = (zoomLevel) => {
    const scale = 0.5 + zoomLevel * 0.05;
    return new Style({
      image: new Icon({
        src: "/map/pin.png",
        scale: scale,
      }),
    });
  };

  const removeLayerByName = (layerName) => {
    map.getLayers().forEach((layer) => {
      if (layer && layer.get("name") === layerName) {
        map.removeLayer(layer);
      }
    });
  };

  const addRoute = (cityId) => {
    removeLayerByName("route");

    DestinationsRepository.fetchAll({
      searchParams: {
        "fromCity.id": cityId,
        "toCity.id": data.toCity ? data.toCity.id : undefined,
      },
      page: 0,
      size: 1,
      orderDirection: "DESC",
      orderBy: "dateCreated",
    })
      .then((res) => {
        const destination = res.data.content[0];
        if (destination && destination["coordinates"]) {
          const route = JSON.parse(destination["coordinates"]);
          const routeCoordinates = route.map((coordinate) =>
            fromLonLat([coordinate[0], coordinate[1]])
          );

          const routeElement = (
            <div style={{ display: "inline-flex" }}>
              <Box
                component="div"
                sx={{
                  "& > img": { flexShrink: 0 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <ReactCountryFlag
                  countryCode={destination.fromCity.country.code}
                  svg
                  style={{
                    width: "1.8em",
                    borderRadius: "8px",
                    height: "1.8em",
                    marginBottom: "5px",
                    marginRight: "5px",
                  }}
                  title={destination.fromCity.country.name}
                />
              </Box>
              <b
                style={{
                  fontSize: "16px",
                  color: "#202020",
                }}
              >
                {destination.fromCity.name}
              </b>
              <Box
                component="div"
                sx={{
                  "& > img": { flexShrink: 0 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <b
                  style={{
                    fontSize: "16px",
                    marginRight: "5px",
                    marginLeft: "5px",
                    color: "#202020",
                  }}
                >
                  -
                </b>
              </Box>
              <Box
                component="div"
                sx={{
                  "& > img": { mr: 2, flexShrink: 0 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <ReactCountryFlag
                  countryCode={destination.toCity.country.code}
                  svg
                  style={{
                    width: "1.8em",
                    borderRadius: "8px",
                    height: "1.8em",
                    marginBottom: "5px",
                    marginRight: "5px",
                  }}
                  title={destination.toCity.country.name}
                />
              </Box>
              <b
                style={{
                  fontSize: "16px",
                  color: "#202020",
                }}
              >
                {destination.toCity.name}
              </b>
            </div>
          );

          const routeFeature = new Feature({
            geometry: new LineString(routeCoordinates),
            name: `${destination.fromCity.name} (${destination.fromCity.country.nameEn}) - ${destination.toCity.name} (${destination.toCity.country.nameEn})`,
            id: destination.toCity.id,
            routeElement: routeElement,
          });

          const toCityFeature = new Feature({
            geometry: new Point(
              fromLonLat([
                destination.toCity.longitude,
                destination.toCity.latitude,
              ])
            ),
            name: `${destination.fromCity.name} (${destination.fromCity.country.nameEn}) - ${destination.toCity.name} (${destination.toCity.country.nameEn})`,
            id: destination.toCity.id,
          });

          var vectorLayerToCities = new VectorLayer({
            name: "toCities",
            source: new VectorSource({
              features: [toCityFeature],
            }),
            style: new Style({
              image: new Icon({
                src: "/map/pin.png",
                scale: 0.5 + map.getView().getZoom() * 0.05,
              }),
            }),
          });
          let flag = false;
          map
            .getLayers()
            .getArray()
            .filter((layer) => layer.get("name") === "route")
            .forEach((layer) => {
              layer.set(vectorLayerToCities);
              flag = true;
            });
          if (!flag) map.addLayer(vectorLayerToCities);
          let vectorLayer = new VectorLayer({
            name: "route",
            source: new VectorSource({
              features: [routeFeature],
            }),
            style: new Style({
              stroke: new Stroke({
                color: "#40a7b3",
                width: "3",
                lineDash: [10, 10],
              }),
            }),
          });
          flag = false;
          map
            .getLayers()
            .getArray()
            .filter((layer) => layer.get("name") === "route")
            .forEach((layer) => {
              layer.set(vectorLayer);
              flag = true;
            });
          if (!flag) map.addLayer(vectorLayer);
          map.getLayers().forEach((layer) => {
            if (layer && layer.get("name") === "route") {
              map.getView().fit(layer.getSource().getExtent(), map.getSize());
              map.getView().setZoom(map.getView().getZoom() - 0.25);
            }
          });
          if (toCityCallback) {
            handleChangeData("toCity", toCityCallback);
            setToCityCallback(null);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (fromCity && map) {
      setFromCityPoint();
      setToCityPoint();
      // addRoute(data?.fromCity.id);
    }
  }, [fromCity, toCity]);

  useEffect(() => {
    if (map) {
      map.setTarget(mapElement.current);
      if (!map.hasListener("pointermove"))
        map.on("pointermove", function (evt) {
          if (map.hasFeatureAtPixel(evt.pixel)) {
            map.getViewport().style.cursor = "pointer";
            addEventMove(map, evt);
          } else {
            map.getViewport().style.cursor = "inherit";
            popup.hide();
          }
        });
    }
  }, [map]);

  useEffect(() => {
    if (data.fromCity && data.toCity) {
      addRoute(data.fromCity.id);
    } else if (data.fromCity && !data.toCity) {
      addRoute(data.fromCity.id);
    } else if (!data.fromCity && !data.toCity) {
      handleChangeData("toCity", undefined);
      map.getLayers().forEach((layer) => {
        if (
          layer &&
          (layer.get("name") === "route" || layer.get("name") === "toCity")
        ) {
          map.removeLayer(layer);
        }
      });
      map.setView(
        new View({
          center: fromLonLat([17, 49]),
          zoom: 4,
          minZoom: 1,
          maxZoom: 10,
        })
      );
    }
  }, [data.fromCity, data.toCity]);

  return (
    <div
      className="mapRow"
      style={{ textAlign: "center", height: "100%", width: "100%" }}
    >
      <div
        id="map"
        ref={mapElement}
        className="map-container"
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
}
