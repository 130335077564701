import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const OrganizationsRepository = {
  fetchAll: (payload) => {
    return Axios({
      url: SETTINGS.API_URL + "organizations",
      method: "GET",
      params: {
        page: payload.page,
        size: payload.size,
        orderBy: payload.orderBy,
        orderDirection: payload.orderDirection,
        searchParams: payload.searchParams,
      },
    });
  },
  fetchAllWithoutPaging: () => {
    return Axios({
      url: SETTINGS.API_URL + "organizations/all",
      method: "GET",
    });
  },

  getOrganizationsOrNoteConversationOrganizations: (contactId) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations/getOrganizationsOrNoteConversationOrganizations`,
      method: "GET",
      params: { contactId: contactId },
    });
  },

  getAllResponsibleOffices: () => {
    return Axios({
      url: SETTINGS.API_URL + "organizations/responsibleOffices",
      method: "GET",
    });
  },

  get: (id) => {
    return Axios.get(`${SETTINGS.API_URL}organizations/${id}`);
  },

  createOrganization: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations`,
      method: "POST",
      data: data,
    });
  },
  updateOrganization: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations`,
      method: "PUT",
      data: data,
    });
  },

  deleteOrganization: (id) => {
    return Axios.delete(`${SETTINGS.API_URL}organizations/${id}`);
  },

  generateInvitationCode: (id) => {
    return Axios.post(
      `${SETTINGS.API_URL}organizations/${id}/generateInvitationCode`
    );
  },

  getOrganizationByInvitationCode: (code) => {
    return Axios.get(
      `${SETTINGS.API_URL}organizations/getByInvitationCode/${code}`
    );
  },

  sendInvitationalMails: (organizationEmailsInvitations) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations/sendMails`,
      method: "POST",
      data: organizationEmailsInvitations,
    });
  },

  findByEmail: (email) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations/findByEmail`,
      method: "GET",
      params: { email: email },
    });
  },

  allEmails: () => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations/emails`,
      method: "GET",
    });
  },

  getOrganizationMailConfiguration: (id) => {
    return Axios.get(
      `${SETTINGS.API_URL}organizations/${id}/mailConfiguration`
    );
  },

  getOrganizationWhatsAppConfiguration: (id) => {
    return Axios.get(
      `${SETTINGS.API_URL}organizations/${id}/whatsAppConfiguration`
    );
  },

  updateOrganizationMailConfiguration: (params) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations/${params.id}/mailConfiguration`,
      method: "PUT",
      data: params.data,
    });
  },

  updateOrganizationWhatsAppConfiguration: (params) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations/${params.id}/whatsAppConfiguration`,
      method: "PUT",
      data: params.data,
    });
  },

  getAllOfficeCountries: () => {
    return Axios({
      url: SETTINGS.API_URL + "organizations/officeCountries",
      method: "GET",
    });
  },

  getSignatureConfiguration: (organizationId) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations/getSignatureConfigurationForOrganization`,
      method: "GET",
      params: { organizationId: organizationId },
    });
  },

  updateOrganizationSignatureConfiguration: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations/signatureConfiguration`,
      method: "PUT",
      data: data,
    });
  },

  getAllOrganizationsNoPaging: () => {
    return Axios({
      url: SETTINGS.API_URL + "organizations/allOrganizations",
      method: "GET",
    });
  },

  getBySearchString: (searchString) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations/getBySearchString`,
      method: "GET",
      params: {
        searchString: searchString,
      },
    });
  },

  getOnlyOrganizationsForViberConversation: (clientId) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations/getOnlyOrganizationsForViberConversation`,
      method: "GET",
      params: { clientId: clientId },
    });
  },

  getOnlyWhatsAppConversationOrganizations: (clientId) => {
    return Axios({
      url: `${SETTINGS.API_URL}organizations/getOnlyWhatsAppConversationOrganizations`,
      method: "GET",
      params: { clientId: clientId },
    });
  },
};
