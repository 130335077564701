import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const UsersRepository = {
  resetPassword: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}users/reset_password`,
      method: "PUT",
      params: {
        username: data.username,
        newPassword: data.newPassword,
        oldPassword: data.oldPassword,
      },
    });
  },
  forgotPasswordRequest: (email) => {
    return Axios({
      url: `${SETTINGS.API_URL}register/user/forgotPassword`,
      method: "PUT",
      params: {
        email: email,
      },
    });
  },
  forgotPasswordCheck: (token) => {
    return Axios({
      url: `${SETTINGS.API_URL}register/user/forgotPasswordCheck`,
      method: "GET",
      params: {
        token: encodeURIComponent(token),
      },
    });
  },
  forgotPasswordReset: (token, newPassword) => {
    return Axios({
      url: `${SETTINGS.API_URL}register/user/forgotPasswordReset`,
      method: "PUT",
      params: {
        token: encodeURIComponent(token),
        password: newPassword,
      },
    });
  },
};
