import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";

export default function ChooseComplexDeparture({
  allComplexDepartures,
  setAvailableComplexDepartures,
  availableComplexDepartures,
  selectedComplexDepartureKey,
  setSelectedComplexDepartureKey,
  data,
  returnComplexDestinationChecked,
}) {
  return (
    <>
      {Object.keys(allComplexDepartures)
        .filter((key) =>
          returnComplexDestinationChecked
            ? key.endsWith("CMPX-RT")
            : key.endsWith("CMPX")
        )
        .map((complexDeparture, index) => (
          <>
            <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
              <Card
                sx={{ height: "350px" }}
                onClick={() => {
                  setAvailableComplexDepartures(
                    allComplexDepartures[complexDeparture]
                  );
                  setSelectedComplexDepartureKey(complexDeparture);
                }}
              >
                <CardActionArea
                  onClick={() => {
                    setAvailableComplexDepartures(
                      allComplexDepartures[complexDeparture]
                    );
                    setSelectedComplexDepartureKey(complexDeparture);
                  }}
                >
                  <ReactCountryFlag
                    countryCode={data?.fromCity?.country?.code}
                    svg
                    style={{
                      width: "50%",
                      height: "100%",
                    }}
                    title={data?.fromCity?.country?.name}
                  />
                  <ReactCountryFlag
                    countryCode={data?.toCity?.country?.code}
                    svg
                    style={{
                      width: "50%",
                      height: "100%",
                    }}
                    title={data?.toCity?.country?.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {complexDeparture}
                    </Typography>
                    {Object.keys(allComplexDepartures[complexDeparture]).map(
                      (departure, departureIndex) => (
                        <>
                          <Typography
                            variant="inherit"
                            color="text.secondary"
                            component="div"
                          >
                            {`${allComplexDepartures[complexDeparture][departure].legNumber}. ${allComplexDepartures[complexDeparture][departure]?.destination?.name}`}
                          </Typography>
                          {allComplexDepartures[complexDeparture][departure]
                            ?.productAvailableDeparturesDTOList &&
                            allComplexDepartures[complexDeparture][
                              departure
                            ]?.productAvailableDeparturesDTOList.map(
                              (availableDeparture) => (
                                <>
                                  <Typography
                                    variant="inherit"
                                    color="text.primary"
                                    component="div"
                                    style={{
                                      color: "#4180ec",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {availableDeparture?.operator?.name
                                      ? `${availableDeparture?.operator?.name}`
                                      : `${availableDeparture?.supplier?.name}`}
                                  </Typography>
                                </>
                              )
                            )}
                          <br></br>
                        </>
                      )
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </>
        ))}
      <Grid
        container
        spacing={2}
        mt={-10}
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: { xs: "row", md: "row" },
          flexWrap: "wrap",
        }}
      >
        {Object.keys(allComplexDepartures)
          .filter((key) =>
            returnComplexDestinationChecked
              ? key.endsWith("CMPX-RT")
              : key.endsWith("CMPX")
          )
          .map((complexDeparture, index) => (
            <>
              <Grid item xs={12} sx={{ marginTop: "10px", marginLeft: "20px" }}>
                <Card
                  sx={{ height: "300px" }}
                  onClick={() => {
                    setAvailableComplexDepartures(
                      allComplexDepartures[complexDeparture]
                    );
                    setSelectedComplexDepartureKey(complexDeparture);
                  }}
                >
                  <CardActionArea
                    onClick={() => {
                      setAvailableComplexDepartures(
                        allComplexDepartures[complexDeparture]
                      );
                      setSelectedComplexDepartureKey(complexDeparture);
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={data?.fromCity?.country?.code}
                      svg
                      style={{
                        width: "50%",
                        height: "100%",
                      }}
                      title={data?.fromCity?.country?.name}
                    />
                    <ReactCountryFlag
                      countryCode={data?.toCity?.country?.code}
                      svg
                      style={{
                        width: "50%",
                        height: "100%",
                      }}
                      title={data?.toCity?.country?.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {complexDeparture}
                      </Typography>
                      {Object.keys(allComplexDepartures[complexDeparture]).map(
                        (departure, departureIndex) => (
                          <>
                            <Typography
                              variant="inherit"
                              color="text.secondary"
                              component="div"
                            >
                              {`${allComplexDepartures[complexDeparture][departure].legNumber}. ${allComplexDepartures[complexDeparture][departure]?.destination?.name}`}
                            </Typography>
                            {allComplexDepartures[complexDeparture][departure]
                              ?.productAvailableDeparturesDTOList &&
                              allComplexDepartures[complexDeparture][
                                departure
                              ]?.productAvailableDeparturesDTOList.map(
                                (availableDeparture) => (
                                  <>
                                    <Typography
                                      variant="inherit"
                                      color="text.primary"
                                      component="div"
                                      style={{
                                        color: "#4180ec",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {availableDeparture?.operator?.name
                                        ? `${availableDeparture?.operator?.name}`
                                        : `${availableDeparture?.supplier?.name}`}
                                    </Typography>
                                  </>
                                )
                              )}
                            <br></br>
                          </>
                        )
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </>
          ))}
      </Grid>
    </>
  );
}
