import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { InvitationRegistrationRepository } from "../../repositories/InvitationRegistrationRepository";
import _ from "lodash";
import * as yup from "yup";
import { ErrorMessageResolver } from "../../common/ErrorMessageResolver";
import { useDispatch } from "react-redux";
import { notifyShowErrorMessage } from "../../common/CommonActions";
import { LOCALE } from "../../properties/Locale";
import { RegistrationAdministratorForm } from "./RegistrationAdministratorForm";

export const UserInvitationRegistration = () => {
  const { invitationCode } = useParams();
  const [globalError, setGlobalError] = useState();
  const [formData, setFormData] = useState();
  const [formDataErrors, setFormDataErrors] = useState({});
  const [globalFormError, setGlobalFormError] = useState();
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState();
  const dispath = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    setLoading(true);
    InvitationRegistrationRepository.getUserInvitation(invitationCode)
      .then((res) => {
        console.log(res.data);
        if (!res.data) {
          setGlobalError("Invalid invitation code");
        }
        setFormData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleSubmit = () => {
    setFormDataErrors({});
    setGlobalFormError();
    let validation = yup.object().shape({
      email: yup.string().required("Required field").email(),
      firstName: yup.string().required("Required field"),
      lastName: yup.string().required("Required field").nullable(),
      confirmPassword: yup
        .string()
        .required("Required field")
        .min(6)
        .max(20)
        .test("passwords-match", "Passwords must match", function (value) {
          console.log(value, this.parent.password);
          return this.parent.password === value;
        })
        .nullable(),
      password: yup
        .string()
        .required("Required field")
        .min(6)
        .max(20)
        .test("passwords-match", "Passwords must match", function (value) {
          console.log(value, this.parent.confirmPassword);
          return this.parent.confirmPassword === value;
        }),
    });
    if (!validation.isValidSync(formData)) {
      let validationErrors = {};
      validation.validate(formData, { abortEarly: false }).catch((err) => {
        err.inner.forEach((validationError) => {
          validationErrors[validationError.path] = {};
          validationErrors[validationError.path] = validationError.message;
        });
        console.log(validationErrors);
        setFormDataErrors(validationErrors);
      });
    } else {
      setLoading(true);
      let data = { ...formData };
      data.username = data.email;
      InvitationRegistrationRepository.registerUser(data)
        .then((res) => {
          setLoading(false);
          navigate("/login");
        })
        .catch((err) => {
          setLoading(false);
          setGlobalFormError(ErrorMessageResolver.resolve(err));
          dispath(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
        });
    }
  };

  const handleChangeData = (name, value) => {
    setFormData(_.set({ ...formData }, name, value));
  };

  return (
    <>
      {redirectTo && <Navigate to={redirectTo} push />}
      <Container
        sx={{
          height: "91vh",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={0} md={3}></Grid>
          <Grid item xs={0} md={6}>
            <Paper
              className="roundedBorder"
              square
              style={{
                padding: "20px",
                marginTop: "30px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Grid item xs={12} style={{ textAlign: "left" }}>
                    <img src={"/logo.png"} height={50} />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    variant="h6"
                    style={{ marginTop: "10px", textAlign: "right" }}
                  >
                    {LOCALE.invitationCode}: {invitationCode?.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              {globalError && (
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <Alert severity="error">{globalError}</Alert>
                </Grid>
              )}
              {!globalError && (
                <Grid container spacing={2}>
                  {loading && (
                    <Grid
                      item
                      xs={12}
                      style={{ marginTop: "50px", marginBottom: "50px" }}
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                  {globalFormError && (
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                      <Alert severity="error">{globalFormError}</Alert>
                    </Grid>
                  )}
                  {!loading && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        pt={2}
                        pb={4}
                        style={{ marginTop: "10px" }}
                      >
                        <Grid item xs={12} md={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} pb={2}>
                              <Typography
                                variant="h4"
                                style={{ textAlign: "center" }}
                              >
                                {LOCALE.create_an_account_on_our_platform}
                              </Typography>
                            </Grid>
                            <RegistrationAdministratorForm
                              handleChangeData={handleChangeData}
                              formData={formData}
                              formDataErrors={formDataErrors}
                            />
                          </Grid>
                          <Grid item xs={12} pt={2}>
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{ fontSize: "14px" }}
                              onClick={() => handleSubmit()}
                            >
                              Create
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
            </Paper>
          </Grid>

          <Grid item xs={0} md={3}></Grid>
        </Grid>
      </Container>
    </>
  );
};
