import { useTheme } from "@emotion/react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import {
  alpha,
  Collapse,
  Grid,
  Icon,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { jwtDecode } from "jwt-decode";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Drawer } from "../../common/MainLayout";
import {
  CreateNewBookingListMobile,
  LogoutListMobile,
  ProfileListMobile,
  SideMenuLinksMobile,
} from "../../common/SideMenuLink";
import { AuthRepository } from "../../repositories/AuthRepository";
import AstaAdriaLogo from "../../assets/img/Asta-Adria-logo.svg";

export const SidebarMobile = ({ setOpen, open }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = React.useState();
  const [menuLinks, setMenuLinks] = React.useState(SideMenuLinksMobile);
  const [menuLinksDown, setMenuLinksDown] = React.useState(
    CreateNewBookingListMobile
  );
  const [menuLinksProfile, setMenuLinksProfile] =
    React.useState(ProfileListMobile);
  const [menuLinksLogout, setMenuLinksLogout] =
    React.useState(LogoutListMobile);
  const [submenuExpanded, setSubmenuExpanded] = React.useState({
    submenuPath: undefined,
  });
  const [selectedCreateNewBooking, setSelectedCreateNewBooking] =
    React.useState(false);
  const [isCreateNewBookingVisible, setCreateNewBookingVisible] =
    React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);
  const handleCloseProfile = () => {
    setAnchorEl(null);
  };
  const conversationsWhatsApp = useSelector(
    (state) => state.conversationsWhatsApp
  );
  const conversationsViber = useSelector((state) => state.conversationsViber);
  const handleClickProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const isCreateNewBooking =
    window.location.pathname.includes("/client/newBooking");
  const [totalUnreadNotificationsViber, setTotalUnreadNotificationsViber] =
    React.useState(0);

  const [
    totalUnreadNotificationsWhatsApp,
    setTotalUnreadNotificationsWhatsApp,
  ] = React.useState(0);

  const checkActiveTab = (item) => {
    return (
      (window.location.pathname.includes(item.selectedPath) &&
        window.location.pathname !== "/" &&
        item.path !== "/") ||
      (window.location.pathname === item.selectedPath &&
        item.selectedPath === "/")
    );
    return false;
  };

  const getUserInitials = (user) => {
    if (!user) return "";
    const firstNameInitial = user.firstName?.charAt(0)?.toUpperCase() || "";
    const lastNameInitial = user.lastName?.charAt(0)?.toUpperCase() || "";
    return firstNameInitial + lastNameInitial;
  };
  React.useEffect(() => {
    let count = 0;
    // for (let conversationViber of conversationsViber) {
    //   count = count + conversationViber.unreadMessages;
    // }
    conversationsViber.map((conversationViber) => {
      count = count + conversationViber.unreadMessages;
    });

    setTotalUnreadNotificationsViber(count);
  }, [conversationsViber]);

  React.useEffect(() => {
    loadCurrentUser();
  }, []);

  const loadCurrentUser = () => {
    if (localStorage.getItem("jwt")) {
      setCurrentUser(jwtDecode(localStorage.getItem("jwt")));
      // countAllExpiringSoonReservationCodes();
    }
  };

  const getUserFullName = (user) => {
    if (!user) return "";
    return `${user.firstName || ""} ${user.lastName || ""}`;
  };

  const getUserEmail = (user) => {
    if (!user) return "";
    return `${user.email || ""}`;
  };

  React.useEffect(() => {
    let count = 0;
    // for (let conversationWhatsApp of conversationsWhatsApp) {
    //   count = count + conversationWhatsApp.unreadMessages;
    // }
    conversationsWhatsApp.map((conversationWhatsApp) => {
      count = count + conversationWhatsApp.unreadMessages;
    });

    setTotalUnreadNotificationsWhatsApp(count);
  }, [conversationsWhatsApp]);

  useEffect(() => {
    loadCurrentUser();
    if (
      window.location.hostname === "localhost" &&
      window.location.port === "3001"
    ) {
      // Ensure all menu items are visible when on localhost:3001
      setMenuLinks(SideMenuLinksMobile);
      setMenuLinksProfile(ProfileListMobile);
      setMenuLinksLogout(LogoutListMobile);
      setMenuLinksDown(CreateNewBookingListMobile);
    }
  }, []);

  const handleLogout = () => {
    AuthRepository.authLogout(currentUser?.userId)
      .then((res) => {
        AuthRepository.logout();
      })
      .catch((err) => {
        AuthRepository.logout();
      });
  };

  return (
    <>
      <Drawer
        open={open}
        color="default"
        sx={{
          display: { xs: "block", md: "none" },
          position: "relative",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        PaperProps={{
          sx: {
            width: 240,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 240,
              boxSizing: "border-box",
              zIndex: (theme) => theme.zIndex.drawer,
              position: "relative",
            },
          },
        }}
      >
        <div style={{ flex: 1 }}>
          <Grid item style={{ marginTop: "12px", marginLeft: "12px" }}>
            <IconButton onClick={() => setOpen(!open)}>
              <MenuIcon />
            </IconButton>
          </Grid>
          <List>
            {menuLinks.map(
              (item, index) =>
                AuthRepository.hasAnyRole(item.roles) && (
                  <React.Fragment key={index}>
                    {
                      <>
                        {!(
                          item.name === "Create New Booking" &&
                          isCreateNewBooking
                        ) && (
                          <ListItem
                            button
                            key={index}
                            onClick={() => {
                              setSelectedItem(item.name);
                              if (
                                setSelectedItem(item.name) ===
                                "Create New Booking"
                              ) {
                                setCreateNewBookingVisible(false);
                                setSelectedCreateNewBooking(
                                  (prevState) => !prevState
                                );
                              } else {
                                setCreateNewBookingVisible(true);
                                setSelectedCreateNewBooking(false);
                              }
                              navigate(item.path);
                              setSubmenuExpanded(
                                submenuExpanded.submenuPath == item.path
                                  ? { submenuPath: "" }
                                  : { submenuPath: item.path }
                              );
                            }}
                            style={{
                              backgroundColor:
                                item.name === "Create New Booking"
                                  ? selectedCreateNewBooking
                                    ? theme.palette.background.default
                                    : theme.palette.primary.main
                                  : checkActiveTab(item)
                                  ? alpha(theme.palette.secondary.main, 0.6)
                                  : theme.palette.background.default,
                              color:
                                checkActiveTab(item) &&
                                theme.palette.background.default,
                              borderRadius: "8px",
                              transition: "all 0.3s ease",
                              transform: "scale(0.93)",
                            }}
                          >
                            <ListItemIcon
                              style={{
                                minWidth: "40px",
                                minHeight: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {item.name === "ASTA ADRIA" ? (
                                <img
                                  src={AstaAdriaLogo}
                                  alt="Asta Adria"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    marginLeft: "-5px",
                                  }}
                                />
                              ) : (
                                <Icon
                                  sx={{
                                    color:
                                      item.name === "Create New Booking"
                                        ? theme.palette.background.default
                                        : checkActiveTab(item)
                                        ? theme.palette.background.default
                                        : theme.palette.darkContrast.main,
                                  }}
                                >
                                  {item.icon}
                                </Icon>
                              )}
                            </ListItemIcon>
                            {open && (
                              <>
                                <ListItemText
                                  primary={
                                    item.name === "ASTA ADRIA" ? (
                                      <Typography
                                        variant="body1"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "18px",
                                          color:
                                            theme.palette.darkContrast.main,
                                        }}
                                      >
                                        {item.name}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          color:
                                            item.name === "Create New Booking"
                                              ? theme.palette.background.default
                                              : checkActiveTab(item)
                                              ? theme.palette.background.default
                                              : theme.palette.darkContrast.main,
                                        }}
                                      >
                                        {item.name}
                                      </Typography>
                                    )
                                  }
                                  style={{ marginLeft: "5px" }}
                                />
                              </>
                            )}

                            {open &&
                            item.submenus &&
                            item.submenus.length > 0 ? (
                              submenuExpanded.submenuPath == item.path ? (
                                <ListItemIcon style={{ minWidth: "30px" }}>
                                  <ExpandLess />
                                </ListItemIcon>
                              ) : (
                                <ListItemIcon style={{ minWidth: "30px" }}>
                                  <ExpandMore />
                                </ListItemIcon>
                              )
                            ) : (
                              ""
                            )}
                          </ListItem>
                        )}
                        {item.name === "Booking" && (
                          <Divider
                            style={{
                              margin: "5px",
                              borderBottomWidth: "2px",
                            }}
                          />
                        )}
                      </>
                    }
                    {item.submenus && item.submenus.length > 0 ? (
                      <Collapse
                        in={submenuExpanded.submenuPath == item.path}
                        timeout="auto"
                        unmountOnExit
                      >
                        {item.submenus.map(
                          (submenu, index) =>
                            AuthRepository.hasAnyRole(submenu.roles) && (
                              <>
                                {submenu.name == "Clients" &&
                                AuthRepository.getUserDetails()?.organization?.nameEn.includes(
                                  "ROMANIA"
                                ) ? (
                                  ""
                                ) : (
                                  <ListItem
                                    button
                                    key={index}
                                    onClick={() => navigate(submenu.path)}
                                    style={{
                                      backgroundColor: checkActiveTab(submenu)
                                        ? "#d0d0d0"
                                        : "transparent",

                                      paddingLeft: "20px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <ListItemIcon
                                      style={{
                                        minWidth: "30px",
                                        minHeight: "25px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon>{submenu.icon}</Icon>
                                    </ListItemIcon>
                                    {open && (
                                      <ListItemText
                                        primary={submenu.name}
                                        style={{ marginLeft: "5px" }}
                                      />
                                    )}
                                  </ListItem>
                                )}
                              </>
                            )
                        )}
                      </Collapse>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )
            )}
          </List>
        </div>
        <Divider style={{ borderColor: "rgb(255 255 255 / 20%)" }} />

        <div style={{ marginTop: "auto" }}>
          <List>
            {menuLinksDown.map(
              (item, index) =>
                AuthRepository.hasAnyRole(item.roles) && (
                  <React.Fragment key={index}>
                    {" "}
                    {item.name == "Administration" &&
                    AuthRepository.getUserDetails()?.organization?.nameEn.includes(
                      "ROMANIA"
                    ) ? (
                      ""
                    ) : (
                      <>
                        <ListItem
                          button
                          key={index}
                          onClick={() => {
                            if (item.name === "Logout") {
                              handleLogout();
                            } else {
                              navigate(item.path);
                            }
                            setSubmenuExpanded(
                              submenuExpanded.submenuPath == item.path
                                ? { submenuPath: "" }
                                : { submenuPath: item.path }
                            );
                          }}
                          style={{
                            backgroundColor: checkActiveTab(item)
                              ? "#d0d0d0"
                              : "transparent",
                            paddingLeft: "20px",
                            paddingRight: "5px",
                          }}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: "30px",
                              minHeight: "25px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {item.name === "Profile" ? (
                              <div
                                style={{
                                  borderColor: theme.palette.lightGray.main,
                                  borderRadius: "4px",
                                  width: "33px",
                                  height: "35px",
                                  minWidth: "30px",
                                  minHeight: "25px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  color: theme.palette.darkContrast.main,
                                  opacity: 0.6,
                                }}
                              >
                                {getUserInitials(currentUser)}
                              </div>
                            ) : (
                              <Icon
                                sx={{ color: theme.palette.darkContrast.main }}
                              >
                                {item.icon}
                              </Icon>
                            )}
                          </ListItemIcon>
                          {open && (
                            <>
                              <ListItemText
                                primary={
                                  item.name === "Profile" ? (
                                    <div>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color: theme.palette.lightGray.main,
                                        }}
                                      >
                                        {currentUser?.organization?.nameEn}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          marginTop: "-1px",
                                          marginBottom: "-1px",
                                        }}
                                      >
                                        <b>{getUserFullName(currentUser)}</b>
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color: theme.palette.secondary.main,
                                        }}
                                      >
                                        {getUserEmail(currentUser)}
                                      </Typography>
                                    </div>
                                  ) : (
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      {item.name}
                                    </Typography>
                                  )
                                }
                                style={{ marginLeft: "5px" }}
                              />
                            </>
                          )}
                          {open && item.submenus && item.submenus.length > 0 ? (
                            submenuExpanded.submenuPath == item.path ? (
                              <ListItemIcon style={{ minWidth: "30px" }}>
                                <ExpandLess />
                              </ListItemIcon>
                            ) : (
                              <ListItemIcon style={{ minWidth: "30px" }}>
                                <ExpandMore />
                              </ListItemIcon>
                            )
                          ) : (
                            ""
                          )}
                        </ListItem>
                        {item.name === "Profile" && (
                          <Divider
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              borderBottomWidth: "2px",
                            }}
                          />
                        )}
                      </>
                    )}
                    {item.submenus && item.submenus.length > 0 ? (
                      <Collapse
                        in={submenuExpanded.submenuPath == item.path}
                        timeout="auto"
                        unmountOnExit
                      >
                        {item.submenus.map(
                          (submenu, index) =>
                            AuthRepository.hasAnyRole(submenu.roles) && (
                              <>
                                {submenu.name == "Clients" &&
                                AuthRepository.getUserDetails()?.organization?.nameEn.includes(
                                  "ROMANIA"
                                ) ? (
                                  ""
                                ) : (
                                  <ListItem
                                    button
                                    key={index}
                                    onClick={() => navigate(submenu.path)}
                                    style={{
                                      backgroundColor: checkActiveTab(submenu)
                                        ? "#d0d0d0"
                                        : "transparent",

                                      paddingLeft: "20px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <ListItemIcon
                                      style={{
                                        minWidth: "30px",
                                        minHeight: "25px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon>{submenu.icon}</Icon>
                                    </ListItemIcon>
                                    {open && (
                                      <ListItemText
                                        primary={submenu.name}
                                        style={{ marginLeft: "5px" }}
                                      />
                                    )}
                                  </ListItem>
                                )}
                              </>
                            )
                        )}
                      </Collapse>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )
            )}
          </List>
        </div>
      </Drawer>
    </>
  );
};
