import { Button, Grid, TextField, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOCALE } from "../properties/Locale";
import { UsersRepository } from "../repositories/UsersRepository";
import { useTheme } from "@emotion/react";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [globalFormError, setGlobalFormError] = React.useState();
  const [loading, setLoading] = useState();
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleForgotPassword = () => {
    setLoading(true);
    UsersRepository.forgotPasswordRequest(email).then(
      (res) => {
        setSuccess(true);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  };

  return (
    <>
      <Grid container sx={{ height: "87vh" }}>
        <Grid item xs={4} sm={4} md={4} lg={4.75} xl={5}></Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={2.5}
          xl={2}
          style={{
            marginTop: "100px",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", marginBottom: "24px" }}
            >
              <img
                alt=""
                src={require("../assets/img/new_logo.png")}
                width="280px"
                onClick={() => navigate("/")}
                style={{ cursor: "pointer", marginLeft: "-10px" }}
              ></img>{" "}
            </Grid>
            {globalFormError && (
              <Grid item xs={12}>
                <Alert severity="error">{globalFormError}</Alert>
              </Grid>
            )}
            {!success && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={LOCALE.email}
                    value={email}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleForgotPassword();
                      }
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      height: "48px",
                      borderRadius: "4px",
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      handleForgotPassword();
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ height: "48px" }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {LOCALE.next.toLowerCase()}
                    </Typography>
                  </Button>
                </Grid>
              </>
            )}
            {success && (
              <Grid item xs={12}>
                <Alert severity="success">
                  {LOCALE.forgotPasswordSuccessCheckYourEmail}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <a
                style={{
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                  textDecoration: "underline",
                }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                {LOCALE.login}
              </a>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: { xs: "265px", md: "385px" },
                textAlign: "center",
                color: theme.palette.lightGray.main,
              }}
            >
              &copy; Asta Adria 2024
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4.75} xl={5}></Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
