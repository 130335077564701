import { Icon as IconViber, Icon as IconWhatsApp } from "@iconify/react";
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { moment } from "../../App";
import { useTheme } from "@emotion/react";
import { Close, Mail } from "@mui/icons-material";
import AstaAdriaLogo from "../../assets/img/Asta-Adria-logo.svg";
import { defaultStyles, FileIcon } from "react-file-icon";

export function ClientTicketCommunication({
  messages,
  ticket,
  openUpload,
  handleDownloadMessageAttachment,
}) {
  const theme = useTheme();
  const [expandedIndex, setExpandedIndex] = useState(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleExpandClick = (index) => {
    if (expandedIndex === index) {
      return;
    }
    setExpandedIndex(index);
  };

  const handleCloseClick = (index, event) => {
    event.stopPropagation();
    setExpandedIndex(null);
  };

  return (
    <List
      sx={{
        width: "100%",
        maxHeight: openUpload
          ? { md: "440px", xs: "350px" }
          : { md: "540px", xs: "450px" },
        height: openUpload
          ? { md: "440px", xs: "350px" }
          : { md: "540px", xs: "450px" },
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "0px",
          background: "transparent",
        },
        scrollbarWidth: "none",
      }}
    >
      {messages?.map((message, index) => {
        const isClient = ticket?.bookedByClientOnWeb
          ? message?.createdBy?.id === ticket?.createdBy?.id
          : ticket?.organization?.type === "CLIENT" &&
            message?.createdBy != null;

        const isExpanded = expandedIndex === index;

        return (
          <React.Fragment key={index}>
            <ListItem
              alignItems="center"
              sx={{
                flexDirection: isClient ? "row-reverse" : "row",
                border: "1px solid #c4c4c4",
                borderRadius: "5px",
                marginBottom: "15px",
                cursor: isExpanded ? "" : "pointer",
                padding: "10px",
                height: isExpanded
                  ? "auto"
                  : isSmallScreen &&
                    message?.ticketMessageAttachments?.length > 0
                  ? "160px"
                  : !isSmallScreen &&
                    message?.ticketMessageAttachments?.length > 0
                  ? "150px"
                  : "100px",
                minHeight: "100px",
              }}
              onClick={() => handleExpandClick(index)}
            >
              <ListItemAvatar
                style={{
                  marginLeft: "10px",
                  display: "inline-block",
                  verticalAlign: "top",
                  height: 40,
                  width: 40,
                }}
              >
                {!message?.type.startsWith("MAIL") &&
                !message?.type.startsWith("VIBER") &&
                !message?.type.startsWith("WHATSAPP") ? (
                  <Avatar
                    alt=""
                    sx={{
                      borderRadius: "6px",
                      position: "absolute",
                      top: 15,
                    }}
                  >
                    {!isClient ? (
                      <img
                        src={AstaAdriaLogo}
                        alt="Asta Logo"
                        width="30"
                        height="30"
                      />
                    ) : (
                      <>
                        {`${message?.createdBy?.firstName?.charAt(
                          0
                        )}${message?.createdBy?.lastName?.charAt(0)}`}
                      </>
                    )}
                  </Avatar>
                ) : (
                  <Avatar
                    alt=""
                    sx={{
                      borderRadius: "6px",
                      position: "absolute",
                      top: 15,
                    }}
                  >
                    <img
                      src={AstaAdriaLogo}
                      alt="Asta Logo"
                      width="30"
                      height="30"
                    />
                    {/* {`${message?.sender?.charAt(0)}`} */}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    <Box sx={{ alignItems: "center" }}>
                      {message?.type.startsWith("VIBER") ? (
                        <IconViber
                          style={{
                            color: "#8875f0",
                            fontSize: 22,
                            marginRight: "5px",
                          }}
                          icon="fa-brands:viber"
                        />
                      ) : message?.type.startsWith("WHATSAPP") ? (
                        <IconWhatsApp
                          style={{
                            color: "#2ccc47",
                            fontSize: 22,
                            marginRight: "5px",
                          }}
                          icon="fa-brands:whatsapp"
                        />
                      ) : message?.type.startsWith("MAIL") ? (
                        <Mail
                          style={{
                            color: theme.palette.primary.main,
                            fontSize: 20,
                            marginRight: "5px",
                          }}
                        />
                      ) : (
                        ""
                      )}
                      <span
                        style={{
                          marginLeft:
                            message?.type.startsWith("VIBER") ||
                            message?.type.startsWith("WHATSAPP")
                              ? "5px"
                              : "0px",
                        }}
                      >
                        {message?.createdBy
                          ? `${message?.createdBy?.firstName} ${message?.createdBy?.lastName}`
                          : `${message?.sender}`}
                      </span>
                    </Box>
                  </Typography>
                }
                secondary={
                  <Typography component="div">
                    <Typography
                      sx={{
                        display: "inline",
                        fontWeight: "light",
                      }}
                      component="span"
                      variant="h6"
                      color={theme.palette.lightGray.main}
                    >
                      {`${moment(message?.dateCreated).format(
                        "DD.MM.YYYY HH:mm"
                      )} (${moment(message?.dateCreated).fromNow()})`}{" "}
                      {message?.type === "NOTE" ? (
                        <Chip
                          size="small"
                          label={"Internal note"}
                          color="error"
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "-5px",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Typography>
                    <br />
                    <Typography
                      variant="h5"
                      style={{
                        color: theme.palette.darkContrast.main,
                        fontWeight: 500,
                        marginTop: "10px",
                        marginLeft: isClient ? "0px" : "-55px",
                        marginRight: isClient ? "-45px" : "0px",
                        lineHeight: "16px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          {isExpanded
                            ? message?.message
                            : `${message?.message?.slice(0, 50)}...`}
                        </Grid>
                        {message?.ticketMessageAttachments?.length > 0 && (
                          <Grid item xs={12} pt={2}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: isClient ? "end" : "start",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {message?.ticketMessageAttachments?.map(
                                  (attachment) => (
                                    <Box
                                      sx={{
                                        position: "relative",
                                        transition:
                                          "transform 0.2s, box-shadow 0.2s",
                                        cursor: "pointer",
                                        "&:hover": {
                                          boxShadow:
                                            "0px 6px 10px rgba(0, 0, 0, 0.15)",
                                        },
                                      }}
                                    >
                                      <Card
                                        key={attachment.id}
                                        sx={{
                                          width: "200px",
                                          height: "40px",
                                          border: "1px solid",
                                          borderColor: theme.palette.divider,
                                          borderRadius: 2,
                                          boxShadow:
                                            "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          padding: 1,
                                          overflow: "hidden",
                                        }}
                                        onClick={() =>
                                          handleDownloadMessageAttachment(
                                            attachment
                                          )
                                        }
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "20px",
                                            height: "20px",
                                            backgroundColor:
                                              theme.palette.grey[200],
                                            borderRadius: "50%",
                                          }}
                                        >
                                          <FileIcon
                                            extension={attachment.fileExtension.replace(
                                              ".",
                                              ""
                                            )}
                                            {...defaultStyles[
                                              attachment.fileExtension.replace(
                                                ".",
                                                ""
                                              )
                                            ]}
                                          />
                                        </Box>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            flex: 1,
                                            marginLeft: 1,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            textAlign: "center",
                                          }}
                                          title={attachment.fileName}
                                        >
                                          {attachment.fileName.split(".")[0]
                                            .length > 20
                                            ? `${attachment.fileName
                                                .split(".")[0]
                                                .substring(0, 20)}...`
                                            : attachment.fileName.split(".")[0]}
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          color="text.secondary"
                                          sx={{
                                            marginLeft: 1,
                                            minWidth: "30px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {attachment.fileExtension
                                            .replace(".", "")
                                            .toUpperCase()}
                                        </Typography>
                                      </Card>
                                    </Box>
                                  )
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Typography>
                  </Typography>
                }
                sx={{
                  textAlign: isClient ? "right" : "left",
                }}
              />
              {isExpanded && (
                <IconButton
                  onClick={(event) => handleCloseClick(index, event)}
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: isClient ? "auto" : 5,
                    left: isClient ? 5 : "auto",
                  }}
                >
                  <Close
                    sx={{
                      border: "1px solid #c4c4c4",
                      borderRadius: "5px",
                      height: 24,
                      width: 24,
                      padding: "5px",
                    }}
                  />
                </IconButton>
              )}
            </ListItem>
          </React.Fragment>
        );
      })}
    </List>
  );
}
