import {
  Badge,
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  Skeleton,
  Paper,
  alpha,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { moment } from "../../App";
import { useTheme } from "@emotion/react";
import {
  AttachFile,
  AttachFileOutlined,
  Chat,
  ChatOutlined,
  ChecklistRtl,
  ChecklistRtlOutlined,
  ConfirmationNumber,
  ConfirmationNumberOutlined,
  Route,
  RouteOutlined,
} from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import { ClientTicketCommunication } from "../tickets/ClientTicketCommunication";
import { ClientTicketNewMessageDialog } from "../tickets/ClientTicketNewMessageDialog";
import { ClientTicketLogContent } from "../tickets/ClientTicketLogContent";
import ClientTicketPreviewOpenLayer from "../map/ClientTicketPreviewOpenLayer";
import { ClientTicketContentMobile } from "./ClientTicketContentMobile";
import { ClientTicketAttachments } from "../tickets/ClientTicketAttachments";

export function ClientTicketTabsMobile({
  data,
  lifeCycleMessages,
  messages,
  addNewMessage,
  newMessage,
  setNewMessage,
  dialogMessageType,
  loading,
  loadLifeCycleMessages,
  handleChangeMessageData,
  messageTemplates,
  setData,
  toCityCallback,
  setToCityCallback,
  handleChangeData,
  availableDepartures,
  readyForInvoice,
  checkIfLocked,
  invoiced,
  handleDownloadAttachment,
  downloadAttachments,
  downloadMessageAttachments,
  files,
  setFiles,
  chatDocument,
  setChatDocument,
  handleUploadDocument,
  handleOnProcessFile,
  openUpload,
  setOpenUpload,
  handleDownloadMessageAttachment,
  messageAttachments,
}) {
  const theme = useTheme();
  const [communicationTabValue, setCommunicationTabValue] = useState("1");

  const handleCommunicationTabChange = (event, newValue) => {
    setCommunicationTabValue(newValue);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Paper className="roundedBorder" sx={{ flex: 1, overflowY: "auto" }}>
        <TabContext value={communicationTabValue}>
          <TabPanel value="1">
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "block", md: "none" },
                height: "71.5vh",
                overflow: "scroll",
                scrollbarWidth: "none",
              }}
            >
              <ClientTicketContentMobile
                data={data}
                setData={setData}
                readyForInvoice={readyForInvoice}
                checkIfLocked={checkIfLocked}
                invoiced={invoiced}
                availableDepartures={availableDepartures}
                selectedDeparture={{
                  productDestinationId: data?.productDestination?.id,
                  priceId: data?.productPrice?.id,
                  departureDate: moment(data?.departureDay),
                  arrivalDay: data?.productDestinationDeparture?.arrivalDay,
                  ticketSurchargeList: data?.ticketSurcharges,
                  productDestinationDepartureId:
                    data?.productDestinationDeparture?.id,
                  departureTime: data?.departureHour,
                  arrivalTime: data?.productDestinationDeparture?.arrivalHour,
                }}
              />
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "-15px",
                  height: "73.5vh",
                }}
              >
                <Grid item xs={12}>
                  {messages ? (
                    <>
                      <ClientTicketCommunication
                        messages={messages}
                        ticket={data}
                        openUpload={openUpload}
                        handleDownloadMessageAttachment={
                          handleDownloadMessageAttachment
                        }
                      />
                      <Divider sx={{ paddingBottom: "5px" }} />
                    </>
                  ) : (
                    <>
                      <Skeleton height={60} />
                      <Skeleton height={60} />
                      <Skeleton height={60} />
                      <Skeleton height={60} />
                      <Skeleton height={60} />
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {messages ? (
                    <ClientTicketNewMessageDialog
                      type={dialogMessageType}
                      addNewMessage={addNewMessage}
                      setNewMessage={setNewMessage}
                      message={newMessage}
                      handleChangeMessageData={handleChangeMessageData}
                      loading={loading}
                      messageTemplates={messageTemplates}
                      files={files}
                      setFiles={setFiles}
                      chatDocument={chatDocument}
                      setChatDocument={setChatDocument}
                      handleUploadDocument={handleUploadDocument}
                      handleOnProcessFile={handleOnProcessFile}
                      openUpload={openUpload}
                      setOpenUpload={setOpenUpload}
                    />
                  ) : (
                    <>
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="3">
            <Grid container spacing={2} sx={{ height: "73.5vh" }}>
              <Grid item xs={12}>
                {lifeCycleMessages ? (
                  <ClientTicketLogContent
                    data={data}
                    lifeCycleMessages={lifeCycleMessages}
                    loadLifeCycleMessages={loadLifeCycleMessages}
                    loading={loading}
                  />
                ) : (
                  <>
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                    <Skeleton height={40} />
                  </>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="4" sx={{ padding: 0, marginTop: "-31px" }}>
            <Grid container spacing={2} sx={{ height: "81vh" }}>
              <Grid item xs={12}>
                <ClientTicketPreviewOpenLayer
                  handleChangeData={handleChangeData}
                  setData={setData}
                  data={data}
                  fromCity={data?.fromCity}
                  toCity={data?.toCity}
                  toCityCallback={toCityCallback}
                  setToCityCallback={(value) => setToCityCallback(value)}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="5" sx={{ padding: 0, marginTop: "-31px" }}>
            <Grid container spacing={2} sx={{ height: "81vh" }}>
              <ClientTicketAttachments
                data={data}
                handleDownloadAttachment={handleDownloadAttachment}
                handleDownloadMessageAttachment={
                  handleDownloadMessageAttachment
                }
                downloadAttachments={downloadAttachments}
                downloadMessageAttachments={downloadMessageAttachments}
                messageAttachments={messageAttachments}
              />
            </Grid>
          </TabPanel>
        </TabContext>
      </Paper>
      <Tabs
        value={communicationTabValue}
        onChange={handleCommunicationTabChange}
        aria-label="Communication tabs"
        sx={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "15px",
          backgroundColor: theme.palette.background.paper,
          //clipPath: "inset(0 round 1em)",
          borderRadius: "5px",
        }}
        indicatorColor="none"
        textColor={theme.palette.darkContrast.main}
        // variant="scrollable"
        variant="fullWidth"
        // scrollButtons="auto"
      >
        <Tab
          value="1"
          label={
            <>
              <Grid container>
                <Grid item xs={12} sx={{ alignContent: "center" }}>
                  {communicationTabValue == "1" ? (
                    <>
                      <ConfirmationNumber />
                      <Typography
                        sx={{ fontWeight: "light", fontSize: "10px" }}
                      >
                        {"Booking".toLowerCase()}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <ConfirmationNumberOutlined />
                      <Typography
                        sx={{ fontWeight: "light", fontSize: "10px" }}
                      >
                        {"Booking".toLowerCase()}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          }
          sx={{
            // flex: "0 0 70px",
            backgroundColor:
              communicationTabValue === "1"
                ? theme.palette.secondary.main
                : "transparent",
            color:
              communicationTabValue === "1"
                ? "white"
                : theme.palette.darkContrast.main,
            fontSize: "20px",
            fontWeight: "bold",
            textTransform: "capitalize",
            height: "48px",
            minWidth: "70px",
          }}
        />
        <Tab
          value="2"
          label={
            <>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ alignContent: "center", marginTop: "5px" }}
                >
                  {communicationTabValue == "2" ? (
                    <>
                      <Badge
                        badgeContent={
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                          >
                            {messages ? messages.length : "0"}
                          </Typography>
                        }
                        color="primary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          "& .MuiBadge-badge": {
                            borderRadius: 1,
                            height: 16,
                            minWidth: 14,
                          },
                          color: `1px solid ${alpha(
                            theme.palette.primary.main,
                            0.5
                          )}`,
                        }}
                      >
                        <Chat />
                      </Badge>
                      <Typography
                        sx={{
                          fontWeight: "light",
                          fontSize: "10px",
                        }}
                        mt={0.3}
                      >
                        {"Chat".toLowerCase()}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Badge
                        badgeContent={
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {messages ? messages.length : "0"}
                          </Typography>
                        }
                        color="primary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          "& .MuiBadge-badge": {
                            borderRadius: 1,
                            height: 16,
                            minWidth: 14,
                          },
                          color: `1px solid ${alpha(
                            theme.palette.primary.main,
                            0.5
                          )}`,
                        }}
                      >
                        <ChatOutlined />
                      </Badge>
                      <Typography
                        sx={{
                          fontWeight: "light",
                          fontSize: "10px",
                        }}
                        mt={0.3}
                      >
                        {"Chat".toLowerCase()}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          }
          sx={{
            // flex: "0 0 70px",
            backgroundColor:
              communicationTabValue === "2"
                ? theme.palette.secondary.main
                : "transparent",
            color:
              communicationTabValue === "2"
                ? "white"
                : theme.palette.darkContrast.main,
            borderRight: "1px solid #c4c4c4",
            borderLeft: "1px solid #c4c4c4",
            fontWeight: "bold",
            textTransform: "capitalize",
            height: "48px",
            minWidth: "70px",
          }}
        />
        <Tab
          value="3"
          label={
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ alignContent: "center" }}>
                  {communicationTabValue == "3" ? (
                    <>
                      <Badge
                        badgeContent={
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: "10px" }}
                          >
                            {lifeCycleMessages ? lifeCycleMessages.length : "0"}
                          </Typography>
                        }
                        color="primary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          "& .MuiBadge-badge": {
                            borderRadius: 1,
                            height: 16,
                            minWidth: 14,
                          },
                          color: `1px solid ${alpha(
                            theme.palette.primary.main,
                            0.5
                          )}`,
                        }}
                      >
                        <ChecklistRtl />
                      </Badge>
                      <Typography
                        sx={{
                          fontWeight: "light",
                          fontSize: "10px",
                          position: "relative",
                          paddingTop: "18px",
                          marginLeft: "-5px",
                        }}
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: 1,
                            left: 0,
                            right: 0,
                            width: "50px",
                          }}
                        >
                          {"Booking log".toLowerCase()}
                        </span>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Badge
                        badgeContent={
                          <Typography
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {lifeCycleMessages ? lifeCycleMessages.length : "0"}
                          </Typography>
                        }
                        color="primary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{
                          "& .MuiBadge-badge": {
                            borderRadius: 1,
                            height: 16,
                            minWidth: 14,
                          },
                          color: `1px solid ${alpha(
                            theme.palette.primary.main,
                            0.5
                          )}`,
                        }}
                      >
                        <ChecklistRtlOutlined />
                      </Badge>
                      <Typography
                        sx={{
                          fontWeight: "light",
                          fontSize: "10px",
                          position: "relative",
                          paddingTop: "18px",
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: 1,
                            left: 0,
                            right: 0,
                            minWidth: "50px",
                          }}
                        >
                          {"Booking log".toLowerCase()}
                        </span>
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          }
          sx={{
            // flex: "0 0 70px",
            backgroundColor:
              communicationTabValue === "3"
                ? theme.palette.secondary.main
                : "transparent",
            color:
              communicationTabValue === "3"
                ? "white"
                : theme.palette.darkContrast.main,
            borderRight: "1px solid #c4c4c4",
            fontSize: "20px",
            fontWeight: "bold",
            textTransform: "capitalize",
            height: "48px",
            minWidth: "70px",
          }}
        />
        <Tab
          value="4"
          label={
            <>
              <Grid container>
                <Grid item xs={12} sx={{ alignContent: "center" }}>
                  {communicationTabValue == "4" ? (
                    <>
                      <Route />
                      <Typography
                        sx={{ fontWeight: "light", fontSize: "10px" }}
                      >
                        {"Map".toLowerCase()}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <RouteOutlined />
                      <Typography
                        sx={{
                          color: theme.palette.lightGray.main,
                          fontWeight: "light",
                          fontSize: "10px",
                        }}
                      >
                        {"Map".toLowerCase()}{" "}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          }
          sx={{
            // flex: "0 0 70px",
            backgroundColor:
              communicationTabValue === "4"
                ? theme.palette.secondary.main
                : "transparent",
            color:
              communicationTabValue === "4"
                ? "white"
                : theme.palette.darkContrast.main,
            borderRight: "1px solid #c4c4c4",
            fontSize: "20px",
            fontWeight: "bold",
            textTransform: "capitalize",
            height: "48px",
            minWidth: "70px",
          }}
        />
        <Tab
          value="5"
          label={
            <>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {communicationTabValue == "5" ? (
                    <>
                      <AttachFile />
                      <Typography
                        sx={{
                          fontWeight: "light",
                          fontSize: "10px",
                          marginLeft: "-5px",
                        }}
                      >
                        {"Attachments".toLowerCase()}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <AttachFileOutlined />
                      <Typography
                        sx={{
                          color: theme.palette.lightGray.main,
                          fontWeight: "light",
                          fontSize: "10px",
                          marginLeft: "-5px",
                        }}
                      >
                        {"Attachments".toLowerCase()}{" "}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          }
          sx={{
            // flex: "0 0 70px",
            backgroundColor:
              communicationTabValue === "5"
                ? theme.palette.secondary.main
                : "transparent",
            color:
              communicationTabValue === "5"
                ? "white"
                : theme.palette.darkContrast.main,
            fontSize: "20px",
            fontWeight: "bold",
            textTransform: "capitalize",
            height: "48px",
            minWidth: "75px",
          }}
        />
      </Tabs>
    </Box>
  );
}
