import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Alert,
  IconButton,
  Tooltip,
  Icon,
  Box,
} from "@mui/material";
import { AttachFile, SendOutlined } from "@mui/icons-material";
import { LOCALE } from "../../properties/Locale";
import { FilePond } from "react-filepond";

export function ClientTicketNewMessageDialog({
  message,
  setNewMessage,
  addNewMessage,
  handleChangeMessageData,
  loading,
  type,
  messageTemplates,
  files,
  setFiles,
  chatDocument,
  setChatDocument,
  handleUploadDocument,
  handleOnProcessFile,
  openUpload,
  setOpenUpload,
}) {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    if (selectedTemplate != null) {
      let template = messageTemplates.find(
        (template) => template.id === selectedTemplate
      );

      let tmp = { ...message };
      let localization = template?.messageTemplateLocalizations?.find(
        (x) => x.language === localStorage.getItem("locale")
      );
      tmp.message = localization?.content;
      tmp.type = type;
      setNewMessage(tmp);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    console.log("messageTemplates", messageTemplates);
    if (messageTemplates && messageTemplates.length > 0) {
      console.log(messageTemplates[0].id);
      setSelectedTemplate(messageTemplates[0].id);
    }
  }, [messageTemplates]);

  useEffect(() => {
    if (!message?.message || message.message === "") {
      setOpenUpload(false);
    }
  }, [message]);

  return (
    <Grid container spacing={2}>
      {messageTemplates && messageTemplates.length > 0 ? (
        <>
          {/* <Grid item xs={12}>
            <Alert
              severity="info"
              sx={{ borderRadius: "5px", fontSize: { md: "14px", xs: "11px" } }}
            >
              Please choose the correct reason below. The priority of the
              message will be calculated based on the reason. Every reason has
              its own message template. You can leave it as it is or add
              additional details. Thanks!
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel>Select reason</InputLabel>
              <Select
                size="small"
                value={selectedTemplate ? selectedTemplate : ""}
                label="Select reason"
                onChange={(e) => setSelectedTemplate(e.target.value)}
              >
                {messageTemplates.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              multiline
              rows={5}
              fullWidth
              disabled={selectedTemplate == null || loading}
              label={<span style={{ fontStyle: "italic" }}>Your Message</span>}
              value={message?.message ? message?.message : ""}
              onChange={(e) =>
                handleChangeMessageData("message", e.target.value)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color={type === "CLIENT" ? "primary" : "warning"}
                      onClick={() => {
                        addNewMessage();
                        setOpenUpload(false);
                      }}
                      disabled={
                        loading || !message?.message || message.message === ""
                      }
                    >
                      <SendOutlined sx={{ width: 25, height: 25 }} />
                    </IconButton>
                    <Tooltip title={LOCALE.attach_files}>
                      <IconButton
                        color={type === "CLIENT" ? "primary" : "warning"}
                        disabled={
                          loading || !message?.message || message.message === ""
                        }
                        onClick={() => setOpenUpload(!openUpload)}
                      >
                        <AttachFile sx={{ width: 23, height: 23 }} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {openUpload && (message?.message || message.message !== "") && (
            <Grid item xs={12}>
              <FilePond
                onprocessfile={handleOnProcessFile}
                files={files}
                onupdatefiles={setFiles}
                onremovefile={() => {
                  setChatDocument(null);
                }}
                allowMultiple={false}
                acceptedFileTypes={["image/*", "application/pdf"]}
                maxFiles={1}
                maxFileSize="5MB"
                allowFileSizeValidation={true}
                maxTotalFileSize="25MB"
                server={{
                  process: (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort
                  ) => {
                    try {
                      handleUploadDocument(file, setChatDocument);
                      load(file);
                    } catch (err) {
                      error("An error occurred during file processing");
                    }
                  },
                  revert: null,
                  restore: null,
                  load: null,
                }}
                name="file"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              />
            </Grid>
          )}
        </>
      ) : (
        <Grid item md={12}>
          <Alert
            severity="warning"
            sx={{ borderRadius: "5px", fontSize: "14px" }}
          >
            There are no message templates available for the current status of
            the ticket.
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}
