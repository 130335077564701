import * as React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Icon, Badge, Grid, Divider, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { moment } from "../App";
import { NotificationsRepository } from "../repositories/NotificationsRepository";
import { useNavigate } from "react-router-dom";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { ThemeContext, useTheme } from "@emotion/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Close, MarkChatRead, MarkChatReadOutlined } from "@mui/icons-material";
export default function NotificationsMenu({
  notifications,
  unreadNotifications,
  fetchMoreNotifications,
  getNotifications,
  getUnreadNotifications,
  setPage,
  hasMore,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const openNotification = (notification) => {
    NotificationsRepository.markAsRead(notification.id).then(() => {
      setPage(0);
      getNotifications(0);
      getUnreadNotifications();
    });
    if (notification.relId !== null && notification.relId !== undefined) {
      navigate(
        `${resolveUrlByNotificationType(notification.type)}${
          notification.relId
        }`
      );
    } else {
      navigate(`${resolveUrlByNotificationType(notification.type)}`);
    }
  };

  const calculateUnreadNotifications = () => {
    let unreadNotificationsCounter = 0;
    unreadNotifications &&
      unreadNotifications.forEach((notification) => {
        if (!notification.read) {
          unreadNotificationsCounter++;
        }
      });
    return unreadNotificationsCounter;
  };

  const unreadCount = calculateUnreadNotifications();

  const resolveUrlByNotificationType = (type) => {
    if (type == "NEW_TICKET_CREATED_BY_AGENT") {
      return "/client/booking/0/15/%7B%7D/";
    } else if (type == "NEW_TICKET_MESSAGE_FROM_AGENT") {
      return "/client/booking/0/15/%7B%7D/";
    } else if (type == "NEW_TICKET_STATUS_MESSAGE_FOR_CLIENT") {
      return "/client/booking/0/15/%7B%7D/";
    }
  };

  const styleUnreadNotification = (content) => {
    const messageRegex = /\bmessage\b/gi;
    const nameRegex = /(\b[A-Z][a-z]*\b)\s(\b[A-Z][a-z]*\b)/g;
    const destinationRegex = /destination\s(\b[\w-]+\b)/gi;
    let styledContent = content
      .replace(
        messageRegex,
        (match) =>
          `<strong><u><span style="color: ${theme.palette.primary.main};">${match}</span></u></strong>`
      )
      .replace(
        nameRegex,
        (match, firstName, lastName) =>
          `<strong><u><span style="color: ${theme.palette.primary.main};">${firstName} ${lastName}</span></u></strong>`
      )
      .replace(
        destinationRegex,
        (match, destinationName) =>
          `<strong><u><span style="color: ${
            theme.palette.primary.main
          };">${destinationName.replace(/-/g, " ")}</span></u></strong>`
      );

    return `<strong>${styledContent}</strong>`;
  };

  const styleReadNotification = (content) => {
    const messageRegex = /\bmessage\b/gi;
    const nameRegex = /(\b[A-Z][a-z]*\b)\s(\b[A-Z][a-z]*\b)/g;
    const destinationRegex = /destination\s([\w\s-]+)/gi;

    return content
      .replace(
        messageRegex,
        (match) =>
          `<span style="color: ${theme.palette.primary.main};">${match}</span>`
      )
      .replace(
        nameRegex,
        (match, firstName, lastName) =>
          `<span style="color: ${theme.palette.primary.main};">${firstName} ${lastName}</span>`
      )
      .replace(
        destinationRegex,
        (match, destinationName) =>
          `<span style="color: ${
            theme.palette.primary.main
          };">${destinationName.replace(/-/g, " ")}</span>`
      );
  };

  const markAllNotificationsAsRead = () => {
    NotificationsRepository.markAllAsRead(unreadNotifications).then(() => {
      setPage(0);
      getNotifications(0);
      getUnreadNotifications();
    });
  };

  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title="Notifications">
          <IconButton
            onClick={handleClick}
            style={{
              width: isSmallScreen && "45px",
              height: isSmallScreen && "45px",
              border: `1px solid rgba(128, 128, 128, 0.5)`,
              borderRadius: "4px",
              padding: "5px",
              backgroundColor: !isMenuOpen
                ? "transparent"
                : theme.palette.primary.main,
            }}
          >
            {isMenuOpen ? (
              <CloseIcon
                sx={{
                  fontSize: isSmallScreen ? "30px" : "23px",
                  color: "white",
                }}
              />
            ) : (
              <NotificationsNoneIcon
                sx={{
                  fontSize: isSmallScreen ? "30px" : "23px",
                  color: theme.palette.primary.main,
                }}
              />
            )}
          </IconButton>
        </Tooltip>
        {!isMenuOpen && (
          <Badge
            badgeContent={calculateUnreadNotifications()}
            color="primary"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{
              borderRadius: 0,
              position: "absolute",
              bottom: isSmallScreen ? "5px" : "0",
              right: isSmallScreen ? "3px" : "0",
              "& .MuiBadge-badge": {
                borderRadius: 1,
                fontSize: "12px",
              },
            }}
          />
        )}
      </div>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            overflowX: "hidden",
            // maxHeight: "600px",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.32)",
            width: isSmallScreen ? "100vw" : "auto",
            height: isSmallScreen ? "100vh" : "auto",
            position: isSmallScreen ? "fixed" : "absolute",
            left: isSmallScreen ? 0 : "auto",
            top: isSmallScreen ? 0 : "auto",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div
          id="scrollableConversationsDiv"
          style={{
            height: isSmallScreen
              ? "550px"
              : notifications?.length > 5
              ? "450px"
              : "350px",
            minHeight: "750px",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InfiniteScroll
            dataLength={notifications?.length}
            next={fetchMoreNotifications}
            hasMore={hasMore}
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
            scrollableTarget="scrollableConversationsDiv"
          >
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Typography
                  variant="h6"
                  key="2"
                  color="text.primary"
                  style={{ fontWeight: "bold", padding: "10px" }}
                >
                  {unreadCount > 0
                    ? `${unreadCount} Unread Notifications`
                    : "No Unread Notifications"}
                </Typography>
              </Grid>
              {unreadCount > 0 && (
                <Grid
                  item
                  xs={3}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Tooltip arrow title="Mark all as read">
                    <IconButton onClick={() => markAllNotificationsAsRead()}>
                      <MarkChatReadOutlined />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>

            {notifications && notifications.length > 0 ? (
              notifications.map((notification) => (
                <MenuItem
                  key={notification.id}
                  style={{
                    width: isSmallScreen ? "90vw" : "250px",
                    height: isSmallScreen ? "65px" : "65px",
                    borderBottom: "1px solid lightGray",
                  }}
                  onClick={() => {
                    openNotification(notification);
                  }}
                >
                  <Grid container>
                    <Grid item md={notification.read ? 12 : 11}>
                      <Typography
                        variant="caption"
                        style={{ color: theme.palette.lightGray.main }}
                      >
                        <b>
                          {moment(notification.dateCreated).format(
                            "DD.MM.YYYY HH:mm"
                          )}
                        </b>
                      </Typography>
                      {!notification.read ? (
                        <Typography
                          variant="body2"
                          style={{ whiteSpace: "pre-wrap" }}
                          dangerouslySetInnerHTML={{
                            __html: styleUnreadNotification(
                              notification.content
                            ),
                          }}
                        ></Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          style={{ whiteSpace: "pre-wrap" }}
                          dangerouslySetInnerHTML={{
                            __html: styleReadNotification(notification.content),
                          }}
                        ></Typography>
                      )}
                    </Grid>
                    {/* {!notification.read && (
                    <Grid
                      item
                      md={1}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Badge
                        variant="dot"
                        color="primary"
                        sx={{
                          "& .MuiBadge-badge": {
                            height: 14,
                            width: 14,
                            borderRadius: "50%",
                          },
                        }}
                      />
                    </Grid>
                  )} */}
                  </Grid>
                </MenuItem>
              ))
            ) : (
              <MenuItem style={{ width: "400px" }}>
                <Divider></Divider>
                <Typography
                  variant="h6"
                  key="2"
                  color="text.primary"
                  style={{
                    fontWeight: "bold",
                    padding: "10px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  There are no notifications
                </Typography>
              </MenuItem>
            )}
          </InfiniteScroll>
        </div>
      </Menu>
    </React.Fragment>
  );
}
