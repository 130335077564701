import { Button, Grid, TextField, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { triggerRerender } from "../common/CommonActions";
import { LOCALE } from "../properties/Locale";
import { AuthRepository } from "../repositories/AuthRepository";
import { useTheme } from "@emotion/react";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [globalFormError, setGlobalFormError] = React.useState();
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleLogin = () => {
    setLoading(true);
    AuthRepository.fetchToken({ username: username, password: password }).then(
      (res) => {
        window.localStorage.setItem("jwt", res?.data?.jwt);
        dispatch(triggerRerender());
        if (AuthRepository.hasAnyRole("ROLE_ASTA_ADRIA_AGENT")) {
          navigate(
            `/client/bookings/0/15/${JSON.stringify({
              status: "ALL_ACTIVE",
            })}/dateCreated/DESC`
          );
        } else {
          navigate(
            `/client/bookings/0/15/${JSON.stringify({
              status: "ALL_ACTIVE",
            })}/dateCreated/DESC`
          );
        }
        setLoading(false);
      },
      (err) => {
        console.log(err);
        console.log(err.response);
        setGlobalFormError(err.response?.data);
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Grid
        container
        sx={{
          height: "87vh",
        }}
      >
        <Grid item xs={4} sm={4} md={4} lg={4.75} xl={5}></Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={2.5}
          xl={2}
          style={{
            marginTop: "100px",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", marginBottom: "24px" }}
            >
              <img
                alt=""
                src={require("../assets/img/new_logo.png")}
                width="280px"
                onClick={() => navigate("/")}
                style={{ cursor: "pointer", marginLeft: "-10px" }}
              ></img>{" "}
            </Grid>
            {globalFormError && (
              <Grid item xs={12}>
                <Alert severity="error">{globalFormError}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mail"
                value={username}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                onChange={(e) => setUsername(e.target.value)}
                style={{
                  height: "48px",
                  borderRadius: "4px",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={LOCALE.password}
                type="password"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                style={{
                  height: "48px",
                  borderRadius: "4px",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={() => {
                  handleLogin();
                }}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ height: "48px" }}
              >
                <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
                  {LOCALE.login.toLowerCase()}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <a
                style={{
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                  textDecoration: "underline",
                }}
                onClick={() => {
                  navigate("/forgotPassword");
                }}
              >
                {LOCALE.forgotPassword}
              </a>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: { xs: "200px", md: "320px" },
                textAlign: "center",
                color: theme.palette.lightGray.main,
              }}
            >
              &copy; Asta Adria 2024
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4.75} xl={5}></Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
