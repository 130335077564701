import { Button, Grid, TextField, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { notifyShowSuccessMessage } from "../common/CommonActions";
import { ErrorMessageResolver } from "../common/ErrorMessageResolver";
import { LOCALE } from "../properties/Locale";
import { UsersRepository } from "../repositories/UsersRepository";
import { ForgotPasswordResetValidator } from "./PasswordValidator";
import { useTheme } from "@emotion/react";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [globalFormError, setGlobalFormError] = React.useState();
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [loading, setLoading] = useState();
  const [step, setStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [networkErrorMessage, setNetworkErrorMessage] = useState("");
  const [data, setData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  React.useEffect(() => {
    if (searchParams.get("token")) {
      setLoading(true);
      UsersRepository.forgotPasswordCheck(searchParams.get("token")).then(
        (res) => {
          if (res.data) {
            setStep(1);
          } else {
            setStep(3);
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          console.log(err);
        }
      );
    }
  }, [searchParams]);

  const handleResetPassword = () => {
    setLoading(true);
    validateData(data);
    if (ForgotPasswordResetValidator.isValidSync(data)) {
      UsersRepository.forgotPasswordReset(
        searchParams.get("token"),
        data.newPassword
      ).then(
        (response) => {
          setLoading(false);
          dispatch(notifyShowSuccessMessage(LOCALE.password_reset_success));
          navigate("/login");
        },
        (err) => {
          console.log(err);
          setNetworkErrorMessage(ErrorMessageResolver.resolve(err));
          setLoading(false);
        }
      );
    }
  };

  const validateData = (data) => {
    if (!ForgotPasswordResetValidator.isValidSync(data)) {
      var validationErrors = {};
      (async () => {
        await ForgotPasswordResetValidator.validate(data, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });

          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  return (
    <>
      <Grid container sx={{ height: "87vh" }}>
        <Grid item xs={12} sm={4} md={4} lg={4.5} xl={5.25}></Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={3}
          xl={2.5}
          style={{
            marginTop: "100px",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", marginBottom: "24px" }}
            >
              <img
                alt=""
                src={require("../assets/img/new_logo.png")}
                width="280px"
                style={{ cursor: "pointer", marginLeft: "-10px" }}
              ></img>{" "}
            </Grid>
            {globalFormError && (
              <Grid item xs={12}>
                <Alert severity="error">{globalFormError}</Alert>
              </Grid>
            )}
            {step == 1 && (
              <>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    label={LOCALE.new_password}
                    name="new_password"
                    variant="outlined"
                    fullWidth
                    value={data.newPassword ? data.newPassword : ""}
                    onChange={(e) =>
                      setData({ ...data, newPassword: e.target.value })
                    }
                    error={formDataErrors.newPassword ? true : false}
                    helperText={formDataErrors.newPassword}
                    style={{
                      height: "48px",
                      borderRadius: "4px",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    label={LOCALE.confirm_password}
                    name="confirm_password"
                    variant="outlined"
                    fullWidth
                    value={data.confirmPassword ? data.confirmPassword : ""}
                    onChange={(e) =>
                      setData({ ...data, confirmPassword: e.target.value })
                    }
                    error={formDataErrors.confirmPassword ? true : false}
                    helperText={formDataErrors.confirmPassword}
                    style={{
                      height: "48px",
                      borderRadius: "4px",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      handleResetPassword();
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ height: "48px" }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {LOCALE.next.toLowerCase()}
                    </Typography>
                  </Button>
                </Grid>
              </>
            )}
            {step == 2 && (
              <Grid item xs={12}>
                <Alert severity="success">
                  {LOCALE.password_reset_success}
                </Alert>
              </Grid>
            )}
            {step == 3 && (
              <Grid item xs={12}>
                <Alert severity="error">
                  {LOCALE.forgotPasswordLinkExpired}
                </Alert>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                marginTop: { xs: "300px ", md: "360px" },
                textAlign: "center",
                color: theme.palette.lightGray.main,
              }}
            >
              &copy; Asta Adria 2024
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4.5} xl={5.25}></Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
