import Axios from "axios";
import { SETTINGS } from "../properties/Settings";

export const NotificationsRepository = {
  get: () => {
    return Axios.get(`${SETTINGS.API_URL}notification/get`);
  },

  getUnread: () => {
    return Axios.get(`${SETTINGS.API_URL}notification/getUnread`);
  },

  getUnreadPageable: (page, size) => {
    return Axios({
      url: `${SETTINGS.API_URL}notification/getUnreadPageable`,
      method: "GET",
      params: {
        page: page,
        size: size,
      },
    });
  },

  getPageable: (page, size) => {
    return Axios({
      url: `${SETTINGS.API_URL}notification/getPageable`,
      method: "GET",
      params: {
        page: page,
        size: size,
      },
    });
  },

  getSettings: () => {
    return Axios.get(`${SETTINGS.API_URL}notification/settings/get`);
  },

  markAsRead: (id) => {
    return Axios.put(`${SETTINGS.API_URL}notification/${id}/read`);
  },

  markAllAsRead: (notifications) => {
    return Axios({
      url: `${SETTINGS.API_URL}notification/all/read`,
      method: "PUT",
      data: notifications,
    });
  },
  saveSettings: (notificationsSettingsData) => {
    return Axios({
      url: SETTINGS.API_URL + "notification/settings/save",
      method: "PUT",
      data: notificationsSettingsData,
    });
  },
};
